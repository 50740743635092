import { commonIcons, companyIcons, edgeIcons } from "assets/icons";
import Icon, { QuestionCircleOutlined } from "@ant-design/icons";
import { EdgeInfraType } from "types";

export function getAppEdgeLogo(edgeInfraType?: EdgeInfraType) {
  switch (edgeInfraType) {
    case EdgeInfraType.Amazon:
      return companyIcons.awsAppEdgeIcon;
    case EdgeInfraType.Azure:
      return companyIcons.azureAppEdgeIcon;
    case EdgeInfraType.Gcp:
      return companyIcons.googleCloudAppEdgeIcon;
    case EdgeInfraType.Oci:
      return companyIcons.oracleAppEdgeIcon;
    case EdgeInfraType.OnPrem:
      return edgeIcons.onPremiseEdgeIcon;
    default:
      return edgeIcons.multiCloudEdgeIcon;
  }
}

export function getEdgeLogo(edgeInfraType?: EdgeInfraType, mode?: boolean) {
  switch (edgeInfraType) {
    case EdgeInfraType.Amazon:
      return mode ? companyIcons.awsLogoSvg : companyIcons.awsDarkLogoSvg;
    case EdgeInfraType.Azure:
      return mode ? companyIcons.azureLogoSvg : companyIcons.azureDarkLogoSvg;
    case EdgeInfraType.Gcp:
      return mode
        ? companyIcons.googleCloudLogoSvg
        : companyIcons.googleCloudDarkLogoSvg;
    case EdgeInfraType.Oci:
      return mode ? companyIcons.oracleLogoSvg : companyIcons.oracleDarkLogoSvg;
    case EdgeInfraType.OnPrem:
      return mode ? edgeIcons.onPremiseLogoSvg : edgeIcons.onPremiseDarkModeLogoSvg;
    default:
      return edgeIcons.multiCloudLogoSvg;
  }
}

export function getEdgeShortLogo(edgeInfraType: EdgeInfraType) {
  switch (edgeInfraType) {
    case EdgeInfraType.Amazon:
      return companyIcons.awsShortIcon;
    case EdgeInfraType.Azure:
      return companyIcons.azureShortIcon;
    case EdgeInfraType.Gcp:
      return companyIcons.googleCloudShortIcon;
    case EdgeInfraType.Oci:
      return companyIcons.oracleShortIcon;
    case EdgeInfraType.OnPrem:
      return edgeIcons.onPremiseEdgeShortIcon;
    default:
      return edgeIcons.multiCloudEdgeIcon;
  }
}

export function isEdgeTypeCloud(edgeInfraType: EdgeInfraType) {
  switch (edgeInfraType) {
    case EdgeInfraType.Amazon:
    case EdgeInfraType.Azure:
    case EdgeInfraType.Gcp:
    case EdgeInfraType.Oci:
      return true;
    default:
      return false;
  }
}
