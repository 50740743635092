import { FC, useEffect, useState } from "react";
import {
  Button,
  Space,
  Spin,
  Form,
  Input,
  Typography,
  theme,
  Row,
  Radio,
  RadioChangeEvent,
  InputNumber
} from "antd";

import {
  Operator,
  WorkflowNodeCardProps,
  WorkflowStepOperator,
  OperatorParameterMap,
  TriggerType,
  OperatorLoopType,
} from "types";
import { useWorkflowStepsStore, useWorkflowStore } from "store";
import {
  generateReferenceName,
} from "utility/workflow";

import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree,
  SuggestionsInput,
  validateSuggestionInput
} from "components/Suggestions";
import CollapsePanel from "../../CollapsePanel";

import { SvgIcon } from "components/SvgIcon";
import { workflowIcons } from "assets/icons";
import { FieldLabel } from "components/FieldLabel";
import { notification } from 'utility/notification';

const { Text } = Typography;
const LoopOperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  resourceType,
  nodeType,
  editMode,
  onClose,
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [operatorParameters, setOperatorParameters] = useState({} as OperatorParameterMap);
  const [countLimits, setCountLimits] = useState<{min?: number, max?: number}>({});
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const { operators } = useWorkflowStepsStore((state: any) => ({operators: state.operators}));

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(selectedWorkflow?.steps[id]?.referenceName);
  const [loopType, setLoopType] = useState<string>(selectedWorkflow?.steps[id]?.parameters?.type);

  const syncOperator = async () => {
    try {
      setLoader(true);
      const operator = operators.find((i: Operator) => i.id == resourceId);
      operator && setOperatorParameters(operator.parameters);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    syncOperator();
  }, [operators]);

  const syncCurrentState = async () => {
    try {
      const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
      if (operatorStep) {
        if (!operatorStep.isConfigured) {
          operatorParameters &&
            Object.entries(operatorParameters).forEach(([key, value]) => {
              if (value.default != undefined) {
                operatorStep.parameters[key] = value.default;
              }
            });
        }

        form.setFieldsValue({ 
          type: operatorStep.parameters.type,
          data_set: operatorStep.parameters.data_set,
          count: operatorStep.parameters.count
        });
        setLoopType(operatorStep.parameters.type);
        setCountLimits({min: operatorParameters?.count?.min, max: operatorParameters?.count?.max})
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
  }, [selectedWorkflow, operatorParameters]);

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
        operatorStep.isConfigured = true;
        operatorStep.name = values.name;
        operatorStep.referenceName = referenceName;
        setReferenceName(operatorStep.referenceName);

        operatorStep.parameters = {};
        operatorStep.parameters.type = values.type;
        if (values.type == OperatorLoopType.On) {
          operatorStep.parameters.data_set = values.data_set;
        }

        if (values.type == OperatorLoopType.Range) {
          operatorStep.parameters.count = values.count;
        }

        selectedWorkflow.steps[id] = operatorStep;
        updateSelectedWorkflow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Form
      form={form}
      name="operatorCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} help={"Operator Name"} />}
            initialValue={selectedWorkflow.steps[id]?.name}
            rules={[
              { required: true, message: "Name is required!" },
            ]}
            extra={referenceName != "" && <Text italic type="secondary">Reference Name: {referenceName}</Text>}
          >
            <Input disabled={!editMode} onChange={(e) => setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))}/>
          </Form.Item>
          <CollapsePanel 
            name={
              <Space size={token.marginXXS}>
                <SvgIcon component={workflowIcons.parametersShortIcon} />
                <Text>Parameters</Text>
              </Space>
            }
            ghost={false} 
            collapsePanel={false}
          >
            <Space size={token.sizeXXS} direction="vertical" style={{ display: "flex" }}>
            <Form.Item 
              name="type"
              label={<FieldLabel label={"Loop"} help={"Type of loop operator"} />}
              validateTrigger="onSubmit"
            >
              <Radio.Group
                disabled={!editMode}
                value={loopType}
                onChange={(e: RadioChangeEvent) => setLoopType(e.target.value)}
              >
                <Radio.Button value={OperatorLoopType.On}>On</Radio.Button>
                <Radio.Button value={OperatorLoopType.Range}>Range</Radio.Button>
              </Radio.Group>
            </Form.Item>
            { loopType == OperatorLoopType.On && 
              <Form.Item 
                name="data_set"
                label={<FieldLabel label={"Data Set"} help={"Data set list/map to loop over"} />}
                validateTrigger="onSubmit"
                rules={[
                  { type: "string" },
                  { required: true, message: "Data set is required!" },
                  {
                    validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
                  }
                ]}
              >
                <SuggestionsInput
                  editMode={editMode}
                  name={"data_set"}
                  suggestionsTree={parameterSuggestionsTree}
                />
              </Form.Item>
            }
            { loopType == OperatorLoopType.Range && 
                <Form.Item 
                  name="count"
                  label={<FieldLabel label={"Count"} help={"Maximum number of times to run loop"} />}
                  validateTrigger="onSubmit"
                  rules={[
                    { type: "number" },
                    { required: true, message: "Count is required!" },
                  ]}
                >
                  <InputNumber
                    key={"count"} 
                    disabled={!editMode}
                    min={countLimits?.min}
                    max={countLimits?.max}
                  />
                </Form.Item>
            }
            </Space>
          </CollapsePanel>
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default LoopOperatorCard;
