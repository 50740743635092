import { Affix, Avatar, Card, Empty, List, Space, Spin, Tooltip, Typography, message, theme } from "antd";
import { FC, useEffect, useState } from "react";
import VirtualList from 'rc-virtual-list';

import { Resizable } from "react-resizable";

import { useActivityStore, useOrganizationStore, useUserInfoStore } from "store";
import { Activity, ActivityOverviewProps } from "types";

import { getAvatarColor } from "../../utility";

import { activityIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";

const { Text } = Typography;
const ActivityOverview: FC<ActivityOverviewProps> = ({
  pageSize,
  widthPercentage,
  resizable,
  group,
  nomargin,
}) => {
  const { token } = theme.useToken();
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [cardWidth, setCardWidth] = useState(400);
  const cardWidthPercentage: string = widthPercentage ? widthPercentage : "30%";
  const defaultPageSize = 12;
  const containerHeight = 730;
  
  const context = useOrganizationStore((state) => state.context);

  const { activities, getActivities } = useActivityStore((state) => ({
    activities: state.activities,
    getActivities: state.getActivities,
  }));

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const getActivityList = async () => {
    try {
      setLoader(true);
      await getActivities(
        currentPage,
        pageSize ? pageSize : defaultPageSize,
        group
      );
      //message.success(`${pageSize} more activities loaded!`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getActivityList();
  }, [currentPage, context]);

  useEffect(() => {
    const userIds = activities
      .map((activity) => activity.userID)
      .filter((v, i, a) => v && a.indexOf(v) == i);
    userIds.length > 0 && loadUserInfos(userIds);
  }, [activities]);

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - containerHeight) <= 1) {
      setCurrentPage(currentPage+1);
    }
  };
  
  const activityCard = (width: number | string) => {
    return (
      <Card
        size="small"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Space size={token.marginXS}>
              <SvgIcon component={activityIcons.activityIcon} />
              <Text style={{ color: token.colorTextLightSolid }}>
                Activity Overview
              </Text>
            </Space>
          </div>
        }
        style={{
          width: width,
          minHeight: activities.length ? "auto": "70%",
          minWidth: "400px",
          maxWidth: "450px",
          margin: token.margin,
        }}
        headStyle={{
          backgroundColor: token.colorPrimary,
          textAlign: "left",
        }}
      >
        <Spin spinning={loader}>
          {activities.length == 0 
            ? <Empty />
            :
            <List>
              <VirtualList
                data={activities}
                height={containerHeight}
                itemKey="id"
                onScroll={onScroll}
              >
                {(activity: Activity) => (
                  <List.Item key={activity.id}>
                    <List.Item.Meta
                      avatar={
                        <Tooltip
                          title={
                            userInfos[activity.userID]?.firstName +
                            " " +
                            userInfos[activity.userID]?.lastName
                          }
                          placement="top"
                        >
                          {userInfos[activity.userID]?.logoUrl == "" ? (
                            <Avatar
                              style={{
                                backgroundColor: getAvatarColor(
                                  userInfos[activity.userID]?.firstName + userInfos[activity.userID]?.lastName
                                ),
                              }}
                            >
                              {userInfos[activity.userID]?.firstName
                                .toUpperCase()
                                .charAt(0)}
                              {userInfos[activity.userID]?.lastName
                                .toUpperCase()
                                .charAt(0)}
                            </Avatar>
                          ) : (
                            <Avatar src={userInfos[activity.userID]?.logoUrl} />
                          )}
                        </Tooltip>
                      }
                      title={
                        <Tooltip title={(activity.message.length > 100) && activity.message}>
                          <Text style={{ fontSize: token.sizeMS, fontWeight: "lighter" }}>{activity.message.substring(0, 100) + (activity.message.length > 100 ? "...": "")}</Text>
                        </Tooltip>
                      }
                      description={<Text>{activity.localTime}</Text>}
                    />
                  </List.Item>
                )}
              </VirtualList>
            </List>
          }
        </Spin>
      </Card>
    );
  };

  return (
    <>
      {resizable ? (
        <Resizable
          width={cardWidth}
          height={600}
          onResize={(e, { size }) => {
            setCardWidth(size.width);
          }}
          resizeHandles={["w"]}
          handle={
            <span
              style={{
                position: "absolute",
                width: "5px",
                height: "100%",
                left: "0",
                top: "0",
                cursor: "col-resize",
              }}
            />
          }
        >
          {activityCard(cardWidth)}
        </Resizable>
      ) : (
        activityCard(cardWidthPercentage)
      )}
    </>
  );
};

export default ActivityOverview;
