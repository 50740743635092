import { FC, useState, useEffect } from "react";
import { Avatar, Popover, Space, Spin, Typography, theme } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useOrganizationStore, useUserInfoStore, useWorkspaceItemStore } from "store";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { Approval, ApprovalResourceTypeMap, ApprovalStatus, ApprovalStatusMap, WorkspaceTabType } from "types";
import { TextWithIcon } from "components/TextWithIcon";
import { workspaceIcons } from "assets/icons";
import { useNavigate } from "react-router-dom";
import ApprovalAction from "components/ApprovalAction";
import { useApprovalStore } from "store/approval";
import { HLink } from "components/Link";
import { TableProps } from "antd/lib";
import { DataType } from "@textea/json-viewer";

const { Text, Link } = Typography;

const ApprovalTab: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  
  const context = useOrganizationStore((state) => state.context);

  const { clearApprovals } = useApprovalStore(
    (state) => ({
      clearApprovals: state.clearApprovals,
    })
  );

  const { 
    workspaceItemsMap, 
    getWorkspaceItems, 
    approvalsPage, 
    setCurrentPage,
    approvalsFilters,
    setWorkspaceFilters,
    approvalsSorters,
    setWorkspaceSorters,
  } = useWorkspaceItemStore((state) => ({
    workspaceItemsMap: state.workspaceItemsMap,
    getWorkspaceItems: state.getWorkspaceItems,
    approvalsPage: state.workspacePage[WorkspaceTabType.Approvals],
    setCurrentPage: state.setCurrentPage,
    approvalsFilters: state.workspaceFilters[WorkspaceTabType.Approvals],
    setWorkspaceFilters: state.setWorkspaceFilters,
    approvalsSorters: state.workspaceSorters[WorkspaceTabType.Approvals],
    setWorkspaceSorters: state.setWorkspaceSorters,
  }));

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const onPageChange = (pageNumber : number, pageSize : number)  => {
    setLoader(true);
    setCurrentPage(WorkspaceTabType.Approvals, pageNumber, pageSize)
    getWorkspaceItems(WorkspaceTabType.Approvals, pageNumber, pageSize).then( () => {
      setLoader(false);
    });
  }

  const loadApprovals = async () => {
    try {
      setLoader(true);
      clearApprovals();
      await getWorkspaceItems(WorkspaceTabType.Approvals, approvalsPage?.number as number, approvalsPage?.size as number);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadApprovals();
  }, [approvalsFilters, approvalsSorters, context]);
  
  //Load user infos of workspace items
  useEffect(() => {
    const userIds = [] as string[];
    const items = workspaceItemsMap.get(WorkspaceTabType.Approvals);
    items && items?.map((workspaceItem: any) => {
      workspaceItem.userID && userIds.push(workspaceItem.userID);
    });
    userIds.length > 0 && loadUserInfos(userIds);
  }, [workspaceItemsMap?.get(WorkspaceTabType.Approvals)]);

  const renderStatus = (status: string): JSX.Element => {
    const statusCode = status as ApprovalStatus;
    switch (statusCode) {
      case ApprovalStatus.ApprovalStatusApproved:
        return <Text type="success">{ApprovalStatusMap.get(statusCode)}</Text>;
      case ApprovalStatus.ApprovalStatusRejected:
        return <Text type="danger">{ApprovalStatusMap.get(statusCode)}</Text>;
      case ApprovalStatus.ApprovalStatusPending:
        return <Text type="secondary">{ApprovalStatusMap.get(statusCode)}</Text>;
      case ApprovalStatus.ApprovalStatusExpired:
        return <Text type="warning">{ApprovalStatusMap.get(statusCode)}</Text>;
      case ApprovalStatus.ApprovalStatusCanceled:
        return <Text type="warning">{ApprovalStatusMap.get(statusCode)}</Text>;
      default:
        return <></>;
    }
  };

  const getBackgroundColor = (status: ApprovalStatus): string => {
    switch (status) {
      case ApprovalStatus.ApprovalStatusApproved:
        return token.colorSuccess;
      case ApprovalStatus.ApprovalStatusRejected:
        return token.colorError;
      case ApprovalStatus.ApprovalStatusPending:
        return token.colorTextSecondary;
      case ApprovalStatus.ApprovalStatusExpired:
        return token.colorWarning;
      default:
        return token.colorPrimary;
    }
  };

  const columns: ColumnsType<Approval> = [
/*     {
      title: "Approval Id",
      dataIndex: "",
      key: "id",
      render: (record) => {
        const prefix = record.id?.split("-")[0];
        const id = `approval-${prefix}`;
        return (
          <TextWithIcon
            icon={workspaceIcons.workspaceApproval}
            text={id}
          />
        );
      },
    }, */
    {
      title: "Resource Id",
      dataIndex: "",
      key: "resourceID",
      render: (record) => {
        const suffix = record.resourceID?.split("-")[0];
        const name = ApprovalResourceTypeMap.get(record.resourceType) + `-${suffix}`;
        return (
          <TextWithIcon
            icon={workspaceIcons.workspaceWorkflow}
            iconSize={15}
            text={
              <Popover content={<Text>{record.resourceID}</Text>}>
                <HLink onClick={() => navigate(`/${record.resourceType}/${record.resourceID}`)} text={name} />
              </Popover>
            }
          />
        );
      },
    },
    {
      title: "Resource",
      dataIndex: "",
      key: "resource",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (record) => (
        <Text>
          <HLink onClick={() => navigate(`/${record.resourceType}/${record.resourceID}?approvalId=${record.id}`)} text={record.resourceName} />
        </Text>
      ),
    },
    {
      title: "Approvers",
      dataIndex: "",
      key: "approvers",
      render: (record: Approval) => (
        record.approvers?.map((approver) => 
          <Avatar.Group key={approver.id}>
            <Space>
              <Popover 
                title={<a href={"mailto:" + approver.email}>
                  {approver.isManagedUser 
                    ? 
                      userInfos[approver.id]?.firstName + ' ' + userInfos[approver.id]?.lastName
                    : 
                      approver.email}
                </a>}
                content={
                  <Space direction="vertical">
                    {"Status: " + ApprovalStatusMap.get(approver?.response?.approvalStatus)}
                    {approver.isMyApproval && approver?.response?.approvalStatus == ApprovalStatus.ApprovalStatusPending
                      && <ApprovalAction
                          approvalId={record.id}
                          displayName={record.resourceName}
                          isTooltipButton={false}
                        />
                    }
                    {approver?.response?.reason && "Reason: " + approver?.response?.reason}
                  </Space> 
                }
              >
                {approver.isManagedUser ?
                  <>
                    {userInfos[approver.id]?.logoUrl && userInfos[approver.id].logoUrl != ""
                      ?
                      <Avatar 
                        src={userInfos[approver.id]?.logoUrl} 
                        style={{
                          backgroundColor: getBackgroundColor(approver?.response?.approvalStatus),
                        }}
                      />
                      :
                        <Avatar
                          style={{
                            backgroundColor: getBackgroundColor(approver?.response?.approvalStatus),
                          }}
                        >
                          {userInfos[approver.id]?.firstName
                            .toUpperCase()
                            .charAt(0)}
                          {userInfos[approver.id]?.lastName
                            .toUpperCase()
                            .charAt(0)}
                        </Avatar>
                    }
                  </>
                :
                  <Avatar 
                    icon={<UserOutlined />} 
                    style={{
                      backgroundColor: getBackgroundColor(approver?.response?.approvalStatus),
                    }}
                  />
                }
              </Popover>
            </Space>
          </Avatar.Group>
        )
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterSearch: true,
      filters: Array.from(ApprovalStatusMap, ([tag, value]) => ({ text: value, value: tag })),
      filteredValue: approvalsFilters["status"],
      render: renderStatus,
    },
  ];

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter)  => {
    setWorkspaceSorters(WorkspaceTabType.Approvals, sorter);
    setWorkspaceFilters(WorkspaceTabType.Approvals, filters);
    setCurrentPage(WorkspaceTabType.Approvals);
  }

  return (
    <Spin spinning={loader}>
      <EntitiesDisplay
        header={"Approvals"}
        dataSource={workspaceItemsMap.get(WorkspaceTabType.Approvals) as any[]}
        columns={columns}
        pageNumber={approvalsPage?.number}
        pageSize={approvalsPage?.size}
        total={approvalsPage?.total as number}
        onChange={onChange}
        onPageChange={onPageChange}
      />
    </Spin>
  );
};

export default ApprovalTab;
