import { FC, useEffect, useState } from "react";
import {
  Button,
  Space,
  Spin,
  Form,
  FormInstance,
  Input,
  Typography,
  theme,
  Row
} from "antd";

import { isEqual } from "lodash";

import {
  OperatorParamValueMap,
  Operator,
  WorkflowNodeCardProps,
  WorkflowStepOperator,
  OperatorParameterMap,
  TriggerType,
  OperatorIODataType,
  Condition,
  Conditions,
} from "types";
import { useWorkflowStepsStore, useWorkflowStore } from "store";
import { notification } from 'utility/notification';
import {
  generateReferenceName,
} from "utility/workflow";

import { validateFormFields } from "utility";

import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree
} from "components/Suggestions";
import CollapsePanel from "../../CollapsePanel";

import NodeCardParameters from "./Parameters";
import { SvgIcon } from "components/SvgIcon";
import { workflowIcons } from "assets/icons";
import { FieldLabel } from "components/FieldLabel";

const { Text } = Typography;
const OperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  resourceType,
  nodeType,
  editMode,
  onClose,
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [parametersForm, setParametersForm] = useState<
    undefined | FormInstance
  >(undefined);
  const [operatorParameters, setOperatorParameters] = useState(
    {} as OperatorParameterMap
  );
  const [operatorParametersValues, setOperatorParametersValues] = useState(
    {} as OperatorParamValueMap
  );
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  
  const { operators } = useWorkflowStepsStore((state: any) => ({
    operators: state.operators,
  }));

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(selectedWorkflow?.steps[id]?.referenceName);

  const syncOperator = async () => {
    try {
      setLoader(true);
      const operator = operators.find((i: Operator) => i.id == resourceId);
      operator && setOperatorParameters(operator.parameters);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    syncOperator();
  }, [operators]);

  const syncCurrentState = async () => {
    try {
      const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
      if (operatorStep) {
        if (!operatorStep.isConfigured) {
          operatorParameters &&
            Object.entries(operatorParameters).forEach(([key, value]) => {
              if (value.default != undefined) {
                operatorStep.parameters[key] = value.default;
              }
            });
        }

        setOperatorParametersValues(operatorStep.parameters);
        form.setFieldsValue({ parameters: operatorStep.parameters });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
  }, [selectedWorkflow, operatorParameters]);

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
        operatorStep.isConfigured = true;
        operatorStep.name = values.name;
        operatorStep.referenceName = referenceName;
        setReferenceName(operatorStep.referenceName);

        operatorStep.parameters = {};
        if (values.parameters) {
          Object.entries(operatorParameters).forEach(([key, value]) => {
            if (value.required) {
              operatorStep.parameters[key] = values.parameters[key];
            } else {
              if (values.parameters[key]) {
                operatorStep.parameters[key] = values.parameters[key];
              }
            }
          });
        }

        selectedWorkflow.steps[id] = operatorStep;

        updateSelectedWorkflow();
        if (values.parameters) {
          setOperatorParametersValues(values.parameters);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Form
      form={form}
      name="operatorCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} help={"Operator Name"} />}
            initialValue={selectedWorkflow.steps[id]?.name}
            rules={[
              { required: true, message: "Name is required!" },
            ]}
            extra={referenceName != "" && <Text italic type="secondary">Reference Name: {referenceName}</Text>}
          >
            <Input disabled={!editMode} onChange={(e) => setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))}/>
          </Form.Item>
         
          {operatorParameters && Object.keys(operatorParameters).length ?
            <CollapsePanel 
              name={
                <Space size={token.marginXXS}>
                  <SvgIcon component={workflowIcons.parametersShortIcon} />
                  <Text>Parameters</Text>
                </Space>
              }
              ghost={false} 
              collapsePanel={false}
            >
              <Form.Item 
                name="parameters"
                initialValue={operatorParametersValues}
                validateTrigger="onSubmit"
                rules={[
                  { validator: (_, value) => validateFormFields(parametersForm) },
                ]}>
                <NodeCardParameters 
                  nodeType={nodeType} 
                  editMode={editMode}
                  parameters={operatorParameters} 
                  parametersValues={operatorParametersValues} 
                  parameterSuggestionsTree={parameterSuggestionsTree} 
                  onRender={form => setParametersForm(form)}/>
              </Form.Item>
            </CollapsePanel>
            : null
          }
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default OperatorCard;
