import { FC } from "react";
import { theme } from "antd";
import { ProfileCard } from "./ProfileCard";
import { SecurityCard } from "./SecurityCard";

import ActivityOverview from "../../components/ActivityOverview";
import PageWrapper from "../../components/PageWrapper";
import { InformationCard } from "./InformationCard";

const Profiles: FC = () => {
  const { token } = theme.useToken();
  return (
    <PageWrapper
      content={
        <div style={{ margin: token.margin, width: "100%" }}>
          <ProfileCard />
          <SecurityCard />
          <InformationCard />
        </div>
      }
      feedback={<ActivityOverview pageSize={9} />}
    ></PageWrapper>
  );
};

export default Profiles;
