import { theme } from "antd";
import { sample } from "lodash";
import {
  DistributionData,
  Metric,
  MiniTileData,
  PieData,
  TrendData,
  WidgetType,
} from "types";
import { KpiDistribution } from "./KpiDistribution";
import { KpiMiniTile } from "./KpiMiniTile";
import { KpiPie } from "./KpiPie";
import { KpiTrend } from "./KpiTrend";

export interface MetricDisplayProps {
  metric: Metric;
  stretchMiniTiles?: boolean;
  height?: string;
  onEdit?: (id: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onRefresh?: (id: string) => Promise<void>;
  color?: string;
}

function createProps<T>(props: MetricDisplayProps) {
  const mtd = {
    ...({ ...props.metric.data } as T),
    stretched: props.stretchMiniTiles,
    color: props.color,
    id: props.metric.id,
    onEdit: props.onEdit,
    onDelete: props.onDelete,
    onRefresh: props.onRefresh,
  };
  return mtd;
}

export const MetricDisplay = (props: MetricDisplayProps) => {
  const { token } = theme.useToken();
  const COLORS = [
    token.colorPrimary,
    token.colorPrimaryText,
    token.colorPrimaryTextHover,
    token.colorPrimaryBorder,
    token.colorSuccessText,
    token.colorSuccess,
    token.colorSuccessActive,
    token.colorInfo,
    token.colorWarning,
    token.colorWarningActive,
    token.colorText,
  ];

  if (props.metric?.widgetType === WidgetType.MiniTile) {
    // const mtd = ;
    return <KpiMiniTile {...createProps<MiniTileData>(props)} />;
  } else if (props.metric?.widgetType === WidgetType.Trend) {
    const mtd = createProps<TrendData>(props);
    const zerosToPrepend = Math.max(0, 6 - mtd?.primary.values.length);
    mtd.primary.values = Array(zerosToPrepend)
      .fill(0)
      .concat(mtd?.primary.values);
    return <KpiTrend {...mtd} />;
  } else if (props.metric?.widgetType === WidgetType.Distribution) {
    const mtd = createProps<DistributionData>(props);
    const colorsMap: string[] = [];
    if (mtd?.primary?.values) {
      mtd.primary.values = mtd?.primary.values
        .slice(0, 5)
        .sort((a, b) => a.y - b.y);
    }
    mtd?.primary?.values?.map(() => {
      colorsMap.push(sample(COLORS));
    });
    return <KpiDistribution data={mtd} colors={colorsMap} />;
  } else if (props.metric?.widgetType === WidgetType.Pie) {
    const mtd = createProps<PieData>(props);
    const colorsMap: string[] = [];
    mtd?.primary?.values?.map(() => {
      colorsMap.push(sample(COLORS));
    });
    return <KpiPie data={mtd} colors={colorsMap} height={props.height} />;
  } else {
    return <div></div>;
  }
};
