import {
  Button,
  Empty,
  Modal,
  Typography,
  theme,
  Spin,
  Space,
  notification,
} from "antd";
import { KpiGrid } from "components/Metrics/KpiGrid";
import { useEffect, useState } from "react";
import { Layouts } from "react-grid-layout";
import { useDashboardStore, useGoalStore } from "store";
import {
  Dashboard,
  Metric,
  MetricInterval,
  MiniTileData,
  TrendData,
  Widget,
  WidgetType,
} from "types";
import { getXYForNextItem, reevaluateLayout } from "utility";
import { WidgetEdit } from "./WidgetEdit";

const { Text } = Typography;

const createTrendData = (widget: Widget, data: number[]): TrendData => {
  return {
    primary: {
      text: widget.title,
      values: data,
      unit: widget.unit,
    },
    average: {
      text: "Average",
      value: data.length ? data.reduce((a, b) => a + b, 0) / data.length : 0,
      unit: widget.unit,
    },
    total: {
      text: "Total",
      value: data.length ? data[data.length - 1] : 0,
      unit: widget.unit,
    },
    interval: MetricInterval.TWELVEHOURS,
  };
};

const createMiniTileData = (widget: Widget, data: number[]): MiniTileData => {
  return {
    primary: {
      text: widget.title,
      value: data.length ? data[data.length - 1] : 0,
      unit: widget.unit,
    },
    interval: MetricInterval.TWELVEHOURS,
  };
};
export interface CustomDashboardProps {
  dashboard: Dashboard;
}

export const CustomDashboard = (props: CustomDashboardProps) => {
  const [updatingName, setUpdatingName] = useState(false);
  const [updatingDescription, setUpdatingDescription] = useState(false);
  const [editLayout, setEditLayout] = useState(false);
  // Max number of dashboard elements comuted with the max value across all layouts
  const [dashbordElementsCount, setDashbordElementsCount] = useState(0);
  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const [widgetDeleteInProgress, setWidgetDeleteInProgress] = useState(false);
  const [dashboardDeleteInProgress, setDashboardDeleteInProgress] =
    useState(false);
  const [dashboardDeleteFlag, setDashboardDeleteFlag] = useState(false);

  const { token } = theme.useToken();
  const widgets = useDashboardStore((state) => state.widgets);
  const setLayout = useDashboardStore((state) => state.setLayout);
  const updateDashboardProperties = useDashboardStore(
    (state) => state.updateDashboardProperties
  );
  const deleteWidget = useDashboardStore((state) => state.deleteWidget);
  const updateWidget = useDashboardStore((state) => state.updateWidget);
  const listGoals = useGoalStore((state) => state.listGoals);
  const addWidget = useDashboardStore((state) => state.addWidget);
  const deleteDashboard = useDashboardStore((state) => state.deleteDashboard);

  const goalMetrics = useGoalStore((state) => state.goalMetrics);

  const [addMetric, setAddMetric] = useState(false);
  const [editMetric, setEditMetric] = useState<string | null>(null);
  const [displayedMetrics, setDisplayedMetrics] = useState<{
    [k: string]: Metric;
  }>({});
  const columns: { [k: string]: number } = {
    lg: 12,
    md: 10,
    sm: 8,
    xs: 4,
    xxs: 2,
  };

  useEffect(() => {
    setDashbordElementsCount(
      Math.max(
        ...Object.values(props.dashboard.layout).map((l) => l.length),
        dashbordElementsCount
      )
    );

    /* For each widget in dashboards, get the items from goalMetrics, and make it into a named metric  */
    const metrics: { [k: string]: Metric } = {};

    widgets[props.dashboard.id].forEach((widget) => {
      const metric = goalMetrics[widget.id];
      switch (widget.type) {
        case WidgetType.Trend:
          metrics[widget.id] = {
            id: widget.id,
            widgetType: WidgetType.Trend,
            data: createTrendData(widget, metric || []),
          };
          break;
        case WidgetType.MiniTile:
          metrics[widget.id] = {
            id: widget.id,
            widgetType: WidgetType.MiniTile,
            data: createMiniTileData(widget, metric || []),
          };
          break;
        default:
          console.log("Unknown widget type");
          break;
      }
    });
    setDisplayedMetrics(metrics);
    console.log("Widgets", widgets);
  }, [goalMetrics]);

  const updateName = async (name: string) => {
    try {
      setUpdatingName(true);
      await updateDashboardProperties(
        props.dashboard.id,
        name,
        props.dashboard.description
      );
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Failed to update dashboard name",
        duration: 6,
      });
    } finally {
      setUpdatingName(false);
    }
  };

  const updateDescription = async (description: string) => {
    try {
      setUpdatingDescription(true);
      await updateDashboardProperties(
        props.dashboard.id,
        props.dashboard.name,
        description
      );
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Failed to update dashboard description",
        duration: 6,
      });
    } finally {
      setUpdatingDescription(false);
    }
  };
  return (
    <div
      id={`custom-metrics-dashboard-${props.dashboard.id}`}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: token.colorBgContainer,
        padding: token.padding,
        marginBottom: token.margin,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Space direction="vertical">
          <Spin spinning={updatingName}>
            <Text
              editable={{ onChange: updateName }}
              style={{
                fontSize: token.fontSizeHeading4,
              }}
            >
              {props.dashboard.name}
            </Text>
          </Spin>
          <Spin spinning={updatingDescription}>
            <Text editable={{ onChange: updateDescription }}>
              {props.dashboard.description}
            </Text>
          </Spin>
        </Space>
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <Button type="primary" onClick={() => setDashboardDeleteFlag(true)}>
            Delete Dashboard
          </Button>

          {!editLayout && (
            <Button type="primary" onClick={() => setAddMetric(true)}>
              Add Metric
            </Button>
          )}
          {dashbordElementsCount ? (
            <Button type="primary" onClick={() => setEditLayout(!editLayout)}>
              {editLayout ? "Save Layout" : "Edit Layout"}
            </Button>
          ) : null}
        </div>
      </div>
      {dashbordElementsCount ? (
        <KpiGrid
          layouts={props.dashboard.layout}
          metrics={displayedMetrics}
          cols={columns}
          isDraggable={editLayout}
          isDroppable={editLayout}
          onLayoutChange={
            editLayout
              ? async (_, layouts: Layouts) =>
                  setLayout(props.dashboard.id, layouts)
              : undefined
          }
          onRefresh={!editLayout ? listGoals : undefined}
          onEdit={
            !editLayout
              ? async (id: string) => {
                  setEditMetric(id);
                }
              : undefined
          }
          onDelete={
            !editLayout ? async (id: string) => setIdToDelete(id) : undefined
          }
        />
      ) : (
        <Empty />
      )}
      {addMetric && (
        <WidgetEdit
          dashboardId={props.dashboard.id}
          isAdd={true}
          onClose={async () => setAddMetric(false)}
          onSave={async (widget: Widget) => {
            /* Add a new item to layout, trigger a on layout change, save layout and widget */
            const created: Widget = await addWidget(props.dashboard.id, widget);
            const newLayout: Layouts = { ...props.dashboard.layout };
            Object.keys(newLayout).forEach((key) => {
              const n = columns[key];
              const xy = getXYForNextItem(newLayout[key], n);
              newLayout[key].push({
                i: created.id,
                x: xy.x,
                y: xy.y,
                w: 2,
                h: 1,
              });
            });
            await setLayout(props.dashboard.id, newLayout);
            setAddMetric(false);
          }}
        />
      )}
      {editMetric && (
        <WidgetEdit
          dashboardId={props.dashboard.id}
          isAdd={false}
          widget={widgets[props.dashboard.id].find((w) => w.id === editMetric)}
          onClose={async () => setEditMetric(null)}
          onSave={async (widget: Widget) => {
            await updateWidget(props.dashboard.id, editMetric, widget);
            setEditMetric(null);
          }}
        />
      )}
      {idToDelete && (
        <Modal
          title="Confirm delete"
          onClose={() => setIdToDelete(null)}
          onCancel={() => setIdToDelete(null)}
          open={idToDelete != null}
          onOk={async () => {
            try {
              setWidgetDeleteInProgress(true);
              const newLayout: Layouts = { ...props.dashboard.layout };
              Object.keys(newLayout).forEach((key) => {
                newLayout[key] = reevaluateLayout(
                  newLayout[key].filter((w) => w.i !== idToDelete),
                  columns[key]
                );
              });

              await setLayout(props.dashboard.id, newLayout);
              await deleteWidget(props.dashboard.id, idToDelete);
              setIdToDelete(null);
            } finally {
              setWidgetDeleteInProgress(false);
            }
          }}
        >
          <Spin spinning={widgetDeleteInProgress}>
            <Text>Are you sure you want to delete this item?</Text>
          </Spin>
        </Modal>
      )}
      <Modal
        title="Confirm delete"
        onClose={() => setDashboardDeleteFlag(false)}
        onCancel={() => setDashboardDeleteFlag(false)}
        open={dashboardDeleteFlag}
        onOk={async () => {
          try {
            setDashboardDeleteInProgress(true);
            await deleteDashboard(props.dashboard.id);
          } finally {
            setDashboardDeleteFlag(false);
            setDashboardDeleteInProgress(false);
          }
        }}
      >
        <Spin spinning={dashboardDeleteInProgress}>
          <Text>Are you sure you want to delete this dashboard?</Text>
        </Spin>
      </Modal>
    </div>
  );
};
