import { IODataValueMap, SearchRunActionDatas, SearchRunActionProvider } from "types";

export const enum SearchRunActionStatus {
  Unspecified = "unspecified",
  Running = "running",
  Completed = "completed",
  Failed = "failed",
  Cancelled = "canceled",
  Terminated = "terminated",
  TimedOut = "timed_out",
}

export interface SearchRunActionMetrics {
  tenantsCount: number;
  actionsCount: number;
  appsCount: number;
  timeTaken: string;
}


// RunSearchActionEdgeResult posted by edge sensor as part of action result
export interface RunSearchActionEdgeResult {
  status: boolean;
  error: string;
}

export interface SearchRunActionResultMeta {
  id: string;
  searchRunID: string;
  searchRunActionID: string;
  //ActionName
  actionName: string;
  //appID
  appID: string;
  //AppName
  appName: string;
  appSubscriptionID: string;
  //AppSubscriptionName appname which took the action
  appSubscriptionName: string;
  //EdgeId where the action output is stored
  edgeID: string;
  tenantID: string;
}

// SearchRunActionResultMetas 
export type SearchRunActionResultMetas = SearchRunActionResultMeta[];


export interface SearchRunActionMetrics {
  appsCount: number;
  timeTaken: string;
}

export type SearchRunActionSelectedActions = string[];



export interface SearchRunActionActionInfo  {
  id: string;
  displayName: string;
  parameters: IODataValueMap;
  provider: SearchRunActionProvider;
}

export interface SearchRunAction {
  id: string;
  searchRunID: string;
  actionInfo: SearchRunActionActionInfo;
  actionDatas: SearchRunActionDatas;
  status: SearchRunActionStatus;
  resultCount: number;
  resultMetas: SearchRunActionResultMetas;
  metrics: SearchRunActionMetrics;
  createdAt: string;
  updatedAt: string;
  tagID: string;
  tenantID: string;
  userID: string;
}



export interface SearchRowActionsColumn {
  result: boolean;
  actionName: string;
  appName: string;
  appSubscriptionName: string
}