import { Button, Pagination, Space, Spin, Table, Upload, Modal as AntModal, Checkbox, theme, Flex} from "antd";
import { FC, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { IAction, IDisplayTraits } from "./display_traits";
import Modal  from "components/Modal";

const { Dragger } = Upload;

import { useOrganizationStore } from "store";
import SearchableTags from "components/SearchableTags";
import { TextWithIcon } from "components/TextWithIcon";
import { managedmodeIcons } from "assets/icons";
import SearchInput from "components/SearchInput";

export const EntitiesDisplay: FC<IDisplayTraits> = ({
  header,
  dataSource,
  columns,
  onChange,
  onSearch,
  searchPlaceholder,
  searchText,
  extra,
  actions,
  pageNumber,
  pageSize,
  total,
  rowKey,
  hideRowSelection,
  onPageChange,
  getItemProps,
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [warnEntityModal, setWarnEntityModal] = useState<IAction>();
  const [importEntityModal, setImportEntityModal] = useState<IAction>();
  const [fileList, setFileList] = useState<any[]>([]);
  const [showTags, setShowTags] = useState<IAction>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [includeTags, setIncludeTags] = useState<boolean>(false);
  const [includeMe, setIncludeMe] = useState<boolean>(false);

  const { context, tags } = useOrganizationStore((state) => ({
    context: state.context,
    tags: state.tags,
  }));
  
  useEffect(() => {
    setIncludeTags(!context?.endsWith('_tag') as boolean);
  }, [context]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any[]) => setSelectedRowKeys(selectedRowKeys),
    getCheckboxProps: (record: any) => {
      return getItemProps ? getItemProps(record) : record;
    },
  };

  return (
    <>
      {warnEntityModal && (
        <Modal
          title={warnEntityModal.label + " " + header}
          onClose={() => {
            setWarnEntityModal(undefined);
            setSelectedRowKeys([]);
          }}
          open={warnEntityModal.showWarn ? warnEntityModal.showWarn : false}
          onSubmit={() => {
            setWarnEntityModal(undefined);
            warnEntityModal.onClick(selectedRowKeys);
            setSelectedRowKeys([]);
          }}
        >
        {warnEntityModal.warnMessage ? warnEntityModal.warnMessage : "Are you sure you want to " + warnEntityModal.label + " selected " + header.toLowerCase() + " ?"}
        </Modal>
      )}
      {showTags &&
        <AntModal 
          title={
            <TextWithIcon
              icon={managedmodeIcons.managedModeTags}
              iconSize={18}
              text={showTags.label}
            />
          }
          open={showTags ? true : false} 
          onCancel={() => {
            setShowTags(undefined);
            setSelectedTags([]);
            setSelectedRowKeys([]);
            setIncludeMe(false);
            setIncludeTags(false);
          }}
          footer={[
            (includeTags && selectedTags.length > 0)
            &&
            <Button 
              key="clear" 
              type="primary" 
              onClick={() => setSelectedTags([])}
            >
              Clear Tags
            </Button>,
            ((includeMe && !includeTags) || (includeTags && selectedTags.length > 0))
            &&
            <Button 
              key="copy" 
              type="primary" 
              onClick={() => {
                const tagsOnly = selectedTags && (selectedTags.length > 0) && !includeMe;
                showTags?.onClick(selectedRowKeys, selectedTags, tagsOnly);
                setShowTags(undefined);
                setSelectedTags([]);
                setSelectedRowKeys([]);
                setIncludeMe(false);
                setIncludeTags(false);
              }}
            >
              Submit
            </Button>,
          ]}
        >
          {context?.endsWith('_tag') 
            &&
            <Flex vertical gap={0}>
              <Checkbox 
                style={{ marginBottom: token.marginXS }}
                onChange={(e) => setIncludeMe(e.target.checked)}
              >
                {showTags.label} for me
              </Checkbox>
              <Checkbox 
                style={{ marginBottom: token.marginXS }}
                onChange={(e) => {
                  setIncludeTags(e.target.checked);
                  e.target.checked && setSelectedTags([]);
                }}
              >
                {showTags.label} for tags
              </Checkbox>
            </Flex>
          }
          {includeTags 
            &&
            <SearchableTags
              tags={tags}
              selectedTags={selectedTags}
              onSelect={(tag) => {
                if (selectedTags.includes(tag.id)) {
                  setSelectedTags([...selectedTags.filter((t) => t != tag.id)]);
                } else {
                  setSelectedTags([...selectedTags, tag.id])
                }
              }}
            />
          }
        </AntModal>
      }
      {importEntityModal && (
        <AntModal
          title={importEntityModal.label}
          onCancel={() => {
            setImportEntityModal(undefined);
            setSelectedRowKeys([]);
            setFileList([]);
            setSelectedTags([]);
            setIncludeMe(false);
            setIncludeTags(false);
          }}
          open={importEntityModal ? true : false}
          footer={[
            (importEntityModal.mspMode && (includeTags && selectedTags.length > 0))
            &&
            <Button 
              key="clear" 
              type="primary" 
              onClick={() => setSelectedTags([])}
            >
              Clear Tags
            </Button>,
            ((fileList.length > 0) && (!importEntityModal.mspMode || importEntityModal.mspMode && ((includeMe && !includeTags) || (includeTags && selectedTags.length > 0))))
            &&
            <Button 
              key="import" 
              type="primary" 
              onClick={() => {
                fileList.forEach((file) => {
                  const reader = new FileReader();
                  reader.onload = e => {
                    if (e.target) {
                      importEntityModal.mspMode 
                      ?
                        importEntityModal.onClick(JSON.parse(e.target.result as string), selectedTags, selectedTags && (selectedTags.length > 0) && !includeMe)
                      :
                        importEntityModal.onClick(JSON.parse(e.target.result as string))
                    }
                  };
                  reader.readAsText(file);
                });
                setImportEntityModal(undefined);
                setSelectedRowKeys([]);
                setFileList([]);
                setSelectedTags([]);
                setIncludeMe(false);
                setIncludeTags(false);
              }}
            >
              Submit
            </Button>,
          ]}
        >
          <Space direction="vertical" style={{ display: "flex" }}>
            <Dragger
              accept=".json"
              multiple={true}
              fileList={[...fileList]}
              onRemove={(file) => {
                setFileList(() =>  {
                  const index = fileList.indexOf(file);
                  const newFileList = fileList.slice();
                  newFileList.splice(index, 1);
                  return newFileList;
                })
              }}
              beforeUpload={(file, files) => {
                if (files) {
                  setFileList([...fileList, ...files]);
                } else {
                  setFileList([...fileList, file]);
                }
                return false;
              }}
            >
              <InboxOutlined />
              <div>Click or drag workflow files here to import</div>
            </Dragger>
            {(importEntityModal.mspMode && context?.endsWith('_tag'))
              &&
              <Flex vertical gap={0}>
                <Checkbox 
                  style={{ marginBottom: token.marginXS }}
                  onChange={(e) => setIncludeMe(e.target.checked)}
                >
                  {importEntityModal.label} for me
                </Checkbox>
                <Checkbox 
                  style={{ marginBottom: token.marginXS }}
                  onChange={(e) => setIncludeTags(e.target.checked)}
                >
                  {importEntityModal.label} for tags
                </Checkbox>
              </Flex>
            }
            {(importEntityModal.mspMode && includeTags)
              &&
              <SearchableTags
                tags={tags}
                selectedTags={selectedTags}
                onSelect={(tag) => {
                  if (selectedTags.includes(tag.id)) {
                    setSelectedTags([...selectedTags.filter((t) => t != tag.id)]);
                  } else {
                    setSelectedTags([...selectedTags, tag.id])
                  }
                }}
              />
            }
          </Space>
        </AntModal>
      )}
      <div style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: "2px"
        }}
      >
        <div style={{ flex: 1 }}>
          <h3>
            Total {header} ({total})
          </h3>
        </div>
        <Space>
          {onSearch &&
            <div style={{ width: "300px" }}>
              <SearchInput
                placeholder={searchPlaceholder ? searchPlaceholder : "search " + header.toLowerCase()}
                value={searchText != "" ? searchText : undefined}
                onFilter={(e) => onSearch(e.target.value)}
              />
            </div>
          }
          {actions && 
            actions.filter((action) => !action.hidden).map((action) => (
              <Button 
                key={action.key} 
                type="primary" 
                disabled={action.enable ? !action.enable(selectedRowKeys) : false}
                onClick={() => {
                  if (action.showWarn) {
                    setWarnEntityModal(action);
                  } else if (action.isImport) {
                    setImportEntityModal(action);
                  } else {
                    if (action.mspMode) {
                      setShowTags(action);
                    } else {
                      action.onClick(selectedRowKeys);
                      setSelectedRowKeys([]);
                    }
                  }
                }}
              >
                {action.label}
              </Button>
          ))}
          {extra}
        </Space>
      </div>
      <Spin spinning={loader}>
        <div style={{ width: "100%"}}>
          <Table
            rowKey={rowKey ? rowKey : "id"}
            rowSelection={actions && !hideRowSelection ? rowSelection : undefined}
            columns={columns}
            dataSource={dataSource}
            onChange={onChange ? onChange : undefined}
            bordered={true}
            pagination={false}
            scroll={{scrollToFirstRowOnChange: true}}
          />
        </div>
        {pageNumber && pageSize &&
          <Pagination
            style={{ float: "right", marginTop: "5px" }}
            current={pageNumber}
            onChange={(pageNumber, pageSize) => {
              if(onPageChange) {
                setLoader(true);
                setSelectedRowKeys([]);
                onPageChange(pageNumber, pageSize);
                setLoader(false);
              }
            }}
            pageSize={pageSize}
            total={total}
            showSizeChanger={false}
            hideOnSinglePage={true}
          />
        }
      </Spin>
    </>
  );
};
