import { Typography, theme, Button, Divider, Space } from "antd";
import { Drawer } from "components/Drawer";
import { FC, useEffect, useState } from "react";
import { useProfileStore } from "store";
import OtpInput from "react-otp-input";
import { generateRandomString } from "utility";
import { notification } from 'utility/notification';

const { Text, Title } = Typography;
export interface ResetPasswordProps {
  visible: boolean;
  onClose: () => Promise<void>;
}
const Initial_Timer = 60;
export const ResetPasswordDrawer: FC<ResetPasswordProps> = ({
  visible,
  onClose,
}) => {
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [showVerify, setShowVerify] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);

  const { token } = theme.useToken();
  useEffect(() => {
    if (timer <= 0) return;
    setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
  }, [timer]);

  const { passwordVerifyStart, passwordVerifyComplete } = useProfileStore(
    (state) => ({
      passwordVerifyStart: state.passwordVerifyStart,
      passwordVerifyComplete: state.passwordVerifyComplete,
    })
  );

  const resendOtp = async () => {
    if (timer === 0) {
      await verificationStart();
    }
  };

  const verificationStart = async () => {
    try {
      setLoader(true);
      await passwordVerifyStart(generateRandomString());
      setShowVerify(true);
      setTimer(Initial_Timer);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const verifyComplete = async () => {
    await passwordVerifyComplete(otp);
  };

  const verifyOtp = async () => {
    try {
      setLoader(true);
      await verifyComplete();
      notification.success({
        message: "Otp verified successfully",
        duration: 6,
      });
      onClose();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Otp verification failed",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Drawer
      title="Reset password"
      subtitle={""}
      open={visible}
      onClose={async () => onClose()}
      hideFooter={true}
    >
      <div>
        <Title level={4}>Reset password</Title>
        <Text>
          A verification code will be sent to your primary phone for validation
        </Text>
        <Divider></Divider>
      </div>

      {showVerify && (
        <div>
          <Title level={5}>Enter One time code</Title>
          <OtpInput
            containerStyle={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: token.margin,
            }}
            inputStyle={{
              height: "2rem",
              borderRadius: token.borderRadius,
              borderColor: token.colorBorder,
              borderWidth: "1px",
              borderStyle: "solid",
              width: "30px",
            }}
            numInputs={6}
            onChange={setOtp}
            renderSeparator={<span>{"-"}</span>}
            value={otp}
            inputType="text"
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus
          />
          <Space>
            <Text style={{ marginBottom: token.marginXXS }}>
              {"Didnt receive the otp? "}
            </Text>

            <Button
              type="link"
              onClick={resendOtp}
              style={{ margin: token.marginXXS }}
            >
              {"Resend"}
            </Button>
            <Text>{`in ${timer} seconds`}</Text>
          </Space>
        </div>
      )}

      <Space>
        <Button onClick={onClose} type="default">
          Cancel
        </Button>
        {!showVerify ? (
          <Button type="primary" onClick={verificationStart}>
            Send Code
          </Button>
        ) : (
          <Button type="primary" disabled={otp?.length < 6} onClick={verifyOtp}>
            Verify
          </Button>
        )}
      </Space>
    </Drawer>
  );
};
