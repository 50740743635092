import { FC, useEffect, useState } from "react";
import {
  Form,
  Select,
  Input,
  Space,
  Button,
  theme,
} from "antd";
import { PlusOutlined, DeleteFilled } from "@ant-design/icons";

import {
  ArrayObject,
  IODataType,
} from "types";

import { FieldLabel } from "components/FieldLabel";
import { SuggestionsInput, validateSuggestionInput } from "components/Suggestions";
import CollapsePanel from "components/CollapsePanel";
import { FormInstance } from "antd/lib";

const { Option } = Select;

export interface HttpKVFormProps {
  editMode: boolean;
  name: string;
  keyValues: Record<string, string>;
  suggestionsTree: [];
  onChange?: (value: Record<string, string>) => void;
  onRender?: (form: FormInstance) => void;
}

export const HttpKVForm: FC<HttpKVFormProps> = (props) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  useEffect(() => {
    if (props.keyValues) {
      const kvList = Object.entries(props.keyValues).map(([k, v]) => ({
        key: k,
        value: v,
      }));
      form.setFieldValue("kvList", kvList);
    } 
  }, [props.keyValues]);

  return (
    <Form
      form={form}
      name="httpKVForm"
      layout="vertical"
      autoComplete="off"
      onValuesChange={(changedValues: any, _: any) => {
        const values = form.getFieldsValue(true);
        const kvList = values.kvList;
        const kvs = {} as Record<string,string>
        kvList.map((kv: any) => kvs[kv?.key] = kv?.value);
        props.onChange?.(kvs);
      }}
    >
      <Form.List name={"kvList"}>
        {(fields, { add, remove }) => (
          <div style={{width: "100%"}}>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} style={{display: "flex",gap: "6px", maxWidth: "100%", alignItems: "baseline"}}>
                <Form.Item
                  {...restField}
                  name={[name, 'key']}
                  validateTrigger="onSubmit"
                  rules={[
                    { required: true, message: "Key is required!" },
                    {
                      validator: (_, value) =>
                        value && !value.includes(" ")
                          ? Promise.resolve()
                          : Promise.reject(new Error("No spaces allowed")),
                    },
                  ]}
                  style={{
                    width: "100%"
                  }}
                >
                    <Input placeholder="Key" disabled={!props.editMode} />
                </Form.Item>
                
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  validateTrigger="onSubmit"
                  rules={[
                    { required: true, message: "Value is required!" },
                    {
                      validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
                    }
                  ]}
                  style={{
                    width: "100%"
                  }}
                >
                  <SuggestionsInput
                    editMode={props.editMode}
                    name={"value"}
                    suggestionsTree={props.suggestionsTree}
                  />
                </Form.Item>
                {props.editMode &&<Button
                  type="default"
                  shape="circle"
                  size="small"
                  style={{background: token.colorPrimaryBg }}
                  icon={<DeleteFilled style={{ color: token.colorPrimary }}/>}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    remove(name);
                  }}
                />}
              </div>
            ))}
            {props.editMode && ( 
              <Form.Item>
                <Button 
                  type="default" 
                  style={{ background: token.colorPrimaryBg }}
                  onClick={() => add()} 
                  block 
                  icon={<PlusOutlined />}
                >
                  {props.name}
                </Button>
              </Form.Item>
            )}
          </div>
        )}
      </Form.List>
    </Form>
  );
};

export interface OperatorOutputsFormProps {
  editMode: boolean;
  outputs: ArrayObject[];
  hideValue?: boolean;
  suggestionsTree?: [];
  onChange?: (value: ArrayObject[]) => void;
  onRender?: (form: FormInstance) => void;
}

export const OperatorOutputsForm: FC<OperatorOutputsFormProps> = (props) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [outputNames, setOutputNames] = useState<string[]>([]);

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  useEffect(() => {
    form.setFieldValue("operatorOutputs", props.outputs);
    const names = Object.values(props.outputs).map((value) => value.name);
    setOutputNames(names);
  }, [props.outputs]);

  return (
    <Form
      form={form}
      name="operatorOutputForm"
      layout="vertical"
      autoComplete="off"
      onValuesChange={(changedValues: any, _: any) => {
        const values = form.getFieldsValue(true);
        const outputs = values.operatorOutputs;
        console.log("outputs", outputs);
        const names = outputs?.map((value: any) => value?.name);
        setOutputNames(names);
        props.onChange?.(values.operatorOutputs);
      }}
    >
      <Form.List 
        name="operatorOutputs"
        initialValue={[{ name: "", type: IODataType.String, value: "" }]}
      >
        {(fields, { add, remove }) => (
          <Space direction="vertical" style={{ display: "flex" }}>
           {fields.map(({ key, name, ...restField }) => (
              <CollapsePanel
                key={key}
                name={outputNames[name]}
                collapsePanel={false}
                extraElement={
                  props.editMode && (
                  <Button
                    type="default"
                    shape="circle"
                    size="small"
                    style={{ background: token.colorPrimaryBg }}
                    icon={ <DeleteFilled style={{ color: token.colorPrimary }}/> }
                    onClick={(e: any) => {
                      e.stopPropagation();
                      remove(name);
                    }}
                  />
                  )
                }
              >
                <Form.Item
                  {...restField}
                  name={[name, 'name']}
                  validateTrigger="onSubmit"
                  label={<FieldLabel label={"Name"} help={"Output Name"} />}
                  normalize={(value) => value.trim()}
                  rules={[
                    { required: true, message: "Output Name is required!" },
                  ]}
                >
                  <Input disabled={!props.editMode} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'type']}
                  validateTrigger="onSubmit"
                  label={<FieldLabel label={"Type"} help={"Output Value Type"} />}
                  rules={[{ required: true, message: "Type is required!" }]}
                >
                  <Select 
                    disabled={!props.editMode} 
                    defaultValue={IODataType.String}
                    showAction={["focus", "click"]}
                  >
                    <Option key={IODataType.String} value={IODataType.String}>
                      String
                    </Option>
                    <Option key={IODataType.Numeric} value={IODataType.Numeric}>
                      Number
                    </Option>
                    <Option key={IODataType.Boolean} value={IODataType.Boolean}>
                      Boolean
                    </Option>
                    <Option key={IODataType.Array} value={IODataType.Array}>
                      List
                    </Option>
                  </Select>
                </Form.Item>
                {!props.hideValue &&
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    validateTrigger="onSubmit"
                    label={<FieldLabel label={"Value"} help={"Output Value"} />}
                    rules={[
                      { required: true, message: "Value is required!" },
                      {
                        validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
                      }
                    ]}
                  >
                    <SuggestionsInput
                        editMode={props.editMode}
                        name={"value"}
                        suggestionsTree={props.suggestionsTree}
                    />
                  </Form.Item>
                }
              </CollapsePanel>
            ))}
            {props.editMode && (
              <Form.Item>
                <Button
                  type="default"
                  style={{ background: token.colorPrimaryBg }}
                  onClick={() => add({type: IODataType.String})} 
                  block
                  icon={<PlusOutlined />}
                >
                  Output
                </Button>
              </Form.Item>
            )}
          </Space>
        )}
      </Form.List>
    </Form>
  );
};
