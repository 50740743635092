import { FC } from "react";
import { Button, Divider, Layout } from "antd";

import ProviderForm from "./ProviderForm";
import styles from "./IdentityProvider.module.scss";

const { Header, Content } = Layout;

const IdentityProvider: FC = () => {
  return (
    <div>
      <ProviderForm />
    </div>
  );
};

export default IdentityProvider;
