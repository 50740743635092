
import { WidgetDto, addWidgetApi, createDashboardApi, deleteDashboardApi, deleteWidgetApi, listDashboardsApi, setLayoutApi, updateWidgetApi, listWidgetsApi, type DashboardDto, type WidgetCreateRequestDto, type WidgetUpdateRequestDto, updateDashboardPropertiesApi } from "api";
import { GridItemDto } from "api/dashboard";
import { Layouts } from "react-grid-layout";
import { Dashboard, DashboardStore, FormFactor, Widget, WidgetCreateRequest, WidgetType, WidgetUpdateRequest } from "types";
import { create } from "zustand";

const dashboardFromDto = (dashboard: DashboardDto): Dashboard => { 
  return {
    id : dashboard.id,
    name: dashboard.name,
    description: dashboard.description,
    layout: Object.keys(dashboard.layout).reduce((acc, key) => {      
      acc[key] = dashboard.layout[key as FormFactor].map((item: GridItemDto) => ({
        i: item.i,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
      }));      
      return acc;
    }, {} as Layouts ),
  };
}

const widgetFromDto = (widget: WidgetDto): Widget => {
  return {
    id: widget.id,
    type: widget.type as WidgetType,
    unit: widget.unit,
    title: widget.title,
    description: widget.description,
    search: widget.search,
  };
}

export const useDashboardStore = create<DashboardStore>(
  (set, get) => ({
    dashboards: {},
    widgets: {},
    getDashboards: async () => {
      console.log("getting dashboards")
      const response = await listDashboardsApi();
      const dashboards = response.data.dashboards.reduce((acc, dashboard) => {
        acc[dashboard.id] = dashboardFromDto(dashboard);
        return acc;
      }, {} as Record<string, Dashboard>);
      const widgets = await Object.keys(dashboards).reduce(async (acc, dashboardId) => {
        const accumulator = await acc;
        const response = await listWidgetsApi(dashboardId);
        accumulator[dashboardId] = response.data.widgets.map(widgetFromDto);
        return Promise.resolve(accumulator);
      }, Promise.resolve({} as Record<string, Widget[]>));
      set((state) => ({...state, dashboards, widgets}));
      console.log("done getting dashboards")
    },
    
    createDashboard: async (name: string, description: string) => {
      await createDashboardApi(name, description);
      await get().getDashboards();    
    },

    setLayout: async (dashboardId: string, layouts : Layouts ) => {
      const gridItems : {[key in FormFactor]: GridItemDto[]} = Object.keys(layouts).reduce((acc, key) => {
        acc[key as FormFactor] = layouts[key].map((item) => ({
          i: item.i,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
        }));
        return acc;
      }, {} as {[key in FormFactor]: GridItemDto[]}); 
      await setLayoutApi(dashboardId, gridItems);
      await get().getDashboards();
    },

    addWidget: async (dashboardId: string, widget : WidgetCreateRequest) : Promise<Widget>=> {
      const widgetDto : WidgetCreateRequestDto = {
        type: widget.type,
        unit: widget.unit,
        title: widget.title,
        description: widget.description,
        search: widget.search,
      };
      const response = await addWidgetApi(dashboardId, widgetDto);
      await get().getDashboards();
      const dto: WidgetDto = response.data;
      return {
        id: dto.id,
        type: dto.type as WidgetType,
        unit: dto.unit,
        title: dto.title,
        description: dto.description,
        search: dto.search,
      };
    },

    updateWidget: async (dashboardId: string, widgetId : string , widget: WidgetUpdateRequest) => {
      const widgetDto : WidgetUpdateRequestDto = {
        type: widget.type,
        unit: widget.unit,
        title: widget.title,
        description: widget.description,
        search: widget.search,
      };
      const response = await updateWidgetApi(dashboardId, widgetId, widgetDto);
      await get().getDashboards();
      const dto : WidgetDto = response.data;
      return {
        id: dto.id,
        type: dto.type as WidgetType,
        unit: dto.unit,
        title: dto.title,
        description: dto.description,
        search: dto.search,
      };
    },    
    
    
    deleteWidget: async (dashboardId: string, widgetId: string) => {
      await deleteWidgetApi(dashboardId, widgetId);
      await get().getDashboards();
    },  
    
    deleteDashboard: async (dashboardId: string) => {
      await deleteDashboardApi(dashboardId);
      await get().getDashboards();
    }  ,
    
    updateDashboardProperties: async (dashboardId: string, name: string, description: string) => {
      await updateDashboardPropertiesApi(dashboardId, name, description);
      await get().getDashboards();
    }
  })
);
