	
export const AppCategoriesMap = new Map<string,string >([
    ["email.security", "Email Security"],
    ["biz.apps", "Biz Apps"],
    ["iam", "IAM"],
    ["observability", "Observability"],
    ["endpoint.security", "Endpoint Security"],
    ["case.management", "Case Management"],
    ["csp", "CSP"],
    ["exposure.management", "Exposure Management"],
    ["dev.sec.ops", "Dev Sec Ops"],
    ["siem", "SIEM"],
    ["data.security", "Data Security"],
    ["appsec", "AppSec"],
    ["network.security", "Network Security"],
    ["collaboration", "Collaboration"],
    ["grc", "GRC"],
    ["cloud.security", "Cloud Security"],
    ["automation", "Automation"],
    ["email.security", "Email Security"],
    ["asset.management", "Asset Management"],
    ["data.security", "Data Security"],
    ["database", "Database"],
    ["threat.intel", "Threat Intel"],
    ["saas", "Saas"],
    ["hosted", "Hosted"],
    ["ai", "AI"],

  ]);


  export const EdgeCategoriesMap = new Map<string,string >([
    ["delievery.cloud", "Cloud"],
    ["delievery.onpremise", "OnPremise"],
    ["cloud.azure", "Azure"],
    ["cloud.aws", "AWS"],
    ["cloud.oci", "Oracle"],
    ["cloud.gcp", "GCP"],
    ["managed.hypredge", "Managed"],
    ["unmanaged.hypredge", "Unmanaged"],
  ]);