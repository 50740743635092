import { theme } from "antd";
import { useMemo } from "react";
import { Layouts } from "react-grid-layout";
import { createUseStyles } from "react-jss";

import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { Metric } from "types";
import { MetricDisplay } from "./MetricDisplay";

const ResponsiveGridLayout = WidthProvider(Responsive);

export interface KpiGridProps {
  layouts: Layouts;
  stretchMiniTiles?: boolean;
  metrics: { [k: string]: Metric };
  onLayoutChange?: (layout: Layout[], layouts: Layouts) => Promise<void>;
  onEdit?: (id: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onRefresh?: (id: string) => Promise<void>;
  cols?: { [k: string]: number };
  isDraggable?: boolean;
  isDroppable?: boolean;
}

export const KpiGrid = (props: KpiGridProps) => {
  const { token } = theme.useToken();
  const children = useMemo(() => {
    const displayMetrics = props.layouts?.lg.map((layout) => layout.i) || [];
    return displayMetrics.map((metric) => {
      const metricData = props.metrics[metric];
      if (!metricData) return null;
      return (
        <div key={metric}>
          <MetricDisplay
            key={metric}
            metric={props.metrics[metric]}
            stretchMiniTiles={props.stretchMiniTiles}
            onDelete={props.onDelete}
            onEdit={props.onEdit}
            onRefresh={props.onRefresh}
          />
        </div>
      );
    });
  }, [props.layouts, props.metrics, props.isDraggable]);

  const useStyles = createUseStyles({
    container: {
      width: "100%",
      position: "relative",
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ResponsiveGridLayout
        rowHeight={100}
        layouts={props.layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={props.cols ?? { lg: 12, md: 10, sm: 8, xs: 4, xxs: 2 }}
        width={1000}
        verticalCompact={true}
        onLayoutChange={props.onLayoutChange}
        resizeHandles={[]}
        containerPadding={[token.padding, token.padding]}
        margin={[token.marginXXS, token.marginXXS]}
        isDraggable={props.isDraggable}
      >
        {children}
      </ResponsiveGridLayout>
    </div>
  );
};
