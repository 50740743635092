import { FC } from "react";

import { OperatorType, TriggerType, WorkflowNodeCardProps, WorkflowNodeType} from "types";
import { useWorkflowStore } from "store";

import CustomTriggerCard from "./CustomTrigger";
import ScheduleTriggerCard from "./ScheduleTrigger";
import ActionCard from "./Action";
import OperatorCard from "./Operator";
import SearchOperatorCard from "./SearchOperator";
import LoopOperatorCard from "./LoopOperator";
import ApprovalOperatorCard from "./ApprovalOperator";
import KVOperatorCard from "./KVOperator";
import SubworkflowOperatorCard from "./SubworkflowOperator";
import HttpOperatorCard from "./HttpOperator";
import DBOperatorCard from "./DBOperator";
import AIOperatorCard from "./AIOperator";

const NodeCard: FC<WorkflowNodeCardProps> = (props) => {

  const { selectedWorkflow } = useWorkflowStore(
    (state) => ({
      selectedWorkflow: state.selectedWorkflow,
  }));

  const TriggerCard: FC = () => {
    switch (selectedWorkflow.triggerRef.triggerType) {
      case TriggerType.Custom:
        return <CustomTriggerCard {...props}></CustomTriggerCard>;
      case TriggerType.Schedule:
        return <ScheduleTriggerCard {...props}></ScheduleTriggerCard>;
      default:
        return <></>
    }
  };

  return (
    <>
      {props.nodeType == WorkflowNodeType.Trigger ? (
        <TriggerCard/>
      ) : props.nodeType == WorkflowNodeType.Action ? (
        <ActionCard key={props.id} {...props}></ActionCard>
      ) : (
        props.resourceType == OperatorType.Search ?
          <SearchOperatorCard {...props}/>
          : props.resourceType == OperatorType.Loop ?
            <LoopOperatorCard {...props}/>
            : props.resourceType == OperatorType.KV ?
              <KVOperatorCard {...props}/>
              : props.resourceType == OperatorType.Approval ?
                <ApprovalOperatorCard {...props}/> 
                : props.resourceType == OperatorType.Subworkflow ?
                  <SubworkflowOperatorCard {...props}/> 
                  : props.resourceType == OperatorType.Http ?
                    <HttpOperatorCard {...props}/> 
                    : props.resourceType == OperatorType.Db ?
                      <DBOperatorCard {...props}/> 
                      : props.resourceType == OperatorType.Ai ?
                        <AIOperatorCard {...props}/> 
                        : <OperatorCard key={props.id} {...props}></OperatorCard>
      )}
    </>
  );
};

export default NodeCard;
