import { SpecStateType, ActionProvider, TriggerProvider, ArtifactProvider,SpecActionType} from "types";
import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import {
  actionProvidersApiPath,
  artifactProvidersApiPath,
  jsonApiDeserializer, triggerProvidersApiPath
} from "./constant";
import { dtoFactory } from "utility/resource";

export const getTriggerProvidersApi = async (
  triggerId: string | null, 
  appId: string | null= null, 
  pageNumber?: number,
  pageSize?: number,
) :  Promise<[TriggerProvider[], number]> => {   
  let url = `${triggerProvidersApiPath}?accessInfoRequired=true&appInfoRequired=true&include=triggers&fields[trigger-providers]=name,displayName,description,appID,triggerID,state,shared,parameters,outputs,adapterType,http,updatedAt&fields[triggers]=name,displayName,description,state,shared,parameters,outputs,accessInfo`
  if (appId) 
    url += `&filter=equals(appID,'${appId}')`
  if (triggerId)
    url += `&filter=equals(triggerID,'${triggerId}')`
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`
  
  
  const response = await axiosInterceptor("get",url);
  const triggerProviders =  jsonApiDeserializer.deserialize(response.data)  as TriggerProvider[];
  return [triggerProviders, response?.data?.meta?.totalCount];
};

export const getTriggerProviderVersionsApi = async (triggerProviderId: string):Promise<TriggerProvider[]> => {
  if(triggerProviderId) {
    const response = await axiosInterceptor(
      "get",
      `${triggerProvidersApiPath}}?includeVersions=true&filter=equals(id,'${triggerProviderId}')&fields[trigger-providers]=version,versionName,comments&sort=-version`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as TriggerProvider[];
  }
  throw new Error("triggerProviderId should be present in the trigger providers versions get");
};


export const getActionProviderVersionsApi = async (actionProviderId: string):Promise<ActionProvider[]> => {
  if(actionProviderId) {
    const response = await axiosInterceptor(
      "get",
      `${actionProvidersApiPath}}?includeVersions=true&filter=equals(id,'${actionProviderId}')&fields[action-providers]=version,versionName,comments&sort=-version`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as ActionProvider[];
  }
  throw new Error("actionProviderId should be present in the action providers versions get");
};


export const getActionProvidersApi = async (
  actionId: string | null,
  appId: string | null = null, 
  pageNumber?: number,
  pageSize?: number,
) :  Promise<[ActionProvider[], number]> => {  
  let url = `${actionProvidersApiPath}?accessInfoRequired=true&appInfoRequired=true&include=actions&fields[action-providers]=name,displayName,description,state,shared,appID,actionID,parameters,outputs,adapterType,http,updatedAt&fields[actions]=name,displayName,description,state,shared,parameters,outputs,accessInfo`
  if (appId) 
    url += `&filter=equals(appID,'${appId}')`
  if (actionId)
    url += `&filter=equals(actionID,'${actionId}')`
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`
  
  const response = await axiosInterceptor("get", url)
  const actionProviders =  jsonApiDeserializer.deserialize(response.data)  as ActionProvider[];
  return [actionProviders, response?.data?.meta?.totalCount];
};

/** Create action provider  */
export const createActionProviderApi = async (actionProvider: ActionProvider): Promise<ActionProvider> => {
  const response = await axiosInterceptor(
    "post",
    actionProvidersApiPath,
    {
        "data": {
            "type": "action-providers",
            "attributes": dtoFactory.convertActionProviderToUploadObject(actionProvider),
        }
    }
  );
  const actionProviderOut = jsonApiDeserializer.deserialize(response.data)  as ActionProvider;
  return actionProviderOut;
}

export const getActionProviderApi = async (actionProviderId: string) : Promise<ActionProvider> => {
  const response = await axiosInterceptor("get", (`${actionProvidersApiPath}/${actionProviderId}?accessInfoRequired=true`));
  return jsonApiDeserializer.deserialize(response.data) as ActionProvider;
};


export const updateActionProviderApi = async (actionProvider: ActionProvider): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "patch",
    `${actionProvidersApiPath}/${actionProvider.id}`,
    {
      "data": {
        "type": "action-providers",      
        "id": actionProvider.id,
        "attributes": dtoFactory.convertActionProviderToUploadObject(actionProvider),
      }
    }
  );
};

export const actionActionProviderApi = async (actionProviderId: string, action: SpecActionType, actionProvider?: unknown) => {
  const response = await axiosInterceptor(
    "post",
    `${actionProvidersApiPath}/${actionProviderId}/${action}`,
      actionProvider
      && {
            "data": {
              "type": "action-providers",      
              "id": actionProviderId,
              "attributes": actionProvider,
            }
          }
    );
    return response && response.data ? jsonApiDeserializer.deserialize(response.data) : null;
};


export const deleteActionProviderApi = async (actionProviderId: string): Promise<AxiosResponse<any,any>>=> {
  return  await axiosInterceptor("delete",`${actionProvidersApiPath}/${actionProviderId}`);
}


/** Create trigger provider  */
export const createTriggerProviderApi = async (triggerProvider: TriggerProvider) : Promise<TriggerProvider> => {
  const response = await axiosInterceptor(
    "post",
    triggerProvidersApiPath,
    {
        "data": {
            "type": "trigger-providers",
            "attributes": dtoFactory.convertTriggerProviderToUploadObject(triggerProvider),
        }
    }
  );
  const triggerProviderOut = jsonApiDeserializer.deserialize(response.data)  as TriggerProvider;
  return triggerProviderOut;
}

export const getTriggerProviderApi = async (triggerProviderId: string) : Promise<TriggerProvider> => {
  const response = await axiosInterceptor("get", (`${triggerProvidersApiPath}/${triggerProviderId}?accessInfoRequired=true`));
  return jsonApiDeserializer.deserialize(response.data) as TriggerProvider;
};



export const updateTriggerProviderApi = async (triggerProvider: TriggerProvider): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "patch",
    `${triggerProvidersApiPath}/${triggerProvider.id}`,
    {
      "data": {
        "type": "trigger-providers",      
        "id": triggerProvider.id,
        "attributes": dtoFactory.convertTriggerProviderToUploadObject(triggerProvider),
      }
    }
  );
};

export const actionTriggerProviderApi = async (triggerProviderId: string, action: SpecActionType, triggerProvider?: unknown) => {
  const response = await axiosInterceptor(
    "post",
    `${triggerProvidersApiPath}/${triggerProviderId}/${action}`,
      triggerProvider
      && {
            "data": {
              "type": "trigger-providers",      
              "id": triggerProviderId,
              "attributes": triggerProvider,
            }
          }
    );
    return response && response.data ? jsonApiDeserializer.deserialize(response.data) : null;
};


export const deleteTriggerProviderApi = async (triggerProviderId: string): Promise<AxiosResponse<any,any>>=> {
  return  await axiosInterceptor("delete",`${triggerProvidersApiPath}/${triggerProviderId}`);
};

/** Create artifact provider  */
export const createArtifactProviderApi = async (artifactProvider: ArtifactProvider) : Promise<ArtifactProvider> => {
  const response = await axiosInterceptor(
    "post",
    artifactProvidersApiPath,
    {
        "data": {
            "type": "artifact-providers",
            "attributes": dtoFactory.convertArtifactProviderToUploadObject(artifactProvider),
        }
    }
  );
  const artifactProviderOut = jsonApiDeserializer.deserialize(response.data)  as ArtifactProvider;
  return artifactProviderOut;
}

export const getArtifactProvidersApi = async (
  artifactId: string | null, 
  appId: string | null= null, 
  pageNumber?: number,
  pageSize?: number,
) :  Promise<[ArtifactProvider[], number]> => {   
  let url = `${artifactProvidersApiPath}?accessInfoRequired=true&appInfoRequired=true&include=artifacts&fields[artifact-providers]=state,shared,appID,artifactID,adapterType,http,updatedAt&fields[artifacts]=name,displayName,description,state,shared,fields,accessInfo`
  if (appId) 
    url += `&filter=equals(appID,'${appId}')`
  if (artifactId)
    url += `&filter=equals(artifactID,'${artifactId}')`
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`
  
  const response = await axiosInterceptor("get",url);
  const artifactProviders =  jsonApiDeserializer.deserialize(response.data)  as ArtifactProvider[];
  return [artifactProviders, response?.data?.meta?.totalCount];
};



export const getArtifactProviderVersionsApi = async (artifactProviderId: string):Promise<ArtifactProvider[]> => {
  if(artifactProviderId) {
    const response = await axiosInterceptor(
      "get",
      `${artifactProvidersApiPath}}?includeVersions=true&filter=equals(id,'${artifactProviderId}')&fields[artifact-providers]=state,comments`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as ArtifactProvider[];
  }
  throw new Error("artifactProviderId should be present in the artifact providers versions get");
};


export const getArtifactProviderApi = async (artifactProviderId: string) : Promise<ArtifactProvider> => {
  const response = await axiosInterceptor("get", (`${artifactProvidersApiPath}/${artifactProviderId}?accessInfoRequired=true`));
  return jsonApiDeserializer.deserialize(response.data) as ArtifactProvider;
};



export const updateArtifactProviderApi = async (artifactProvider: ArtifactProvider): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "patch",
    `${artifactProvidersApiPath}/${artifactProvider.id}`,
    {
      "data": {
        "type": "artifact-providers",      
        "id": artifactProvider.id,
        "attributes": dtoFactory.convertArtifactProviderToUploadObject(artifactProvider)
      }
    }
  );
};

export const actionArtifactProviderApi = async (artifactProviderId: string, action: SpecActionType, artifactProvider?: unknown) => {
  const response = await axiosInterceptor(
    "post",
    `${artifactProvidersApiPath}/${artifactProviderId}/${action}`,
      artifactProvider
      && {
            "data": {
              "type": "artifact-providers",      
              "id": artifactProviderId,
              "attributes": artifactProvider,
            }
          }
    );
    return response && response.data ? jsonApiDeserializer.deserialize(response.data) : null;
};


export const deleteArtifactProviderApi = async (artifactProviderId: string): Promise<AxiosResponse<any,any>>=> {
  return  await axiosInterceptor("delete",`${artifactProvidersApiPath}/${artifactProviderId}`);
};



