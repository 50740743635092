import { FC, useEffect, useState } from "react";

import { useMetricStore } from "store";
import PageWrapper from "../../components/PageWrapper";

import { KpiPageHighlightGrid } from "components/Metrics/KpiPageHighlightGrid";
import { MetricLayoutIdentifier } from "types";
import AppCards from "./AppCards";
import AppList from "./AppList";
import AppConfigs from "./AppConfigs";
import { useParams } from "react-router-dom";

export const Apps: FC = () => {
    const { appId: appId } = useParams();

    const {
      metrics,
      fetchMetricsForLayout,
    } = useMetricStore((state) => ({
      metrics: state.metrics,
      fetchMetricsForLayout: state.fetchMetricsForLayout,
    }));
  
    useEffect(() => {
      const asyncUseEffect = async () => {
        fetchMetricsForLayout(MetricLayoutIdentifier.MetricLayoutApps);
      };
      asyncUseEffect();
    }, []);
        
    return (
      <>
        <PageWrapper
          highlights={
            <KpiPageHighlightGrid key="apps" metrics={metrics && metrics.get(MetricLayoutIdentifier.MetricLayoutApps)} />
          }
          content={
            appId 
              ?
              <AppConfigs appId={appId} />
              :
              <AppList />
          }
        />
      </>
    );
  };
  
  export default Apps;