import { FC, useEffect, useState } from "react";
import { Form, Input, InputNumber, Select, Typography } from "antd";
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

import { generateReferenceName } from "utility/workflow";
import { WorkflowCardParameterProps, IODataType } from "types";

import { FieldLabel } from "components/FieldLabel";
import { generateRandomString } from "utility";

const WorkflowCardParameter: FC<WorkflowCardParameterProps> = (props) => {
  const [form] = Form.useForm();
  const [stringVal, setStringVal] = useState("");
  const [numericVal, setNumericVal] = useState(0);
  const [booleanVal, setBooleanVal] = useState(true);
  const [referenceName, setReferenceName] = useState<string>(props.parameter?.referenceName);

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const generateReferenceName = (name: string) => {
    if (name != "") {
        let referenceName = name.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase()
        referenceName += "_" + generateRandomString(3).toLowerCase();
        return referenceName
    } else {
      return "";
    }
  }

  const onValuesChange = async (changedValues: any, _: any) => {
    const values = form.getFieldsValue(true);
    values.referenceName = generateReferenceName(values.name);
    setReferenceName(values.referenceName);
    props.onChange?.(values);
  };

  return (
    <Form 
      form={form} 
      name="parameterForm" 
      layout="vertical" 
      autoComplete="off"
      initialValues={props.parameter}
      onValuesChange={onValuesChange}>
        <Form.Item 
          name="name" 
          label={<FieldLabel label={"Name"} help={"Name of parameter"} />}
          rules={[
            { required: true, message: 'Name is required!'}
          ]}
          extra={referenceName != "" &&  <Text italic type="secondary">Reference Name: {referenceName}</Text>}
        >
          <Input disabled={!props.editMode}/>
        </Form.Item>
        <Form.Item 
          name="description" 
          label={<FieldLabel label={"Description"} help={"Description of parameter"} />}
        >
          <TextArea
            disabled={!props.editMode}
            showCount
            maxLength={100}
          />
        </Form.Item>
        <Form.Item 
          name="type"
          label={<FieldLabel label={"Type"} help={"Type of parameter"} />}
          rules={[
            { required: true, message: 'Type is required!'},
          ]}
        >
            <Select
              disabled={!props.editMode}
              showAction={["focus", "click"]}
              onChange={(value) => 
                form.setFieldValue("value", (value == IODataType.String) ? stringVal : (value == IODataType.Numeric) ? numericVal : booleanVal)}
            >
              <Option key={IODataType.String} value={IODataType.String}>String</Option>
              <Option key={IODataType.Numeric} value={IODataType.Numeric}>Number</Option>
              <Option key={IODataType.Boolean} value={IODataType.Boolean}>Boolean</Option>
            </Select>
        </Form.Item>
        <Form.Item 
          name="value" 
          label={<FieldLabel label={"Default Value"} help={"Default value of the parameter"} />}
        >
          {
            (form.getFieldValue("type") == IODataType.Numeric) && 
            <InputNumber
              disabled={!props.editMode}
              onChange={(value) => setNumericVal(value as number)}
            /> ||
            (form.getFieldValue("type") == IODataType.String) && 
            <Input 
              disabled={!props.editMode} 
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStringVal(e.target.value)}
            /> ||
            (form.getFieldValue("type") == IODataType.Boolean) && 
            <Select
                disabled={!props.editMode}
                showAction={['focus', 'click']}
                onChange={setBooleanVal}
            >
              <Option key={1} value={true}>True</Option>
              <Option key={0} value={false}>False</Option>
            </Select>
          }
        </Form.Item>
    </Form>
  );
};
  
export default WorkflowCardParameter;