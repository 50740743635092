import { Artifact, ArtifactFields, ConditionOpType } from 'types';
import * as constants from './constants';
import {getArtifacts, getArtifactFields, getArtifactFieldOperators, getArtifactLogicalOperators} from "./lexer"
import { queryLexer } from "./lexer";
import { parserInstance } from "./parser";


interface QuerySuggestion {
  lastToken: string;
  tokens: Array<string>;
  mandatoryTokens: Array<string>
}


export const getSearchSuggestions = (inputString: string) => {
  const suggestions = getSuggestions(inputString)
  const finalSuggestions =  new Array<string>
  const lastTokens =  new Array<string>
  for (const suggestion of suggestions) {
    finalSuggestions.push(...suggestion.tokens)
  }
  for (const suggestion of suggestions) {
    lastTokens.push(suggestion.lastToken)
  }
  return {suggestions,lastTokens}
}

function getSuggestions(inputText: string): QuerySuggestion[] {
    const lexResult = queryLexer.tokenize(inputText);
    const suggestions: Array<QuerySuggestion> = []
  
    //handling the tokens
    const partialTokenVector = lexResult.tokens
    
    const syntacticSuggestions = parserInstance.computeContentAssist(
      "queryStatement",
      partialTokenVector
    )
    
    for (let i = syntacticSuggestions.length -1 ; i >= 0; i--) {
      const currSyntaxSuggestion = syntacticSuggestions[i]
      const currTokenType = currSyntaxSuggestion.nextTokenType
      let suggestion = {} as QuerySuggestion
      switch (currTokenType.name) {
        case constants.TokenTypes.Projection:{
          suggestion = suggestArtifacts(inputText)
          break;
        } 
        case constants.TokenTypes.Where:{
          suggestion = suggestWhere(lexResult, inputText)
          break;
        } 
        case constants.TokenTypes.ConditionArtifactField: {
          suggestion = suggestArtifactFields(lexResult, inputText)
          break;
        }
        case constants.TokenTypes.ConditionOperator: {
          suggestion = suggestConditionOperators(lexResult, inputText)
          break;
        }
        case constants.TokenTypes.ConditionValue: {
          suggestion = suggestConditionValue(lexResult, inputText)
          break;
        }
        case constants.TokenTypes.LogicalOperator: {
          suggestion = suggestLogicalOperators(lexResult, inputText)
          break;
        }
      }
      if (suggestion && suggestion.tokens && suggestion.tokens.length) {
        suggestions.push(suggestion)
      }
    }
    return suggestions
  }
  
function suggestArtifacts(inputText: string): QuerySuggestion {
  const suggestion = <QuerySuggestion>{}
  suggestion.lastToken = inputText
  suggestion.tokens = new Array<string>
  const artifacts = getArtifacts().sort((a: Artifact, b: Artifact) => a.name.localeCompare(b.name))
  for (const artifact of artifacts) {
    if (artifact.private) {
      continue
    }
    if (artifact.name.includes(inputText)) {
      suggestion.tokens.push(artifact.name)
    }
  }
  return suggestion
}

export function getMandatoryFields(lexResult: any, artifactFields: ArtifactFields) {
  const mandatoryFields = Object.entries(artifactFields).filter ( ([name, field]) => {
    if (!field.isMandatory) {
      return false
    }
    if(field.complementaryMandatoryFields) {
      for(const cmf of field.complementaryMandatoryFields){
        if (lexResult.tokens.find( (x: any) => x.image == cmf)) {
          return false
        }
      }
    }
    if (lexResult.tokens.find( (x: any) => x.image == name)) {
      return false
    }
    return field.isMandatory == true
  })
  return mandatoryFields
}
  
function suggestArtifactFields(lexResult: any, inputText: string): QuerySuggestion {
  const suggestion = <QuerySuggestion>{}
  suggestion.tokens = new Array<string>
  suggestion.mandatoryTokens = new Array<string>
  const artifactFields = getArtifactFields(lexResult.tokens[0].image)
  const includeString = lastStringWithErrorToken(lexResult, inputText)
  suggestion.lastToken = includeString
  
  //find mandatory fields 
  const mandatoryFields = getMandatoryFields(lexResult, artifactFields);
  for (const [name, field] of Object.entries(artifactFields)) {
   
    if (name.includes(includeString))  {
      if (field.isNotRepeatable && lexResult.tokens.find( (x: any) => x.image == name)) {
        continue;
      }
      suggestion.tokens.push(name);
      if(mandatoryFields.find( ( [m,f]) => m == name)) {
        suggestion.mandatoryTokens.push(name);
      }
    }
  }
  return suggestion
}

function suggestWhere(lexResult: any, inputText: string): QuerySuggestion {
  const suggestion = <QuerySuggestion>{}
  suggestion.tokens = new Array<string>
  const includeString = lastStringWithErrorToken(lexResult, inputText)
  suggestion.lastToken = includeString
  suggestion.tokens.push("where");
  return suggestion
}

function suggestConditionValue(lexResult: any, inputText: string): QuerySuggestion {
  const suggestion = <QuerySuggestion>{}
  suggestion.tokens = new Array<string>
  const includeString = lastStringWithErrorToken(lexResult, inputText)
  suggestion.lastToken = includeString
  if (lexResult.tokens && lexResult.tokens.length != 0 ) {
    if (lexResult.tokens[lexResult.tokens.length-1].image == ConditionOpType.IsEmpty) {
      suggestion.tokens.push("true", "false");
    }
  }
  return suggestion
}
  
function suggestConditionOperators(lexResult: any, inputText: string): QuerySuggestion {
  const suggestion = <QuerySuggestion>{}
  suggestion.tokens = new Array<string>
  const noOftokens = lexResult.tokens.length
  const artifactOperators = getArtifactFieldOperators(lexResult.tokens[0].image, lexResult.tokens[noOftokens-1].image)
  const includeString = lastStringWithErrorToken(lexResult, inputText)
  suggestion.lastToken = includeString
  for (const op of artifactOperators) {
    if (op.includes(includeString)) {
        suggestion.tokens.push(op)
    }
  }
  return suggestion
}

function suggestLogicalOperators(lexResult: any, inputText: string): QuerySuggestion {
  const suggestion = <QuerySuggestion>{}
  suggestion.tokens = new Array<string>
  const artifactLogicalOperators = getArtifactLogicalOperators(lexResult.tokens[0].image)
  const includeString = lastStringWithErrorToken(lexResult, inputText)
  suggestion.lastToken = includeString
  for (const op of artifactLogicalOperators) {
      if (op.includes(includeString)) {
          suggestion.tokens.push(op)
      }
  }
  return suggestion
}

function lastStringWithErrorToken(lexResult: any, inputText: string): string {
  let str = ""
  if (lexResult.errors.length > 0) {
      str = inputText.substring(lexResult.errors[0].offset)
  }
  return str
}