import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Typography, theme } from "antd";
import { goalsIcons } from "assets/icons";
import { FC, useState } from "react";
import { useProfileStore, useSettingsStore } from "store";

import { GoalsItem } from "./GoalsItem";
import { StarterCard, StarterCardProps } from "./StarterCard";
const { Text } = Typography;

function getGreeting(): string {
  const currentTime = new Date().getHours();

  if (currentTime >= 5 && currentTime < 12) {
    return "Good Morning";
  } else if (currentTime >= 12 && currentTime < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}

function formatDateDetails(date: Date): {
  dayOfWeek: string;
  monthTextFull: string;
  dayOfMonthNumeral: string;
} {
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    dateStyle: "full",
    timeStyle: "long",
  }).format(date);
  const [dayOfWeek, monthTextFull, dayOfMonthNumeral] =
    formattedDate.split(" ");
  return { dayOfWeek, monthTextFull, dayOfMonthNumeral };
}

export const Home: FC = () => {
  const lightMode = useSettingsStore((state) => state.lightMode);
  const { dayOfWeek, monthTextFull, dayOfMonthNumeral } = formatDateDetails(
    new Date()
  );
  const { profile } = useProfileStore((state) => ({
    profile: state.profile,
  }));

  const { token } = theme.useToken();

  const colors: string[] = [
    "#fee8da",
    "#e8f4da",
    "#dae8f4",
    "#e8daee",
    "#e8e8e8",
    "#fee8da",
    "#e8f4da",
    "#dae8f4",
    "#e8daee",
    "#e8e8e8",
    "#fee8da",
    "#e8f4da",
    "#dae8f4",
    "#e8daee",
    "#e8e8e8",
  ];

  const cardProps: StarterCardProps[] = [
    {
      id: "starter-card-0",
      category: "Setup",
      duration: "5 minutes",
      cta: "Create Measurable Goals",
      backgroundColor: lightMode ? colors[0] : token.colorPrimaryBg,
      description:
        "Create and manage an organization goal, track progress and share with your team",
      icon: goalsIcons.goalsCreateGoalsIcon,
      destination: "/home",
      videoUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-goals.mp4",
      thumbnailUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-thumbnail-goals.png",
    },
    {
      id: "starter-card-1",
      category: "Configuration",
      duration: "3 minutes",
      cta: "Configure Apps",
      backgroundColor: lightMode ? colors[0] : token.colorPrimaryBg,
      description:
        "Configure apps to enable capabilities on the hypredge platform. Leverage the power of automation using you enterprise application like servicenow amd virustotal",
      icon: goalsIcons.goalsConfigureAppsIcon,
      destination: "/apps",
      videoUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-apps.mp4",
      thumbnailUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-thumbnail-apps.png",
    },
    {
      id: "starter-card-2",
      category: "Configuration",
      duration: "3 minutes",
      cta: "Configure Edges",
      backgroundColor: lightMode ? colors[0] : token.colorPrimaryBg,
      description:
        "Configure edges to optimize data transfer on the hypredge platform. Prevent data leakage and optimize data transfer",
      icon: goalsIcons.goalsConfigureEdgesIcon,
      destination: "/edges",
      videoUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-edge.mp4",
      thumbnailUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-thumbnail-edge.png",
    },
    {
      id: "starter-card-3",
      backgroundColor: lightMode ? colors[0] : token.colorPrimaryBg,
      category: "Operate",
      duration: "3 minutes",
      cta: "Create HyprFlows",
      description:
        "Create workflows that tie together multiple applications and automate your business processes. Save hours of manual work and improve productivity",
      icon: goalsIcons.goalsCreateHyprFlowsIcon,
      destination: "/hyprflows",
      videoUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-hyprflows.mp4",
      thumbnailUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-thumbnail-hyprflows.png",
    },
    {
      id: "starter-card-4",
      backgroundColor: lightMode ? colors[0] : token.colorPrimaryBg,
      category: "Operate",
      duration: "3 minutes",
      cta: "Try Federated Search",
      description:
        "Search for artifacts across multiple applications and get a unified view of your data. Find the needle in the haystack in seconds",
      icon: goalsIcons.goalsTrySearchIcon,
      destination: "/search",
      videoUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hypredge-reel-hyprlens.mp4",
      thumbnailUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-thumbnail-hyprlens.png",
    },
    {
      id: "starter-card-5",
      backgroundColor: lightMode ? colors[0] : token.colorPrimaryBg,
      category: "Collaborate",
      duration: "3 minutes",
      cta: "Invite Users",
      description:
        "Invite team members to collaborate on your goals. Assign roles and permission boundaries for each user",
      icon: goalsIcons.goalsInviteUsersIcon,
      destination: "/roles",
      videoUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hypredge-reel-rbac.mp4",
      thumbnailUrl:
        "https://app.dev.hypredge.com/apps/media/assets/hyprhdge-reel-thumbnail-rbac.png",
    },
  ];
  const [startIndex, setStartIndex] = useState(0);

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        padding: token.padding,
        width: "100%",
      }}
    >
      <div
        id="welcome-to-hypredge-box"
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: token.colorBgContainer,
          padding: token.padding,
          marginBottom: token.margin,
        }}
      >
        <Text
          style={{
            fontSize: token.fontSizeHeading5,
          }}
        >
          {dayOfWeek} {monthTextFull} {dayOfMonthNumeral}
        </Text>
        <Text
          style={{
            fontSize: token.fontSizeHeading3,
          }}
        >
          {getGreeting()}, {profile?.firstName} {profile?.lastName}
        </Text>
      </div>
      {/* <div id="goals-metrics-box">Home</div> */}
      <div id="goals-table-box">
        <GoalsItem />
      </div>
      <div
        id="configure-box"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: token.colorBgContainer,
          padding: token.padding,
          marginBottom: token.margin,
        }}
      >
        <div
          id="configure-text-box"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignSelf: "flex-start",
          }}
        >
          <Text strong>Steps to get started</Text>
        </div>
        <div
          id="configure-carousel-box"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: "20px",
            width: "100%",
            backgroundColor: token.colorBgContainer,
            padding: token.padding,
          }}
        >
          <LeftOutlined
            //disabled={startIndex == 0}
            style={{
              fontSize: token.fontSizeHeading5,
              color: token.colorText,
            }}
            onClick={() =>
              startIndex > 0 ? setStartIndex(startIndex - 1) : null
            }
          />
          {cardProps.slice(startIndex, startIndex + 3).map((p) => (
            <StarterCard
              backgroundColor={p.backgroundColor}
              category={p.category}
              cta={p.cta}
              description={p.description}
              destination={p.destination}
              duration={p.duration}
              icon={p.icon}
              key={p.id}
              id={p.id}
              videoUrl={p.videoUrl}
              thumbnailUrl={p.thumbnailUrl}
            />
          ))}
          <RightOutlined
            style={{
              fontSize: token.fontSizeHeading5,
              color: token.colorText,
            }}
            onClick={() =>
              startIndex < cardProps.length - 3
                ? setStartIndex(startIndex + 1)
                : null
            }
          />
        </div>
      </div>
    </div>
  );
};
