import { axiosInterceptor } from "./axios";
import { Activity } from "types";
import {activitiesApiPath, jsonApiDeserializer} from "./constant"


export const getActivitiesApi = async (
  pageNumber: number,
  pageSize: number,
  group?: string) : Promise<Activity[]> => {
  let url = `${activitiesApiPath}?page[number]=${pageNumber}&page[size]=${pageSize}`
  if (group) {
    url = url + `&filter=equals(group,'${group}')`
  }
  const response = await axiosInterceptor("get",url);
  return jsonApiDeserializer.deserialize(response.data)  as Activity[];
};
