
import { FormInstance } from "antd";
import { ColumnsType } from 'antd/es/table';

import { 
  App,
  Edge,
  WorkflowNodeType,  
  OperatorParameterMap, 
  OperatorParamValue, 
  WorkflowIOData, 
  WorkflowIODatas,
  IODataMap,
  IODataValue,
  AppInfo, 
  Condition, 
  Conditions, 
  Rule, 
  Rules, 
  SpecActionType, 
  SpecType, 
  AppSubscription,
  TextType,
  SearchTabType,
  PageInfo,
  Tag as Label,
  ArrayObjects,
  ArrayObject,
  IODatas,
  OperatorOutputMap,
} from "types";

import { UserDto, RoleDto, PermissionSetDto } from "./uam/rbac"
import { CSSProperties, ReactNode } from "react";
import { TooltipPlacement } from "antd/es/tooltip";
import { ParameterStoreParameter } from "./parameter_store";

//common
export interface SearchInputProps {
  placeholder?: string,
  value?: string;
  onFilter: (event : any) => void,
}

export interface ErrorModalProps {
  open: boolean;
  loader?: boolean;
  title: string;
  message: string;
  children?: any
  onClose: () => void;
}

export interface ModalProps {
  open: boolean;
  loader?: boolean;
  title: string | ReactNode;
  children?: any
  footerName?: string;
  disableSubmit?: boolean;
  hideSubmit?: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export interface ConditionsFormProps {
  editMode: boolean;
  required?: boolean;
  conditions: Array<Condition | Conditions> | undefined;
  factSuggestionsTree?: [];
  factList?: string[];
  valueSuggestionsTree?: [];
  valueList?: string[];
  onChange?: (value: Array<Condition | Conditions> | undefined) => void;
  onRender?: (form: FormInstance) => void;
}

export interface ConditionFormProps {
  editMode: boolean;
  condition: Condition | undefined;
  isCloseRequired: boolean;
  factSuggestionsTree?: [];
  factList?: string[];
  valueSuggestionsTree?: [];
  valueList?: string[];
  onChange?: (value: Condition) => void;
  onRender?: (form: FormInstance) => void;
  onDelete?: () => void;
}


//signin/up
export interface SignInSignUpMessageProps {
  isSuccess: boolean;
  title: string;
  sibTitle: string;
  buttonTitle: string;
  buttonOnclick: () => void;
}

//user/roles
export interface AddUserDrawerProps {
  visible: boolean;
  parentLoading: boolean;
  onClose: (fetchAllData?: boolean) => void;
  onSubmit: (value: any) => void;
  openAddRolesDrawer: () => void;
  userIdNeedToEdit?: string;
}
export interface AddRolesDrawerProps {
  visible: boolean;
  onClose: (fetchAllData?: boolean) => void;
  onSubmit: (value: any) => void;
  roleIdNeedToEdit?: string;
}
//user/roles
export interface AddPermissionSetDrawerProps {
  visible: boolean;
  onClose: (fetchAllData?: boolean) => void;
  onSubmit: (value: any) => void;
  openAddPermissionSetsDrawer: () => void;
  permissionSetIdNeedToEdit?: string;
}

export interface UserCardsProps {
  users: UserDto[];
  editUserTriggerHelper: (userId?: string) => void;
}
export interface UserCardProps extends UserDto {
  editUserTriggerHelper: (userId?: string) => void;
}

export interface RoleCardsProps {
  roles: RoleDto[];
  editRoleTriggerHelper: (roleId?: string) => void;
}

export interface RoleCardProps extends RoleDto {
  editRoleTriggerHelper: (roleId?: string) => void;
}

export interface PermissionSetCardsProps {
  psets: PermissionSetDto[];
  editPermissionSetTriggerHelper: (psetId?: string) => void;
}

export interface PermissionSetCardProps extends PermissionSetDto {
  editPermissionSetTriggerHelper: (psetId?: string) => void;
}


//profile
export interface AddEmailDrawerProps {
  visible: boolean;
  onClose: () => void;
}

export interface AddPhoneDrawerProps {
  visible: boolean;
  onClose: () => void;
}

export interface AddPasswordDrawerProps {
  visible: boolean;
  onClose: () => void;
}

export interface AddVerificationDrawerProps {
  visible: boolean;
  onClose: () => void;
}

export interface AddPurchasesDrawerProps {
  visible: boolean;
  onClose: () => void;
}

export interface AddCardsDrawerProps {
  visible: boolean;
  onClose: () => void;
}

export interface PhoneAddressOpenProps {
  loader: boolean;
  setLoader: (value: boolean) => void;
  onClose: (value?: any) => void;
}

export interface EmailAddressOpenProps {
  loader: boolean;
  setLoader: (value: boolean) => void;
  onClose: (value?: any) => void;
}


//apps
export interface AppConfigsProps {
  appId: string;
}

export interface AppConfigProps {
  appId: string;
  appSubscriptionId?: string;
  open: boolean;
  onClose: (refresh?: boolean) => void;
  onSave: (as: AppSubscription) => void;
}

export interface AppParameterProps{
  form:  FormInstance;
  edtiMode: boolean;
}

export interface HttpConfigProps{
  form:  FormInstance;
  edtiMode: boolean;
}

export interface SqlDBConfigProps{
  form:  FormInstance;
  edtiMode: boolean;
}

export interface WebhookConfigProps{
  appSubscriptionId: string;
  open: boolean;
  onClose: (isRegenerated?: boolean) => void;
  onRegenerate: (as: AppSubscription) => Promise<void>;
}

export interface AppEdgeConfigProps{
  form: FormInstance;
  appId: string;
  enableEdgeAutoDetect: boolean;
  editMode: boolean;
}

export interface AppCardProps {
  key: number;
  app: App;
  appSubscriptions: AppSubscription[];
  onOpenAppConfig: () => void;
}

//edges
export type EdgeConfigProps = {
  open: boolean;
  onClose: (refreshData?: boolean) => void;
};

export interface EdgeCardProps {
  edge: Edge;
  deleteEdge: (edgeId?: string) => void;
  enableEdge: (edgeId?: string) => void;
  disableEdge: (edgeSubscriptionId?: string) => void;
}

//search

export interface SearchTabProps {
  searchTabType: SearchTabType;
  searchPageInfo: PageInfo;
  headerPrefix: string;
  searchItemColumns: ColumnsType<any>;
  searchItemsDelete?: (value: string[]) => void;
  onPageChange?: (searchTabType: SearchTabType, pageNumber : number, pageSize : number) => void;
}
export interface SearchBarProps {
  inHeader: boolean;
  /** If provided then the search does not navigate, and expects the caller to handle the search */
  onQuery?: (value: string) => void;
}
export interface SaveSearchModalProps {
  open: boolean;
  loader: boolean;
  onClose: () => void;
  onSubmit: (name: string, description: string, selectedTags?: string[], tagsOnly?: boolean) => void;
  isEditId?: string;
}


//activity
export interface ActivityOverviewProps {
  pageSize?: number;
  widthPercentage?: string;
  resizable?: boolean;
  group?: string;
  nomargin?: boolean;
}

//workflow
export type CreateWorkflowModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: any) => void;
  loader: boolean;
};

export type PublishWorkflowModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: any) => void;
  loader: boolean;
};

export type WorkflowEditorProps = {
  editMode: boolean;
  templateMode?: boolean;
};

export enum WorkflowNodeActionType {
  Execute = "execute",
  Delete = "delete",
  Copy = "copy",
  SwapBranch = "swapBranch",
  Click = "click",
  Paste = "paste",
  Drop = "drop"
}

export type NodeActionCallbackFn = (type: WorkflowNodeActionType, id: string, data?: any) => void;
export interface WorkflowNodeProps {
  id: string; //id of node, same as step id
  resourceId: string; //this is the actual id from backend perspective, operator/action/triggerID
  resourceType: string; //sub resource type like schedule/wait/parallel
  nodeType: WorkflowNodeType;
  actionCallback?: NodeActionCallbackFn;
  editMode: boolean;
  templateMode?: boolean;
  hidden?: boolean;
  hiddenIcon?: any;
  dropzoneHint?: any;
  dropzoneNodeType?: any;
}

export interface WorkflowNodeActionProps {
  id: string;
  resourceType: string; //sub resource type like schedule/wait/parallel
  nodeType: WorkflowNodeType;
  actionCallback?: NodeActionCallbackFn;
  isCart: boolean;
}

export interface ApprovalActionProps {
  approvalId: string|undefined;
  displayName: string;
  isTooltipButton: boolean;
  buttonProperties?: CSSProperties;
  toolTipPlacement?: TooltipPlacement;
  onApproval?: () => void;
  showApprovalPending?: boolean;
  showApprovalCancel?: boolean;
  onCancel?: () => void;
}

export interface RenderCardProps {
  editMode: boolean;
  showWorkflowCard: boolean; 
  onClose: () => void;
  nodeCardProps?: WorkflowNodeCardProps;
}

export interface VersionCardProps {
  specType: SpecType;
  specId: string;
  width?: number;
  open?: boolean;
  editMode: boolean;
  onFetchVersions: (specId: string) => Promise<any[]>;
  onAction:(specId: string, action: SpecActionType, version?: unknown) => void;
  onActionCompleted:(specId: string, action: SpecActionType) => void;
  onClose: () => void;
}

export interface WorkflowCardProps {
  editMode: boolean;
  onClose: () => void;
}

export interface WorkflowNodeCardProps {
  id: string; //step id
  workflowId: string;
  resourceId: string; //this is the actual id from backend perspective, operator/action/triggerID
  resourceType: string;
  nodeType: WorkflowNodeType;
  editMode: boolean;
  actionCallback?: NodeActionCallbackFn;
  onClose: () => void;
}

export interface WorkflowCardParametersProps {
  editMode: boolean;
  parameters: WorkflowIODatas;
  onChange?: (value: WorkflowIODatas) => void;
  onRender?: (form: FormInstance) => void;
}

export interface WorkflowCardParameterProps {
  editMode: boolean;
  parameter: WorkflowIOData;
  onChange?: (value: WorkflowIOData) => void;
  onRender?: (form: FormInstance) => void;
}

export interface WorkflowCardOutputsProps {
  editMode: boolean;
  outputs: WorkflowIODatas;
  onChange?: (value: WorkflowIODatas) => void;
  onRender?: (form: FormInstance) => void;
}

export interface WorkflowCardOutputProps {
  editMode: boolean;
  output: WorkflowIOData;
  outputSuggestionsTree?: [];
  onChange?: (value: WorkflowIOData) => void;
  onRender?: (form: FormInstance) => void;
}

export interface ArrayObjectsFormProps {
  editMode: boolean;
  name: string;
  objects: ArrayObjects;
  required?: boolean;
  hideValue?: boolean;
  suggestionsTree?: [];
  onChange?: (value: ArrayObjects | undefined) => void;
  onRender?: (form: FormInstance) => void;
}

export interface ArrayObjectFormProps {
  editMode: boolean;
  name: string;
  hideValue?: boolean;
  object: ArrayObject;
  suggestionsTree?: [];
  onChange?: (value: ArrayObject) => void;
  onRender?: (form: FormInstance) => void;
}

export interface RunLogTreeProps {
  isWorkflowCard: boolean;
  stepId?: string;
}

export interface CollapsePanelProps {
  name: any;
  ghost?: boolean
  collapsePanel?: boolean
  extraElement?: any;
  style?: any;
  children?: any;
}

export interface NodeCardAppsProps {
  isSingle?: boolean;
  appInfos: Map<string, AppInfo>;
  selectedApps: Record<string, string|string[]>|  Record<string, string|string[]>[];
  editMode: boolean;
  onChange?: (apps: Record<string, string|string[]>|  Record<string, string|string[]>[]) => void;
}

export interface NodeCardParametersProps {
  nodeType: WorkflowNodeType;
  editMode: boolean;
  parameters: IODataMap | OperatorParameterMap;
  parametersValues?: Record<string, IODataValue> | Record<string, OperatorParamValue>;
  parameterSuggestionsTree?: [];
  appName?: string | ReactNode;
  onChange?: (value: Record<string, IODataValue> | Record<string, OperatorParamValue>) => void;
  onRender?: (form: FormInstance) => void;
}

export interface NodeCardOutputsProps {
  nodeType: WorkflowNodeType;
  outputs: IODatas | OperatorOutputMap;
}

export interface NodeCardRulesProps {
  editMode: boolean;
  rules: Rules;
  factSuggestionsTree?: [];
  onChange?: (value: Rules) => void;
  onRender?: (form: FormInstance) => void;
}

export interface NodeCardRuleProps {
  editMode: boolean;
  rule: Rule;
  factSuggestionsTree?: [];
  onChange?: (value: Rule) => void;
  onRender?: (form: FormInstance) => void;
}

export interface WorkflowParametersProps {
  editMode: boolean;
  parameters: WorkflowIODatas;
  parametersValues?: Record<string, any>;
  parameterSuggestionsTree?: [];
  onChange?: (value: Record<string, any>) => void;
  onRender?: (form: FormInstance) => void;
}

export interface ActionFormProps {
  actionId: string;
  tenantId?: string;
  appId?: string;
  isConfigured?: boolean;
  editMode: boolean;
  actionData: Record<string, any>;
  hideOutputs?: boolean;
  suggestionsTree?: [];
  onChange?: (actionData: Record<string, any>) => void;
  onRender?: (form: FormInstance) => void;
}

export const WorkflowFitViewOptions = {minZoom: 1.1, maxZoom: 1.1};

export const WorkflowDefaultZoom = 1
//metric

export interface SuggestionsInputProps {
  editMode: boolean;
  name: string;
  suggestionsTree?: [];
  allowClear?: boolean;
  textType?: TextType;
  setValue?: (value: string) => void;
  onChange?: (value: string) => void;
}

export interface SearchableTreeProps {
  placeholder?: string;
  treeData: [];
  onSelect?: (node: any) => void;
}

export interface SearchableTagsProps {
  tags: Label[];
  defaultTag?: string;
  selectedTags: string[];
  onSelect?: (tag: Label) => void;
}

export interface ParameterStoreItemProps {
  editMode: boolean;
  item: ParameterStoreParameter;
  onSubmit: (value: ParameterStoreParameter) => void;
  onClose: () => void;
  checkNameExists: (name: string) => boolean;
}