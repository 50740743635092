import { Card, Space, Image, Tag } from "antd"
import { AppInfo,  AppSubscriptionInfo } from "types"
import { getTagColor } from "utility"
import { SvgIcon } from "components/SvgIcon";
import { getAppEdgeLogo } from "utility/edge";

export interface appConfigurationView {
    ai: AppInfo
    asi: AppSubscriptionInfo
}
export type appConfigurationViews = appConfigurationView[];

export const viewAppConfiguration = (lightMode: boolean, view: appConfigurationView) => {
    return (
        <Card size="small">
            <Space size={20}>
                <Image
                    preview={false}
                    draggable={false}
                    // width={"90px"}
                    height={"15px"}
                    src={lightMode ? view.ai.logoUrl : view.ai.darkLogoUrl}
                />
                <Tag color={getTagColor(view.asi.name)}>{view.asi.name}</Tag>
                <Tag color={getTagColor(view.asi.edge?.displayName ? view.asi.edge.displayName : "")}>
                    <Space>
                        <SvgIcon component={getAppEdgeLogo(view.asi.edge?.infraType)} />
                        {view.asi.edge?.displayName}
                    </Space>
                </Tag>
            </Space>
        </Card>

    )
}