
import { FC, useEffect, useReducer, useState } from "react";
import { theme } from "antd";
import { useDragLayer } from 'react-dnd'
import { Node, isNode } from "reactflow";
import { v4 as uuidv4 } from "uuid";


import { useWorkflowStore, useSettingsStore } from "store";
import {
  DraggableItem,
  DraggableSourceType,
  OperatorType,
  ParameterStoreEdgePolicyName,
  PolicyCategoryType,
  TriggerType,
  WorkflowEditorProps,
  WorkflowNodeActionType,
  WorkflowNodeCardProps,
  WorkflowNodeProps,
  WorkflowNodeType,
} from "types";
import { notification } from "utility/notification";

import RenderCard from "../RenderCard";
import Sidebar from "../Sidebar";

import {
  addWorkflowNode,
  deleteWorkflowNode,
  getEditorNotCompatibleDroppables,
  getNotCompatibleDroppablesForBreakOperator,
  moveWorkflowNode,
  pasteWorkflowStep,
  swapNodeBranch,
} from "./utils";

import { getCanvasElementsFromWorkflow } from "./canvas";

import RenderFlow from "../RenderFlow";
import { LabelColors } from "./constants";
import { usePolicyStore } from "store/policy";
import { useParameterStoreStore } from "store/parameter_store";
import { createPortal } from "react-dom";
import NodeDragPreview from "../Nodes/Preview";
import { autoPopulateWorkflowStepsProviders } from "utility/workflow";
export enum WorkflowActionType {
  Sync = "sync",
}


const WorkflowEditor: FC<WorkflowEditorProps> = ({ editMode, templateMode }) => {
  const { token } = theme.useToken();
  const [nodeCardProps, setNodeCardProps] = useState<WorkflowNodeCardProps>(
    {} as WorkflowNodeCardProps
  );
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));
  const labelColors: LabelColors = {
    successBgColor: token.colorSuccessBg,
    errorBgColor: token.colorErrorBg,
    successColor: token.colorSuccess,
    errorColor: token.colorError,
  } as LabelColors;

  const {
    dragItem,
    isDragging,
  } = useDragLayer((monitor) => ({
    dragItem: monitor.getItem() as DraggableItem,
    isDragging: monitor.isDragging(),
  }));

  const loadParametersStore = async () => {
    try {
      const paramStorePolicy = await usePolicyStore.getState().getPolicy(PolicyCategoryType.WorkflowGeneral, ParameterStoreEdgePolicyName, false);
      if (paramStorePolicy) {
        if (paramStorePolicy?.value.data && paramStorePolicy?.value.data != "" && paramStorePolicy?.id) {
          const edgeId = paramStorePolicy.value.data;
          const storeId = paramStorePolicy.id;
          await useParameterStoreStore.getState().getParameters(edgeId, storeId);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    editMode && loadParametersStore();
  }, []);

  const {
    selectedWorkflow,
    updateWorkflow,
    setActiveNodeId,
    activeNodeCardId,
    activeCopyId,
    activeDropItem,
    stepsSidebarCollapsed,
    setActiveNodeCardId,
    setActiveCopyId,
    setActiveDragItem,
    setActiveDropItem,
    setElements,
  } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
    setActiveNodeId: state.setActiveNodeId,
    activeNodeCardId: state.activeNodeCardId,
    stepsSidebarCollapsed: state.stepsSidebarCollapsed,
    setActiveNodeCardId: state.setActiveNodeCardId,
    activeDropItem: state.activeDropItem,
    activeCopyId: state.activeCopyId,
    setActiveCopyId: state.setActiveCopyId,
    setActiveDragItem: state.setActiveDragItem,
    setActiveDropItem: state.setActiveDropItem,
    setElements: state.setElements,
  }));

  const onActionCallBack = (
    type: WorkflowNodeActionType,
    id: string,
    data?: any
  ) => {
    actionHandler({ type: type, id: id, data: data });
  };

  const getNodeCardPropsFromNode = (node: Node) => {
    return {
      id: node.id,
      workflowId: node.data.workflowId,
      resourceId: node.data.resourceId,
      resourceType: node.data.resourceType,
      nodeType: node.data.nodeType,
      actionCallback: onActionCallBack,
      editMode: editMode,
    } as WorkflowNodeCardProps;
  };

  const updateSelectedWorkflow = async () => {
    try {
      updateWorkflow(selectedWorkflow);
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    }
  };

  const refreshElements = (elements: any[]) => {
    elements.forEach((el: any) => {
      if (isNode(el)) {
        el.data = {
          ...el.data,
          editMode: editMode,
          templateMode: templateMode,
          actionCallback: onActionCallBack,
        };
      }
    });
  };

  const [elements, actionHandler] = useReducer(
    (elements: any[], action: any) => {
      switch (action.type) {
        case WorkflowActionType.Sync: {
          const updatedElements = getCanvasElementsFromWorkflow(
            selectedWorkflow,
            labelColors
          );
          updatedElements && refreshElements(updatedElements);
          setElements(updatedElements ? updatedElements : elements);
          return updatedElements ? updatedElements : elements;
        }
        case WorkflowNodeActionType.Delete: {
          const updatedElements = deleteWorkflowNode(
            action.id,
            action.data,
            selectedWorkflow,
            labelColors
          );
          updatedElements && refreshElements(updatedElements);
          updatedElements && updateSelectedWorkflow();
          activeCopyId && activeCopyId == action.id && setActiveCopyId(null);
          setElements(updatedElements ? updatedElements : elements);
          return updatedElements ? updatedElements : elements;
        }
        case WorkflowNodeActionType.Paste: {
          const updatedElements = pasteWorkflowStep(
            action.data,
            action.id,
            selectedWorkflow,
            elements,
            labelColors
          );
          updatedElements && refreshElements(updatedElements);
          updatedElements && updateSelectedWorkflow();
          setElements(updatedElements ? updatedElements : elements);
          return updatedElements ? updatedElements : elements;
        }
        case WorkflowNodeActionType.SwapBranch: {
          const updatedElements = swapNodeBranch(
            action.id,
            selectedWorkflow,
            labelColors
          );
          updatedElements && refreshElements(updatedElements);
          updatedElements && updateSelectedWorkflow();
          setElements(updatedElements ? updatedElements : elements);
          return updatedElements ? updatedElements : elements;
        }
        case WorkflowNodeActionType.Drop: {
          const updatedElements =
            action.source == DraggableSourceType.Sidebar
              ? addWorkflowNode(
                action.nodeProps,
                action.targetId,
                selectedWorkflow,
                elements,
                labelColors
              )
              : moveWorkflowNode(
                action.nodeProps,
                action.targetId,
                selectedWorkflow,
                labelColors
              );

          updatedElements && refreshElements(updatedElements);
          updatedElements && autoPopulateWorkflowStepsProviders(selectedWorkflow, false, [action.nodeProps.id]).then( (anyWorkflowChangeHappend) => {
            if(anyWorkflowChangeHappend) updateSelectedWorkflow();
          })
          setElements(updatedElements ? updatedElements : elements);
          return updatedElements ? updatedElements : elements;
        }
        case WorkflowNodeActionType.Click: {
          const node = elements.find(
            (el: any) => isNode(el) && el.id == action.id
          ) as Node;
          if (node && node.data.nodeType != WorkflowNodeType.Dropzone) {
            setActiveNodeCardId(action.id);
            setNodeCardProps(getNodeCardPropsFromNode(node));
            setActiveNodeId(action.id);
          }
          return elements;
        }
        case WorkflowNodeActionType.Copy: {
          setActiveCopyId(action.id);
          return elements;
        }
        case WorkflowNodeActionType.Execute:
          return elements;
        default:
          throw new Error("unknown action type", action);
      }
    },
    []
  );

  const syncWorkflow = async () => {
    try {
      if (selectedWorkflow.id) {
        actionHandler({ type: WorkflowActionType.Sync });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showNodeCard = () => {
    if (activeNodeCardId != "workflow" && activeNodeCardId != "trigger") {
      //delete case
      const activeNode = elements.find(
        (x: any) => isNode(x) && x.id == activeNodeCardId
      );
      if (!activeNode) return false;
    }

    //trigger manual case
    if (
      activeNodeCardId == "trigger" &&
      selectedWorkflow.triggerRef.triggerType == TriggerType.Manual
    ) {
      return false;
    }

    return activeNodeCardId != "";
  };

  //onDrop
  useEffect(() => {
    setActiveDragItem(null);
    if (!activeDropItem || !activeDropItem.source) return;
    if (!(
      activeDropItem.source == DraggableSourceType.Sidebar ||
      activeDropItem.source == DraggableSourceType.Editor
    )
    ) {
      return;
    }

    const props: WorkflowNodeProps = {
      id: activeDropItem.id ? activeDropItem.id : uuidv4(),
      nodeType: activeDropItem.nodeType,
      resourceId: activeDropItem.resourceId,
      resourceType: activeDropItem.resourceType,
      editMode: editMode,
    } as WorkflowNodeProps;

    actionHandler({
      type: WorkflowNodeActionType.Drop,
      nodeProps: props,
      source: activeDropItem.source,
      targetId: activeDropItem.targetId,
    });
    setActiveDropItem(null);
  }, [activeDropItem]);

  //ondrag
  useEffect(() => {
    if (!isDragging) return;
    if (!dragItem || !dragItem.source) return;
    if (
      !(
        dragItem.source == DraggableSourceType.Sidebar ||
        dragItem.source == DraggableSourceType.Editor
      )
    )
      return;

    if (dragItem.source == DraggableSourceType.Editor && dragItem.id) {
      dragItem.notCompatibleDroppables = getEditorNotCompatibleDroppables(
        dragItem.id,
        elements
      );
    }
    if (
      dragItem.source == DraggableSourceType.Sidebar &&
      dragItem.nodeType == WorkflowNodeType.Operator &&
      dragItem.resourceType == OperatorType.Break
    ) {
      dragItem.notCompatibleDroppables =
        getNotCompatibleDroppablesForBreakOperator(elements);
    }
    setActiveDragItem(dragItem);
  }, [isDragging]);

  useEffect(() => {
    syncWorkflow();
    setNodeCardProps({ ...nodeCardProps, editMode: editMode })
  }, [selectedWorkflow, editMode]);

  useEffect(() => {
    syncWorkflow();
  }, [mode]);

  return (
    <div
      id="workflow-editor"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        position: "relative",
      }}
    >
      {editMode && <NodeDragPreview />}
      {editMode && !stepsSidebarCollapsed && <Sidebar />}
      <RenderFlow elements={elements} />
      {showNodeCard() && (
        <RenderCard
          editMode={editMode}
          showWorkflowCard={activeNodeCardId == "workflow"}
          onClose={() => setActiveNodeCardId("")}
          nodeCardProps={nodeCardProps}
        />
      )}
    </div>
  );
};

export default WorkflowEditor;
