import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { ActivityStore } from "types";

import { getActivitiesApi } from "api";

export const useActivityStore = create<ActivityStore>()(
  devtools((set, get) => ({
    activities: [],
    getActivities: async (pageNumber, pageSize, group) => {
      try {
        const activities = await getActivitiesApi(pageNumber, pageSize, group);
        
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric", month: "short",
          hour: "2-digit", minute: "2-digit"
        };
        activities.forEach( (activity) => {
          activity.localTime = new Date(activity.localTime).toLocaleTimeString(undefined, options)
        })

        set((state) => ({
          activities: [...state.activities, ...activities]
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },
  }),
  { name: "ActivityStore" })
);
