import { APIError } from "openai";

export const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  day: "numeric", month: "short", year: "numeric",
  hour: "2-digit", minute: "2-digit", second: "numeric"
};

export const userRegions: string[] = [
  "North America",
  "Europe",
  "Asia Pacific",
  "Latin America",
  "Middle East",
  "Africa",
];

export interface Content {
  url : string;
  id : string;
  mime : string;
}

export interface PageInfo {
  size: number;
  number: number;
  total: number;
}

export interface Item {
  name: string; 
  description: string; 
  id: string
}

export interface ApiError {
  status: string;
  title?: string;
}
export type ApiErrors = ApiError[];

export const BigScreenQuery = { query: "(min-width: 1800px)" }


