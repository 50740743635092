import {
  Workflow,
  WorkflowNodeType,
  WorkflowStepAction,
  WorkflowStepOperator,
  Trigger,
  OperatorType,
  Action,
  Operator,
  WorkflowIODatas,
  IODataType,
  IOData,
  IODataMap,
  IODataArray,
  WorkflowSteps,
  WorkflowStepType,
  Artifact,
  OperatorOutput,
  OperatorIODataType,
  OperatorKVItems,
  WorkflowIOData,
  ArrayObjects,
  ArrayObjectDataType,
  OperatorAIResponseFormat,
} from "types";


import { useSearchArtifactStore, useWorkflowStepsStore, useWorkflowStore } from "store";
import { getTreeTitleIcon } from "./icons";
import { DecisionStepLabel } from "components/HyprFlows/Editor/constants";
import { useParameterStoreStore } from "store/parameter_store";

export const getRegEx = (...args: string[]): string => {
  const prefix = "{{\\$\\.";
  const suffix = "$";
  if (!args || !args.length) {
    return prefix + suffix;
  } else {
    return prefix + args.join("\\.") + "\\."+ suffix;
  }
};

const findAllNodesByKeyPrefix = (prefix: string, tree: any[], outNodes: any[]) => {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    const key = node.key as string;
    if (key.startsWith(prefix)) {
      outNodes.push(node);
    }
    if (node.children) {
      findAllNodesByKeyPrefix(prefix, node.children, outNodes);
    }
  }
};

export function getWorkflowStepsWithOrder(steps: WorkflowSteps, stepId: string, destStepId: string, workflowSteps: [string, WorkflowStepType][]) { 
  const step = Object.entries(steps).find(([id]) => id == stepId)
  if (step && ( destStepId ? (stepId != destStepId) : true)) {
    workflowSteps.push(step);
    if (step[1].type == WorkflowNodeType.Operator) {
      const decisionSteps = step[1].decisionSteps;
      if (Object.keys(decisionSteps).length) {
        decisionSteps[DecisionStepLabel.True] != "" && getWorkflowStepsWithOrder(steps, decisionSteps[DecisionStepLabel.True], destStepId, workflowSteps);
        decisionSteps[DecisionStepLabel.False] != "" && getWorkflowStepsWithOrder(steps, decisionSteps[DecisionStepLabel.False], destStepId, workflowSteps);
      }
      const parallelSteps = step[1].parallelSteps;
      if (Object.keys(parallelSteps).length) {
        parallelSteps.map((parallelStep) => {
          parallelStep != "" && getWorkflowStepsWithOrder(steps, parallelStep, destStepId, workflowSteps);
        })
      }
      const loopStep = step[1].loopStep;
      loopStep != "" && getWorkflowStepsWithOrder(steps, loopStep, destStepId, workflowSteps);
    }
    const nextStep = step[1].nextStep;
    nextStep != "" && getWorkflowStepsWithOrder(steps, nextStep, destStepId, workflowSteps);
  }
}

export const buildParameterStoreTree = (tree: any[]) => {
  const parameters = useParameterStoreStore.getState().parameters;
  if (parameters && parameters.length) {
    tree.push({
      title: "parameter store",
      label: "parameter store",
      icon: getTreeTitleIcon("paramstore"),
      key: "$.paramstore",
      regex: getRegEx(),
      value: "{{$.paramstore",
      selectable: false,
      children: parameters.map((parameter) =>  ({
        key: `"$.paramstore.${parameter.name}`,
        regex: getRegEx("paramstore"),
        title: parameter.name,
        label: parameter.name,
        value: `{{$.paramstore.${parameter.name}}}`,
        isLeaf: true,
      }))
    });
  }
}

export const buildWorkflowParameterTree = (parameters: WorkflowIODatas, tree: any[]) => {
  buildParameterStoreTree(tree);
  if (parameters) {
    const paramList: WorkflowIOData[] = parameters.filter((parameter) => parameter.type == IODataType.String || parameter.type == IODataType.Numeric || parameter.type == IODataType.Boolean).map((paramData) => paramData);
    if (paramList.length) {
      tree.push({
        title: "workflow",
        label: "workflow",
        icon: getTreeTitleIcon("workflow"),
        key: "$.workflow",
        regex: getRegEx(),
        value: "{{$.workflow",
        selectable: false,
        children: [
          {
            title: "parameters",
            label: "parameters",
            icon: getTreeTitleIcon("parameters"),
            key: "$.workflow.parameters",
            regex: getRegEx("workflow"),
            value: "{{$.workflow.parameters",
            selectable: false,
            children: paramList.map((param) =>  ({
              key: `$.workflow.parameters.${param.name}`,
              regex: getRegEx("workflow", "parameters"),
              title: param.name,
              label: param.name,
              value: `{{$.workflow.parameters.${param.referenceName}}}`,
              isLeaf: true,
            }))
          }
        ]
      });
    }
  }
};

export const buildTriggerOutputTree = (triggerId: string, tree: any[]) => {
  const triggers = useWorkflowStepsStore.getState().triggers;
  const trigger = triggers.find((i: Trigger) => i.id == triggerId) as Trigger;
  if (trigger && trigger.outputs && Object.keys(trigger.outputs).length) {
    tree.push({
      title: "trigger",
      label: "trigger",
      icon: getTreeTitleIcon("trigger"),
      key: "$.trigger",
      regex: getRegEx(),
      value: "{{$.trigger",
      selectable: false,
      children: [
        {
          title: "hits",
          label: "hits",
          //icon: getTreeTitleIcon("outputs"),
          key: "$.trigger.hits",
          regex: getRegEx("trigger"),
          value: "{{$.trigger.hits",
          selectable: false,
          children: [
            {
              title: "count",
              label: "count",
              //icon: getTreeTitleIcon("outputs"),
              key: "$.trigger.hits.count",
              regex: getRegEx("trigger", "hits"),
              value: "{{$.trigger.hits.count}}",
              selectable: true,
              children: []
            }
          ]
        },
        {
          title: "outputs",
          label: "outputs",
          icon: getTreeTitleIcon("outputs"),
          key: "$.trigger.outputs",
          regex: getRegEx("trigger"),
          value: "{{$.trigger.outputs",
          selectable: false,
          children: [
            ...getOutputTree(["trigger"], "$.trigger.outputs", trigger.outputs),
            {
              title: "raw",
              label: "raw",
              key: `$.trigger.outputs.raw`,
              regex: getRegEx("trigger", "outputs"),
              value: "{{$.trigger.outputs.raw}}"
            }
          ]
        }
      ]
    });
  } 
};

export const buildStepOutputTree = (workflow: Workflow, stepId: string, tree: any[]) => {
  const prevSteps: [string, WorkflowStepType][] = [];
  getWorkflowStepsWithOrder(workflow.steps, workflow.triggerRef.nextStep, stepId, prevSteps);
  const stepNodes: any[] = [];
  prevSteps.forEach( ([id, pStep]) => {
    if (pStep.type == WorkflowNodeType.Action) {
      const step = pStep as WorkflowStepAction;
      const actions = useWorkflowStepsStore.getState().actions;
      const action = actions.find((i: Action) => i.id == step.actionID) as Action;
      if (action && action.outputs && Object.keys(action.outputs).length) {
        if (step.provider) {
          stepNodes.push({
            title: step.name,
            label: step.referenceName,
            icon: getTreeTitleIcon("steps"),
            key: `$.steps.${step.referenceName}`,
            regex: getRegEx("steps"),
            value: `{{$.steps.${step.referenceName}`,
            selectable: false,
            children: [{
              title: "outputs",
              label: "outputs",
              icon: getTreeTitleIcon("outputs"),
              key: `$.steps.${step.referenceName}.outputs`,
              regex: getRegEx("steps", step.referenceName),
              value: `{{$.steps.${step.referenceName}.outputs`,
              selectable: false,
              children: [
                ...getOutputTree(
                ["steps", step.referenceName],
                `$.steps.${step.referenceName}.outputs`, 
                action.outputs),
                {
                  title: "raw",
                  label: "raw",
                  key: `$.steps.${step.referenceName}.outputs.raw`,
                  regex: getRegEx("steps", step.referenceName, "outputs"),
                  value: `{{$.steps.${step.referenceName}.outputs.raw}}`,
                }
              ]
            }]
          });
        }
      }
    } else if (pStep.type == WorkflowNodeType.Operator) {
      const step = pStep as WorkflowStepOperator;
      const operators = useWorkflowStepsStore.getState().operators;
      const operator = operators.find((i: Operator) => i.id == step.operatorID) as Operator;      

      if (operator.type == OperatorType.Ai) {
        stepNodes.push({
          title: step.name,
          label: step.referenceName,
          icon: getTreeTitleIcon("steps"),
          key: `$.steps.${step.referenceName}`,
          regex: getRegEx("steps"),
          value: `{{$.steps.${step.referenceName}`,
          selectable: false,
          children: 
            [{
                title: "outputs",
                label: "outputs",
                icon: getTreeTitleIcon("outputs"),
                key: `$.steps.${step.referenceName}.outputs`,
                regex: getRegEx("steps", step.referenceName),
                value: `{{$.steps.${step.referenceName}.outputs`,
                selectable: false,
                children: 
                  step?.parameters?.action_parameters?.response_format == OperatorAIResponseFormat.String
                  ?
                    [{
                      title: "data",
                      label: "data",
                      key: `$.steps.${step.referenceName}.outputs.data`,
                      regex: getRegEx("steps", step.referenceName, "outputs"),
                      value: `{{$.steps.${step.referenceName}.outputs.data}}`,
                    }]
                  :
                    step?.parameters?.action_parameters?.response_format == OperatorAIResponseFormat.JsonArray
                    ?
                      [{
                        title: "data[*]",
                        label: "data[*]",
                        key: `$.steps.${step.referenceName}.outputs.data[*]`,
                        regex: getRegEx("steps", step.referenceName, "outputs"),
                        value: `{{$.steps.${step.referenceName}.outputs.data[*]}}`,
                        children: getArrayObjectsTree(
                          ["steps", step.referenceName, "outputs"],
                          `$.steps.${step.referenceName}.outputs.data[*]`,
                          "data[*]", 
                          step?.parameters?.action_parameters?.outputs)
                      }]
                    :
                      [{
                        title: "data",
                        label: "data",
                        key: `$.steps.${step.referenceName}.outputs.data`,
                        regex: getRegEx("steps", step.referenceName, "outputs"),
                        value: `{{$.steps.${step.referenceName}.outputs.data`,
                        selectable: false,
                        children: getArrayObjectsTree(
                          ["steps", step.referenceName, "outputs", "data"],
                          `$.steps.${step.referenceName}.outputs.data`, 
                          "",
                          step?.parameters?.action_parameters?.outputs)
                      }]
            }]
        });
      }

      if (operator.type == OperatorType.Http || operator.type == OperatorType.Db) {
        stepNodes.push({
          title: step.name,
          label: step.referenceName,
          icon: getTreeTitleIcon("steps"),
          key: `$.steps.${step.referenceName}`,
          regex: getRegEx("steps"),
          value: `{{$.steps.${step.referenceName}`,
          selectable: false,
          children: [{
            title: "outputs",
            label: "outputs",
            icon: getTreeTitleIcon("outputs"),
            key: `$.steps.${step.referenceName}.outputs`,
            regex: getRegEx("steps", step.referenceName),
            value: `{{$.steps.${step.referenceName}.outputs`,
            selectable: false,
            children: [
              ...getArrayObjectsTree(
              ["steps", step.referenceName, "outputs"],
              `$.steps.${step.referenceName}.outputs`, 
              "",
              operator.type == OperatorType.Http ? step?.parameters?.http_outputs : step?.parameters?.db_outputs)
              ,
              {
                title: "raw",
                label: "raw",
                key: `$.steps.${step.referenceName}.outputs.raw`,
                regex: getRegEx("steps", step.referenceName, "outputs"),
                value: `{{$.steps.${step.referenceName}.outputs.raw}}`,
              }
            ]
          }]
        });
      }

      if (operator.type == OperatorType.Approval) {
        stepNodes.push({
          title: step.name,
          label: step.referenceName,
          icon: getTreeTitleIcon("steps"),
          key: `$.steps.${step.referenceName}`,
          regex: getRegEx("steps"),
          value: `{{$.steps.${step.referenceName}`,
          selectable: false,
          children: [{
            title: "outputs",
            label: "outputs",
            icon: getTreeTitleIcon("outputs"),
            key: `$.steps.${step.referenceName}.outputs`,
            regex: getRegEx("steps", step.referenceName),
            value: `{{$.steps.${step.referenceName}.outputs`,
            selectable: false,
            children: getOutputTree(
              ["steps", step.referenceName],
              `$.steps.${step.referenceName}.outputs`, 
              operator.outputs)
          }]
        });
      }

      if (operator.type == OperatorType.KV) {
        if (step.parameters && step.parameters.items) {
          stepNodes.push({
            title: step.name,
            label: step.referenceName,
            icon: getTreeTitleIcon("steps"),
            key: `$.steps.${step.referenceName}`,
            regex: getRegEx("steps"),
            value: `{{$.steps.${step.referenceName}`,
            selectable: false,
            children: [
              {
                title: "key",
                label: "key",
                key: `$.steps.${step.referenceName}.key`,
                regex: getRegEx("steps", step.referenceName),
                value: `{{$.steps.${step.referenceName}.key`,
                selectable: false,
                children: getKVKeys(
                  ["steps", step.referenceName],
                  `$.steps.${step.referenceName}.key`, 
                  step.parameters.items
                )
              }
            ]
          });
        }
      }

      if (operator.type == OperatorType.Loop) {
        if (step.parameters && step.parameters.data_set) {
          const dataSet = step.parameters.data_set as string;
          const nodeKeyPrefix = dataSet.replace("{{", "").replace("}}", "") + ".";
          const outputNodes:any[] = [];
          findAllNodesByKeyPrefix(nodeKeyPrefix, stepNodes, outputNodes);
          stepNodes.push({
            title: step.name,
            label: step.referenceName,
            icon: getTreeTitleIcon("steps"),
            key: `$.steps.${step.referenceName}`,
            regex: getRegEx("steps"),
            value: `{{$.steps.${step.referenceName}`,
            selectable: false,
            children: [
              {
                title: "key",
                label: "key",
                key: `$.steps.${step.referenceName}.key`,
                regex: getRegEx("steps", step.referenceName),
                value: `{{$.steps.${step.referenceName}.key}}`,
              },
              {
                title: "value",
                label: "value",
                key: `$.steps.${step.referenceName}.value`,
                regex: getRegEx("steps", step.referenceName),
                value: `{{$.steps.${step.referenceName}.value}}`,
                children: outputNodes.map((output: any) => ({
                  title: `${output.title}`,
                  label: `value.${output.title}`,
                  key: `$.steps.${step.referenceName}.value.${output.title}`,
                  regex: getRegEx("steps", step.referenceName),
                  value: `{{$.steps.${step.referenceName}.value.${output.title}}}`,
                  isLeaf: true,
                })),
              }
            ]
          });
        }
      }

      if (operator.type == OperatorType.Search) {
        if (step.parameters && step.searchProviders) {
          const artifactId = step.parameters.artifact_id as string;
          stepNodes.push({
            title: step.name,
            label: step.referenceName,
            icon: getTreeTitleIcon("steps"),
            key: `$.steps.${step.referenceName}`,
            regex: getRegEx("steps"),
            value: `{{$.steps.${step.referenceName}`,
            selectable: false,
            children:  [{
              title: "outputs",
              label: "outputs",
              icon: getTreeTitleIcon("outputs"),
              key: `$.steps.${step.referenceName}.outputs`,
              regex: getRegEx("steps", step.referenceName),
              value: `{{$.steps.${step.referenceName}.outputs`,
              selectable: false,
              children: getSearchResultTree(
                  ["steps", step.referenceName],
                  `$.steps.${step.referenceName}.outputs`, 
                  artifactId
                )
            }]
          });
        }
      }

      if (operator.type == OperatorType.Subworkflow) {
        if (step.parameters && step.parameters.workflow_id) {
          const workflowId = step.parameters.workflow_id as string;
          const subworkflows = useWorkflowStore.getState().subworkflows;
          const subworkflow = subworkflows?.find((w: Workflow) => w.id == workflowId) as Workflow;   
          stepNodes.push({
            title: step.name,
            label: step.referenceName,
            icon: getTreeTitleIcon("steps"),
            key: `$.steps.${step.referenceName}`,
            regex: getRegEx("steps"),
            value: `{{$.steps.${step.referenceName}`,
            selectable: false,
            children:  [{
              title: "outputs",
              label: "outputs",
              icon: getTreeTitleIcon("outputs"),
              key: `$.steps.${step.referenceName}.outputs`,
              regex: getRegEx("steps", step.referenceName),
              value: `{{$.steps.${step.referenceName}.outputs`,
              selectable: false,
              isLeaf: subworkflow?.outputs ? false : true,
              children: subworkflow?.outputs.map((output: any) => ({
                title: `${output.name}`,
                label: `${output.name}`,
                key: `$.steps.${step.referenceName}.outputs.${output.referenceName}`,
                regex: getRegEx("steps", step.referenceName, "outputs"),
                value: `{{$.steps.${step.referenceName}.outputs.${output.referenceName}}}`,
                isLeaf: true,
              }))
            }]
          });
        }
      }
    }
  });

  if (stepNodes.length) {
    tree.push({
      title: "steps",
      label: "steps",
      icon: getTreeTitleIcon("steps"),
      key: `$.steps`,
      regex: getRegEx(),
      value: "{{$.steps",
      selectable: false,
      children: stepNodes,
    })
  }
}

const getSearchResultTree = (regExPrefix: string[], prefix: string, artifactId: string) => {
  const tree: any[] = [{
    title: "total_count",
    label: "total_count",
    key: `${prefix}.total_count`,
    regex: getRegEx(...regExPrefix, "outputs"),
    value: `{{${prefix}.total_count}}`,
    isLeaf: true,
  },
  {
    title: "results_url",
    label: "results_url",
    key: `${prefix}.results_url`,
    regex: getRegEx(...regExPrefix, "outputs"),
    value: `{{${prefix}.results_url}}`,
    isLeaf: true,
  }]

  //add required fields from search artifact spec
  const artifacts = useSearchArtifactStore.getState().artifacts;
  if (artifacts?.length) {
    const artifact = artifacts?.find((i: Artifact) => i.id === artifactId);
    if (artifact) {
      /* TODO - enable this code when we support backend to resolve elements references */

      const requiredFields: string[] = [];
      for (const [name, field] of Object.entries(artifact?.fields)) {
        field.required && requiredFields.push(name);
      }

      const outputList = requiredFields
      if (outputList.length) {
        tree.push({
            title: `${artifact.name}[*]`,
            label: `${artifact.name}[*]`,
            key: `${prefix}.${artifact.name}[*]`,
            regex: getRegEx(...regExPrefix, "outputs"),
            value: `{{${prefix}.${artifact.name}[*]}}`,
            children: outputList.map((output) =>  ({
              title: output,
              label: `${artifact.name}[*].${output}`,
              key: `${prefix}.${artifact.name}[*].${output}`,
              regex: getRegEx(...regExPrefix, "outputs"),
              value: `{{${prefix}.${artifact.name}[*].${output}}}`,
              isLeaf: true,
            }))
        });
      }
    }
  }
  return tree;
}

/*
export const buildActionProviderOutputTree = (steps: WorkflowSteps, stepId: string, tree: any[]) => {
  //TODO - assume that previous step always has one element
  const prevStepId = ""
  // const prevStepId = (steps[stepId] && steps[stepId].prevSteps) ? steps[stepId].prevSteps[0] : "";
  if (!prevStepId || prevStepId == "") {
    return
  }
  
  buildActionProviderOutputTree(steps, prevStepId, tree);
  
  if (steps[prevStepId] && steps[prevStepId].type == WorkflowNodeType.Action) {
    const stepNodes: any[] = [];
    const step = steps[prevStepId] as WorkflowStepAction;
    const actions = useWorkflowStepsStore.getState().actions;
    const action = actions.find((i: Action) => i.id == step.actionID) as Action;
    if (action && action.outputs && Object.keys(action.outputs).length) {
      const appNodes: any[] = [];
      Object.values(step.providers).map((provider) => {
        provider.appSubscriptionInfos.map((appSubscriptionInfo) => {
          appNodes.push({
            title: `${provider.displayName} (${appSubscriptionInfo.name})`,
            label: appSubscriptionInfo.referenceName,
            icon: getTreeTitleIcon("apps"),
            key: `$.steps.${step.referenceName}.apps.${appSubscriptionInfo.referenceName}`,
            regex: getRegEx("steps", step.referenceName, "apps"),
            value: `{{$.steps.${step.referenceName}.apps.${appSubscriptionInfo.referenceName}`,
            selectable: false,
            children: [
              {
                title: "outputs",
                label: "outputs",
                icon: getTreeTitleIcon("outputs"),
                key: `$.steps.${step.referenceName}.apps.${appSubscriptionInfo.referenceName}.outputs`,
                regex: getRegEx("steps", step.referenceName, "apps", appSubscriptionInfo.referenceName),
                value: `{{$.steps.${step.referenceName}.apps.${appSubscriptionInfo.referenceName}.outputs`,
                selectable: false,
                children: getOutputTree(
                  ["steps", step.referenceName, "apps", appSubscriptionInfo.referenceName],
                  `$.steps.${step.referenceName}.apps.${appSubscriptionInfo.referenceName}.outputs`, 
                  action.outputs)
              }
            ]
          });
        })
      })
      if (appNodes.length) {
        stepNodes.push({
          title: step.name,
          label: step.referenceName,
          icon: getTreeTitleIcon("steps"),
          key: `$.steps.${step.referenceName}`,
          regex: getRegEx("steps"),
          value: `{{$.steps.${step.referenceName}`,
          selectable: false,
          children: [
            {
              title: "apps",
              label: "apps",
              icon: getTreeTitleIcon("apps"),
              key: `$.steps.${step.referenceName}.apps`,
              regex: getRegEx("steps", step.referenceName),
              value: `{{$.steps.${step.referenceName}.apps`,
              selectable: false,
              children: appNodes,
            }
          ]
        });
      }
    }
    if (stepNodes.length) {
      tree.push({
        title: "steps",
        label: "steps",
        icon: getTreeTitleIcon("steps"),
        key: `$.steps`,
        regex: getRegEx(),
        value: "{{$.steps",
        selectable: false,
        children: stepNodes,
      })
    }
  }
};
*/

/*
export function buildAllStepsOutputTree(steps: WorkflowSteps, tree: any[]) {
  const actions = useWorkflowStepsStore.getState().actions;
  const stepNodes: any[] = [];
  Object.values(steps).filter((workflowStep) => workflowStep.type == WorkflowNodeType.Action).forEach(actionStep => {
      const step = actionStep as WorkflowStepAction;
      const action = actions.find((i: Action) => i.id == step.actionID) as Action;
      if (action && action.outputs && Object.keys(action.outputs).length) {
        if (step.provider) {
          stepNodes.push({
            title: step.name,
            label: step.referenceName,
            icon: getTreeTitleIcon("steps"),
            key: `$.steps.${step.referenceName}`,
            regex: getRegEx("steps"),
            value: `{{$.steps.${step.referenceName}`,
            selectable: false,
            children: [{
              title: "outputs",
              label: "outputs",
              icon: getTreeTitleIcon("outputs"),
              key: `$.steps.${step.referenceName}.outputs`,
              regex: getRegEx("steps", step.referenceName),
              value: `{{$.steps.${step.referenceName}.outputs`,
              selectable: false,
              children: getOutputTree(
                ["steps", step.referenceName],
                `$.steps.${step.referenceName}.outputs`, 
                action.outputs)
            }]
          });
        }
      }
  });
  if (stepNodes.length) {
    tree.push({
      title: "steps",
      label: "steps",
      icon: getTreeTitleIcon("steps"),
      key: `$.steps`,
      regex: getRegEx(),
      value: "{{$.steps",
      selectable: false,
      children: stepNodes,
    })
  }
}
*/

const getKVKeys = (regExPrefix: string[], prefix: string, items: OperatorKVItems) => {
  const tree: any[] = [];
  Object.entries(items).map(([_, item]) => {
    switch (item.type) {
      case  OperatorIODataType.String:
      case OperatorIODataType.Numeric:
      case OperatorIODataType.Boolean:
        tree.push({
          title: `${item.key}`,
          label: `${item.key}`,
          key: `${prefix}.${item.key}`,
          regex:  getRegEx(...regExPrefix, "key"),
          value: `{{${prefix}.${item.key}}}`,
          isLeaf: true,
        });
        break;
      case OperatorIODataType.Array:
        tree.push({
          title: `${item.key}[*]`,
          label: `${item.key}[*]`,
          key: `${prefix}.${item.key}[*]`,
          regex: getRegEx(...regExPrefix, "key"),
          value: `{{${prefix}.${item.key}[*]}}`,
          isLeaf: true,
        });
        break;
    }
  });
  return tree;
}

const getOutputTree = (regExPrefix: string[], prefix: string, outputs: Record<string, IOData | IODataMap | IODataArray | OperatorOutput>) => {
  const tree: any[] = [];
  Object.entries(outputs).forEach(([k1, v1]) => {
    if ("type" in v1) {
      if (v1.type == IODataType.String || v1.type == IODataType.Numeric || v1.type == IODataType.Boolean) {
        tree.push({
          title: k1,
          label: k1,
          key: `${prefix}.${k1}`,
          regex: getRegEx(...regExPrefix, "outputs"),
          value: `{{${prefix}.${k1}}}`,
          isLeaf: true,
        });
      }
    } else {
      if (Array.isArray(v1)) {
        v1.map((output, index) => { 
          const outputList: string[] = [];
          Object.entries(output).forEach(([k2, v2]) => {
            (v2.type == IODataType.String || v2.type == IODataType.Numeric || v2.type == IODataType.Boolean || v2.type == IODataType.IP) && outputList.push(k2);
          });
          if (outputList.length) {
            //TODO - support user provided index or range
            tree.push({
              title: `${k1}[*]`,
              label: `${k1}[*]`,
              key: `${prefix}.${k1}[*]`,
              regex: getRegEx(...regExPrefix, "outputs"),
              value: `{{${prefix}.${k1}[*]}}`,
              children: outputList.map((output) =>  ({
                title: output,
                label: `${k1}[*].${output}`,
                key: `${prefix}.${k1}[*].${output}`,
                regex: getRegEx(...regExPrefix, "outputs", `${k1}\\[\\*\\]`),
                value: `{{${prefix}.${k1}[*].${output}}}`,
                isLeaf: true,
              }))
            });
          }
        });
      } else {
        const outputList: string[] = [];
        Object.entries(v1).forEach(([k2, v2]) => {
          (v2.type == IODataType.String || v2.type == IODataType.Numeric || v2.type == IODataType.Boolean || v2.type == IODataType.IP) &&  outputList.push(k2);
          (v2.type == IODataType.Array) &&  outputList.push(`${k2}[*]`)
        });
        if (outputList.length) {
          tree.push({
            title: k1,
            label: k1,
            key: `${prefix}.${k1}`,
            regex: getRegEx(...regExPrefix, "outputs"),
            value: `{{${prefix}.${k1}}}`,
            selectable: false,
            children: outputList.map((output) =>  ({
              title: `${output}`,
              label: `${output}`,
              key: `${prefix}.${k1}.${output}`,
              regex: getRegEx(...regExPrefix, "outputs", k1),
              value: `{{${prefix}.${k1}.${output}}}`,
              isLeaf: true,
            }))
          });
        }
      }
    }
  });
  return tree;
};

const getArrayObjectsTree = (regExPrefix: string[], prefix: string, labelPrefix: string, objects: ArrayObjects) => {
  const tree: any[] = [];
  objects?.map((object) => {
    if ("type" in object) {
      if (object.type == ArrayObjectDataType.String || object.type == ArrayObjectDataType.Numeric || object.type == ArrayObjectDataType.Boolean) {
        tree.push({
          title: object.name,
          label: labelPrefix != "" ? `${labelPrefix}.${object.name}` : object.name,
          key: `${prefix}.${object.name}`,
          regex: getRegEx(...regExPrefix),
          value: `{{${prefix}.${object.name}}}`,
          isLeaf: true,
        });
      } else if (object.type == ArrayObjectDataType.Array) {
        tree.push({
          title: `${object.name}[*]`,
          label: labelPrefix != "" ? `${labelPrefix}.${object.name}[*]` : `${object.name}[*]`,
          key: `${prefix}.${object.name}[*]`,
          regex: getRegEx(...regExPrefix),
          value: `{{${prefix}.${object.name}[*]}}`,
          isLeaf: true,
        });
      }
    }
  });
  return tree;
};

export const buildSearchActionParametersTree = (artifactName: string, tree: any[]) => {
  const artifacts = useSearchArtifactStore.getState().artifacts;
  if (artifacts?.length) {
    const artifact = artifacts?.find((i: Artifact) => i.name === artifactName);
    if (artifact) {
      const requiredFields: string[] = [];
      for (const [name, field] of Object.entries(artifact?.fields)) {
        field.required && requiredFields.push(name);
      }
      if (requiredFields.length) {
        tree.push({
          title: `${artifact.name}`,
          label: `${artifact.name}`,
          key: `$.${artifact.name}`,
          regex: getRegEx(),
          value: `{{$.${artifact.name}`,
          selectable: false,
          children: requiredFields.map((field) =>  ({
            title: `${field}`,
            label: `${field}`,
            key: `$.${artifact.name}.${field}`,
            regex: getRegEx(`${artifact.name}`),
            value: `{{$.${artifact.name}.${field}}}`,
            isLeaf: true,
          }))
        });
      }
    }
  }
};