import { create } from "zustand"
import { devtools } from "zustand/middleware";

import {
  App,
  AppSubscription,
  AppStore,
  AppSubscriptionStore,
  PageInfo
} from "types";

import {
  getAppsApi,
  getRecommendedEdgesForAppApi,
  createAppSubscriptionApi,
  getAppSubscriptionApi,
  updateAppSubscriptionApi,
  deleteAppSubscriptionApi,
  getAppApi,
  createAppApi,
  getAppSubscriptionsApi
} from "api";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import { convertToApiFilters, convertToApiSorters } from "./utils";

export const useAppStore = create<AppStore>()(
  devtools((set, get) => ({
    apps: [],
    selectedApp: {} as App,
    appPage: {number:1, size: 12, total: 0} as PageInfo,
    appFilter: {} as Record<string, FilterValue | null>,
    appSorter: {} as SorterResult<DataType>,
    appFields: [] as string[],
    appSearchTextMap: new Map<string, string>(),
    flippedAppId: "",
    totalAppsCount: 0,
    subscribedOnly: undefined,

    getApps: async (subscribedOnly, all) => {
      try {
        const pageInfo = get().appPage;
        const filters = get().appFilter;
        const searches = get().appSearchTextMap;
        const sorters = get().appSorter;
        const fields = get().appFields;
        
        const appFilters = convertToApiFilters(filters, searches)     
        const appSorters = convertToApiSorters(sorters);
        //default sorting
        if (appSorters.length == 0) {
          appSorters.push("displayName");
        }

        const pageNumber = all ? -1 : pageInfo.number;
        const pageSize = all ? -1 : pageInfo.size
        const [apps, totalCount] = await getAppsApi(pageNumber, pageSize, false, subscribedOnly, appFilters, appSorters, fields);
       
        set((state) => ({
          ...state,
          apps: [...apps],
          totalAppsCount: totalCount,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearApps: () => {
      set((state) => ({
        ...state,
        apps: [],
        selectedApp: {} as App,
        appsPage: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    resetAppStore: () => {
      set((state) => ({
        ...state,
        apps: [],
        selectedApp: {} as App,
        appFilter: {} as Record<string, FilterValue | null>,
        appSorter: {} as SorterResult<DataType>,
        appFields: [] as string[],
        appSearchTextMap: new Map<string, string>(),
        appsPage: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    getApp: async (appId) => {
      try {
        let app = get().apps.find((app) => app.id == appId);
        if (!app) {
          app = await getAppApi(appId);
        }
        set((state) => ({
          ...state,
          selectedApp: app,
        }));
        return app;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    createApp: async (appIn: App) => {
      try {
        const app = await createAppApi(appIn);
        set((state) => {
          const index = state.apps.findIndex((x) => x.id == app.id);
          index == -1 ? state.apps = [...state.apps, app] : state.apps[index] = app;
          state.selectedApp = app;
          return { ...state }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    setFlippedAppId: (flippedAppId) => {
      set((state) => ({
        ...state,
        flippedAppId: flippedAppId,
      }));
    },

    clearApp: () => {
      set((state) => ({
        ...state,
        selectedApp: {} as App,
      }));
    },

    setCurrentPage: (pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const newPage: PageInfo = {number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: state.appPage.total};
        return { ...state, appPage : newPage};
      });
      return;
    },

    setAppFilter: (filters?: Record<string, FilterValue | null>) => {
      set( (state) => {
        return { ...state, appFilter : filters};
      });
      return;
    },

    setAppSorter: (sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
      set( (state) => {
        return { ...state, appSorter : sorters};
      });
      return;
    },

    setAppSearchTextMap: (searches: Map<string, string>) => {
      set((state) => ({
        ...state,
        appSearchTextMap: searches,
      }));
    },

    setAppFields: (fields?: string[]) => {
      set( (state) => {
        return { ...state, appFields : fields};
      });
      return;
    },

    setSubscribedOnly: (subscribedOnly?: boolean) => {
      set( (state) => {
        return { ...state, subscribedOnly : subscribedOnly};
      });
      return;
    },
  }),
    { name: "AppStore" }
  )
);


export const useAppSubscriptionStore = create<AppSubscriptionStore>()(
  devtools((set, get) => ({
    appSubscriptions: [] as AppSubscription[],
    selectedApp: {} as App,
    selectedAppSubscription: {} as AppSubscription,
    appSubscriptionPage: {number:1, size: 12, total: 0} as PageInfo,
    recommendedEdges: [],

    getAppSubscriptions: async (appId: string, pageNumber: number, pageSize: number) => {
      try {
        const filters = [] as string[];
        filters.push("equals(appID,'" + appId + "')");
        const [appSubscriptions, totalCount] = await getAppSubscriptionsApi(pageNumber, pageSize, filters);
        const newPage: PageInfo = {number: pageNumber, size: pageSize, total: totalCount};
        let app = {} as App;
        if (totalCount > 0) {
          app = appSubscriptions[0].apps;
        } else {
          app = await useAppStore.getState().getApp(appId);
        }

        set((state) => ({
          ...state,
          appSubscriptions: [...appSubscriptions],
          selectedApp: totalCount > 0 ? appSubscriptions[0].apps : app,
          totalAppsCount: totalCount,
          appSubscriptionPage: newPage,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearAppSubscriptions: async () => {
      set((state) => ({
        ...state,
        appSubscriptions: [],
        selectedApp: {} as App,
        selectedAppSubscription: {} as AppSubscription,
        appSubscriptionPage: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    getAppSubscription: async (appSubscriptionId, refresh) => {
      try {
        let as = get().appSubscriptions.find(as => as.id == appSubscriptionId);
        if (!as || (as && !as.isConfigIncluded) || refresh) {
          as = await getAppSubscriptionApi(appSubscriptionId);
          as.isConfigIncluded = true;
        }

        set((state) => {
          if (as) {
            const index = state.appSubscriptions.findIndex((x) => x.id == appSubscriptionId);
            index == -1 ? state.appSubscriptions = [...state.appSubscriptions, as] : state.appSubscriptions[index] = as;
            state.selectedAppSubscription = as;
          }
          return { ...state }
        });

        return as;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearAppSubscription: async () => {
      set((state) => ({
        ...state,
        recommendedEdges: [],
        selectedAppSubscription: {} as AppSubscription,
      }));
    },

    createAppSubscription: async (appSubscription) => {
      try {
        const as = await createAppSubscriptionApi(appSubscription);
        as.isConfigIncluded = true;
        
        set((state) => {
          const index = state.appSubscriptions.findIndex((x) => x.id == appSubscription.id);
          index == -1 ? state.appSubscriptions = [...state.appSubscriptions, as] : state.appSubscriptions[index] = as;
          state.selectedAppSubscription = appSubscription;
          return { ...state }
        });
        return as;
      } catch (error: any) {
        console.log(error);
        throw new Error(error);
      }
    },

    updateAppSubscription: async (appSubscription) => {
      try {
        //TODO - need to fetch object from backend after update to reflect any backend changes like webhook etc..?
        await updateAppSubscriptionApi(appSubscription);

        set((state) => {
          const idx =  state.appSubscriptions.findIndex(as => as.id == appSubscription.id);
          state.appSubscriptions[idx] = appSubscription;
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.log(error);
        throw new Error(error);
      }
    },

    deleteAppSubscription: async (appSubscriptionId) => {
      try {
        await deleteAppSubscriptionApi(appSubscriptionId);

        set((state) => {
          state.appSubscriptions = state.appSubscriptions.filter(as => as.id !== appSubscriptionId);
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getRecommendedEdges: async (appId, httpConfig) => {
      try {
        const aer = await getRecommendedEdgesForAppApi(appId, httpConfig);
        
        set((state) => ({
          ...state,
          recommendedEdges: aer.edges,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearRecommendedEdges: () => {
      set((state) => ({
        ...state,
        recommendedEdges: [],
      }));
    },

    setCurrentPage: (pageNumber: number, pageSize: number) => {
      set( (state) => {
        const newPage: PageInfo = {number: pageNumber, size: pageSize, total: state.appSubscriptionPage.total};
        return { ...state, appSubscriptionPage : newPage};
      });
      return;
    },
  }),
    { name: "AppSubscriptionStore" }
  )
);