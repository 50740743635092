import {
  Button,
  Divider,
  Space,
  Spin,
  Typography,
  theme,
  Image,
  Badge,
  Tag,
  Card,
  Popover,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { FC, useEffect, useState } from "react";

import { useAppStore, useEdgeStore, useOrganizationStore, useSettingsStore } from "store";

import { getTagColor } from "utility";
import { useNavigate } from "react-router-dom";
import { App, AppSubscription, AppCategoriesMap } from "types";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { getAppLogoUrl } from "utility/app";
import { HLink } from "components/Link";
import { SvgIcon } from "components/SvgIcon";
import { getAppEdgeLogo } from "utility/edge";
import { organizationId } from "api/constant";
import { TableProps } from "antd/lib/table";
import { DataType } from "@textea/json-viewer";
import { FilterValue } from "antd/es/table/interface";

const { Text, Title, Paragraph } = Typography;

export const AppList: FC = () => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);

  const context = useOrganizationStore((state) => state.context);
 
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));

  const {mspEnabled, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  const edges = useEdgeStore((state) => state.edges);

  const {
    getApps,
    clearApps,
    apps,
    totalAppsCount,
    appPage,
    setCurrentPage,
    appFilter,
    setAppFilter,
    appSorter,
    setAppSorter,
    appSearchTextMap,
    setAppSearchTextMap,
    subscribedOnly, 
    setSubscribedOnly,
  } = useAppStore((state) => ({
    getApps: state.getApps,
    clearApps: state.clearApps,
    apps: state.apps,
    totalAppsCount: state.totalAppsCount,
    appPage: state.appPage,
    setCurrentPage: state.setCurrentPage,
    appFilter: state.appFilter,
    setAppFilter: state.setAppFilter,
    appSorter: state.appSorter,
    setAppSorter: state.setAppSorter,
    appSearchTextMap: state.appSearchTextMap,
    setAppSearchTextMap: state.setAppSearchTextMap,
    subscribedOnly: state.subscribedOnly, 
    setSubscribedOnly: state.setSubscribedOnly,
  }));

  const loadApps = async () => {
    try {
      setLoader(true);
      clearApps();
      await getApps(subscribedOnly);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  
  useEffect(() => {
    loadApps();
  }, [subscribedOnly, appSearchTextMap, appFilter, appSorter, appPage, context]);
  
  const viewAppConfiguration = (as: AppSubscription)  => {
    const edge = edges?.find((edge) => edge.id == as.edgeID);
    return (
      <Card size="small">
        <Space>
          <Tag color={getTagColor(as.name)}>
            {mspEnabled 
              ?
                <Popover 
                  title="Tag"
                  content= {
                    <Tag color={getTagColor(as.name)}>
                      {tags?.find((tag) => (tag.id == `${as.tenantID}_tag`))?.value}
                    </Tag>
                  }
                >
                  {as.name}
                </Popover>
              :
                as.name
            }
          </Tag>          
          <Tag color={getTagColor(edge?.displayName ? edge.displayName : "")}>
            <Space>
              <SvgIcon component={getAppEdgeLogo(edge?.infraType)}/>
              {edge?.displayName}
            </Space>
          </Tag>
        </Space>
      </Card>
    )
  }

  const columns: ColumnsType<App> = [   
    {
      title: "App",
      dataIndex: "",
      key: "app",
      width: '10%',
      render: (_, app) => {
        return (
          <Image
            preview={false}
            draggable={false}
            width={"90px"}
            height={"40px"}
            src={getAppLogoUrl(app, mode)}
            alt="company-logo"
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      width: '10%',
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (_, app) => {
        return (
          <HLink
            onClick={() => navigate(`/apps/${app.id}`)}
            text={app.displayName}
          />
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: '30%',
      render: (description) => {
        return (
          <Paragraph 
            style={{ overflow: "hidden" }}
            ellipsis={{
              rows: 2,
              expandable: 'collapsible',
              symbol: ((expanded: boolean) => expanded ? 'Less' : 'More') 
          }}>
            {description}
          </Paragraph>   
        );
      },
    },
    {
      title: "Owned By",
      dataIndex: "tenantID",
      key: "tenantID",
      width: '10%',
      filters: context ? [
        {
          text: 'HyprEdge',
          value: organizationId,
        },
        {
          text: 'Me',
          value: context?.split('_')[0] as string,
        },
      ] : undefined,
      filteredValue: appFilter["tenantID"],
      render: (tenantID) => {
        return (
          <Text>{`${tenantID}_tag}` == context ? "Me" : "HyprEdge"}</Text>
        );
      },
    },
    {
      title: "Configurations",
      dataIndex: "",
      key: "configurations",
      width: '30%',
      filters: [
        {
          text: 'Configured',
          value: 'configured',
        },
        {
          text: 'Not Configured',
          value: 'not-configured',
        },
      ],
      filteredValue: subscribedOnly != undefined ? (subscribedOnly ? ["configured"] : ["not-configured"]) : [],
      render: (_, app) => {
        return (
          app?.appSubscriptions?.length > 0
          ?
            <Space size={20}>
              {app?.appSubscriptions?.filter((_, index) => index == 0).map((as) => {
                return (
                  <Badge 
                    key={as.id} 
                    count={
                      app?.appSubscriptions?.length == 1
                        ? 0
                        :
                          <Popover
                            title={"Configurations"}
                            style={{ overflow: "scroll" }}
                            content={
                              <Space direction="vertical">
                              {app?.appSubscriptions?.filter((as, index) => index > 0).map((as) => viewAppConfiguration(as))}
                              </Space>
                            }
                          >
                            <Button 
                              type={"primary"} 
                              size={"small"} 
                              shape={"circle"} 
                              style={{ background: token.colorLinkHover }}
                              onClick={() => navigate(`/apps/${app.id}`)}
                            >
                              {`+${app?.appSubscriptions?.length-1}`}
                            </Button>
                          </Popover>
                    }
                  >
                    {viewAppConfiguration(as)}
                  </Badge>
              )})}
              <Button
                type="default"
                size="small"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 30,
                  zIndex: 1,
                }}
                icon={<EditOutlined style={{
                  color: token.colorPrimary,
                  position: "absolute",
                  right: 4,
                  top: 4,
                }} />}
                onClick={() => navigate(`/apps/${app.id}`)}
              />
            </Space>
          :
          <HLink
            onClick={() => navigate(`/apps/${app.id}`)}
            text={"Configure"}
            underline
            alwaysLinkColor
          />
        );
      },
    },
    {
      title: "Categories",
      dataIndex: "tags",
      key: "tags",
      width: '10%',
      filterSearch: true,
      filters: Array.from(AppCategoriesMap, ([tag, value]) => ({ text: value, value: tag })),
      filteredValue: appFilter["tags"],
      render: (tags: string[]) => {
        return (
          <Space size={1} style={{ display: "flex" }}>
            {tags?.map((tag) => 
              <Tag
                key={tag}
                color={getTagColor(tag)}
              >
                {AppCategoriesMap.get(tag)}
              </Tag>
            )}
          </Space>
        );
      },
    },
  ];

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter)  => {
    setAppSorter(sorter);

    const modifiedFilters = {} as Record<string, FilterValue | null>;
    if (filters) {
      Object.entries(filters).map(([field, values]) => {
        if (field == "configurations") {
          if (values && values?.length == 1) {
            setSubscribedOnly(values?.includes("configured"));
          } else {
            setSubscribedOnly(undefined);
          }
        } else {
          modifiedFilters[field] = values;
        }
      });
    }

    setAppFilter(modifiedFilters);
    setCurrentPage();
  }

  return (
    <div style={{ margin: token.margin, width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          height: "60px",
          paddingRight: token.padding,
          alignItems: "center",
        }}
      >
        <Title level={3}>Apps</Title> 
      </div>
      {useDivider && <Divider />}
      <Spin spinning={loader}>
        <EntitiesDisplay
          header={"Apps"}
          dataSource={apps as any[]}
          columns={columns}
          pageNumber={appPage.number}
          pageSize={appPage.size}
          total={totalAppsCount}
          onChange={onChange}
          onSearch={(text: string) => {
            setCurrentPage();
            appSearchTextMap.set("displayName", text);
            setAppSearchTextMap(appSearchTextMap);
          }}
          searchPlaceholder={"search apps by name"}
          searchText={appSearchTextMap.get("displayName")}
          onPageChange={(pageNumber : number, pageSize : number)  => setCurrentPage(pageNumber, pageSize)}
        />   
      </Spin>
    </div>
  );
};

export default AppList;