import {
  Divider,
  Tabs,
  Typography,
  theme,
} from "antd";

import { FC, useEffect } from "react";
import {
  MetricLayoutIdentifier,
  WorkspaceTabType
} from "types";
import ActivityOverview from "../../components/ActivityOverview";
import PageWrapper from "../../components/PageWrapper";
import WorkflowRunTab from "./WorkflowRun";
import SearchTab from "./Search";
import SearchActionTab from "./SearchAction";
import ApprovalTab from "./Approval";

import { workspaceIcons } from "assets/icons";
import { KpiPageHighlightGrid } from "components/Metrics";
import { TextWithIcon } from "components/TextWithIcon";
import { useParams } from "react-router-dom";
import { useMetricStore, useSettingsStore, useWorkspaceItemStore } from "store";

const { Title } = Typography;

const Workspace: FC = () => {
  const { token } = theme.useToken();
  const { active } = useParams();

  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);

  const metrics = useMetricStore((state) => state.metrics);
  const fetchMetricsForLayout = useMetricStore((state) => state.fetchMetricsForLayout);

  const { activeTab, setActiveTab } =
    useWorkspaceItemStore((state) => ({
      activeTab: state.activeTab,
      setActiveTab: state.setActiveTab,
    }));


  useEffect(() => {
    const asyncUseEffect = async () => {
      fetchMetricsForLayout(MetricLayoutIdentifier.MetricLayoutWorkspace);
    };
    asyncUseEffect();
  }, []);

  return (
    <PageWrapper
      highlights={
        <KpiPageHighlightGrid
          key="workspace"
          metrics={metrics && metrics.get(MetricLayoutIdentifier.MetricLayoutWorkspace)}
        />
      }
      // feedback={<ActivityOverview pageSize={10} resizable={true} />}
      content={
        <div style={{ margin: token.margin, width: "100%" }}>
          <Title level={3}>Workspace</Title>
          {useDivider && <Divider />}
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key as WorkspaceTabType)}
            items={[
              {
                label: (
                  <TextWithIcon
                    icon={workspaceIcons.workspaceWorkflow}
                    text={"Workflows"}
                  />
                ),
                key: WorkspaceTabType.WorkflowRuns,
                children: (
                  <WorkflowRunTab />
                ),
              },
              {
                label: (
                  <TextWithIcon
                    icon={workspaceIcons.workspaceSearch}
                    text={"Searches"}
                  />
                ),
                key: WorkspaceTabType.SearchRuns,
                forceRender: false,
                children: (
                  <SearchTab />
                ),
              },
              {
                label: (
                  <TextWithIcon
                    icon={workspaceIcons.workspaceAction}
                    text={"Actions"}
                  />
                ),
                key: WorkspaceTabType.ActionRuns,
                forceRender: false,
                children: (
                  <SearchActionTab />
                ),
              },
              {
                label: (
                  <TextWithIcon
                    icon={workspaceIcons.workspaceApproval}
                    text={"Approvals"}
                  />
                ),
                key: WorkspaceTabType.Approvals,
                forceRender: false,
                children: (
                  <ApprovalTab />
                ),
              },
            ]}
          />
        </div>
      }
    />
  );
};

export default Workspace;