import { FC, useState, useEffect } from "react";
import { Space, Spin, theme, Typography, Badge, Avatar } from "antd";
import { useNavigate } from "react-router-dom";

import { useOrganizationStore, useUserInfoStore, useWorkflowStore } from "store";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { HyprFlowTabType, SpecStateMap, SpecStateType, Workflow, dateTimeFormatOptions } from "types";
import { workflowIcons } from "assets/icons";
import { NIL } from "uuid";
import { HLink } from "components/Link";
import { notification } from 'utility/notification';
import { TextWithIcon } from "components/TextWithIcon";
import { createWorkflowApi } from "api";
import { cloneDeep } from "lodash";
import { autoPopulateWorkflowStepsProviders } from "utility/workflow";
import { TableProps } from "antd/lib";
import { DataType } from "@textea/json-viewer";

const { Text, Link } = Typography;

const TemplatesTab: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const { mspEnabled, context } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    context: state.context,
  }));

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const { 
    templates, 
    templatePage, 
    workflowPage, 
    getWorkflowTemplates, 
    getWorkflows, 
    setCurrentPage,
    templateFilters,
    setWorkflowFilters,
    templateSorters,
    setWorkflowSorters,
    workflowSearchTextMap,
    setWorkflowSearchTextMap,
  } = useWorkflowStore((state) => ({
    templates: state.templates,
    templatePage: state.workflowPage[HyprFlowTabType.WorkflowTemplates],
    workflowPage: state.workflowPage[HyprFlowTabType.Workflows],
    getWorkflowTemplates: state.getWorkflowTemplates,
    getWorkflows: state.getWorkflows,
    setCurrentPage: state.setCurrentPage,
    templateFilters: state.workflowFilters[HyprFlowTabType.WorkflowTemplates],
    setWorkflowFilters: state.setWorkflowFilters,
    templateSorters: state.workflowSorters[HyprFlowTabType.WorkflowTemplates],
    setWorkflowSorters: state.setWorkflowSorters, 
    workflowSearchTextMap: state.workflowSearchTextMap,
    setWorkflowSearchTextMap: state.setWorkflowSearchTextMap
  }));

  useEffect(() => {
    const asyncUseEffect = async () => {  
      setLoader(true);
      await getWorkflowTemplates(templatePage.number, templatePage.size);
      setLoader(false);
    };
    asyncUseEffect();
  }, [workflowSearchTextMap, templateFilters, templateSorters, context]);

   //Fetch user info for all users linked to workflows
   useEffect(() => {
    try {
      const userIds = [] as string[];
      templates.map((workflow) => {
        workflow.userID && workflow.userID != "" && userIds.push(workflow.userID);
        workflow.users?.map((user) => user && userIds.push(user));
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [templates]);

  const onPageChange = (pageNumber : number, pageSize : number)  => {
    setLoader(true);
    setCurrentPage(HyprFlowTabType.WorkflowTemplates, pageNumber, pageSize)
    getWorkflowTemplates(pageNumber, pageSize).then( () => {
      setLoader(false);
    });
  }

  const OnUseTemplates = async (items: any[], selectedTags?: string[], tagsOnly?: boolean) => {
    try {
      setLoader(true);
      await Promise.all(
        items.map(async (item: Workflow) => {
          const workflow = cloneDeep(item)
          workflow.id = NIL
          workflow.importTags = selectedTags;
          await autoPopulateWorkflowStepsProviders(workflow, true, []);
          await createWorkflowApi(workflow, true, tagsOnly);
          notification.success({
            message: `Workflow template ${workflow.name} imported successfully`,
            duration: 6,
          });
        })
      );
      await getWorkflows(workflowPage.number, workflowPage.size);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while imported templates...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }

  const columns: ColumnsType<Workflow> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortDirections: ['ascend', 'descend', null],
      render: (_, workflow) => (
        <TextWithIcon
          icon={workflowIcons.workflowShortIcon}
          iconSize={15}
          text={
            <HLink onClick={() => navigate("/workflow-templates/" + workflow.id)} text={workflow.name} />
          }
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, workflow) => (
        <Space size={token.marginXXS}>
          <Text>
            {workflow.description}
          </Text>
        </Space>
      ),
    },
    {
      title: "Shared By",
      dataIndex: "userID",
      key: "userID",
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <HLink href={"mailto:" + userInfos[userID]?.email} text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      filterSearch: true,
      filters: Array.from(SpecStateMap, ([tag, value]) => ({ text: value, value: tag })),
      filteredValue: templateFilters["state"],
      render: (state) => (
        <Space>
          { (state == SpecStateType.StateShared) && (
            <Badge count="shared" color="blue" />
          )}
          { (state == SpecStateType.StatePendingShareReview) && (
            <Badge count="pending review" color="orange" />
          )}
          { (state == SpecStateType.StatePendingDeleteReview) && (
            <Badge count="pending review" color="orange" />
          )}
        </Space>
      ),
    },  
    {
      title: "Created On",
       dataIndex: "updatedAt",
       key: "updatedAt",
       sorter: true,
       sortDirections: ['descend', 'ascend', null],
       render: (updatedAt: string) =>
         new Date(updatedAt).toLocaleTimeString(
           undefined,
           dateTimeFormatOptions
         ),
    },
  ];

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter)  => {
    setWorkflowSorters(HyprFlowTabType.WorkflowTemplates, sorter);
    setWorkflowFilters(HyprFlowTabType.WorkflowTemplates, filters);
    setCurrentPage(HyprFlowTabType.WorkflowTemplates);
  }

  return (
    <Spin spinning={loader}>
      <EntitiesDisplay
        header={"Templates"}
        dataSource={templates as any[]}
        columns={columns}
        pageNumber={templatePage.number}
        pageSize={templatePage.size}
        total={templatePage.total}
        onChange={onChange}
        onSearch={(text: string) => {
          setCurrentPage(HyprFlowTabType.WorkflowTemplates);
          const searchTextMap = workflowSearchTextMap[HyprFlowTabType.WorkflowTemplates];
          searchTextMap.set("name", text);
          setWorkflowSearchTextMap(HyprFlowTabType.WorkflowTemplates, searchTextMap);
        }}
        searchPlaceholder={"search templates by name"}
        searchText={workflowSearchTextMap[HyprFlowTabType.WorkflowTemplates]?.get("name")}
        onPageChange={onPageChange}
        actions={
          [
            {
              key: "useTemplate", 
              label: "Use Templates", 
              enable: (itemIds) => {
                return itemIds && itemIds.length > 0
              },
              mspMode: mspEnabled,
              onClick: (value, selectedTags, tagsOnly) => {
                if (value) {
                  const itemIds = value as string[];
                  const items = templates.filter((w) => itemIds.find((id) => id == w.id));
                  if (items) {
                    OnUseTemplates(items, selectedTags, tagsOnly);
                  }
                }
              }
            },
          ]
        }
      />
    </Spin>
  );
};

export default TemplatesTab;
