import {
  CheckCircleFilled,
  CloseCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { AutoComplete, Button, Input, Space, Switch, theme, Tooltip, Typography} from "antd";
import { FieldLabel } from "components/FieldLabel";
import { FC, KeyboardEvent, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import {
  OpenAIOutlined
} from "@ant-design/icons";

import { useSearchArtifactStore, useSearchStore } from "store";
import { SearchBarProps, SearchMetaData } from "types";
import { validateSearchQuery } from "utility/search";
import { getSearchSuggestions } from "utility/search/assist";
import styles from "./SearchBar.module.scss";

const SearchBar: FC<SearchBarProps> = ({ inHeader, onQuery }) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [searchButtonStatus, setSearchButtonStatus] = useState(false);
  const [searchSuggestionOptions, setSearchSuggestionOptions] = useState<{ label: string, value: string }[]>([]);
  const [searchQueryLastTokensList, setSearchQueryLastTokensList] = useState<string[]>([]);
  const [suggestionBoxOpen, setSuggestionBoxOpen] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const { artifacts, getArtifacts } = useSearchArtifactStore((state) => ({
    artifacts: state.artifacts,
    getArtifacts: state.getArtifacts,
  }));

  const { searchQuery, setSearchQuery, runSearchX, runSearch} = useSearchStore(
    (state) => ({
      searchQuery: state.searchQuery,
      setSearchQuery: state.setSearchQuery,
      runSearchX: state.runSearchX,
      runSearch: state.runSearch,
    })
  );
  
  const syncArtifacts = async () => {
    try {
      if (!artifacts.length) {
        await getArtifacts();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const asyncUseEffect = async () => {
      syncArtifacts();
    };
    asyncUseEffect();
    if(searchQuery && searchQuery.query && searchQuery.query != "") {
      validateSearch();
    }
  }, []);

  useEffect(() => {
    onSearchItemChange(searchQuery.query);
  }, [searchQuery.query]);

  const resetState = () => {
    setSearchButtonStatus(false);
    setSearchSuggestionOptions([]);
    setSearchQueryLastTokensList([]);
    setSuggestionBoxOpen(false);
    setSearchInProgress(false);
  }

  function setSearchOptions(value: string) {
    // generating search suggestion
    const {suggestions, lastTokens} = getSearchSuggestions(value);
    const searchSuggestionOptions = suggestions.map( (suggestion) => suggestion.tokens.map(
      (token) => {
        const isMandatory = suggestion.mandatoryTokens?.find ( (x) => x == token)
        return {
          label: isMandatory ? token + "*" : token,
          value: token,
        };
      }
    ));
    setSearchSuggestionOptions(searchSuggestionOptions[0]);
    setSearchQueryLastTokensList(lastTokens);
    if (value != searchQuery.query ) {
      setSearchQuery({query: value, isAiBasedQuery: searchQuery.isAiBasedQuery} as SearchMetaData)
    }else {
      setSearchQuery({...searchQuery, query: value, nativeQuery: ""} as SearchMetaData)
    }
  }

  function onSearchItemChange(value: string) {
    setSearchButtonStatus(false);
    if (value == "") {
      setSearchOptions(value);
      return;
    }
    // generating search suggestion
    setSearchOptions(value);
    validateSearch();
  }

  function calculateSearchQueryOnSuggestionSelect(selectedValue: string): string {
    let newSearchQuery = "";
    if (searchQuery.query) {
      newSearchQuery = searchQuery.query;
    }

    const searchTokens = searchQuery.query?.split(" ");
    if (searchTokens?.length) {
      if (searchQueryLastTokensList.length) {
        for (const token of searchQueryLastTokensList) {
          if (
            token.length &&
            selectedValue.includes(token) &&
            searchTokens.pop() == token
          ) {
            newSearchQuery = searchTokens.join(" ");
          }
        }
      }
    }
    if (newSearchQuery.at(-1) && newSearchQuery.at(-1) != " ") {
      newSearchQuery = newSearchQuery + " ";
    }
    return newSearchQuery + selectedValue;
  }

  const onSelect = (value: string) => {
    setSearchButtonStatus(false)
    onSearchItemChange(calculateSearchQueryOnSuggestionSelect(value));
    setSearchButtonStatus(true)
  };

  const onSearch = async () => {
    try {
      if (searchQuery.query) {
        if (onQuery) onQuery(searchQuery.query);
        else {
          setLoader(true);
          const searchRunId = searchQuery.id ? await runSearch() : await runSearchX(searchQuery.query);
          if (searchRunId ) {
            navigate(`/search-runs/${searchRunId}`);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
      setSearchInProgress(false);
    }
  };


  const searchButton = () => (
    <Button
      style={{
        backgroundColor: token.colorPrimaryActive,
      }}
      loading={loader}
      icon={<SearchOutlined style={{ color: token.colorTextLightSolid }} />}
      onClick={() => {
        if (!searchInProgress && searchButtonStatus) {
          setSearchInProgress(true);
          onSearch();
        }
      }}
    />
  );

  const handleKeyDownAutoComplete = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case "Escape": {
        e.preventDefault();
        e.stopPropagation();
        break;
      }
      case "Enter": {
        e.preventDefault();
        e.stopPropagation();
        break;
      }
    }
  };

  const validateSearch  = () => {
    if (searchQuery.query != "") {
      let result = false;
      try {
        if (searchQuery.query) {
          result = validateSearchQuery(searchQuery.query);
        }
      } catch (error) {
        result = false;
      }
      result ? setSearchButtonStatus(true) : setSearchButtonStatus(false);
    }
  }

  const renderInputStatus = () => {
    if (searchQuery.query != "") {
      return (
        searchButtonStatus ? (
          <CheckCircleFilled style={{ color: token.colorSuccess }} />
        ): 
          <CloseCircleFilled style={{ color: token.colorWarning }} />
      )
    }else {
      return (
        <></>
      )
    }
  }

  function onAiSearchItemChange(value: string) {
    if (value != searchQuery.query ) {
      setSearchQuery({query: value, isAiBasedQuery: searchQuery.isAiBasedQuery} as SearchMetaData)
    }else {
      setSearchQuery({...searchQuery, query: value, nativeQuery: ""} as SearchMetaData)
    }
  }

  const getInputPrefix = (aiEnabled :boolean) => {
    return (
      <OpenAIOutlined
        className={!aiEnabled ?  styles.aiEnabled : styles.aiDisabled}
        onClick={ () => {
          setSearchQuery({query:"", isAiBasedQuery: aiEnabled} as SearchMetaData);
          resetState();
        }}
        style={{color: searchQuery.isAiBasedQuery  ?  token.colorSuccess : token.colorPrimary,  marginRight: "5px"}}
      />
    )
  }

  const getNativeQueryInput = () => {
    return (
      <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <AutoComplete
            style={{ flex: 1 }}
            autoFocus={inHeader ? false : true}
            //defaultActiveFirstOption={true}
            defaultOpen={false}
            options={searchSuggestionOptions}
            disabled={searchInProgress}
            onSelect={onSelect}
            onSearch={onSearchItemChange}
            onFocus={() => setSearchOptions(searchQuery.query)}
            value={searchQuery.query}
            onDropdownVisibleChange={(open: boolean) => setSuggestionBoxOpen(open)}
            onKeyDown={handleKeyDownAutoComplete}
          >
            <Input.Search
              placeholder="Search for any artifact across HyprEdge and connected Apps"
              loading={loader}
              enterButton={searchButton()}
              onPressEnter={() => {
                if (!searchInProgress && !suggestionBoxOpen && searchButtonStatus) {
                  setSearchInProgress(true);
                  onSearch();
                }
              }}
              onSearch={() => {
                if (!searchInProgress && !suggestionBoxOpen && searchButtonStatus) {
                  setSearchInProgress(true);
                  onSearch();
                }
              }}
              prefix={getInputPrefix(true)}
              suffix={renderInputStatus()}
            />
        </AutoComplete>
      </div>
    )
  }

  const getAiQueryInput = () => {
    return (
      <div style={{ display: "flex", width: "100%", flexDirection: "column"}}>
        <Input.Search
          placeholder="Search for any artifact across HyprEdge and connected Apps"
          loading={loader}
          enterButton={searchButton()}
          onPressEnter={() => {
            if (!searchInProgress) {
              setSearchInProgress(true);
              onSearch();
            }
          }}
          onSearch={() => {
            if (!searchInProgress) {
              setSearchInProgress(true);
              onSearch();
            }
          }}
          prefix={getInputPrefix(false)}
          onChange= {(e) => {
            onAiSearchItemChange(e.target.value)
          }}
          value={searchQuery.query}
        />
        {searchQuery.assistMessage && 
          <Tooltip title={searchQuery.assistMessage}>
            <Typography.Text italic  
              ellipsis={inHeader}
              style={{
                marginLeft: token.marginSM, 
                fontSize: token.fontSizeSM,
                maxWidth: inHeader ? "500px": ""
              }}
            >{searchQuery.assistMessage}</Typography.Text>
          </Tooltip>
        }
      </div>
    )
  }

  return !searchQuery.isAiBasedQuery ? getNativeQueryInput() : getAiQueryInput();
};

export default SearchBar;
