import { FC, useState } from "react";
import { Space, Card, Tooltip, Typography, theme } from "antd";
import { WarningOutlined, AuditOutlined} from "@ant-design/icons";

import { SvgIcon } from "components/SvgIcon";
import { useSettingsStore, useWorkflowStore } from "store";
import { workflowIcons } from "assets/icons";

import {
  OperatorType,
  TriggerType,
  WorkflowNodeActionType,
  WorkflowNodeProps,
  WorkflowNodeType,
  WorkflowRunStatusCode,
} from "types";
import {
  getStepColor,
  getStepLogo,
  getStepName,
  getTitle,
  getTriggerDisplayName,
} from "utility/workflow";

import NodeAction from "components/HyprFlows/NodeAction";
import ApprovalAction from "components/ApprovalAction";
import { useNodeStyles } from "../styles";

const { Text } = Typography;
const NodeBody: FC<WorkflowNodeProps> = ({
  id,
  resourceId,
  resourceType,
  nodeType,
  actionCallback,
  editMode,
  templateMode,
}) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState(false);
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const commonStyles = useNodeStyles();

  const nodeBandColor = getStepColor(nodeType);
  const nodeHoverColor = token.colorPrimaryBg;
  const nodeSelectedColor = token.colorPrimaryBgHover;

  const boxShadowColor = lightMode ? "#D3D3D3" : "#1c1c1c";
  const nodeBgColor = token.colorBgBase;

  const { activeNodeId, selectedWorkflow, selectedWorkflowRun } =
    useWorkflowStore((state) => ({
      activeNodeId: state.activeNodeId,
      selectedWorkflow: state.selectedWorkflow,
      selectedWorkflowRun: state.selectedWorkflowRun,
    }));

  const getReferenceName = () => {
    let displayName = "";
    if (nodeType == WorkflowNodeType.Trigger) {
      const triggerDisplayName = getTriggerDisplayName(selectedWorkflow);
      if (triggerDisplayName) displayName = triggerDisplayName;
    } else {
      displayName = selectedWorkflow.steps?.[id]?.name
        ? selectedWorkflow.steps[id]?.name
        : "";
    }
    return displayName;
  };

  const getIsConfigured = () => {
    if (nodeType == WorkflowNodeType.Trigger) {
      if (selectedWorkflow?.triggerRef?.triggerType != TriggerType.Manual) {
        return selectedWorkflow.triggerRef?.isConfigured;
      } else {
        return true;
      }
    }
    return selectedWorkflow.steps?.[id]?.isConfigured;
  };

  const getIsAuditRequired = () => {
    if (nodeType == WorkflowNodeType.Trigger) {
      if (selectedWorkflow?.triggerRef?.triggerType != TriggerType.Manual) {
        return selectedWorkflow.triggerRef?.isAuditRequired;
      } else {
        return false;
      }
    }
    return selectedWorkflow.steps?.[id]?.isAuditRequired;
  };

  const getRunStatus = () => {
    let status = WorkflowRunStatusCode.Unspecified;
    let reason = "";

    switch (nodeType) {
      case WorkflowNodeType.Trigger:
        status = selectedWorkflowRun?.result?.trigger.status;
        reason = selectedWorkflowRun?.result?.trigger?.reason;
        break;
      case WorkflowNodeType.Operator:
      case WorkflowNodeType.Action:
        if (selectedWorkflowRun?.result?.steps) {
          status = selectedWorkflowRun.result.steps[id]?.status;
          reason = selectedWorkflowRun.result.steps[id]?.reason;
        }
        break;
    }
    return { status, reason };
  };

  const onNodeClick = () => {
    actionCallback && actionCallback(WorkflowNodeActionType.Click, id);
  };
  const showRunStatus = () => {
    switch (getRunStatus().status) {
      case WorkflowRunStatusCode.Running:
        return (
          <SvgIcon
            size={15}
            component={workflowIcons.logProgressIcon}
            hexColor={"orange"}
          />
        );
      case WorkflowRunStatusCode.PendingApproval:
        return (
          <SvgIcon
            size={15}
            component={workflowIcons.logPendingApprovalIcon}
            hexColor={"blue"}
          />
        );
      case WorkflowRunStatusCode.Completed:
        return (
          <SvgIcon
            size={15}
            component={workflowIcons.logSuccessIcon}
            hexColor={"green"}
          />
        );
      case WorkflowRunStatusCode.Failed:
        return (
          <Tooltip
            placement="right"
            color={token.colorPrimary}
            title={getRunStatus().reason}
          >
            <div>
              <SvgIcon
                size={15}
                component={workflowIcons.logFailedIcon}
                hexColor="red"
              />
            </div>
          </Tooltip>
        );
      default:
        return <></>;
    }
  };

  const showConfigStatus = () => {
    if (templateMode || getIsConfigured()) {
      return <></>;
    }
    return (
      <Tooltip placement="right" title={"configuration is incomplete"}>
        <WarningOutlined style={{ color: token.colorWarning }} />
      </Tooltip>
    );
  };

  const showAuditStatus = () => {
    if (templateMode || !getIsAuditRequired()) {
      return <></>;
    }
    return (
      <Tooltip placement="right" title={"audit is required"}>
        <AuditOutlined style={{ color: token.colorSuccess }} />
      </Tooltip>
    );
  };

  const showApprovalPendingStatus = () => {
    if (getRunStatus().status != WorkflowRunStatusCode.PendingApproval) {
      return <></>;
    }
    return (
      <Tooltip
        placement="right"
        title={"approval is pending"}
        color={token.colorPrimaryHover}
      >
        <WarningOutlined style={{ color: token.colorInfo }} />
      </Tooltip>
    );
  };

  const isStepApprovalPending = () => {
    return (
      resourceType == OperatorType.Approval &&
      selectedWorkflowRun?.result?.steps?.[id]?.status ==
        WorkflowRunStatusCode.PendingApproval
    );
  };

  return (
    <Card.Grid
      className={commonStyles.node}
      style={{
        backgroundColor: hover
          ? activeNodeId == id || isStepApprovalPending()
            ? nodeSelectedColor
            : nodeHoverColor
          : activeNodeId == id || isStepApprovalPending()
          ? nodeSelectedColor
          : nodeBgColor,
        borderRadius: token.sizeXS,
        border: "1px solid",
        borderLeftWidth: token.sizeXXS,
        borderLeftColor: nodeBandColor,
        borderRightColor: token.colorBorder,
        borderTopColor: token.colorBorder,
        borderBottomColor: token.colorBorder,
        position: "fixed",
        opacity:  1,
        boxShadow: `2px 2px 2px ${boxShadowColor}`,
        zIndex: 200,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(event: any) => {
        event.stopPropagation();
        onNodeClick();
      }}
    >
      {editMode &&
        selectedWorkflowRun.status == WorkflowRunStatusCode.NotRunning && (
          <div
            style={{
              display: hover ? "block" : "none",
              backgroundColor: nodeBgColor,
              position: "absolute",
              top: "-25px",
              right: "0px",
              zIndex: "100",
            }}
          >
            <NodeAction
              id={id}
              resourceType={resourceType}
              actionCallback={actionCallback}
              nodeType={nodeType}
              isCart={false}
            />
          </div>
        )}
      {resourceType == OperatorType.Approval && (
        <div
          style={{
            display: hover ? "block" : "none",
            backgroundColor: nodeBgColor,
            position: "absolute",
            top: "-25px",
            right: "0px",
            zIndex: "100",
          }}
        >
          <ApprovalAction
            approvalId={selectedWorkflowRun?.result?.steps?.[id]?.approvalID}
            displayName={getReferenceName()}
            buttonProperties={{
              color: nodeBandColor,
              borderColor: token.colorBorder,
            }}
            isTooltipButton={true}
          />
        </div>
      )}
      <Space size={0}>
        <SvgIcon
            size={40}
            style={{
              marginTop: token.sizeXXS,
              marginLeft: token.sizeXXS,
              height:"40px"
            }}
            component={getStepLogo(nodeType, resourceType)}
          />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: token.sizeXS,
          }}
        >
          <Text
            style={{
              fontSize: "12px",
              fontWeight: "300",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: token.colorTextLabel,
              marginTop: token.sizeXXS,
            }}
            ellipsis
          >
            {getTitle(nodeType, resourceId)}
          </Text>
          <Text
            style={{
              fontSize: "12px",
              fontWeight: "400",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: lightMode ? token.colorPrimary : token.colorText 
            }}
            ellipsis
          >
            {getReferenceName()}
          </Text>
        </div>
      </Space>
      <div
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          padding: "1px",
        }}
      >
        {showAuditStatus()}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "2px",
        }}
      >
        {showConfigStatus()}
      </div>
      <div
        style={{ position: "absolute", top: "0", right: "0", padding: "2px" }}
      >
        {showRunStatus()}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "2px",
        }}
      >
        {showApprovalPendingStatus()}
      </div>
    </Card.Grid>
  );
};

export default NodeBody;
