import { FC, useState } from "react";
import { Card, Spin, theme, Space, MenuProps, Row, Col, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { useWorkflowStepsStore, useWorkflowStore } from "store";
import SearchInput from "../../SearchInput";
import {
  Action,
  DraggableItem,
  Operator,
  WorkflowNodeType,
  DraggableSourceType,
  WorkflowRunStatusCode,
  OperatorType,
} from "types";
import { getStepName } from "utility/workflow";

import SidebarDraggableItem from "./draggableItem";

const Sidebar: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState("");
  const [showAllOperators, setShowAllOperators] = useState<boolean>(false);

  const { actions, operators } = useWorkflowStepsStore((state) => ({
    actions: state.actions,
    operators: state.operators,
  }));

  const {selectedWorkflowRunStatus, selectedWorkflow } = useWorkflowStore(
    (state: any) => ({
      selectedWorkflowRunStatus: state.selectedWorkflowRun.status,
      selectedWorkflow: state.selectedWorkflow,
    })
  );

  return (
    <Spin spinning={loader}>
      <Card
        size="small"
        title="Steps"
        style={{
          minWidth: "250px",
          maxWidth: "300px",
          overflow: "hidden",
          position: "absolute",
          zIndex: "1",
          margin: token.marginXXS,
          marginTop: token.margin,
          marginBottom: token.margin,
          borderRadius: "2px"
        }}
        styles={{
          header: {
            backgroundColor: token.colorPrimary,
            textAlign: "center",
            color: token.colorTextLightSolid,
          },
          body: {
            backgroundColor: token.colorBgContainer,
            borderRadius: "2px",
            overflow: "hidden",
          }
        }}
      >
        <div style={{
          marginBottom: token.marginXXS,
          padding: token.paddingXXS,
        }}
        >
          <Row gutter={16}>
            {operators
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .slice(0, showAllOperators ? operators.length : 8)
              .map((operator: Operator) => (
                <Col key={operator.id} span={6}>
                  <SidebarDraggableItem
                    key={operator.id}
                    resourceId={operator.id}
                    resourceType={operator.type}
                    displayName={operator.displayName}
                    description={operator.description}
                    nodeType={WorkflowNodeType.Operator}
                    disabled={
                      selectedWorkflowRunStatus == WorkflowRunStatusCode.Running
                      || selectedWorkflowRunStatus == WorkflowRunStatusCode.PendingApproval
                      || (selectedWorkflow?.isSubworkflow && operator.type == OperatorType.Subworkflow)
                    }
                  />
                </Col>
              ))}
          </Row>
          <Button type="text" size="small" block onClick={() => setShowAllOperators(!showAllOperators)}>
            {showAllOperators ? <DoubleRightOutlined rotate={270} /> : <DoubleRightOutlined rotate={90} />}
          </Button>
        </div>
        <Space size={token.sizeXXS} style={{ display: "flex" }}>
          <div style={{ marginLeft: token.marginXS, marginBottom: token.marginXS, display: "flex" }}>
            <SearchInput
              placeholder="search actions"
              onFilter={(e) => setFilter(e.target.value)}
            />
          </div>
        </Space>
        <div style={{
          height: "70%",
          maxHeight: "calc(100vh - 330px)",
          overflowX: "hidden",
          overflowY: "auto",
        }}>
          {actions
            ?.filter((x: Action) => x.visibility == true)
            ?.filter((x: Action) => x.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            .sort((a, b) => {
              return a.displayName.localeCompare(b.displayName);
            })
            .map((action: Action) => (
              <SidebarDraggableItem
                key={action.id}
                resourceId={action.id}
                resourceType={action.type}
                displayName={action.displayName}
                description={action.description}
                nodeType={WorkflowNodeType.Action}
                disabled={
                  selectedWorkflowRunStatus == WorkflowRunStatusCode.Running || selectedWorkflowRunStatus == WorkflowRunStatusCode.PendingApproval
                }
              />
            ))}
        </div>
      </Card>
    </Spin>
  );
};

export default Sidebar;
