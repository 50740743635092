import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import { dashboardsApiPath } from "./constant";
import { FormFactor } from "types";

export interface WidgetDto {
  id: string;
  type: string;
  unit: string;
  title: string;
  description: string;
  search: string;
}

export interface GridItemDto {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface WidgetListDto {
  widgets: WidgetDto[];
}

export interface DashboardDto {
  id: string;
  name: string;
  description: string;
  layout: { [key in FormFactor] : GridItemDto[] };
}

export interface DashboardListDto {
  dashboards: DashboardDto[];
}

export type WidgetCreateRequestDto = Omit<WidgetDto, "id">;
export type WidgetUpdateRequestDto = Partial<WidgetCreateRequestDto>;


export const listDashboardsApi = async (): Promise<AxiosResponse<DashboardListDto>> => axiosInterceptor("get", dashboardsApiPath);
export const getDashboardApi = async (id: string): Promise<AxiosResponse<DashboardDto>> => axiosInterceptor("get", `${dashboardsApiPath}/${id}`);
export const createDashboardApi = async (name: string, description: string): Promise<AxiosResponse<DashboardDto>> => axiosInterceptor("post", dashboardsApiPath, { name, description });
export const setLayoutApi = async (id: string, layout: {[key in FormFactor]: GridItemDto[]}): Promise<AxiosResponse<DashboardDto>> => axiosInterceptor("patch", `${dashboardsApiPath}/${id}`, { layout });
export const updateDashboardPropertiesApi = async (id: string, name: string, description: string): Promise<AxiosResponse<DashboardDto>> => axiosInterceptor("patch", `${dashboardsApiPath}/${id}`, { name, description });
export const addWidgetApi = async (id: string, widget: WidgetCreateRequestDto): Promise<AxiosResponse<WidgetDto>> => axiosInterceptor("post", `${dashboardsApiPath}/${id}/widgets`, widget );
export const updateWidgetApi = async (dashboardId: string, widgetId: string, widget: WidgetUpdateRequestDto): Promise<AxiosResponse<WidgetDto>> => axiosInterceptor("patch", `${dashboardsApiPath}/${dashboardId}/widgets/${widgetId}`, widget );
export const listWidgetsApi = async (dashboardId: string): Promise<AxiosResponse<WidgetListDto>> => axiosInterceptor("get", `${dashboardsApiPath}/${dashboardId}/widgets`);
export const deleteWidgetApi = async (dashboardId: string, widgetId: string): Promise<AxiosResponse<void>> => axiosInterceptor("delete", `${dashboardsApiPath}/${dashboardId}/widgets/${widgetId}`);
export const deleteDashboardApi = async (dashboardId: string): Promise<AxiosResponse<void>> => axiosInterceptor("delete", `${dashboardsApiPath}/${dashboardId}`);
