import {
  Button,
  Descriptions,
  Divider,
  MenuProps,
  Space,
  Spin,
  Typography,
  theme,
  Image,
  Tag,
  Badge,
  Modal as AntModal,
  Tooltip,
  Popover,
  Card
} from "antd";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";

import { FC, useEffect, useState } from "react";

import { SvgIcon } from "components/SvgIcon";
import { useEdgeStore, useOrganizationStore, useSettingsStore } from "store";
import { getEdgeLogo, getEdgeShortLogo } from "../../../utility/edge";
import { notification } from 'utility/notification';

import { AppSubscription, Edge, EdgeCategoriesMap, EdgeInfraType, EdgeInfraTypeMap, EdgeManageType, EdgeManageTypeMap, EdgeStatus, EdgeStatusMap, EdgeSubscription } from "types";
import EdgeConfig from "../EdgeConfig";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { getTagColor } from "utility";
import { TableProps } from "antd/lib/table";
import { DataType } from "@textea/json-viewer";
import { edgeIcons } from "assets/icons";
import { HLink } from "components/Link";
import { getAppSubscriptionsApi } from "api";
import { getAppLogoUrl } from "utility/app";

const { Title, Text, Paragraph } = Typography;

const EdgeList: FC = () => {
  const { token } = theme.useToken();
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  const [loader, setLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [showEdgeConfig, setShowEdgeConfig] = useState<boolean>(false);
  const [deleteEdgeModal, setDeleteEdgeModal] = useState<{enable: boolean; edge?: Edge;}>({ enable: false });
  const [disableEdgeModal, setDisableEdgeModal] = useState<{enable: boolean; edgeSubscription?: EdgeSubscription;}>({ enable: false });
  const [showAppsModal, setShowAppsModal] = useState<boolean>(false);
  const [edgeAppsMap, setEdgeAppsMap] = useState<Map<string, AppSubscription[]>>(new Map<string, AppSubscription[]>());

  const context = useOrganizationStore((state) => state.context);

  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));

  const {
    edges,
    totalEdgesCount,
    edgePage,
    setCurrentPage,
    edgeFilter,
    setEdgeFilter,
    edgeSorter,
    setEdgeSorter,
    getEdges,
    clearEdges,
    deleteEdge,
    enableEdgeSubscription,
    disableEdgeSubscription,
    clearSelectedEdge,
    edgeSearchTextMap,
    setEdgeSearchTextMap,
  } = useEdgeStore((state) => ({
    edges: state.edges,
    totalEdgesCount: state.totalEdgesCount,
    edgePage: state.edgePage,
    setCurrentPage: state.setCurrentPage,
    edgeFilter: state.edgeFilter,
    setEdgeFilter: state.setEdgeFilter,
    edgeSorter: state.edgeSorter,
    setEdgeSorter: state.setEdgeSorter,
    getEdges: state.getEdges,
    clearEdges: state.clearEdges,
    deleteEdge: state.deleteEdge,
    enableEdgeSubscription: state.enableEdgeSubscription,
    disableEdgeSubscription: state.disableEdgeSubscription,
    clearSelectedEdge: state.clearSelectedEdge,
    edgeSearchTextMap: state.edgeSearchTextMap,
    setEdgeSearchTextMap: state.setEdgeSearchTextMap,
  }));

  const {mspEnabled, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  const loadEdges = async () => {
    try {
      setLoader(true);
      clearEdges(); 
      await getEdges(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadEdges();
    return () => {
      clearEdges();
    };
  }, [edgeFilter, edgeSorter, edgeSearchTextMap, edgePage, context]);

  const enableEdge = async (edgeId?: string) => {
    try {
      setLoader(true);
      await enableEdgeSubscription(String(edgeId));
      notification.success({
        message: "Edge enabled successfully",
        duration: 6,
      });
      await loadEdges();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error enabling edge",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const disableEdge = async (edgeSubscriptionId?: string) => {
    try {
      setLoader(true);
      setDisableEdgeModal({ enable: false });
      await disableEdgeSubscription(String(edgeSubscriptionId));
      notification.success({
        message: "Edge disabled successfully",
        duration: 6,
      });
      await loadEdges();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error disabling edge",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const deleteCurrentEdge = async (edgeId?: string) => {
    try {
      setLoader(true);
      setDeleteEdgeModal({ enable: false });
      await deleteEdge(String(edgeId));
      notification.success({
        message: "Edge deleted successfully",
        duration: 6,
      });
      await loadEdges();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in deleting edge",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const loadAppsByEdge = async (edge: Edge) => {
    try {
      setModalLoader(true);
      const edgeApps = new Map<string, AppSubscription[]>();
      if (edge.edgeSubscriptions?.length) {
        const filters = [] as string[];
        filters.push("equals(edgeID,'" + edge.id + "')");
        const [appSubscriptions] = await getAppSubscriptionsApi(undefined, undefined, filters);
        appSubscriptions?.map((as) => {
          const subscriptions = edgeApps.get(as.appID);
          if (subscriptions) {
            subscriptions.push(as);
            edgeApps.set(as.appID, subscriptions)
          } else {
            const subscriptions = [] as AppSubscription[];
            subscriptions.push(as);
            edgeApps.set(as.appID, subscriptions);
          }
        });        
      }
      setEdgeAppsMap(edgeApps);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in loading apps",
        duration: 6,
      });
    } finally {
      setModalLoader(false);
    }
  }

  const loadAppsByEdgeSubscription = async (edgeSubscription: EdgeSubscription) => {
    try {
      setModalLoader(true);
      const edgeApps = new Map<string, AppSubscription[]>();
      const filters = [] as string[];
      filters.push("equals(edgeSubscriptionID,'" + edgeSubscription.id + "')");
      const [appSubscriptions] = await getAppSubscriptionsApi(undefined, undefined, filters);
      appSubscriptions?.map((as) => {
        const subscriptions = edgeApps.get(as.appID);
        if (subscriptions) {
          subscriptions.push(as);
          edgeApps.set(as.appID, subscriptions)
        } else {
          const subscriptions = [] as AppSubscription[];
          subscriptions.push(as);
          edgeApps.set(as.appID, subscriptions);
        }
      });        
      setEdgeAppsMap(edgeApps);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in loading apps",
        duration: 6,
      });
    } finally {
      setModalLoader(false);
    }
  }


  const closeEdgeConfig = async (refreshData?: boolean) => {
    clearSelectedEdge();
    setShowEdgeConfig(false);
    if (refreshData) {
      setLoader(true);
      await loadEdges();
      setLoader(false);
    }
  };

  const getEdgeLabel = (
    infraType?: EdgeInfraType,
    includeDisplayName?: boolean
  ) => {
    return infraType ? (
      <Space>
        <SvgIcon component={getEdgeShortLogo(infraType)} />
        {includeDisplayName && EdgeInfraTypeMap.get(infraType)}
      </Space>
    ) : (
      "All"
    );
  };

  const getEdgeStatus= (edgeStatus: EdgeStatus) => {
    switch (edgeStatus) {
      case EdgeStatus.Online:
        return <Badge status="processing" color="green" text={<Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      case EdgeStatus.Offline:
        return <Badge status="default" color="darkgrey" text={<Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      case EdgeStatus.Installing:
        return <Badge status="processing" text={<Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      case EdgeStatus.Updating:
        return <Badge status="warning" text={<Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      case EdgeStatus.Uninstalling:
        return <Badge status="processing" color="red" text={<Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      default:
        return <Badge status="default" text={<Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
    }
  }

  const columns: ColumnsType<Edge> = [   
    {
      title: "Edge",
      dataIndex: "",
      key: "infraType",
      width: '10%',
      filters: Array.from(EdgeInfraTypeMap, ([infraType, value]) => ({ text: getEdgeLabel(infraType, true), value: infraType })),
      filterSearch: true,
      filteredValue: edgeFilter["infraType"],
      render: (_, edge) => {
        return (
          <Image
            preview={false}
            draggable={false}
            width={"80px"}
            height={"30px"}
            src={getEdgeLogo(edge?.infraType, mode)}
            alt="company-logo"
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      width: '10%',
      sorter: true,
      sortDirections: ['ascend', 'descend', null],
      render: (displayName) => {
        return (
          <Text>{displayName}</Text>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: '30%',
      render: (description) => {
        return (
          <Paragraph 
            style={{ overflow: "hidden" }}
            ellipsis={{
              rows: 2,
              expandable: 'collapsible',
              symbol: ((expanded: boolean) => expanded ? 'Less' : 'More') 
          }}>
            {description}
          </Paragraph>   
        );
      },
    },
    {
      title: "Owned By",
      dataIndex: "manageType",
      key: "manageType",
      width: '15%',
      filters: Array.from(EdgeManageTypeMap, ([manageType, value]) => ({ text: value, value: manageType })),
      filteredValue: edgeFilter["manageType"],
      render: (manageType) => {
        return (
          <Text>{manageType == EdgeManageType.HyprEdge ? "HyprEdge" : "Me"}</Text>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: '10%',
      filters: Array.from(EdgeStatusMap, ([tag, value]) => ({ text: value, value: tag })),
      filterSearch: true,
      filteredValue: edgeFilter["status"],
      render: (status) => {
        return (
          getEdgeStatus(status)
        );
      },
    },
    {
      title: "Apps",
      dataIndex: "",
      key: "apps",
      width: '10%',
      render: (_, edge) => (
        <Tooltip title={"Click here to view apps"}>
          <div>
            <HLink
              onClick={() => {
                setShowAppsModal(true);
                loadAppsByEdge(edge);
              }}
              text={"View"}
              underline
              alwaysLinkColor
            />
          </div>
        </Tooltip>
      )
    },
    {
      title: "Tags",
      dataIndex: "edgeSubscriptions",
      key: "tags",
      hidden: !mspEnabled,
      width: '10%',
      render: (edgeSubscriptions: EdgeSubscription[]) => (
        edgeSubscriptions?.length
          ?
            <Badge 
              offset={[100, 100]}
              count={
                edgeSubscriptions?.length == 1
                  ? 0
                  :
                    <Popover
                      title={"Tags"}
                      style={{ overflow: "scroll" }}
                      content={
                        <Space direction="vertical">
                          {edgeSubscriptions?.filter((as) => as.tenantID != context?.split('_')[0]).map((es) => {
                            const tag = tags?.find((tag) => (tag.id == `${es?.tenantID}_tag`));
                            return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>
                          })}
                        </Space>
                      }
                    >
                      <Button 
                        type={"primary"} 
                        size={"small"} 
                        shape={"circle"} 
                        style={{ background: token.colorLinkHover }}
                      >
                        {`+${edgeSubscriptions?.length-1}`}
                      </Button>     
                    </Popover>
              }
            >
              {edgeSubscriptions?.filter((as) => as.tenantID == context?.split('_')[0]).map((es) => {
              const tag = tags?.find((tag) => (tag.id == `${es?.tenantID}_tag`));
              return tag 
                  ? 
                    <Card size="small">
                      <Tag color={getTagColor(tag.id)}>{tag.value}</Tag>
                    </Card>
                  : <></>
              })}
            </Badge>
          :
           <></>
      )
    },
    {
      title: "Categories",
      dataIndex: "tags",
      key: "tags",
      width: '20%',
      filters: Array.from(EdgeCategoriesMap, ([tag, value]) => ({ text: value, value: tag })),
      filterSearch: true,
      filteredValue: edgeFilter["tags"],
      render: (tags: string[]) => {
        return (
          <Space size={1} style={{ display: "flex" }}>
            {tags?.map((tag) => 
              <Tag
                key={tag}
                color={getTagColor(tag)}
              >
                {EdgeCategoriesMap.get(tag)}
              </Tag>
            )}
          </Space>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      width: '10%',
      render: (_, edge) => {
        return (
          <Space>
            <Tooltip title={edge.edgeSubscriptions.length ? "Click here to disable": "Click here to enable"}>
              <Button
                size="small"
                icon= {<SvgIcon size={18} component={edge.edgeSubscriptions.length == 0 ? edgeIcons.edgeDisabledIcon : edgeIcons.edgeEnabledIcon} />}
                onClick={() => {
                    const es = edge?.edgeSubscriptions?.find((es) => es.tenantID == context?.split('_')[0])
                    if (!es) {
                      enableEdge(edge.id);
                    } else {
                      setDisableEdgeModal({ enable: true, edgeSubscription: es});
                      loadAppsByEdgeSubscription(es);
                    }
                }}
              />
            </Tooltip>
            {edge.manageType == EdgeManageType.Custom && edge.edgeSubscriptions.length == 0
              &&
              <Tooltip title={"Click here to delete"}>
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => setDeleteEdgeModal({ enable: true, edge: edge })}
                />
              </Tooltip>
            }
          </Space>
        );
      },
    },
  ]

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter)  => {
    setEdgeSorter(sorter);
    setEdgeFilter(filters);
    setCurrentPage();
  }

  return (
    <>
      <Spin spinning={loader}>
        <EntitiesDisplay
          header={"Edges"}
          hideRowSelection={true}
          dataSource={edges as any[]}
          columns={columns}
          pageNumber={edgePage.number}
          pageSize={edgePage.size}
          total={totalEdgesCount}
          onChange={onChange}
          onSearch={(text: string) => {
            setCurrentPage();
            edgeSearchTextMap.set("displayName", text);
            setEdgeSearchTextMap(edgeSearchTextMap)
          }}
          searchPlaceholder={"search edges by name"}
          searchText={edgeSearchTextMap.get("displayName")}
          onPageChange={(pageNumber : number, pageSize : number)  => setCurrentPage(pageNumber, pageSize)}
          actions={
            [
              {
                key: "create", 
                label: "Create", 
                enable: () => true, 
                onClick: () => {setShowEdgeConfig(true)}
              },
            ]
          }
        />   
      </Spin>
      {showEdgeConfig && (
        <EdgeConfig
          open={showEdgeConfig}
          onClose={(refresh) => closeEdgeConfig(refresh)}
        />
      )}
      {disableEdgeModal?.enable && (
        <AntModal
          title="Disable Edge"
          open={disableEdgeModal.enable}
          loading={modalLoader}
          onCancel={() => setDisableEdgeModal({ enable: false })}
          footer={[
            <Button key="ok" type="primary" onClick={() => setDisableEdgeModal({ enable: false })}>
              Cancel
            </Button>,
            <Button key="ok" type="primary" disabled={Array.from(edgeAppsMap).length != 0} onClick={() => disableEdge(disableEdgeModal?.edgeSubscription?.id)}>
              Confirm
            </Button>
          ]}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}
        >
          {edgeAppsMap?.size
            ?
              <Space direction="vertical" style={{ display: "flex" }}>
                <Title type="warning" level={5} >{"Edge can not be disabled, Below apps are configured on this edge!"}</Title>
                {Array.from(edgeAppsMap).map(([appId, subscriptions]) =>
                  <Card 
                    key={appId} 
                    size="small" 
                    title={
                      <Image 
                        preview={false} 
                        draggable={false}
                        height={"15px"}
                        src={getAppLogoUrl(subscriptions[0].apps, mode)}
                        alt="company-logo"
                      />
                    }
                  >
                    <Space wrap>
                      {subscriptions.map((as: AppSubscription) => 
                        <Tag key={as.id} color={getTagColor(as.name)}>
                          {mspEnabled 
                            ?
                              <Popover 
                                title="Tag"
                                content= {
                                  <Tag color={getTagColor(`${as.tenantID}_tag`)}>
                                    {tags?.find((tag) => (tag.id == `${as.tenantID}_tag`))?.value}
                                  </Tag>
                                }
                              >
                                {as.name}
                              </Popover>
                            :
                              as.name
                          }
                        </Tag>
                      )}
                    </Space>
                  </Card>
                )}
              </Space>
            :
              <Text>{"Are you sure you want to disable edge?"}</Text>
          }
        </AntModal>
      )}
      {deleteEdgeModal?.enable && (
        <AntModal
          title="Delete Edge"
          open={deleteEdgeModal.enable}
          onClose={() => setDeleteEdgeModal({ enable: false })}
          footer={[
            <Button key="ok" type="primary" onClick={() => setDeleteEdgeModal({ enable: false })}>
              Cancel
            </Button>,
            <Button key="ok" type="primary" onClick={() => deleteCurrentEdge(deleteEdgeModal.edge?.id)}>
              Confirm
            </Button>
          ]}
        >
          {deleteEdgeModal.edge?.infraType == EdgeInfraType.OnPrem
            ?
              <Descriptions
                  size="small"
                  layout="vertical"
                  colon={false}
                  column={1}
              >
                <Descriptions.Item label="Stop docker container">
                  <Text copyable>docker stop `container id`</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Remove docker image">
                  <Text copyable>docker rm `container id`</Text>
                </Descriptions.Item>
              </Descriptions>
            :
              "Are you sure you want to delete edge?"
          }
        </AntModal>
      )}
      {showAppsModal && (
        <AntModal
          title="Apps"
          open={showAppsModal}
          loading={modalLoader}
          onCancel={() => setShowAppsModal(false)}
          footer={[
            <Button key="ok" type="primary" onClick={() => setShowAppsModal(false)}>
              OK
            </Button>
          ]}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}
        >
          {edgeAppsMap?.size
            ?
              <Space direction="vertical" style={{ display: "flex" }}>
                {Array.from(edgeAppsMap).map(([appId, subscriptions]) =>
                  <Card 
                    key={appId} 
                    size="small" 
                    title={
                      <Image 
                        preview={false} 
                        draggable={false}
                        height={"15px"}
                        src={getAppLogoUrl(subscriptions[0].apps, mode)}
                        alt="company-logo"
                      />
                    }
                  >
                    <Space wrap>
                      {subscriptions.map((as: AppSubscription) => 
                        <Tag key={as.id} color={getTagColor(as.name)}>
                          {mspEnabled 
                            ?
                              <Popover 
                                title="Tag"
                                content= {
                                  <Tag color={getTagColor(`${as.tenantID}_tag`)}>
                                    {tags?.find((tag) => (tag.id == `${as.tenantID}_tag`))?.value}
                                  </Tag>
                                }
                              >
                                {as.name}
                              </Popover>
                            :
                              as.name
                          }
                        </Tag>
                      )}
                    </Space>
                  </Card>
                )}
              </Space>
            :
              <Text type="secondary">Apps are not configured on this edge</Text>
          }
        </AntModal>
      )}
    </>
  );
};

export default EdgeList;
