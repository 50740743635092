import { FC, useState, useEffect } from "react";

import {
  Form,
  Button,
  Space,
  theme,
  Input,
  Spin,
  Row,
  Typography,
  notification,
  Select,
  FormInstance,
  Card,
  Tooltip,
  Flex,
  Empty,
} from "antd";

import {
  WorkflowNodeCardProps,
  WorkflowStepOperator,
  TriggerType,
  Workflow,
} from "types";
import CollapsePanel from "components/CollapsePanel";

import { FieldLabel } from "components/FieldLabel";

import { useWorkflowStore } from "store";
import { generateReferenceName } from "utility/workflow";
import { buildStepOutputTree, buildTriggerOutputTree, buildWorkflowParameterTree } from "components/Suggestions";
import { SvgIcon } from "components/SvgIcon";
import { workflowIcons } from "assets/icons";
import WorkflowParameters from "../WorkflowParameters";
import { validateFormFields } from "utility";
import SearchInput from "components/SearchInput";
import { getWorkflowsApi } from "api";

const { Text } = Typography;

const SubworkflowOperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  resourceType,
  nodeType,
  editMode,
  onClose,
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [workflowParamsForm, setWorkflowParamsForm] = useState<undefined | FormInstance>(undefined);
  const [subworkflows, setSubworkflows] = useState<Workflow[]>([]);
  const [selectedSubworkflow, setSelectedSubworkflow] = useState<Workflow|undefined>({} as Workflow);
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const [workflowParametersValues, setWorkflowParametersValues] = useState<Record<string, any>>({});
  const [filter, setFilter] = useState("");
  const [subworkflowHoverId, setSubworkflowHoverId] = useState("");

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(
    selectedWorkflow?.steps[id]?.referenceName
  );

  const loadSubworkflows = async () => {
    try {
      setLoader(true);
      //TODO - use pagination and may move to separate store for subworkflows
      const [subworkflows] = await getWorkflowsApi(undefined, undefined, undefined, true);
      setSubworkflows(subworkflows);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadSubworkflows();
  }, []);

  const syncCurrentState = async () => {
    const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
    if (operatorStep) {
        form.setFieldValue("workflow_id", operatorStep?.parameters?.workflow_id);
        setSelectedSubworkflow(subworkflows.find((w: Workflow) => operatorStep?.parameters?.workflow_id == w.id));
        form.setFieldValue("workflow_parameters", operatorStep?.parameters?.workflow_parameters);
        setWorkflowParametersValues(operatorStep?.parameters?.workflow_parameters);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
  }, [subworkflows]);

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
        operatorStep.isConfigured = true;
        operatorStep.name = values.name;
        operatorStep.referenceName = referenceName;

        operatorStep.parameters = {};
        operatorStep.parameters.workflow_id = values.workflow_id;
        operatorStep.parameters.workflow_version = selectedSubworkflow?.version;
        operatorStep.parameters.workflow_parameters = values.workflow_parameters;

        selectedWorkflow.steps[id] = operatorStep;
        updateSelectedWorkflow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Form
        form={form}
        name="kvForm"
        layout="vertical"
        autoComplete="off"
        onFinish={OnFinish}
      >
        <Spin spinning={loader}>
            <Space direction="vertical" style={{ display: "flex" }}>
              <Form.Item
                name="name"
                label={<FieldLabel label={"Name"} help={"Operator Name"} />}
                initialValue={selectedWorkflow.steps[id]?.name}
                rules={[{ required: true, message: "Name is required!" }]}
                extra={
                    referenceName != "" && (
                    <Text italic type="secondary">
                        Reference Name: {referenceName}
                    </Text>
                    )
                }
              >
              <Input
                  disabled={!editMode}
                  onChange={(e) =>
                    setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))
                  }
              />
              </Form.Item>
              {!selectedSubworkflow 
                ?
                  Object.entries(subworkflows).length
                    ?
                    <div>
                      <div style={{ marginBottom: "8px" }}>
                        <SearchInput
                          placeholder="search subworkflow"
                          onFilter={(e) => setFilter(e.target.value)}
                        />
                      </div>
                      {subworkflows?.filter((x: Workflow) => x.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                      .sort((a: Workflow, b: Workflow) => a.name.localeCompare(b.name)).map((i: Workflow) => (
                      <Space key={i.id} direction="vertical" style={{ display: "flex" }}>
                        <Card
                          hoverable={true}
                          style={{ 
                            height: "40px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: (i.id == subworkflowHoverId ? token.colorPrimaryBg : token.colorBgBase),
                          }}
                          onClick={() => {
                            setSelectedSubworkflow(i);
                            form.setFieldValue("workflow_id", i.id);
                          }}
                          onMouseEnter={() => setSubworkflowHoverId(i.id)}
                          onMouseLeave={() => setSubworkflowHoverId("")}
                        >
                          <Tooltip
                            color={token.colorPrimaryHover}
                            title={i.description}
                            placement={"left"}
                          > 
                            <Flex gap="small" justify={"flex-start"}>
                              <SvgIcon size={15} component={workflowIcons.workflowShortIcon} />          
                              <Text>
                                {i.description}
                              </Text>
                            </Flex>    
                          </Tooltip>
                        </Card>
                      </Space>
                      ))}
                    </div>
                    :
                    <Empty 
                      image={Empty.PRESENTED_IMAGE_SIMPLE} 
                      description={"No Subworkflows"}
                    />
                :
                <Form.Item
                  name="workflow_id"
                  label={<FieldLabel label={"Sub Workflow"} help={"Select subworkflow which shold be part of main workflow"} />}
                  rules={[
                    { required: true, message: "Sub workflow should be selected!" },
                  ]}
                >
                  <Select
                    disabled={!editMode}
                    allowClear
                    showAction={["focus", "click"]}
                    placeholder="Select Subworkflow..."
                    value={selectedSubworkflow?.name}
                    onSelect={(id) => {
                      setSelectedSubworkflow(subworkflows.find((w: Workflow) => w.id == id));
                    }}
                    onClear={() => {
                      setSelectedSubworkflow(undefined);
                      setFilter("");
                    }}
                    options={subworkflows
                      .filter((w: Workflow) => w.isSubworkflow)
                      .map((w: Workflow) => ({
                        label: w.name,
                        value: w.id,
                      }))}
                  />
                </Form.Item>
              }
              {selectedSubworkflow?.parameters && selectedSubworkflow.parameters.length > 0
                &&
                <CollapsePanel 
                  name={
                    <Space size={token.marginXXS}>
                      <SvgIcon component={workflowIcons.parametersShortIcon} />
                      <Text>Workflow Parameters</Text>
                    </Space>
                  }
                  ghost={false} 
                  collapsePanel={false}
                >
                  <Form.Item 
                    name="workflow_parameters"
                    validateTrigger="onSubmit"
                    rules={[
                      { validator: (_, value) => validateFormFields(workflowParamsForm) },
                    ]}
                  >
                    <WorkflowParameters 
                      editMode={editMode}
                      parameters={selectedSubworkflow?.parameters}
                      parametersValues={workflowParametersValues}
                      parameterSuggestionsTree={parameterSuggestionsTree}
                      onRender={(form) => setWorkflowParamsForm(form)}
                    />
                  </Form.Item>
                </CollapsePanel>
              }
            </Space>
        </Spin>
        {editMode && (
          <Row justify="space-between" style={{ marginTop: token.margin }}>
            <Button key="cancel" onClick={onClose}>
              Cancel
            </Button>
            <Button key="save" type="primary" htmlType="submit" size="middle" disabled={selectedSubworkflow == undefined}>
              Save
            </Button>
          </Row>
        )}
      </Form>
    </>
  );
};

export default SubworkflowOperatorCard;
