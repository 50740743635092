import { Layouts } from "react-grid-layout";
import {
  Metric, MetricLayout,
} from "types";
import { jsonApiDeserializer, metricsApiPath, metricsLayoutsApiPath } from "./constant";
import { axiosInterceptor } from "./axios";
//import defaultMetrics from 'data/defaultMetrics.json'


export const getMetricLayouts = async () : Promise<MetricLayout[]> => { 
  const url = `${metricsLayoutsApiPath}`
  const response = await axiosInterceptor("get", url);
  const metricLayouts = jsonApiDeserializer.deserialize(response.data) as MetricLayout[];
  return metricLayouts;
}

export const getMetricsApi = async (cache?: boolean) : Promise<Metric[]> => { 
  let url = `${metricsApiPath}`

  if (cache) {
    url += `?cache=true`
  }
  const response = await axiosInterceptor("get", url);
  const metrics = jsonApiDeserializer.deserialize(response.data) as Metric[];
  return metrics;
}

export const getMetricsWithLayoutApi = async (layout: string, cache?: boolean) : Promise<Metric[]> => { 
  let url = `${metricsApiPath}?layout=${layout}`

  if (cache) {
      url += `&cache=true`
  }
  const response = await axiosInterceptor("get", url);
  const metrics = jsonApiDeserializer.deserialize(response.data) as Metric[];
  return metrics;
}