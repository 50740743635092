import { useEffect, useState, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  Collapse,
  Space,
  Typography,
  theme,
  Badge,
  Alert,
  MenuProps,
  Dropdown,
  Switch,
  Spin,
  Skeleton,
  Layout,
  Popover
} from "antd";
import { Content } from "antd/es/layout/layout";
import { HistoryOutlined, SaveOutlined, StopOutlined } from "@ant-design/icons";

import pRetry, {FailedAttemptError} from 'p-retry';

import { getStyles } from "utility/styles";
import { commonIcons, workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { useOrganizationStore, useSearchStore, useSettingsStore } from "store";
import PageWrapper from "components/PageWrapper";
import ResultPanel from "./ResultPanel";
import {  SearchRunActionStatus, SearchRunStatus } from "types";
import {  getActionName, getAppsBadges, getRunActionStatus, getSearchRunIdsListFromSearchRun, getSubHeadingForSearchActionResults, getSubHeadingMessageForSearchActionResults } from "./Utils";
import ActionsHistory from "./ActionPanel/actionsHistory";
import ControlButton from "components/ControlButton/controlButton";
import { notification } from 'utility/notification';

// const { Option } = Select;
const { Panel } = Collapse;
const { Text, Title } = Typography;

export const SearchRunActionComponent = () => {
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  const context = useOrganizationStore((state) => state.context);
  const { token } = theme.useToken();
  const [showActionsHistory, setShowActionsHistory] = useState<boolean>(false);
  const [summaryBannerHover, setSummaryBannerHover] = useState(false);
  const abortController = new AbortController();
  const classes = getStyles({
    container: {
      gap: token.marginXS,
      overflow: "hidden"
    },
  })();
  const headerDropDownItems: MenuProps["items"] = [
    {
      key: "actions",
        label: "Related Actions",
        icon: <HistoryOutlined />,
    },
  ];


  const { id: searchRunActionId } = useParams();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const {
    currentSearchRun,
    currentSearchRunAction,
    searchLoader,
    showDetailedResults,
    recommendedActions,
    getSearchRun,
    getSearchRunAction,
    getSearchRunActions,
    setShowDetailedResults,
    cancelSearchRunAction,
    resetSearchState,
  } = useSearchStore((state) => ({
    currentSearchRun: state.currentSearchRun,
    currentSearchRunAction: state.currentSearchRunAction,
    searchLoader: state.searchLoader,
    showDetailedResults: state.showDetailedResults,
    recommendedActions: state.recommendedActions,
    getSearchRun: state.getSearchRun,
    getSearchRunAction: state.getSearchRunAction,
    getSearchRunActions: state.getSearchRunActions,
    setShowDetailedResults: state.setShowDetailedResults,
    cancelSearchRunAction: state.cancelSearchRunAction,
    resetSearchState: state.resetSearchState,
  }));

  const syncSearchRunActionInBackground = async () => {
    if (searchRunActionId && searchRunActionId != "") {
      const sra = await getSearchRunAction(searchRunActionId, true);
      return sra.status;
    }
  }

  useEffect(() => {
    const asyncUseEffect = async () => {
      if (searchRunActionId && searchRunActionId != "") {
        const sra = await getSearchRunAction(searchRunActionId, true);
        await getSearchRun(sra.searchRunID);
        pRetry(() => syncSearchRunActionInBackground(), {
          retries: 60, 
          minTimeout: 5000,
          maxTimeout: 5000,
          signal: abortController.signal
        }).catch( (e : FailedAttemptError)  => {
          console.log("pretry sync search run actionstatus completed, exiting sync of status");
        })
      }
    };
    asyncUseEffect();
  }, [searchRunActionId, context]);

  useEffect(() => {
    if(currentSearchRun.status != SearchRunStatus.Running 
        && currentSearchRun.resultMetas 
        && currentSearchRun.resultMetas.length 
    ) {
      const searchRunIdsList = getSearchRunIdsListFromSearchRun(currentSearchRun);
      if(searchRunIdsList !=  "") {
        getSearchRunActions(searchRunIdsList, 1, 50);
      }
    }
  }, [currentSearchRun]);


  useEffect(() => { 
    resetSearchState();
    return () => {abortController.abort("exiting search run action page"); resetSearchState()} ;
  }, []);

  const getSummaryBanner = () => {
    return (
      <div
        id="search-run-summary-banner"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: token.paddingXS,
          alignItems: "center",
        }}
      >
        <Space size={1} direction="horizontal"  style={{width: "100%", margin: token.marginXS, justifyContent: "space-between"}}>
            <Space size={2} direction="vertical">
              {getSubHeadingForSearchActionResults(currentSearchRunAction, token)}
              {recommendedActions.length ? (getAppsBadges(currentSearchRunAction, recommendedActions, token)):(null)}
            </Space>
          </Space>
        <Space
          style={{
            position: "absolute",
            right: "30px"
          }}
        >
          <Text> Detailed View </Text>
          <Switch
            checked={showDetailedResults}
            size="small"
            onChange={setShowDetailedResults}
          />
        </Space>
      </div>
    )
  }
 

  
  const cancelAction = async () => {
    try {
      setLoader(true);
      await cancelSearchRunAction(currentSearchRunAction.id);
        notification.success({
          message: "Action run cancellation is pipelined succesfully",
          duration: 6,
        });

    }catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while pipeling action cancellation",
        duration: 6,
      });
    }finally{
      setLoader(false);
    }
  }

  const handleActionClick = async () => {
    setShowActionsHistory(true);
  };

  const handleDropdownMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "actions" && handleActionClick();
  };

  const getMenuOptions = () => {
    return {
      items: headerDropDownItems,
      onClick: handleDropdownMenuClick,
    };
  };
  

  const getHeaderControlButtons = () => {
    return (
      <Space size={1}>
        <ControlButton
          displayName={"Back"}
          svgComponent={
            <SvgIcon
              onClick={() => {
                navigate(-1);
              }}
              component={commonIcons.backIcon}
            />
          }
          hoverable={true}
        />
      </Space>   
    );
  }

  const getHeaderActionButtons = () => {
    return (
      <Space size={8}>
        <Space size={8}>
          {(currentSearchRunAction.status == SearchRunActionStatus.Running) && 
            <Button 
            type="primary" 
            icon={<StopOutlined />}
            onClick={cancelAction}
          >
            Stop Action
          </Button>
          }          
        </Space>
        <Dropdown placement="bottomLeft" menu={getMenuOptions()}>
          <Button
            icon={<SvgIcon component={commonIcons.moreOutlinedIcon} />}
            ghost
          />
        </Dropdown>
      </Space>   
    );
  }

  const getTitle = () => {
    return (
      <Space size={8}>
        <Text style={{fontSize: token.fontSizeHeading5}} strong>{getActionName(currentSearchRunAction.actionInfo?.id)}</Text>
        {getRunActionStatus(currentSearchRunAction)}
      </Space>
    );
  }

  return (
    <PageWrapper
      header={
        <div
          id="search-run-actions-header"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            padding: token.paddingXS,
            backgroundColor: token.colorBorderSecondary,
            alignItems: "center",
          }}
        > 
          {getHeaderControlButtons()}
          {getTitle()}
          {getHeaderActionButtons()}
        </div>
      }
      content={
        <div id="search-run-actions-container" className={classes.container}>   
          <Layout style={{width: "100%", overflowY: "auto"}}>
            <Space size={token.sizeXS} direction="vertical" style={{ overflowY: "auto"}}>
              {getSummaryBanner()}
              <Content style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <div id="search-run-action-table" style={{ width: "100%" }}>
                    <Spin spinning={searchLoader || loader}>
                      <Space direction="vertical" style={{ display: "flex" }}>
                        {currentSearchRun.resultMetas?.length ? (
                          currentSearchRun.resultMetas?.filter((rm) => rm.count > 0)
                          .map((rm, index) => (
                            <ResultPanel
                              key={rm.appSubscriptionID}
                              index={index}
                              searchId={currentSearchRun.searchID}
                              appSubscriptionId={rm.appSubscriptionID}
                              artifactName={rm.artifactName}
                              resultMeta={rm}
                              editMode={true}
                              tagId={`${rm.tenantID}_tag`}
                            />
                          ))
                        ) :  (
                          <Skeleton active/>
                        )}
                      </Space>
                    </Spin>
                  </div>
                </div>
              </Content>
            </Space>
          </Layout>
        </div>
      }
      feedback={
        <div>
          {currentSearchRun.id && showActionsHistory && 
            <ActionsHistory 
              title="Related Actions"
              searchTitle="search related actions"
              searchRunId={currentSearchRun.id} 
              widthPercentage={"95%"} 
              maxItems={10} 
              open={showActionsHistory}
              onClose={() => setShowActionsHistory(false)}
            />
          }
      </div>
      }
    ></PageWrapper>
  );
};
