import { FC, useState, useEffect } from "react";
import {
  Spin,
  Avatar,
  Badge,
  Typography,
  theme,
  Space,
  Tooltip,
  Tag,
  Modal as AntModal,
  Button,
} from "antd";
import {
  WarningOutlined,
  AuditOutlined
} from "@ant-design/icons";

import {
  getWorkflowPendingAudits,
  getWorkflowPendingConfigurations,
} from "../../utility/workflow";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { notification } from 'utility/notification';
import {
  useOrganizationStore,
  useUserInfoStore,
  useWorkflowStore,
} from "store";


import { EntitiesDisplay } from "components/EntitiesDisplay";
import { 
  HyprFlowTabType, 
  SpecStateMap, 
  SpecStateType, 
  TriggerType, 
  Workflow, 
  WorkflowActionType, 
  WorkflowRunInfo, 
  dateTimeFormatOptions
} from "types";
import CreateWorkflowModal from "../../components/HyprFlows/CreateWorkflowModal";

import { managedmodeIcons, workflowIcons } from "assets/icons";
import { actionWorkflowApi, exportWorkflowApi } from "api";
import { createNewWorkflow } from "utility/workflow";
import { getAvatarColor, getTagColor } from "../../utility";
import FileSaver from "file-saver";
import { jsonApiDeserializer } from "api/constant";
import { HLink } from "components/Link";
import { TextWithIcon } from "components/TextWithIcon";
import SearchableTags from "components/SearchableTags";
import { TableProps } from "antd/lib";
import { DataType } from "@textea/json-viewer";

const { Text } = Typography;

const WorkflowsTab: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [tabLoader, setTabLoader] = useState(loader);
  const [createEditFlowsModal, setCreateEditFlowsModal] = useState(false);
  
  const navigate = useNavigate();
  
  const { mspEnabled, context, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    context: state.context,
    tags: state.tags,
  }));
  
  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const { 
    workflows,
    workflowPage, 
    getWorkflows, 
    createWorkflow, 
    deleteWorkflow, 
    setCurrentPage,
    workflowFilters,
    setWorkflowFilters,
    workflowSorters,
    setWorkflowSorters,
    workflowSearchTextMap,
    setWorkflowSearchTextMap,
  } = useWorkflowStore(
    (state) => ({
      workflows: state.workflows,
      workflowPage: state.workflowPage[HyprFlowTabType.Workflows],
      getWorkflows: state.getWorkflows,
      createWorkflow: state.createWorkflow,
      deleteWorkflow: state.deleteWorkflow,
      setCurrentPage: state.setCurrentPage,
      workflowFilters: state.workflowFilters[HyprFlowTabType.Workflows],
      setWorkflowFilters: state.setWorkflowFilters,
      workflowSorters: state.workflowSorters[HyprFlowTabType.Workflows],
      setWorkflowSorters: state.setWorkflowSorters,
      workflowSearchTextMap: state.workflowSearchTextMap,
      setWorkflowSearchTextMap: state.setWorkflowSearchTextMap
    })
  );

  useEffect(() => {
    const asyncUseEffect = async () => {  
      setTabLoader(true);
      await getWorkflows(workflowPage.number, workflowPage.size);
      setTabLoader(false);
    };
    asyncUseEffect();
  }, [workflowSearchTextMap, workflowFilters, workflowSorters, context]);


  const onPageChange = (pageNumber : number, pageSize : number)  => {
    setTabLoader(true);
    setCurrentPage(HyprFlowTabType.Workflows, pageNumber, pageSize)
    getWorkflows(pageNumber, pageSize).then( () => {
      setTabLoader(false);
    });
  }

  //Fetch user info for all users linked to workflows
  useEffect(() => {
    try {
      const userIds = [] as string[];
      workflows.map((workflow) => {
        workflow.userID && workflow.userID != "" && userIds.push(workflow.userID);
        workflow.users?.map((user) => user && userIds.push(user));
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [workflows]);

  const saveNewWorkflow = async (values: any) => {
    try {
      setLoader(true);
      const workflowIn = createNewWorkflow(
        values.name,
        values.description,
        values.isSubworkflow ? TriggerType.Manual : values.triggerType,
        values.isSubworkflow,
        values.edgeId,
      );
      const workflowOut = await createWorkflow(workflowIn);
      notification.success({
        message: `Workflow ${workflowIn.name} created successfully`,
        duration: 6,
      });
      const workflowId = workflowOut.id;
      navigate(`/workflows/${workflowId}`);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while creating workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnDeleteSavedWorkflows = async (items: any[]) => {
    try {
      setLoader(true);
      const workflows = items as Workflow[];
      await Promise.all(
        workflows.map(async (workflow) => {
          await deleteWorkflow(workflow.id);
          notification.success({
            message: `Workflow ${workflow.name} deleted successfully`,
            duration: 6,
          });
        })
      );
      
      await getWorkflows(workflowPage.number, workflowPage.size);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting workflows...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnCloneWorkflow = async (item: any, selectedTags?: string[], tagsOnly?: boolean) => {
    try {
      setLoader(true);
      const workflow = item as Workflow;
      workflow.importTags = selectedTags;
      await actionWorkflowApi(workflow.id,  WorkflowActionType.WorkflowActionTypeClone, workflow, tagsOnly);
      notification.success({
        message: `Workflow ${workflow.name} cloned successfully`,
        duration: 6,
      });
      await getWorkflows(workflowPage.number, workflowPage.size);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while cloning workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnImportWorkflow = async (item: any, selectedTags?: string[], tagsOnly?: boolean) => {
    try {
      setLoader(true);
      const workflow = jsonApiDeserializer.deserialize(item) as Workflow;
      workflow.importTags = selectedTags;
      await createWorkflow(workflow, true, tagsOnly);
      await getWorkflows(workflowPage.number, workflowPage.size);
      notification.success({
        message: `Workflow ${workflow.name} imported successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while importing workflows...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }

  const OnExportWorkflow = async (items: any[]) => {
    try {
      setLoader(true);
      const workflows = items as Workflow[];
      await Promise.all(
        workflows.map(async (workflow) => {
          const responseData = await exportWorkflowApi(workflow.id)
          const blob = new Blob([JSON.stringify(responseData, null, 2)], {
            type: "application/json;charset=utf-8",
          });
          FileSaver.saveAs(
            blob,
            `hypredge-workflow-${workflow.name}-export.json`
          );
          notification.success({
            message: `Workflow ${workflow.name} exported successfully`,
            duration: 6,
          });
        })
      );
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while exporting workflows...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }

  const columns: ColumnsType<Workflow> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortDirections: ['ascend', 'descend', null],
      render: (_, workflow) => (
        <TextWithIcon
          icon={workflowIcons.workflowShortIcon}
          iconSize={15}
          iconColor={workflow.isSubworkflow ? "#D21D71" : undefined}
          text={
            <HLink onClick={() => navigate("/workflows/" + workflow.id)} text={workflow.name}></HLink> 
          }
        />
      ),
    },
/*     {
      title: "Type",
      dataIndex: "record",
      key: "type",
      sorter: (a, b) => ~~a.isSubworkflow - ~~b.isSubworkflow,
      sortDirections: ["descend", "ascend"],
      render: (_, workflow) => (
        <Text>{workflow.isSubworkflow ? "Sub-Workflow" : "Normal"}</Text>
      ),
    }, */
    {
      title: "Owner",
      dataIndex: "userID",
      key: "userID",
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <HLink href={"mailto:" + userInfos[userID]?.email} text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Tag",
      dataIndex: "tenantID",
      key: "tenantID",
      hidden: !mspEnabled,
      filterSearch: true,
      filters: tags.map((tag) => ({ text: tag.value, value: tag.id.split("_")[0] })),
      filteredValue: workflowFilters["tenantID"],
      render: (tenantID: string) => {
        const tag = tags?.find((tag) => (tag.id == `${tenantID}_tag`));
        return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>
      },
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (users: string[]) => (
        <span>
          <Avatar.Group maxCount={2}>
            {users?.map((userID, index) => (
              <Tooltip
                key={index}
                title={
                  userInfos[userID]?.firstName +
                  " " +
                  userInfos[userID]?.lastName
                }
                placement="top"
              >
                {userInfos[userID]?.logoUrl == "" ? (
                  <Avatar
                    style={{
                      backgroundColor: getAvatarColor(
                        userInfos[userID]?.firstName + userInfos[userID]?.lastName
                      ),
                    }}
                  >
                    {userInfos[userID]?.firstName.toUpperCase().charAt(0)}
                    {userInfos[userID]?.lastName.toUpperCase().charAt(0)}
                  </Avatar>
                ) : (
                  <Avatar src={userInfos[userID]?.logoUrl} />
                )}
              </Tooltip>
            ))}
          </Avatar.Group>
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      filterSearch: true,
      filters: Array.from(SpecStateMap, ([tag, value]) => ({ text: value, value: tag })),
      filteredValue: workflowFilters["state"],
      render: (_, workflow) => (
        <Space>
          {/* {SpecStateMap.get(workflow.state)} */}
          { (workflow.state == SpecStateType.StateDraft) && (
            <Badge count="draft" color="orange" />
          )}
           { (workflow.state == SpecStateType.StatePublishedDraft) && (
            <Badge count="published draft" color="orange" />
          )}
          {workflow.state == SpecStateType.StatePublishPending && (
            <>
              <Badge count="approval" color={token.colorInfo} />
              <Badge count={<span style={{ color: "green" }}>Pending</span>} />
            </>
          )}
          {workflow.state == SpecStateType.StatePublished && (
            <Badge count="published" color="green" />
          )}
          {workflow.state == SpecStateType.StatePublished &&
            (workflow.enabled ? (
              <Badge
                count={<span style={{ color: "green" }}>Enabled</span>}
              />
            ) : (
              <Badge
                count={<span style={{ color: "grey" }}>Disabled</span>}
              />
          ))}
          {(workflow.state == SpecStateType.StateDraft || workflow.state == SpecStateType.StatePublishedDraft) &&
            (workflow.isConfigured ? (
              <Badge
                count={<span style={{ color: "green" }}>Configured</span>}
              />
            ) : (
              <Badge
                count={
                  <div>
                    <WarningOutlined style={{ color: token.colorWarning, marginRight: token.marginXXS }}/>
                    <span style={{ color: token.colorWarning }}>
                      {getWorkflowPendingConfigurations(workflow).length} pending
                    </span>
                  </div>
                }
              />
          ))}
          {workflow.isAuditRequired && (
            <Badge
              count={
                <div>
                  <AuditOutlined style={{ color: token.colorSuccess, marginRight: token.marginXXS }}/>
                  <span style={{ color: token.colorSuccess }}>
                    {getWorkflowPendingAudits(workflow).length} pending
                  </span>
                </div>
              }
            />
          )}
        </Space>
      ),
    },                        
    {
      title: "Last Update On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (updatedAt: string) =>
        new Date(updatedAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        ),
    },
    {
      title: "#Executions",
      dataIndex: "runInfo",
      key: "runInfo",
      render: (runInfo: WorkflowRunInfo) => (
        <>
          {runInfo.runCount ? (
            <HLink onClick={() => navigate("/workspace")} text={runInfo.runCount} />
          ) : (
            <Text>{runInfo.runCount}</Text>
          )}
        </>
      ),
    },
    // {
    //   title: "Last Execution On",
    //   dataIndex: "runInfo",
    //   key: "runInfo",
    //   sorter: (a, b) =>
    //     new Date(a.runInfo.lastRunAt).getTime() -
    //     new Date(b.runInfo.lastRunAt).getTime(),
    //   sortDirections: ["descend", "ascend"],
    //   render: (runInfo: WorkflowRunInfo) =>
    //     runInfo.lastRunAt != "" &&
    //     new Date(runInfo.lastRunAt).toLocaleTimeString(
    //       undefined,
    //       dateTimeFormatOptions
    //     ),
    // },
  ];

  const deleteAllowed = (record: Workflow) => {
    return `${record.tenantID}_tag` == context && record.state == SpecStateType.StateDraft ;
  };

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter)  => {
    setWorkflowSorters(HyprFlowTabType.Workflows, sorter);
    setWorkflowFilters(HyprFlowTabType.Workflows, filters);
    setCurrentPage(HyprFlowTabType.Workflows);
  }

  return (
    <Spin spinning={tabLoader}>
      {createEditFlowsModal && (
        <CreateWorkflowModal
          open={createEditFlowsModal}
          onClose={() => setCreateEditFlowsModal(false)}
          onSubmit={(values) => saveNewWorkflow(values)}
          loader={loader}
        />
      )}
      <EntitiesDisplay
        header={"Workflows"}
        dataSource={workflows as any[]}
        columns={columns.filter(item => !item.hidden)}
        pageNumber={workflowPage.number}
        pageSize={workflowPage.size}
        total={workflowPage.total}
        onChange={onChange}
        onSearch={(text: string) => {
          setCurrentPage(HyprFlowTabType.Workflows);
          const searchTextMap = workflowSearchTextMap[HyprFlowTabType.Workflows];
          searchTextMap.set("name", text);
          setWorkflowSearchTextMap(HyprFlowTabType.Workflows, searchTextMap);
        }}
        searchPlaceholder={"search workflows by name"}
        searchText={workflowSearchTextMap[HyprFlowTabType.Workflows]?.get("name")}
        onPageChange={onPageChange}
        actions={
          [
            {
              key: "create", 
              label: "Create", 
              enable: () => true, 
              hidden: !context?.endsWith('_tag'),
              onClick: () => {setCreateEditFlowsModal(true)}
            },
            {
              key: "import", 
              label: "Import", 
              enable: () => true, 
              isImport: true,
              mspMode: mspEnabled,
              onClick: (value, selectedTags, tagsOnly) => {
                if (value) {
                  OnImportWorkflow(value, selectedTags, tagsOnly);
                }
              }
            },
            {
              key: "export", 
              label: "Export", 
              enable: (itemIds) => {
                return itemIds && itemIds.length > 0
              },
              showWarn: true,
              warnMessage: "Please make sure no sensitive information in workflows to be exported!",
              onClick: (value) => {
                if (value) {
                  const itemIds = value as string[];
                  const items = workflows.filter((w) => itemIds.find((id) => id == w.id))
                  if (items) {
                    OnExportWorkflow(items);
                  }
                }
              }
            },
            {
              key: "clone", 
              label: "Clone", 
              enable: (itemIds) => {
                return itemIds && itemIds.length == 1
              },
              mspMode: mspEnabled,
              onClick: (value, selectedTags, tagsOnly) => {
                if (value) {
                  const id = value[0] as string;
                  const item = workflows.find((w) => w.id == id);
                  if (item) {
                    OnCloneWorkflow(item, selectedTags, tagsOnly);
                  }
                }
              }
            },
            {
              key: "delete", 
              label: "Delete", 
              enable: (itemIds) => {
                if (itemIds && itemIds.length) {
                  return itemIds.every((id) => workflows.find((w) => w.id == id && deleteAllowed(w)));
                } else {
                  return false;
                }
              }, 
              hidden: !context?.endsWith('_tag'),
              showWarn: true,
              onClick: (value) => {
                const itemIds = value as string[];
                const items = workflows.filter((w) => itemIds.find((id) => id == w.id))
                if (items) {
                  OnDeleteSavedWorkflows(items);
                }
              }
            }
          ]
        }
      />
    </Spin>
  );
};

export default WorkflowsTab;
