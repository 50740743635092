import { create } from "zustand"
import { devtools, subscribeWithSelector, persist } from "zustand/middleware";

import {
  SearchStore,
  AppInfo,
  SearchActionInfo,
  SearchActionInfos,
  searchArtifactStoreName,
  Artifact,
  SearchArtifactStore,
  SearchRun,
  SearchRunAction,
  Search,
  SearchArtifactProvidersStore,
  ArtifactProviders,
  searchArtifactProviderStoreName,
  SearchRunActionType,
  SearchTabType,
  PageInfo,
  SearchMetaData,
} from "types";

import {
  createSearchApi,
  deleteSearchApi,
  getSavedSearchesApi,
  getPopularSearchesApi,
  getRecentSearchesApi,
  getSearchRunRecommendedActionsApi,
  getSearchRunApi,
  getSearchRunActionApi,
  actionSearchRunActionApi,
  actionSearchRunApi,
  runSearchXApi,
  updateSearchApi,
  getArtifactsApi,
  getArtifactProvidersApi,
  runSearchApi,
  getSearchRunActionsBySearchRunIdsApi,
} from "api";
import { useWorkflowStepsStore } from "./workflow/steps";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import { convertToApiFilters, convertToApiSorters } from "./utils";

export const useSearchStore = create<SearchStore>()(
  devtools((set, get) => ({
    // initialize the store
    searchLoader: false,
    searchQuery: { query: "", isAiBasedQuery: true } as SearchMetaData,
    searchRunActionSelectedDatas: {},
    recommendedActions: [],
    recommendedApps: [],
    currentSearchRun: {} as SearchRun,
    currentSearchRunTags: [],
    currentSearchRunAction: {} as SearchRunAction,
    searchRunActions: [],
    showDetailedResults: true,
    searchItemsMap: new Map<SearchTabType, Search[]>(),
    searchPage: {
      [SearchTabType.SavedSearches]: { number: 1, size: 12, total: 0 } as PageInfo,
      [SearchTabType.RecentSearches]: { number: 1, size: 12, total: 0 } as PageInfo,
      [SearchTabType.PopularSearches]: { number: 1, size: 12, total: 0 } as PageInfo
    } as Record<SearchTabType, PageInfo>,
    searchFilters: {
      [SearchTabType.SavedSearches]: {} as Record<string, FilterValue | null>,
      [SearchTabType.RecentSearches]: {} as Record<string, FilterValue | null>,
      [SearchTabType.PopularSearches]: {} as Record<string, FilterValue | null>
    } as Record<SearchTabType, Record<string, FilterValue | null>>,
    searchSorters: {
      [SearchTabType.SavedSearches]: {} as SorterResult<DataType>,
      [SearchTabType.RecentSearches]: {} as SorterResult<DataType>,
      [SearchTabType.PopularSearches]: {} as SorterResult<DataType>
    } as Record<SearchTabType, SorterResult<DataType> | SorterResult<DataType>[]>,
    searchSearchTextMap: {
      [SearchTabType.SavedSearches]: new Map<string, string>(),
      [SearchTabType.RecentSearches]: new Map<string, string>(),
      [SearchTabType.PopularSearches]: new Map<string, string>(),
    } as Record<SearchTabType, Map<string, string>>,
    activeTab: SearchTabType.SavedSearches,

    resetSearchState: () => {
      set((state) => ({
        searchRunActionSelectedDatas: {},
        recommendedActions: [],
        recommendedApps: [],
        currentSearchRun: {} as SearchRun,
        currentSearchRunTags: [],
        currentSearchRunAction: {} as SearchRunAction,
        searchRunActions: [],
        searchFilters: {} as Record<SearchTabType, Record<string, FilterValue | null>>,
        searchSorters: {} as Record<SearchTabType, SorterResult<DataType>>,
        searchSearchTextMap: {} as Record<SearchTabType, Map<string, string>>
      }));
    },

    setSearchLoader: (val) => {
      set((state) => ({
        ...state,
        searchLoader: val,
      }));
    },

    setSearchQuery: (val) => {
      set((state) => ({
        ...state,
        searchQuery: val,
      }));
    },
    setShowDetailedResults: (val) => {
      set((state) => ({
        ...state,
        showDetailedResults: val,
      }));
    },

    runSearchX: async (query) => {
      try {
        const searchRun = await runSearchXApi(query, get().searchQuery.isAiBasedQuery);
        set((state) => ({
          ...state,
          searchQuery: {
            query: searchRun.searchMetaData.query,
            nativeQuery: searchRun.searchMetaData.nativeQuery,
            name: searchRun.searchMetaData.name,
            description: searchRun.searchMetaData.description,
            isAiBasedQuery: searchRun.searchMetaData.isAiBasedQuery
          } as SearchMetaData,
          currentSearchRun: searchRun,
        }));
        return searchRun.id
      } catch (error: any) {
        set((state) => ({
          ...state,
          searchQuery: { ...get().searchQuery, assistMessage: (error as Error).message }
        }));
        console.error(error);
        throw new Error(error);
      }
    },

    runSearch: async () => {
      try {
        const id = get().searchQuery.id
        if (!id) {
          throw new Error("id is not set")
        }
        const searchRun = await runSearchApi(id);
        set((state) => ({
          ...state,
          searchQuery: {
            id: id,
            query: searchRun.searchMetaData.query,
            nativeQuery: searchRun.searchMetaData.nativeQuery,
            name: searchRun.searchMetaData.name,
            description: searchRun.searchMetaData.description,
            isAiBasedQuery: searchRun.searchMetaData.isAiBasedQuery
          } as SearchMetaData,
          currentSearchRun: searchRun,
        }));
        return searchRun.id
      } catch (error: any) {
        set((state) => ({
          ...state,
          searchQuery: { ...get().searchQuery, assistMessage: error.String() }
        }));
        console.error(error);
        throw new Error(error);
      }
    },

    cancelSearchRun: async (searchRunId) => {
      try {
        await actionSearchRunApi(searchRunId, SearchRunActionType.Cancel);
        await get().getSearchRun(searchRunId);
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },


    getSearchRun: async (searchRunId) => {
      try {
        const searchRun = await getSearchRunApi(searchRunId);
        const searchRunTags = searchRun.resultMetas.map((rm) => `${rm.tenantID}_tag`)
        const uniqueSearchRunTags = [...new Set(searchRunTags)];
        set((state) => ({
          ...state,
          currentSearchRun: searchRun,
          currentSearchRunTags: uniqueSearchRunTags,
          searchQuery: {
            query: searchRun.searchMetaData.query,
            nativeQuery: searchRun.searchMetaData.nativeQuery,
            name: searchRun.searchMetaData.name,
            description: searchRun.searchMetaData.description,
            isAiBasedQuery: searchRun.searchMetaData.isAiBasedQuery
          } as SearchMetaData,
        }));
        return searchRun
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getSearchRunRecommendedActions: async (searchRunId) => {
      try {
        const recommendedActions = await getSearchRunRecommendedActionsApi(searchRunId);

        const searchActionInfos: SearchActionInfos = [];

        recommendedActions.actionInfos.forEach((actionInfo: SearchActionInfo) => {
          actionInfo.active = false;
          actionInfo.appInfos.forEach((appInfo: AppInfo) => {
            if (appInfo.isConfigured) {
              actionInfo.active = true;
            }
          });
          searchActionInfos.push(actionInfo);
        });
        set((state) => ({
          ...state,
          recommendedActions: searchActionInfos,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getSearchItems: async (searchTabType: SearchTabType, pageNumber: number, pageSize: number) => {
      const filters = get().searchFilters[searchTabType];
      const sorters = get().searchSorters[searchTabType];
      const searchTextMaps = get().searchSearchTextMap[searchTabType];

      const srFilters = convertToApiFilters(filters, searchTextMaps);
      const srSorters = convertToApiSorters(sorters);
     
      let searches: Search[] = [];
      let total = 0;

      switch (searchTabType) {
        case SearchTabType.SavedSearches: {
          [searches, total] = await getSavedSearchesApi(pageNumber, pageSize, srFilters, srSorters);
          break;
        }
        case SearchTabType.RecentSearches: {
          [searches, total] = await getRecentSearchesApi(pageNumber, pageSize, srFilters, srSorters);
          break;
        }
        case SearchTabType.PopularSearches: {
          [searches, total] = await getPopularSearchesApi(pageNumber, pageSize, srFilters, srSorters);
          break;
        }
        default:
          console.log("wrong search item type");
          throw "wrong search item type";
      }

      const newPage: PageInfo = { number: pageNumber, size: pageSize, total: total };
      set((state) => ({
        searchPage: {...state.searchPage, [searchTabType]: newPage},
        searchItemsMap: new Map<SearchTabType, Search[]>(state.searchItemsMap).set(searchTabType, searches)
      }));
      return;
    },

    createSearch: async (search, tagsOnly): Promise<Search> => {
      try {
        search.query = get().searchQuery.query;
        search.isAiBasedQuery = get().searchQuery.isAiBasedQuery;
        const response: Search = await createSearchApi(search, tagsOnly);
        return response;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },
    runSearchActions: async (searchRunId, searchRunActioninfo, searchRunActionSelectedDatas) => {
      try {
        const searchRun: SearchRun = {
          id: searchRunId,
          actionInfo: searchRunActioninfo,
          actionDatas: searchRunActionSelectedDatas,
        } as SearchRun;

        const searchRunAction = await actionSearchRunApi(
          searchRunId,
          SearchRunActionType.RunActions,
          searchRun
        ) as SearchRunAction;

        set((state) => ({
          ...state,
          currentSearchRunAction: searchRunAction,
          searchRunActions: [searchRunAction, ...state.searchRunActions],
        }));
        return searchRunAction;

      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getSearchRunAction: async (searchRunActionId, isCurrent) => {
      try {
        const searchRunAction = await getSearchRunActionApi(searchRunActionId);
        const a = useWorkflowStepsStore.getState().actions.find((a) => a.id == searchRunAction.actionInfo.id);
        if (a) searchRunAction.actionInfo.displayName = a?.displayName;
        set((state) => {
          const index = state.searchRunActions.findIndex((x) => x.id == searchRunActionId);
          index == -1 ? state.searchRunActions = [...state.searchRunActions, searchRunAction]
            : state.searchRunActions[index] = searchRunAction;
          if (isCurrent) {
            state.currentSearchRunAction = searchRunAction;
          }
          return { ...state }
        });
        return searchRunAction
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getSearchRunActions: async (searchRunIds, pageNumber, pageSize) => {
      try {
        const searchRunActions = await getSearchRunActionsBySearchRunIdsApi(searchRunIds, pageNumber, pageSize);
        searchRunActions.forEach((sra) => {
          const a = useWorkflowStepsStore.getState().actions.find((a) => a.id == sra.actionInfo.id);
          if (a) sra.actionInfo.displayName = a?.displayName;
        })

        set((state) => ({
          ...state,
          searchRunActions: [...state.searchRunActions, ...searchRunActions]
        }));
        return
    } catch(error: any) {
      console.error(error);
      throw new Error(error);
    }
  },

    cancelSearchRunAction: async (searchRunActionId) => {
      try {
        await actionSearchRunActionApi(searchRunActionId, SearchRunActionType.Cancel);
        await get().getSearchRunAction(searchRunActionId, true);
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearCurrentSearchRunAction: async () => {
      set((state) => ({
        ...state,
        currentSearchRunAction: {} as SearchRunAction,
      }));
      return
    },

    clearRecommendedActions: async () => {
      set((state) => ({
        ...state,
        recommendedActions: [],
      }));
      return
    },

    updateSelectedSearchResults: (appSubscriptionID, data) => {
      set((state) => {
        const selectedDatas = { ...state.searchRunActionSelectedDatas };
        selectedDatas[appSubscriptionID] = data;
        return {
          ...state,
          searchRunActionSelectedDatas: selectedDatas,
        };
      });
      return
    },

    clearSelectedSearchResults: (appSubscriptionID) => {
      set((state) => {
        const selectedDatas = { ...state.searchRunActionSelectedDatas };
        delete selectedDatas[appSubscriptionID];
        return {
          ...state,
          searchRunActionSelectedDatas: { ...selectedDatas },
        };
      });
      return
    },

    clearAllSelectedSearchResults: () => {
      set((state) => ({
        ...state,
        searchRunActionSelectedDatas: {},
      }));
      return
    },

    clearSearchRunActions: () => {
      set((state) => ({
        ...state,
        searchRunActions: [],
      }));
      return
    },

    updateSearch: async (searchId, name, description) => {
      try {
        await updateSearchApi(searchId, name, description);
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    deleteSearch: async (searchId) => {
      try {
        await deleteSearchApi(searchId);
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    setActiveTab: (tabType: SearchTabType) => {
      set((state) => ({
        ...state,
        activeTab: tabType,
      }));
    },

    setCurrentPage: (tabType: SearchTabType, pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const pageInfo = state.searchPage[tabType];
        const newPage: PageInfo = {...pageInfo, number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: pageInfo?.total as number};
        return { ...state, searchPage : {...state.searchPage, [tabType]: newPage}};
      });
      return;
    },

    setSearchFilters: (tabType: SearchTabType, filters?: Record<string, FilterValue | null>) => {
      set( (state) => {
        return { ...state, searchFilters: {...state.searchFilters, [tabType]: filters}};
      });
      return;
    },

    setSearchSorters: (tabType: SearchTabType, sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
      set( (state) => {
        return { ...state, searchSorters: {...state.searchSorters, [tabType]: sorters}};
      });
      return;
    },

    setSearchSearchTextMap: (tabType: SearchTabType, searchTextMap: Map<string, string>) => {
      set( (state) => {
        return { ...state, searchSearchTextMap: {...state.searchSearchTextMap, [tabType]: searchTextMap}};
      });
      return;
    },
   
  }),
{ name: "SearchStore" })
);


export const useSearchArtifactStore = create<SearchArtifactStore>()(
  subscribeWithSelector(devtools(
    persist((set, get) => ({
      artifacts: [],

      getArtifacts: async () => {
        try {
          const [artifacts, total] = await getArtifactsApi();

          set((state) => ({
            ...state,
            artifacts: artifacts,
          }));
          return artifacts;
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },
    }),
      {
        name: searchArtifactStoreName,
        getStorage: () => sessionStorage,
      }),
    { name: searchArtifactStoreName }))
);

export const useSearchArtifactProvidersStore = create<SearchArtifactProvidersStore>()(
  devtools((set, get) => ({
    artifactProvidersMap: new Map<string, ArtifactProviders>(),

    getArtifactProviders: async (artifactId: string) => {
      try {
        const artifactProviders: ArtifactProviders = ({} as any) as ArtifactProviders;
        const [providers, total] = await getArtifactProvidersApi(artifactId, null);
        artifactProviders.providers = providers;
        if (artifactProviders.providers.length) {
          artifactProviders.artifact = artifactProviders.providers[0].artifacts;
        }

        set((state) => ({
          ...state,
          artifactProvidersMap: new Map<string, ArtifactProviders>(state.artifactProvidersMap).set(artifactId, artifactProviders)
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },
  }),
    { name: searchArtifactProviderStoreName })
);  