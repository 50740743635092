import { FC, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  Collapse,
  theme,
  Typography,
  Space,
  Image,
  Badge,
  Card,
  Row,
  Tree,
  Tooltip,
} from "antd";
import { PresetStatusColorType } from "antd/es/_util/colors";
import { JsonViewer } from '@textea/json-viewer'

import {
  RunSearchActionEdgeResult,
  SearchRunAction,
  SearchRunActionResultMeta,
  SearchRunActionStatus,
  dateTimeFormatOptions,
} from "types";
import { useSearchStore, useEdgeStore, useSettingsStore } from "store";
import { getAppInfoBySubscriptionId, getRunActionStatus } from "../Utils";
import {
  getSearchActionOutputsApi,
  getSearchActionParametersApi,
  getSearchActionResultApi,
} from "api";
import { getAppLogoUrl } from "utility/app";

const { Text } = Typography;
const { Panel } = Collapse;

const { TreeNode } = Tree;

export interface RowActionResultDetailProps {
  searchRunId:string;
  searchRunAction: SearchRunAction;
  rowIndex: string;
  expanded: boolean;
}

type ActionInfo = {
  key: string;
  actionDisplayName: string;
  appSubscriptionId: string;
  appNameWithSubscription: string;
  statusColor: string;
  status: PresetStatusColorType;
  rm: SearchRunActionResultMeta;
  edgeResult: RunSearchActionEdgeResult;
  outputs: Record<string, any>;
  parameters: Record<string, any>;
};

const RowActionResultDetail: FC<RowActionResultDetailProps> = ({
  searchRunId,
  searchRunAction,
  rowIndex,
  expanded,
}) => {
  const { token } = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const { recommendedActions } = useSearchStore((state) => ({
    recommendedActions: state.recommendedActions,
  }));
  const [actionInfos, setActionInfos] = useState(new Map<string, ActionInfo>());
  const { getEdgeEndpoint } = useEdgeStore((state) => ({
    getEdgeEndpoint: state.getEdgeEndpoint,
  }));

  const capitalizeFirstChar = (str?: string) =>
    str && str.charAt(0).toUpperCase() + str.slice(1);
  const getRowActionInfos = (lActionInfos: Map<string, ActionInfo>) => {
    const arrActionInfos: ActionInfo[] = [];
    lActionInfos.forEach((actionInfo) => {
      arrActionInfos.push(actionInfo);
    });
    return arrActionInfos;
  };

  const getOutputsBadges = (lActionInfos: Map<string, ActionInfo>) => {
    const outputBadges: Record<string, any> = {};
    const arrActionInfos = getRowActionInfos(lActionInfos);
    if (arrActionInfos) {
      arrActionInfos.forEach((actionInfo: ActionInfo, index: number) => {
        if (actionInfo.outputs) {
          Object.entries(actionInfo.outputs).forEach(([key, value]) => {
            if (typeof value == "object") {
              const values: string[] = [];
              Object.entries(value).forEach(([key, value]) => {
                values.push(key + ":" + value);
              });
              outputBadges[key] = values.join(" , ");
            } else if (Array.isArray(value)) {
              const values: string[] = [];
              value.forEach((x) => {
                values.push(key + ":" + value);
              });
              outputBadges[key] = values.join(" , ");
            } else {
              outputBadges[key] = value;
            }
          });
        }
      });
    }
    return (
      <Space size={token.size} style={{ display: "flex" }}>
        {Object.entries(outputBadges).map(([key, value], index) => (
          <Badge
            key={key}
            count={`${key} : ${value}`}
            color={token.colorInfo}
          />
        ))}
      </Space>
    );
  };

  const getUpdatedTime = (updatedAt: string) => {
    return new Date(updatedAt).toLocaleTimeString(
      undefined,
      dateTimeFormatOptions
    );
  };

  const showJsonView = (key: string, data: any) => {
    return (
      <TreeNode
        key={key}
        title={
          <JsonViewer 
            rootName={false}
            editable={true}
            displayDataTypes={false}
            displaySize={false}
            enableClipboard={true}
            value={data}
            theme={lightMode ? "light" : "auto"}
            highlightUpdates={true}
            sx={{paddingLeft: 2}}
          />
        }
        isLeaf={true}
        selectable={false}
      />
    );
  };

  const getParametersTreeNode = (actionInfo: ActionInfo) => {
    const key = `${actionInfo.appSubscriptionId}-parameters`;
    return (
      <TreeNode
        key={key}
        title={"Parameters passed to the action"}
        isLeaf={false}
        selectable={false}
        icon={<Badge status={actionInfo.status} />}
      >
        {showJsonView(`${key}-jsonview`, actionInfo.parameters)}
      </TreeNode>
    );
  };

  const getOutputsTreeNode = (actionInfo: ActionInfo) => {
    const key = `${actionInfo.appSubscriptionId}-outputs`;
    return (
      <TreeNode
        key={key}
        title={"Outputs recieved from action"}
        isLeaf={false}
        selectable={false}
        icon={<Badge status={actionInfo.status} />}
      >
        {showJsonView(`${key}-jsonview`, actionInfo.outputs)}
      </TreeNode>
    );
  };

  const getAppPanelRightIcon = (actionInfo: ActionInfo) => {
    if (
      actionInfo.status == "error" &&
      actionInfo.edgeResult.error &&
      actionInfo.edgeResult.error != ""
    ) {
      return (
        <Tooltip placement="right" title={actionInfo.edgeResult.error}>
          <Space size={token.sizeSM}>
            <Badge status={actionInfo.status} />
            <Text>{capitalizeFirstChar(actionInfo.status)}</Text>
          </Space>
        </Tooltip>
      );
    }
    return (
      <Space size={token.sizeSM}>
        <Badge status={actionInfo.status} />
        <Text>{capitalizeFirstChar(actionInfo.status)}</Text>
      </Space>
    );
  };

  const getAppBasedPanel = (actionInfo: ActionInfo, expanded: boolean) => {
    const appInfo = getAppInfoBySubscriptionId(
      actionInfo.appSubscriptionId,
      recommendedActions
    );
    const treeNodes = [
      getParametersTreeNode(actionInfo),
      getOutputsTreeNode(actionInfo),
    ];
    return (
      <Collapse
        defaultActiveKey={expanded ? [actionInfo.appSubscriptionId] : []}
        size="small"
        key={actionInfo.appSubscriptionId}
      >
        <Panel
          header={
            <Space size={token.sizeMS}>
              {(appInfo?.logoUrl || appInfo?.darkLogoUrl) && (
                <Image
                  preview={false}
                  draggable={false}
                  width={"90px"}
                  height={"30px"}
                  src={lightMode ? appInfo.logoUrl : appInfo.darkLogoUrl}
                  alt="company-logo"
                />
              )}
              {appInfo?.displayName && (
                <Text style={{ fontSize: token.fontSizeHeading5 }}>
                  {`${appInfo.displayName}(${appInfo?.appSubscriptionsInfos[0].name})`}
                </Text>
              )}
            </Space>
          }
          extra={getAppPanelRightIcon(actionInfo)}
          key={actionInfo.appSubscriptionId}
        >
          <Tree showIcon defaultExpandAll={true}>
            {getParametersTreeNode(actionInfo)}
            {getOutputsTreeNode(actionInfo)}
          </Tree>
        </Panel>
      </Collapse>
    );
  };

  const getDefaultActionInfo = (
    actionDisplayName: string,
    rm: SearchRunActionResultMeta
  ) => {
    return {
      statusColor: "orange",
      actionDisplayName: actionDisplayName,
      appSubscriptionId: rm.appSubscriptionID,
      rm: rm,
      appNameWithSubscription: `${rm.appName}(${rm.appSubscriptionName})`,
      status: "processing",
      outputs: {},
      parameters: {},
    } as ActionInfo;
  };

  useEffect(() => {
    const asyncUseEffect = async () => {
      for (const rm of searchRunAction.resultMetas) {
        if(rm.searchRunID != searchRunId) continue;
        const actionInfo = getDefaultActionInfo(
          searchRunAction?.actionInfo?.displayName,
          rm
        );
        try {
          const edgeEndpoint = await getEdgeEndpoint(
            (rm as SearchRunActionResultMeta).edgeID
          );
          const result = await getSearchActionResultApi(
            edgeEndpoint,
            rm.tenantID,
            rm.searchRunID,
            rm.searchRunActionID,
            rm.appSubscriptionID,
            rowIndex
          );
          actionInfo.statusColor = result ? "green" : "red";
          actionInfo.edgeResult = result;
          actionInfo.status = result ? "success" : "error";
          if (result) {
            actionInfo.outputs = await getSearchActionOutputsApi(
              edgeEndpoint,
              rm.tenantID,
              rm.searchRunID,
              rm.searchRunActionID,
              rm.appSubscriptionID,
              rowIndex
            );
            actionInfo.parameters = await getSearchActionParametersApi(
              edgeEndpoint,
              rm.tenantID,
              rm.searchRunID,
              rm.searchRunActionID,
              rm.appSubscriptionID,
              rowIndex
            );
          }
        } catch (err) {
          if (searchRunAction.status != SearchRunActionStatus.Running) {
            actionInfo.statusColor = "red";
            if (err instanceof Error) {
              actionInfo.edgeResult = {
                error: err.message,
              } as RunSearchActionEdgeResult;
            } else {
              actionInfo.edgeResult = {
                error:
                  "search run action is finished, but neither success/error recieved from edge",
              } as RunSearchActionEdgeResult;
            }
          }
        }
        setActionInfos(
          (actionInfos) =>
            new Map(actionInfos.set(rm.appSubscriptionID, actionInfo))
        );
      }
    };
    for (const rm of searchRunAction.resultMetas) {
      if(rm.searchRunID != searchRunId) continue;
      const actionInfo = getDefaultActionInfo(
        searchRunAction?.actionInfo?.displayName,
        rm
      );
      setActionInfos(
        (actionInfos) =>
          new Map(actionInfos.set(rm.appSubscriptionID, actionInfo))
      );
    }
    asyncUseEffect();
  }, [searchRunAction]);

  return (
    <Collapse
      defaultActiveKey={expanded ? [searchRunAction.id] : []}
      size="small"
    >
      <Panel
        header={
          <Space size={8}>
            {getRunActionStatus(searchRunAction)}
            <Text>
              {searchRunAction?.actionInfo?.displayName}
            </Text>
          </Space>
        }
        extra={
          <Space>
            <Text>{getUpdatedTime(searchRunAction.updatedAt)}</Text>
            <Badge
              count={searchRunAction.metrics?.timeTaken}
              color={token.colorInfo}
            />
          </Space>
        }
        key={searchRunAction.id}
      >
        <Space direction="vertical" style={{ display: "flex" }}>
          {/* {getOutputsBadges(actionInfos)}  */}
          {getRowActionInfos(actionInfos).map((actionInfo, index) => (
            <div key={actionInfo.appSubscriptionId}>
              {getAppBasedPanel(actionInfo, !index)}
            </div>
          ))}
        </Space>
      </Panel>
    </Collapse>
  );
};

export default RowActionResultDetail;
