import { FC, memo, useState, } from "react";
import { useNavigate, } from "react-router-dom";

import {Space, theme } from "antd";
import { useReactFlow, useStoreApi} from '@reactflow/core';

import { commonIcons, workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import {ControlButton} from "components/ControlButton";
import { useWorkflowStore } from "store";
import { getWorkflowPendingConfigurations } from "utility/workflow";

export type EditorControlsProps = {
  editMode: boolean
};

const EditorControls: FC<EditorControlsProps> = ({
  editMode
}) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  
//metric
  const { changeSidebarCollapse, activeCopyId, setActiveCopyId, setActiveNodeCardId} = useWorkflowStore(
    (state) => ({
      changeSidebarCollapse: state.changeSidebarCollapse,
      activeCopyId: state.activeCopyId,
      setActiveCopyId: state.setActiveCopyId,
      setActiveNodeCardId: state.setActiveNodeCardId,
    })
  );

  const onZoomInHandler = () => {
    zoomIn();
  };

  const onZoomOutHandler = () => {
    zoomOut();
  };

  const onFitViewHandler = () => {
    fitView({maxZoom: 1});
  };


  return (
    <Space size={1}>
      <ControlButton
        displayName={"Back"}
        svgComponent={
          <SvgIcon
            onClick={() => {
              navigate(-1);
            }}
            component={commonIcons.backIcon}
          />
        }
        hoverable={true}
      />
      <ControlButton
          displayName={"Workflow Properties"}
          svgComponent={
            <SvgIcon
              onClick={() => {setActiveNodeCardId("workflow")}}
              component={commonIcons.editIcon}
            />
          }
          hoverable={true}
      />
      <ControlButton
        displayName={"Zoom In"}
        svgComponent={
          <SvgIcon
            onClick={onZoomInHandler}
            component={commonIcons.zoomIn}
          />
        }
        hoverable={true}
      />
      <ControlButton
        displayName={"Zoom Out"}
        svgComponent={
          <SvgIcon
            onClick={onZoomOutHandler}
            component={commonIcons.zoomOut}
          />
        }
        hoverable={true}
      />
      <ControlButton
        displayName={"Fit View"}
        svgComponent={
          <SvgIcon
            onClick={onFitViewHandler}
            component={commonIcons.fitView}
          />
        }
        hoverable={true}
      />
      {editMode &&
        <ControlButton
          displayName={"Show Sidebar"}
          svgComponent={
            <SvgIcon
              onClick={changeSidebarCollapse}
              component={workflowIcons.stepsSidebarCollapse}
            />
          }
          hoverable={true}
        />
      }
      {editMode &&
        <ControlButton
          displayName={"Clear Clipboard"}
          svgComponent={
            <SvgIcon
              onClick={() => activeCopyId != null && setActiveCopyId(null)}
              component={commonIcons.clipBoardIcon}
            />
          }
          hoverable={activeCopyId != null}
        />
      }
    </Space>
  );
};
export default memo(EditorControls);
