import {
  Badge,
  Button,
  Flex,
  Popover,
  Space,
  Spin,
  Steps,
  Switch,
  Table,
  Tag,
  Typography,
  theme,
} from "antd";

import React, { useEffect, useState } from "react";

import { useOrganizationStore, useTokenStore } from "store";
import { Tag as Label, Subscriber, SubscriptionState } from "types";
import { getTagColor, getUserDetailsFromJWT } from "utility";
import { notification } from "utility/notification";
import { getStyles } from "../../utility/styles";
import { ProviderEditSubscribers } from "./ProviderEditSubscribers";
import { SubscriberEditFields } from "./SubscriberEditFields";
import { TreeNode, subscribersToTree } from "utility/msp";
import { TreeView } from "./TreeView";

const { Text } = Typography;

/** Provider mode functionality
 * 1. Enabled / diable MSP mode
 * 2. Enabled / disable external mode
 * 3. Create new subscriber if MSP mode is enabled
 * 4. Manage existing subscriber if MSP mode is enabled
 */

export const ProviderMode = () => {
  const context = useOrganizationStore((state) => state.context);
  const contextRequest = useOrganizationStore((state) => state.contextRequest);

  const jwt = useTokenStore((state) => state.token);

  const [tenantId, setTenantId] = useState<string>("");
  const subscribers = useOrganizationStore((state) => state.subscribers);
  const tags = useOrganizationStore((state) => state.tags);
  const [dataSource, setDataSource] = useState<Subscriber[]>([]);
  const getSubscribers = useOrganizationStore((state) => state.getSubscribers);
  const approveSubscriber = useOrganizationStore(
    (state) => state.approveSubscriber
  );
  const rejectSubscriber = useOrganizationStore(
    (state) => state.rejectSubscriber
  );
  const detachSubscriber = useOrganizationStore(
    (state) => state.detachSubscriber
  );
  const acknowledgeCancel = useOrganizationStore(
    (state) => state.acknowledgeCancel
  );

  const [create, setCreate] = useState(false);

  const [treeSubscriberEdit, setTreeSubscriberEdit] = useState<string | null>(
    null
  );

  const [treeView, setTreeView] = useState(true);
  const [treeData, setTreeData] = useState<TreeNode[]>([]);
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const { token } = theme.useToken();
  const classes = getStyles({
    container: {
      gap: token.marginXS,
      padding: token.padding,
      alignItems: "flex-start",
    },
  })();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [subscriberTags, setSubscriberTags] = useState<Record<string, Label[]>>(
    {}
  );
  const [, setLoading] = useState(false);

  const onDelete = async (id: string) => {
    try {
      setLoading(true);
      await detachSubscriber(id);
      notification.success({
        message: `Successfully detached tenants`,
        duration: 6,
      });
    } catch (error) {
      notification.error({
        message: (error as any)?.message || `Error deleting users`,
        duration: 6,
      });
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any[]) => setSelectedRowKeys(selectedRowKeys),
  };

  useEffect(() => {
    /** Tenant id to tag list */
    const subscriberTags: Record<string, Label[]> = {};
    Object.values(tags).forEach((tag) => {
      tag.resources.forEach((subscriber) => {
        if (subscriberTags[subscriber.id]) {
          subscriberTags[subscriber.id].push(tag);
        } else {
          subscriberTags[subscriber.id] = [tag];
        }
      });
    });
    setSubscriberTags(subscriberTags);
  }, [tags]);

  useEffect(() => {
    const { tenantId } = getUserDetailsFromJWT();
    setTenantId(tenantId);
  }, [jwt]);

  useEffect(() => {
    const source = Object.values(subscribers).filter(
      (x) => x.state !== SubscriptionState.rejected && x.tenantId !== tenantId
    );
    setDataSource(source);
    /** For treee data , we need to keep the to level node with us  */
    const treeSource = Object.values(subscribers).filter(
      (x) => x.state !== SubscriptionState.rejected
    );
    const treeData = subscribersToTree(treeSource);

    if (treeData.length > 0) {
      setTreeData(treeData);
    }

    // if (context == `${tenantId}_tag`) {
    //   setDataSource(source);
    // } else {
    //   setDataSource(
    //     source.filter((x) =>
    //       subscriberTags[x.tenantId]?.some((tag: Label) => tag.id === context)
    //     )
    //   );
    // }
  }, [subscribers, tenantId, context]);

  return (
    <Spin spinning={contextRequest != null}>
      <div
        id="provider-modes-container"
        className={classes.container}
        style={{
          backgroundColor: token.colorBgContainer,
          marginBottom: token.margin,
          width: "100%",
        }}
      >
        <div
          id="subscriptions-header"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: token.margin,
          }}
        >
          <div>
            <Text style={{ fontSize: token.fontSizeHeading4 }}>
              Total Subscribers (
              {
                Object.values(subscribers).filter((x) => x.tenantId != tenantId)
                  .length
              }
              )
            </Text>
          </div>
          <Space>
            <Switch
              checkedChildren="Tree"
              unCheckedChildren="List"
              onChange={(checked) => {
                setTreeView(checked);
              }}
            ></Switch>
            <Button type="primary" onClick={() => setCreate(true)}>
              Add
            </Button>
            <Button onClick={() => getSubscribers()}>Refresh</Button>
          </Space>
        </div>

        <div style={{ width: "100%" }}>
          {treeView ? (
            <div id="treeWrapper" style={{ height: "100vh" }}>
              <TreeView
                data={treeData}
                onEdit={async (id: string) => setTreeSubscriberEdit(id)}
                onDelete={async (id: string) => onDelete(id)}
              />
            </div>
          ) : (
            <Table
              rowKey={"tenantId"}
              key={`subscribers-table`}
              rowSelection={rowSelection}
              columns={[
                {
                  title: "Name",
                  dataIndex: "name",
                  key: "name",
                  render(value, record) {
                    const components = record.nodePath.split("/");
                    const isSubscriber =
                      components.length == 2 && components[0] == tenantId;
                    return (
                      <Space direction="vertical">
                        <Popover
                          content={
                            <Steps
                              direction="vertical"
                              size="small"
                              status="wait"
                              items={components.map((x) => ({
                                title: subscribers[x]?.name,
                              }))}
                            />
                          }
                        >
                          <Text>{value}</Text>
                        </Popover>
                        {isSubscriber && (
                          <Badge count={"subscriber"} color={"green"} />
                        )}
                      </Space>
                    );
                  },
                },
                {
                  title: "Description",
                  dataIndex: "description",
                  key: "description",
                },
                {
                  title: "Owner",
                  dataIndex: "userDisplayName",
                  key: "userDisplayName",
                  render: (text: string, record: Subscriber) => {
                    /** Use a tooltip to indicate user email address, use a text tag inside it for user full name */
                    return (
                      <Space direction="vertical">
                        <Text>{text}</Text>
                        <Text style={{ fontSize: "0.75rem" }}>
                          {record.userEmailAddress}
                        </Text>
                      </Space>
                    );
                  },
                },
                {
                  title: "Contexts",
                  filterSearch: true,
                  render: (text: string, record: Subscriber) => (
                    <Flex
                      gap="4px 1"
                      wrap="wrap"
                      justify="space-evenly"
                      align={"stretch"}
                    >
                      {subscriberTags[record.tenantId]?.map(
                        (resource: Label) => (
                          <Popover
                            key={resource.id}
                            content={<Text>{resource.value}</Text>}
                          >
                            <Tag color={getTagColor(resource.id)}>
                              {resource.value}
                            </Tag>
                          </Popover>
                        )
                      )}
                    </Flex>
                  ),
                },
                {
                  title: "Status",
                  key: "state",
                  render: (_, record: Subscriber, index: number) => {
                    const components = record.nodePath.split("/");
                    const isSubscriber =
                      components.length == 2 && components[0] == tenantId;

                    if (record.state === SubscriptionState.initiated) {
                      return (
                        <Space direction="vertical">
                          <Badge count={"initiated"} color="green"></Badge>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "10px",
                              width: "100%",
                            }}
                          >
                            <Button
                              type="primary"
                              style={{ minWidth: "100px" }}
                              onClick={async () =>
                                approveSubscriber(record.tenantId)
                              }
                              disabled={!isSubscriber}
                            >
                              Approve
                            </Button>
                            <Button
                              style={{ minWidth: "100px" }}
                              disabled={!isSubscriber}
                              onClick={async () =>
                                rejectSubscriber(record.tenantId)
                              }
                            >
                              Deny
                            </Button>
                          </div>
                        </Space>
                      );
                    }
                    if (record.state === SubscriptionState.approved) {
                      return (
                        <Space direction="vertical">
                          <Badge count={"approved"} color="green" />
                          <Space direction="horizontal">
                            <Button
                              type="primary"
                              style={{ minWidth: "100px" }}
                              onClick={() => {
                                setActiveIndex(index);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              style={{ minWidth: "100px" }}
                              disabled={!isSubscriber}
                              onClick={async () => onDelete(record.tenantId)}
                            >
                              Delete
                            </Button>
                            {activeIndex === index && (
                              <SubscriberEditFields
                                subscriber={record}
                                key={`edit_${record.name}`}
                                onClose={async () => setActiveIndex(-1)}
                              />
                            )}
                          </Space>
                        </Space>
                      );
                    }
                    if (
                      record.state ===
                      SubscriptionState.cancelInitiatedBySubscriber
                    ) {
                      return (
                        <Space direction="vertical">
                          <Badge count={"cancelled"} color="red" />
                          <div>
                            <Button
                              type="primary"
                              disabled={!isSubscriber}
                              onClick={() => acknowledgeCancel(record.tenantId)}
                            >
                              Ackowledge
                            </Button>
                          </div>
                        </Space>
                      );
                    }
                    if (
                      record.state ===
                      SubscriptionState.cancelInitiatedByProvider
                    ) {
                      return (
                        <Space direction="vertical">
                          <Badge count={"cancelled"} color="red" />
                          <Text style={{ fontSize: "0.75rem" }}>
                            Ackowledgement Pending
                          </Text>
                        </Space>
                      );
                    }
                  },
                },
              ]}
              dataSource={dataSource}
              bordered={true}
              pagination={
                Object.keys(subscribers).length <= 10 ? false : { pageSize: 10 }
              }
            />
          )}
        </div>
        {create && (
          <ProviderEditSubscribers
            isAdd={true}
            onClose={async () => setCreate(false)}
          />
        )}
        {treeSubscriberEdit && (
          <SubscriberEditFields
            onClose={async () => setTreeSubscriberEdit(null)}
            key={`edit_${treeSubscriberEdit}`}
            subscriber={subscribers[treeSubscriberEdit]}
          />
        )}
      </div>
    </Spin>
  );
};
