import { DeleteFilled, EditFilled, ReloadOutlined } from "@ant-design/icons";
import { Tooltip as AntdToolTip, Typography, theme } from "antd";
import { metricsIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { useState } from "react";
import {
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { convertToReadbableUnit } from "utility/metrics";

import { TrendData } from "types";
import {
  getContainerStyle,
  getHeadingContainerStyle,
  getHeadingTopLineStyle,
} from "./KpiStyles";
const { Text } = Typography;

export type KpiTrendProps = TrendData & { stretched?: boolean } & {
  id: string;
  onEdit?: (id: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onRefresh?: (id: string) => Promise<void>;
  color?: string;
};

export const KpiTrend = (props: KpiTrendProps) => {
  const [loading, setLoading] = useState(false);
  const { token } = theme.useToken();
  const containerStyle = getContainerStyle({
    height: "100px",
    minWidth: props.stretched ? "200px" : "150px",
    padding: "5px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: props.total ? "space-between" : "flex-start",
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
  });
  const headingtextStyle = {
    fontSize: token.fontSizeSM,
    marginLeft: token.marginXXS,
    opacity: 0.7,
    lineHeight: 1.1,
    maxWidth: props.stretched ? "175px" : "150px",
  };

  const headingContainerStyle = getHeadingContainerStyle({
    flexDirection: "row",
    justifyContent: "space-between",
  });
  const topLine = getHeadingTopLineStyle();
  const unit = props.primary.unit;

  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const value = payload[0].value as number;
      return (
        <AntdToolTip placement="top">
          <Text>{convertToReadbableUnit(unit, value)}</Text>
        </AntdToolTip>
      );
    }
    return null;
  };

  return (
    <div id="kpi-trend-container" style={containerStyle}>
      <div style={topLine}>
        <div style={headingContainerStyle}>
          <Text strong ellipsis style={headingtextStyle}>
            {props.primary.text}
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "5px",
            }}
          >
            {props.onRefresh && (
              <ReloadOutlined
                style={{ color: props.color }}
                onClick={async () => {
                  setLoading(true);
                  await props.onRefresh?.(props.id);
                  setLoading(false);
                }}
              />
            )}
            {props.onEdit && (
              <EditFilled
                style={{ color: props.color }}
                onClick={() => props.onEdit?.(props.id)}
              />
            )}
            {props.onDelete && (
              <DeleteFilled
                style={{ color: props.color }}
                onClick={() => props.onDelete?.(props.id)}
              />
            )}
          </div>
        </div>
      </div>
      <div
        id="kpi-trend-body"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          alignItems: "center",
          marginLeft: token.marginXXS,
        }}
      >
        <ResponsiveContainer width={"80%"} height={"100%"}>
          <LineChart
            data={props.primary.values.map((x) => {
              return { value: x };
            })}
          >
            <XAxis dataKey={"u"} tick={true} hide>
              <Label value={"time"} position="insideTop" />
            </XAxis>
            <Tooltip content={CustomTooltip} />
            <Line
              dataKey={"value"}
              strokeWidth={"2px"}
              stroke={token.colorPrimary}
              type={"natural"}
              dot={true}
              isAnimationActive={false}
            ></Line>
          </LineChart>
        </ResponsiveContainer>
        <div style={{ marginRight: token.marginXXS }}>
          <SvgIcon
            size={30}
            component={metricsIcons[props?.icon as keyof typeof metricsIcons]}
          />
        </div>
      </div>
      {props.total && (
        <Text
          style={{
            height: "20px",
            marginLeft: token.marginXXS,
            fontSize: token.fontSizeSM,
            color: token.colorSuccess,
          }}
        >
          {`Total Savings = ` +
            convertToReadbableUnit(props.total.unit, props.total.value)}
        </Text>
      )}
    </div>
  );
};
