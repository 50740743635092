import { MobileOutlined } from "@ant-design/icons";
import { Button, Typography, theme } from "antd";
import { FC, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "store";
import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import styles from "./MobileOtpVerification.module.scss";
import { notification } from 'utility/notification';

const { Text } = Typography;
const MobileOtpVerification: FC = () => {
  const initialTimer = 60;

  const { signUpValidate, resendOTP } = useAuthStore((state) => ({
    signUpValidate: state.signUpValidate,
    resendOTP: state.resendOTP,
  }));

  const [otp, setOtp] = useState<string>("");
  const [timer, setTimer] = useState<number>(initialTimer);
  const [, setLoader] = useState<boolean>(false);
  const { token } = theme.useToken();

  useEffect(() => {
    if (timer <= 0) return;
    setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
  }, [timer]);

  const navigate = useNavigate();

  const validationHelper = async () => {
    setLoader(true);
    try {
      await signUpValidate(otp);

      // on success
      notification.success({
        message: "OTP VERIFIED",
        description:
          "Your entered OTP is verified successfully and now you can set up your password.",
        duration: 6,
      });
      navigate("/signup-set-password");
    } catch (error) {
      console.error(error);
      // on error
      notification.error({
        message: "INCORRECT OTP",
        description:
          "Your entered OTP is incorrect, please enter the right OTP",
        duration: 6,
      });
      setOtp("");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (otp.length > 5) {
      validationHelper();
    }
  }, [otp]);

  const resendOtp = async () => {
    if (timer === 0) setLoader(true);
    try {
      await resendOTP();

      notification.success({
        message: "OTP SENT",
        description: "OTP is sent successfully to your mobile number",
        duration: 6,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
    setTimer(initialTimer);
  };

  return (
    <SignInSignUpWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={styles.iconWrapper}>
          <MobileOutlined />
        </div>
        <h2 className={styles.verificationTitle}>VERIFICATION</h2>
        <p className={styles.verificationDescription}>
          We will send you a{" "}
          <span className={styles.bold}>one time password</span> on your phone
          number
        </p>
        <OtpInput
          containerStyle={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: token.margin,
          }}
          inputStyle={{
            height: "2rem",
            borderRadius: token.borderRadius,
            borderColor: token.colorBorder,
            borderWidth: "1px",
            borderStyle: "solid",
            width: "30px",
          }}
          numInputs={6}
          onChange={setOtp}
          renderSeparator={<span>{"-"}</span>}
          value={otp}
          inputType="text"
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus
        />
        <Text>{"Didn't receive the OTP?"}</Text>
        <Text>
          Resend OTP in{" "}
          <Button type="link" onClick={resendOtp}>
            {timer}
          </Button>
        </Text>
      </div>
    </SignInSignUpWrapper>
  );
};

export default MobileOtpVerification;
