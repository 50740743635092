import { DeleteFilled, EditFilled, ReloadOutlined } from "@ant-design/icons";
import { Empty, Spin, Typography, theme } from "antd";

import { metricsIcons } from "assets/icons";
import { ItemsGroup } from "components/ItemsGroup";
import { SvgIcon } from "components/SvgIcon";
import { UserAvatarGroup } from "components/UserAvatarGroup";
import { useState } from "react";

import { useRbacStore, useSettingsStore } from "store";
import { MiniTileData } from "types";

const { Text } = Typography;

export type KpiMiniTileProps = MiniTileData & { stretched?: boolean } & {
  id: string;
  onEdit?: (id: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onRefresh?: (id: string) => Promise<void>;
  color?: string;
};

export const KpiMiniTile = (props: KpiMiniTileProps) => {
  const [loading, setLoading] = useState(false);
  const { token } = theme.useToken();
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));
  const { users, roles } = useRbacStore((state) => ({
    users: state.users,
    roles: state.roles,
  }));

  const natureToColor = (nature: string | undefined): string => {
    if (!nature) return mode ? "#282876" : token.colorTextLightSolid;
    switch (nature) {
      case "error":
        return token.colorError;
      case "success":
        return token.colorSuccess;
      case "warning":
        return token.colorWarning;
      default:
        return mode ? "#282876" : token.colorTextLightSolid;
    }
  };

  const getCustomItem = () => {
    switch (props.id) {
      case "users-total":
        return (
          <UserAvatarGroup size={"default"} userIds={Object.keys(users)} />
        );
      case "roles-total":
        return (
          <ItemsGroup
            items={Object.values(roles).map((roleDto) => ({
              id: roleDto.id,
              name: roleDto.name,
              description: roleDto.name,
            }))}
            size={"default"}
          />
        );
      default: {
        if (!props.tertiary) return <></>;
        if (!props.tertiary.items) return <></>;
        return (
          <ItemsGroup
            items={props.tertiary.items.map((x) => ({
              id: x.id,
              name: x.name,
              description: x.name,
            }))}
            size={"default"}
          />
        );
      }
    }
  };

  return (
    <div
      id="kpi-mini-tile-container"
      style={{
        height: "100px",
        minWidth: props.stretched ? "200px" : "150px",
        padding: "5px",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: props.secondary ? "space-between" : "flex-start",
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
      }}
    >
      <div
        id="kpi-mini-tile-header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "15px",
          width: "100%",
          alignItems: "center",
          opacity: 0.7,
          marginBottom: token.marginXS,
        }}
      >
        <Text
          strong
          ellipsis
          style={{
            fontSize: token.fontSizeSM,
            marginLeft: token.marginXXS,
            maxWidth: props.stretched ? "175px" : "150px",
          }}
        >
          {props.primary?.text}
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "5px",
          }}
        >
          {props.onRefresh && (
            <ReloadOutlined
              style={{ color: props.color }}
              onClick={async () => {
                setLoading(true);
                await props.onRefresh?.(props.id);
                setLoading(false);
              }}
            />
          )}

          {props.onEdit && (
            <EditFilled
              style={{ color: props.color }}
              onClick={() => props.onEdit?.(props.id)}
            />
          )}

          {props.onDelete && (
            <DeleteFilled
              style={{ color: props.color }}
              onClick={() => props.onDelete?.(props.id)}
            />
          )}
        </div>
      </div>
      <Spin spinning={loading}>
        <div
          id="kpi-mini-tile-body"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "30px",
            marginLeft: token.marginXXS,
            alignItems: "center",
          }}
        >
          <Text
            strong
            ellipsis
            style={{
              fontSize: props.secondary
                ? token.fontSizeHeading3
                : token.fontSizeHeading1,
              color: mode ? "#282876" : token.colorTextLightSolid,
              maxWidth: props.stretched ? "175px" : "150px",
            }}
          >
            {props.primary.value ?? <Empty />}
            <span style={{ fontSize: token.fontSizeHeading4 }}>
              {`  ${props.primary.unit ?? ""}`}
            </span>
          </Text>
          {getCustomItem()}
          <SvgIcon
            size={30}
            component={metricsIcons[props?.icon as keyof typeof metricsIcons]}
          />
        </div>
      </Spin>
      {props.secondary && (
        <Text
          style={{
            height: "20px",
            marginLeft: token.marginXXS,
            fontSize: token.fontSizeSM,
            color: natureToColor(props.secondaryNature),
          }}
        >
          {`${props.secondary.value}${props.secondary.unit} ${props.secondary.text}`}
        </Text>
      )}
    </div>
  );
};
