import { FC, useEffect, useState } from "react";
import {
  Button,
  Spin,
  Form,
  Input,
  Space,
  Typography,
  theme,
  Row,
  Select,
  Switch,
} from "antd";

import {
  WorkflowStepProvider,
  WorkflowNodeCardProps,
  TriggerType,
  WorkflowAppSubscriptionInfo,
  WorkflowStepOperator,
  Action,
} from "types";
import { notification } from 'utility/notification';
import {  useWorkflowStepProvidersStore, useWorkflowStepsStore, useWorkflowStore } from "store";
import {
  generateReferenceName,
} from "utility/workflow";
import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree,
} from "components/Suggestions";
import { FieldLabel } from "components/FieldLabel";
import { validateFormFields } from "utility";
import { FormInstance } from "antd/lib";
import ActionForm from "../ActionForm";

const { Text } = Typography;
const AIOperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  nodeType,
  editMode,
  onClose
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [actionDataForm, setActionDataForm] = useState<undefined | FormInstance>(undefined);
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const [actionData, setActionData] = useState<Record<string, any>>({});

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(selectedWorkflow?.steps[id]?.referenceName);
   const [selectedActionId, setSelectedActionId] = useState<string>("");

  const { actions } = useWorkflowStepsStore((state) => ({
    actions: state.actions,
  }));

  const { actionProvidersMap, getActionProviders } = useWorkflowStepProvidersStore((state) => ({
    getActionProviders: state.getActionProviders,
    actionProvidersMap: state.actionProvidersMap,
  }));

  const loadActionProviders = async (actionId: string) => {
    try {
      setLoader(true);
      await getActionProviders(actionId);
      setSelectedActionId(actionId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const syncCurrentState = async () => {
    try {
      const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
      if (operatorStep) {
        setSelectedActionId(operatorStep.parameters.action_id);
        form.setFieldValue("action_id", operatorStep.parameters.action_id);

        const actionData = {} as Record<string, any>;
        actionData.paramValues = operatorStep.parameters.action_parameters;
        if (operatorStep.actionProvider && operatorStep.actionProvider.providerID) {
          actionData.providerId = operatorStep.actionProvider.providerID;
          actionData.appSubscriptions = operatorStep.actionProvider?.appSubscriptionInfos?.map((s) => (s.appSubscriptionID));
          actionData.providerParamValues = operatorStep.actionProvider?.parameters;
        }  
        setActionData(actionData);
        form.setFieldValue("actionData", actionData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
  }, [selectedWorkflow]);

  useEffect(() => {
    selectedActionId != "" && loadActionProviders(selectedActionId);
  }, [selectedActionId]);

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const getAppInfo = (providerId: string) => {
    const actionProvider = actionProvidersMap.get(selectedActionId);
    return actionProvider?.providers.find((provider) => provider.id == providerId)?.appInfo;
  };

  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
        operatorStep.isConfigured = true;
        operatorStep.name = values.name;
        operatorStep.referenceName = referenceName;
        setReferenceName(operatorStep.referenceName);

        operatorStep.parameters.action_id = selectedActionId;

        const actionProvider = {} as WorkflowStepProvider;
        const ad = values.actionData;
        if (ad) {
          operatorStep.parameters.action_parameters = ad.paramValues;
          const providerId = ad.providerId as string;
          if (providerId) {
            const appInfo = getAppInfo(providerId);
            if (appInfo) {
              actionProvider.providerID = providerId;
              actionProvider.appID = appInfo.id;
              actionProvider.displayName = appInfo.displayName;
              actionProvider.parameters = ad.providerParamValues;
              actionProvider.appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
              const subscriptions = ad.appSubscriptions as string[];
              subscriptions?.map((subscriptionId) => {
                actionProvider.appSubscriptionInfos.push({
                  appSubscriptionID: subscriptionId,
                } as WorkflowAppSubscriptionInfo);
              });
            }
          }
          setActionData(ad);
        }

        operatorStep.actionProvider = actionProvider;
        operatorStep.isAuditRequired = false;
        selectedWorkflow.steps[id] = operatorStep;
        updateSelectedWorkflow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Form
      form={form}
      name="aiCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} help={"Name of action"} />}
            initialValue={selectedWorkflow.steps[id]?.name}
            rules={[
              { required: true, message: "Name is required!" },
            ]}
            extra={referenceName != "" &&  <Text italic type="secondary">Reference Name: {referenceName}</Text>}
          >
            <Input disabled={!editMode} onChange={(e) => setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))}/>
          </Form.Item>
          <Form.Item
            name="action_id"
            label={<FieldLabel label={"Action"} help={"Select AI action"} />}
            rules={[
              { required: true, message: "AI Action should be selected!" },
            ]}
          >
            <Select
              disabled={!editMode}
              showAction={["focus", "click"]}
              placeholder="Select AI Action..."
              onSelect={setSelectedActionId}
              options={actions
                .filter((a: Action) => a.category == "ai")
                .map((action: Action) => ({
                  label: action.displayName,
                  value: action.id,
                }))}
            />
          </Form.Item>
          {selectedActionId && selectedActionId != "" &&
            <Form.Item 
              name="actionData"
              validateTrigger="onSubmit"
              rules={[
                { validator: (_, value) =>  validateFormFields(actionDataForm) },
              ]}
            >
              <ActionForm
                actionId={selectedActionId}
                tenantId={selectedWorkflow.tenantID}
                isConfigured={selectedWorkflow?.steps[id]?.isConfigured}
                editMode={editMode}
                actionData={actionData}
                hideOutputs={true}
                suggestionsTree={parameterSuggestionsTree}
                onRender={(form) => setActionDataForm(form)}
              />
            </Form.Item>
          }
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default AIOperatorCard;
