import { FC, useState, useEffect } from "react";
import {
  Badge,
  Form,
  Input,
  Select,
  Space,
  theme,
  Typography,
  message,
  Card,
} from "antd";

import {
  CopyOutlined,
} from "@ant-design/icons";

import {
  AppHttpConfiguration,
  AuthType,
  AuthTypeMap,
  KeyPlacementMap,
  HttpConfigProps,
  OAuthGrantTypeMap,
  OAuthGrantType,
  OAuthStyleTypeMap,
} from "types";

import { FieldLabel } from "components/FieldLabel";

import { useAppSubscriptionStore } from "store";
import CollapsePanel from "components/CollapsePanel";
import { commonIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { OAuth2Login } from "pages/OAuth2";

import { showSecrets }  from "utility";

const { Text } = Typography;

const HttpConfig: FC<HttpConfigProps> = ({
  form,
  edtiMode,
}) => {
  const { token } = theme.useToken();
  const [headersForm] = Form.useForm();

  const [authType, setAuthType] = useState<AuthType>(AuthType.AuthTypeNoAuth);
  const [oauthGrantType, setOAuthGrantType] = useState<OAuthGrantType>();
  const [enableOAuthLogin, setEnableOAuthLogin] = useState(false);

  const selectedAppSubscription = useAppSubscriptionStore((state) => state.selectedAppSubscription);
  const selectedApp = useAppSubscriptionStore((state) => state.selectedApp);

  useEffect(() => {
    if (selectedAppSubscription) {
      if (!selectedAppSubscription?.httpConfiguration) {
        selectedAppSubscription.httpConfiguration = {} as AppHttpConfiguration;
        selectedAppSubscription.httpConfiguration.authType = selectedApp?.httpConfiguration.authTypes?.at(0) as AuthType;
        selectedAppSubscription.httpConfiguration.headers = {};
       
        if (selectedApp?.httpConfiguration?.headers) {
          selectedApp?.httpConfiguration?.headers[selectedAppSubscription.httpConfiguration.authType]?.map((header) =>{
            if (header.defaultValue) {
              selectedAppSubscription.httpConfiguration.headers[header.key] = header.defaultValue;
            }
          });
        }

        selectedAppSubscription.httpConfiguration.apiKey = selectedApp?.httpConfiguration?.apiKey;
        selectedAppSubscription.httpConfiguration.basic = selectedApp?.httpConfiguration?.basic;
        selectedAppSubscription.httpConfiguration.bearerToken = selectedApp?.httpConfiguration?.bearerToken;
        selectedAppSubscription.httpConfiguration.oauth = selectedApp?.httpConfiguration?.oauth;
        selectedAppSubscription.httpConfiguration.awsSignature = selectedApp?.httpConfiguration?.awsSignature;
      }

      if (selectedAppSubscription.httpConfiguration.oauth?.clientCredentials?.scopes) {
        selectedAppSubscription.httpConfiguration.oauth.clientCredentials.cscopes = selectedAppSubscription.httpConfiguration.oauth.clientCredentials.scopes.join(",");
      }
      if (selectedAppSubscription.httpConfiguration.oauth?.authorizeCode?.scopes) {
        selectedAppSubscription.httpConfiguration.oauth.authorizeCode.cscopes = selectedAppSubscription.httpConfiguration.oauth.authorizeCode.scopes.join(",");
      }

      form.setFieldsValue(selectedAppSubscription.httpConfiguration);

      setAuthType(selectedAppSubscription.httpConfiguration.authType);
      setOAuthGrantType(selectedAppSubscription.httpConfiguration.oauth?.grantType);

      selectedAppSubscription?.httpConfiguration?.headers
      &&
      Object.entries(selectedAppSubscription.httpConfiguration.headers).map(([key, value]) =>{
        headersForm.setFieldValue(key, value);
      });
    }
  }, [selectedAppSubscription]);

  const onValuesChange = async () => {
    if (oauthGrantType == OAuthGrantType.OAuthGrantTypeAuthorizationCode) {
      setTimeout(() => form.validateFields().then((values) => { 
        setEnableOAuthLogin(true)
      }).catch((e) => console.error(e)));
    }
  };

  return (
    <Card
      style={{
        width: "100%",
        maxHeight: "400px",
        height: "auto",
        overflow: "auto",
        scrollbarWidth: "thin"
      }}
    >
      <Space direction="vertical" style={{ display: "flex" }}>
        <Space wrap={true} style={{ display: "flex", marginTop: token.margin, justifyContent: "flex-start" }} >
          {selectedApp?.httpConfiguration?.labels &&
            selectedApp?.httpConfiguration?.labels[authType] &&
            selectedApp?.httpConfiguration?.labels[authType].map((label) => (
            <Badge 
              key={label}
              count={label} 
              color={token.colorPrimaryActive}>
            </Badge>
            ))
          }
        </Space>
        <Form
          form={form}
          name="httpConfigForm"
          layout="vertical"
          autoComplete="off"
          disabled={!edtiMode}
          onValuesChange={onValuesChange}
        >
          <Form.Item 
            name="url" 
            label={<FieldLabel label={"URL"} help={"URL of App"} />}
            rules={[
              { required: true, message: 'URL is required' },
              { type: "url", message: "URL field must be a valid url." }
            ]}
          >
            <Input />
          </Form.Item>
          {selectedApp?.httpConfiguration?.authTypes?.length 
            ?
              (selectedApp?.httpConfiguration?.authTypes?.length == 1 && 
                selectedApp?.httpConfiguration?.authTypes[0] == AuthType.AuthTypeNoAuth) 
              ?
                null
              :
                <Form.Item 
                  name="authType" 
                  label={<FieldLabel label={"Auth Type"} help={"Type of Authentication"} />}
                  rules={[{ required: true, message: 'Auth Type is required' }]}
                >
                  <Select
                    showAction={["focus", "click"]}
                    value={authType}
                    onSelect={(value) => setAuthType(value)}
                    options={selectedApp?.httpConfiguration?.authTypes?.map((authType) => ({ label: AuthTypeMap.get(authType), value: authType }))}
                  />
                </Form.Item>
            : null
          }
          {authType == AuthType.AuthTypeApiKey && 
            <>
              <Form.Item
                name={['apiKey', 'key']}
                label={<FieldLabel label={"Key"} help={"Key"} />}
                rules={[{ required: true, message: 'Key is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['apiKey', 'value']}
                label={<FieldLabel label={"Value"} help={"Value"} />}
                rules={[{ required: true, message: 'Value is required' }]}
              >
                <Input.Password size="middle" visibilityToggle={showSecrets()} />
              </Form.Item>
              <Form.Item 
                name={['apiKey', 'placement']}
                label={<FieldLabel label={"Key Placement"} help={"Key Placement"} />}
                rules={[{ required: true, message: 'Key Placement is required' }]}
              >
                <Select
                  showAction={["focus", "click"]}
                  placeholder="Select Key Placement..."
                  options={Array.from(KeyPlacementMap, (item) => ({ label: item[1], value: item[0] }))}/>
              </Form.Item>
            </> 
          }
          {authType == AuthType.AuthTypeBearerToken && 
            <Form.Item
              name="bearerToken"
              label={<FieldLabel label={"Bearer Token"} help={"Bearer Token"} />}
              rules={[{ required: true, message: 'Bearer Token is required' }]}
            >
              <Input.Password size="middle" visibilityToggle={showSecrets()} />
            </Form.Item>
          }
          {authType == AuthType.AuthTypeBasic &&
            <>
              <Form.Item
                name={['basic', 'username']}
                label={<FieldLabel label={"Username"} help={"Username"} />}
                rules={[{ required: true, message: 'Username is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['basic', 'password']}
                label={<FieldLabel label={"Password"} help={"Password"} />}
                rules={[{ required: true, message: 'Password is required' }]}
              >
                <Input.Password size="middle" visibilityToggle={showSecrets()} />
              </Form.Item>
            </>
          }
          {authType == AuthType.AuthTypeOAuth &&
            <>
              <Form.Item 
                name={['oauth', 'grantType']}
                label={<FieldLabel label={"Grant Type"} help={"Grant Type"} />}
                rules={[{ required: true, message: 'Grant Type is required' }]}
              >
                <Select
                    showAction={["focus", "click"]}
                    placeholder="Select Grant Type..."
                    onSelect={(value) => setOAuthGrantType(value)}
                    value={oauthGrantType}
                    options={selectedApp?.httpConfiguration?.oAuthGrantTypes?.map((authGrantType) => ({ label: OAuthGrantTypeMap.get(authGrantType), value: authGrantType }))}
                />
              </Form.Item>
              {oauthGrantType == OAuthGrantType.OAuthGrantTypeClientCredentials &&
                <>
                  <Form.Item
                    name={['oauth', 'clientCredentials', 'clientID']}
                    label={<FieldLabel label={"Client ID"} help={"Client ID"} />}
                    rules={[{ required: true, message: 'Client ID is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'clientCredentials', 'clientSecret']}
                    label={<FieldLabel label={"Client Secret"} help={"Client Secret"} />}
                    rules={[{ required: true, message: 'Client Secret is required' }]}
                  >
                    <Input.Password size="middle" visibilityToggle={showSecrets()} />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'clientCredentials', 'tokenURL']}
                    label={<FieldLabel label={"Token URL"} help={"Token URL"} />}
                    rules={[{ required: true, message: 'Token URL is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'clientCredentials', 'audience']}
                    label={<FieldLabel label={"Audience"} help={"Audience"} />}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'clientCredentials', 'cscopes']}
                    label={<FieldLabel label={"Scopes"} help={"Scopes seperated by comma delimiter"} />}
                  >
                    <Input onChange={(e) => { 
                      const value = e.target.value;
                      form.setFieldValue(['oauth', 'clientCredentials', 'scopes'], value.split(",").map(item => item.trim()));
                    }}/>
                  </Form.Item>
                  <Form.Item
                    hidden
                    name={['oauth', 'clientCredentials', 'scopes']}
                  >
                    <Input type="hidden"/>
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'clientCredentials', 'authStyle']}
                    label={<FieldLabel label={"Auth Style"} help={"AuthStyle represents how requests for tokens are authenticated to the server."} />}
                  >
                    <Select
                      showAction={["focus", "click"]}
                      placeholder="Select Auth Style Type..."
                      options={Array.from(OAuthStyleTypeMap, (item) => ({ label: item[1], value: item[0] }))}/>
                  </Form.Item>
                </>
              }
              {oauthGrantType == OAuthGrantType.OAuthGrantTypeAuthorizationCode &&
                <>
                  <Form.Item
                    name={['oauth', 'authorizeCode', 'redirectURL']}
                    initialValue={process.env.REACT_APP_OAUTH2_REDIRECT_URI}
                    label={<FieldLabel label={"Redirect URL"} help={"Redirect URL to be configured with OAuth provider"} />}
                  >
                    <Input
                      readOnly={true}
                      addonAfter={
                        <CopyOutlined
                          onClick={(e: any) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(
                              process.env.REACT_APP_OAUTH2_REDIRECT_URI as string
                            );
                            message.success("Copied!");
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'authorizeCode', 'clientID']}
                    label={<FieldLabel label={"Client ID"} help={"Client ID"} />}
                    rules={[{ required: true, message: 'Client ID is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'authorizeCode', 'clientSecret']}
                    label={<FieldLabel label={"Client Secret"} help={"Client Secret"} />}
                    rules={[{ required: true, message: 'Client Secret is required' }]}
                  >
                    <Input.Password size="middle" visibilityToggle={showSecrets()} />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'authorizeCode', 'authURL']}
                    label={<FieldLabel label={"Auth URL"} help={"Auth URL"} />}
                    rules={[{ required: true, message: 'Auth URL is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'authorizeCode', 'tokenURL']}
                    label={<FieldLabel label={"Token URL"} help={"Token URL"} />}
                    rules={[{ required: true, message: 'Token URL is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'authorizeCode', 'cscopes']}
                    label={<FieldLabel label={"Scopes"} help={"Scopes seperated by comma delimiter"} />}
                    rules={[{ required: true, message: 'Scopes are required' }]}
                  >
                    <Input onChange={(e) => { 
                      const value = e.target.value;
                      form.setFieldValue(['oauth', 'authorizeCode', 'scopes'], value.split(",").map(item => item.trim()));
                    }}/>
                  </Form.Item>
                  <Form.Item
                    hidden
                    name={['oauth', 'authorizeCode', 'scopes']}
                  >
                    <Input type="hidden"/>
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'authorizeCode', 'authStyle']}
                    label={<FieldLabel label={"Auth Style"} help={"AuthStyle represents how requests for tokens are authenticated to the server."} />}
                    rules={[{ required: true, message: 'AuthStyle is required' }]}
                  >
                    <Select
                      showAction={["focus", "click"]}
                      placeholder="Select Auth Style Type..."
                      options={Array.from(OAuthStyleTypeMap, (item) => ({ label: item[1], value: item[0] }))}/>
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'authorizeCode', 'refreshToken']}
                    label={<FieldLabel label={"Refresh Token"} help={"Refresh Token generated on authcode flow after Login and Consent"} />}
                  >
                    <Input.Password size="middle" readOnly={true} visibilityToggle={showSecrets()} />
                  </Form.Item>
                  <OAuth2Login 
                    enable={enableOAuthLogin}
                    authorizeCode={selectedAppSubscription?.httpConfiguration?.oauth?.authorizeCode}
                    onSuccess={(refreshToken) =>  {
                      if (refreshToken) {
                        form.setFieldValue(['oauth', 'authorizeCode', 'refreshToken'], refreshToken);
                        if (selectedAppSubscription && 
                          selectedAppSubscription.httpConfiguration &&
                          selectedAppSubscription.httpConfiguration.oauth &&
                          selectedAppSubscription.httpConfiguration.oauth.authorizeCode) {
                            selectedAppSubscription.httpConfiguration.oauth.authorizeCode.refreshToken = refreshToken;
                          }
                      }
                    }}
                    onFailure={(error) => console.log("failure")} ///enableDetectEdge(false)}
                  />
                </>
              }
              {oauthGrantType == OAuthGrantType.OAuthGrantTypeRefreshToken &&
                <>
                  <Form.Item
                    name={['oauth', 'refreshToken', 'clientID']}
                    label={<FieldLabel label={"Client ID"} help={"Client ID"} />}
                    rules={[{ required: true, message: 'Client ID is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'refreshToken', 'clientSecret']}
                    label={<FieldLabel label={"Client Secret"} help={"Client Secret"} />}
                    rules={[{ required: true, message: 'Client Secret is required' }]}
                  >
                    <Input.Password size="middle" visibilityToggle={showSecrets()} />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'refreshToken', 'tokenURL']}
                    label={<FieldLabel label={"Token URL"} help={"Token URL"} />}
                    rules={[{ required: true, message: 'Token URL is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'refreshToken', 'refreshToken']}
                    label={<FieldLabel label={"Refresh Token"} help={"Refresh Token"} />}
                    rules={[{ required: true, message: 'Refresh Token is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['oauth', 'refreshToken', 'authStyle']}
                    label={<FieldLabel label={"Auth Style"} help={"AuthStyle represents how requests for tokens are authenticated to the server."} />}
                  >
                    <Select
                      showAction={["focus", "click"]}
                      placeholder="Select Auth Style Type..."
                      options={Array.from(OAuthStyleTypeMap, (item) => ({ label: item[1], value: item[0] }))}/>
                  </Form.Item>
                </>
              }
            </>
          }
          {authType == AuthType.AuthTypeAwsSignature && 
            <>
              <Form.Item 
                  name={['awsSignature', 'accessKey']}
                  label={<FieldLabel label={"Access Key"} help={"Access Key"} />}
                  rules={[{ required: true, message: 'Access Key is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['awsSignature', 'secretKey']}
                label={<FieldLabel label={"Secret Key"} help={"Secret Key"} />}
                rules={[{ required: true, message: 'Secret Key is required' }]}
              >
                <Input.Password size="middle" visibilityToggle={showSecrets()} />
              </Form.Item>
              <Form.Item 
                  name={['awsSignature', 'region']}
                  label={<FieldLabel label={"Region"} help={"Region"} />}
                  rules={[{ required: true, message: 'Region is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item 
                  name={['awsSignature', 'service']}
                  label={<FieldLabel label={"Service"} help={"Service"} />}
                  rules={[{ required: true, message: 'Service is required' }]}
              >
                <Input />
              </Form.Item>
            </>
          }
        {selectedApp?.httpConfiguration?.headers && selectedApp?.httpConfiguration?.headers[authType]?.length ?
            <Form
              form={headersForm}
              name="headers"
              layout="vertical"
              autoComplete="off"
            >
              <CollapsePanel
                name={
                  <Space size={token.marginXXS}>
                    <SvgIcon component={commonIcons.httpHeadersIcon} />
                    <Text>Headers</Text>
                  </Space>
                }
                ghost={false}
                collapsePanel={false}
                style={{ marginBottom: token.margin }}
              >
                  {selectedApp?.httpConfiguration?.headers[authType]?.map((header) =>(
                    <Form.Item
                      key={header.key}
                      name={header.key} 
                      label={<FieldLabel label={header.displayKey} />}
                    >
                      {header.isSecret ? <Input.Password size="middle" visibilityToggle={showSecrets()} /> : <Input />}
                    </Form.Item>
                  ))}
              </CollapsePanel>
            </Form>
            : <></>
          }
        </Form>
      </Space>
    </Card>
  );
};

export default HttpConfig;