import { FC } from "react";
import {
  Space,
  Card,
  theme,
  Typography,
  Flex
} from "antd";

import { SvgIcon } from "components/SvgIcon";

import {
  EdgeInfraType,
  EdgeManageType,
  Edge,
  EdgeStatus,
} from "types";

import { getEdgeShortLogo } from "utility/edge";

const { Text, Title } = Typography;

export interface AppEdgeInfoProps {
  edge: Edge;
  editMode: boolean;
  isSelectedEdge: boolean;
  onClick?: () => void;
}

const AppEdgeInfo: FC<AppEdgeInfoProps> = ({
  edge,
  editMode,
  isSelectedEdge,
  onClick,
}) => {
  const { token } = theme.useToken();

  return (
    <Card
      hoverable={editMode && (edge.status == EdgeStatus.Online)}
      style={{ 
        height: "60px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: token.borderRadius, 
        borderColor: edge.status == EdgeStatus.Online ? token.colorPrimaryBorderHover : token.colorBorder,
        background: isSelectedEdge ? token.colorTextTertiary : (edge.status == EdgeStatus.Online ? token.colorBgBase : token.colorBgContainerDisabled),
      }}
      onClick={(editMode && edge.status == EdgeStatus.Online) ? onClick : undefined}
    >
      <Flex gap={"15px"} style={{ marginTop: token.marginXXS }} >
        <SvgIcon size={25} component={getEdgeShortLogo(edge.infraType)} />
        <div          
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
            marginBottom: token.marginXS,
          }}
        >
          <Space direction="vertical" size={0}>
            <Text ellipsis style={{
              fontSize: token.fontSizeLG,
              fontFamily: 'Roboto',
              maxWidth: '200px', 
              overflow:'hidden'
              }}
            >
              {edge.displayName}
            </Text>
            <Space>
              <Text type="secondary">{edge.manageType == EdgeManageType.HyprEdge? "Managed" : "Unmanaged"}</Text>
              {edge.region && <Text type="secondary" keyboard>{edge.region}</Text>}
            </Space>
          </Space>
        </div>
      </Flex>
    </Card>
  );
};

export default AppEdgeInfo;
