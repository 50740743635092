import { Button,  Popover, Typography, theme } from "antd";
import { StepForwardOutlined } from "@ant-design/icons";
import {  useState } from "react";
import ReactPlayer from 'react-player'
const {  Text } = Typography;

export interface VideoPlayerProps {
  backgroundColor: string;
  videoUrl: string;
  thumbnailUrl: string;
  cta?: string;
}

export const VideoPlayer = (props: VideoPlayerProps) => {
  const { token } = theme.useToken();
  const [isPlaying, setPlaying] = useState(false);
  return (
    <div
        style={{
            padding: token.padding,
            backgroundColor: props.backgroundColor,
            display: "flex",
            flexDirection: "column",
            gap: "5px",
        }}
    >
        <div id="react-player" style={{position: "relative", paddingTop: "56.25%"}}>
            <ReactPlayer 
                url={props.videoUrl}
                light={<img 
                    style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} 
                    src={props.thumbnailUrl} 
                    alt='Thumbnail'
                    onClick={() =>  setPlaying(true)}
                />}
                width='100%'
                height='100%'
                playIcon={  
                    <Button type="primary" size="large" shape="circle"  onClick={ () =>  setPlaying(true)} icon={<StepForwardOutlined />}  />
                }
                controls
                style={{position: "absolute", top: 0, left: 0}}
                playing={isPlaying} 
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                muted={false}
            />
        </div>
        {props.cta && 
            <Popover title={props.videoUrl}>
                <Text  strong style={{ fontSize: token.fontSizeSM }}>{props.cta}</Text>
            </Popover>
        }
    </div>
    
  );
};