import { FC, useState, useEffect } from "react";
import {
  Badge,
  Space,
  theme,
  Typography,
} from "antd";


import { RunSearchActionEdgeResult, SearchRunAction, SearchRunActionResultMeta, SearchRunActionStatus } from "types";
import { useEdgeStore, useSearchStore } from "store";
import { getSearchActionResultApi } from "api";

import { PresetStatusColorType } from "antd/es/_util/colors";

export interface RowActionResultProps {
  searchRunId: string;
  searchRunAction: SearchRunAction,
  rowIndex: string;
  showApp: boolean;
}
type ActionInfo = {
  actionDisplayName: string;
  appNameWithSubscription: string; 
  statusColor: string;
  status: PresetStatusColorType;
  edgeResult: RunSearchActionEdgeResult;
}

const RowActionResult: FC<RowActionResultProps> = ({
  searchRunId,
  searchRunAction,
  rowIndex,
  showApp
}) => {
  const { token } = theme.useToken();
  const [actionInfos, setActionInfos] = useState(new Map<string, ActionInfo>())
  const { getEdgeEndpoint } = useEdgeStore(
    (state) => ({
      getEdgeEndpoint: state.getEdgeEndpoint,
    }),
  );

  const getDefaultActionInfo = (actionDisplayName: string, rm: SearchRunActionResultMeta) => {
    return {
      statusColor: "orange",
      actionDisplayName: actionDisplayName,
      appNameWithSubscription: `${rm.appName}(${rm.appSubscriptionName})`,
      status: "processing",
    }as ActionInfo
  }

  useEffect ( () => {
    const asyncUseEffect = async () => {
      for( const rm of searchRunAction.resultMetas){
        if (rm.searchRunID != searchRunId) continue;
        const actionInfo = getDefaultActionInfo(searchRunAction?.actionInfo?.displayName, rm);
        try{
          const edgeEndpoint = await getEdgeEndpoint((rm as SearchRunActionResultMeta).edgeID);
          const result = await getSearchActionResultApi(edgeEndpoint, rm.tenantID, rm.searchRunID, rm.searchRunActionID, rm.appSubscriptionID, rowIndex);
          actionInfo.statusColor = result  ? "green" : "red";
          actionInfo.edgeResult = result ;    
          actionInfo.status = result ? "success" : "error";
        }catch(e){
          if(searchRunAction.status != SearchRunActionStatus.Running) {
            actionInfo.statusColor = "red";
            actionInfo.edgeResult = {error: "search run action is finished, but no satus found from edge"} as RunSearchActionEdgeResult;
            actionInfo.status = "error";
          }
        }
        setActionInfos(actionInfos => new Map(actionInfos.set(rm.appSubscriptionID, actionInfo)));
      }
    }
    for(const rm of searchRunAction.resultMetas){
      if (rm.searchRunID != searchRunId) continue;
      const actionInfo = getDefaultActionInfo(searchRunAction?.actionInfo?.displayName, rm);
      setActionInfos(actionInfos => new Map(actionInfos.set(rm.appSubscriptionID, actionInfo)));
    }
    asyncUseEffect();
  }, [searchRunAction])
  
  const getRowActionInfos = () => {
    const arrActionInfos: ActionInfo[] = [];
    actionInfos.forEach ( (actionInfo) => {
      arrActionInfos.push(actionInfo);
    });
    return arrActionInfos;    
  } 


  return (
    <Space direction="vertical">
      {getRowActionInfos().map ((actionInfo: ActionInfo, index: number) => (
        <Space key={`${rowIndex}-${index}-${actionInfo.appNameWithSubscription}`}>
          <Badge status={actionInfo.status} />
          <Badge count={actionInfo.actionDisplayName} color={actionInfo.statusColor} />
          { showApp && <Badge count={actionInfo.appNameWithSubscription} /> }
        </Space>
      ))}
    </Space>
  );
};

export default RowActionResult;
