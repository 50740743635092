import { FC, useState, useEffect, useReducer } from "react";

import { Form, Button, Space, FormInstance, theme } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";

import { validateFormFields } from "utility";
import { NodeCardRulesProps, Rule, Rules, Condition } from "types";
import Modal from "../../../Modal";
import CollapsePanel from "../../../CollapsePanel";
import NodeCardRule from "./Rule";

interface DeleteRuleProps {
  enable: boolean;
  key?: number;
  name?: string;
}

const NodeCardRules: FC<NodeCardRulesProps> = (props) => {
  const {token} = theme.useToken();
  const [form] = Form.useForm();
  const [currentRules, setCurrentRules] = useState<Rules>(props.rules);
  const [deleteRuleProps, setDeleteRuleProps] = useState<DeleteRuleProps>({
    enable: false,
  });

  const [rulesForms, dispatch] = useReducer(
    (rulesForms: FormInstance[], action: any) => {
      switch (action.type) {
        case "add": {
          const forms = [...rulesForms];
          forms.splice(action.key, 0, action.form);
          return forms;
        }
        case "delete": {
          const forms = [...rulesForms];
          forms.splice(action.key, 1);
          return forms;
        }
        default:
          throw new Error(`Unknown action type: ${action.type}`);
      }
    },
    []
  );

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const onValuesChange = async (changedValues: any, values: any) => {
    props.onChange?.(form.getFieldValue("rules"));
  };

  const onRuleAdd = () => {
    let rules = currentRules
      ? currentRules.map((_, index) => form.getFieldValue(["rules", index]))
      : [];
    rules = [
      ...rules,
      {
        name: `Rule-${rules.length}`,
        desc: "",
        status: "enabled",
        conditions: [{} as Condition],
      },
    ];
    form.setFields([{ name: ["rules"], value: rules }]);
    setCurrentRules(rules);
  };

  const onRuleDelete = () => {
    if (deleteRuleProps.key != undefined) {
      const rules = currentRules.map((_, index) =>
        form.getFieldValue(["rules", index])
      );
      rules.splice(deleteRuleProps.key, 1);
      dispatch({ type: "delete", key: deleteRuleProps.key });
      form.resetFields(["rules", deleteRuleProps.key]);
      form.setFields([{ name: ["rules"], value: rules }]);
      setCurrentRules(rules);
      setDeleteRuleProps({ enable: false });
    }
  };

  useEffect(() => {
    props.onChange?.(currentRules);
  }, [currentRules, deleteRuleProps]);

  return (
    <>
      <Form
        form={form}
        name="rulesForm"
        autoComplete="off"
        initialValues={{ rules: props.rules }}
        layout="vertical"
        onValuesChange={onValuesChange}
      >
        <Form.List name="rules">
          {(fields) => (
            <Space direction="vertical" style={{ display: "flex" }}>
              {fields.map((field) => (
                <CollapsePanel
                  key={field.key}
                  name={currentRules[field.key].name}
                  collapsePanel={false}
                  extraElement={
                    props.editMode && (
                      <Button
                        type="default"
                        shape="circle"
                        size="small"
                        style={{
                          background: token.colorPrimaryBg,
                        }}
                        icon={
                          <DeleteFilled style={{ color: token.colorPrimary }}/>
                        }
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setDeleteRuleProps({
                            enable: true,
                            key: field.key,
                            name: currentRules[field.key].name,
                          });
                        }}
                      />
                    )
                  }
                >
                  <Form.Item
                    {...field}
                    key={field.key}
                    name={[field.key]}
                    rules={[
                      {
                        validator: (_, value) =>
                          validateFormFields(rulesForms[field.key]),
                      },
                    ]}
                  >
                    <NodeCardRule
                      editMode={props.editMode}
                      rule={currentRules[field.key]}
                      factSuggestionsTree={props.factSuggestionsTree}
                      onRender={(form) =>
                        dispatch({ type: "add", key: field.key, form: form })
                      }
                    />
                  </Form.Item>
                </CollapsePanel>
              ))}
              <Form.Item>
                {props.editMode && (
                  <div
                    style={{
                      width: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    <Button
                      type="default"
                      style={{ background: token.colorPrimaryBg }} 
                      onClick={onRuleAdd}
                      block
                      icon={<PlusOutlined />}
                    >
                      Rule
                    </Button>
                  </div>
                )}
              </Form.Item>
            </Space>
          )}
        </Form.List>
      </Form>
      {deleteRuleProps.enable && (
        <Modal
          title="Delete Rule"
          onClose={() => {
            setDeleteRuleProps({ enable: false });
          }}
          open={deleteRuleProps.enable}
          onSubmit={() => onRuleDelete()}
        >
          {
            'Are you sure you want to delete rule "' +
            `${deleteRuleProps?.name}` +
            '" ?'
          }
        </Modal>
      )}
    </>
  );
};

export default NodeCardRules;
