import { FC, useState } from "react";

import { isEqual } from "lodash";
import { 
  Typography, 
  Modal as AntModal,
  theme, 
  Button,
  Space,
} from "antd";

import { 
  JsonViewer, 
  applyValue,
} from '@textea/json-viewer'
import { TextWithIcon } from "components/TextWithIcon";
import { workflowIcons } from "assets/icons";
import SearchInput from "components/SearchInput";
import { useSettingsStore } from "store";

const { Text } = Typography;

export interface JsonEditorProps {
  open?: boolean;
  width?: number;
  title?: string;
  editMode: boolean;
  data: any;
  icon?: any;
  onClose: () => void;
  onCopy?: (data: any) => void;
  onSave?: (data: any) => void;
}

export const JsonEditor: FC<JsonEditorProps> = ({
  open,
  width,
  title,
  editMode,
  data,
  icon,
  onClose,
  onCopy,
  onSave
}) => {
  const { token } = theme.useToken();
  const lightMode = useSettingsStore((state) => state.lightMode);
  const [jsonData, setJsonData] = useState(data);
  const [searchText, setSearchText] = useState("");

  return (
    <AntModal
      title={
      <Space direction="vertical" size={token.sizeLG} style={{ display: "flex" }}>
        <TextWithIcon
          icon={icon}
          iconSize={token.size}
          text={title}
        />
       {/*  <SearchInput
          placeholder="search..."
          onFilter={(e) => setSearchText(e.target.value)}
        /> */}
      </Space>}
      onCancel={onClose}
      open={open}
      width={width}
      centered={true}
      //closeIcon={null}
      keyboard={false}
      maskClosable={false}
      footer={[
        editMode 
        && 
        onSave
        &&
        <Button 
          key="edit" 
          type="primary" 
          disabled={isEqual(data, jsonData)} 
          onClick={() => onSave(jsonData)}
        >
          Save
        </Button>,
        onCopy && 
        <Button key="copy" type="primary" onClick={() => onCopy(jsonData)}>
          Copy
        </Button>,
        <Button key="cancel" type="primary" onClick={onClose}>
          Close
        </Button>,
      ]}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}
    >
      <JsonViewer 
        rootName={false}
        editable={editMode}
        displayDataTypes={false}
        displaySize={false}
        enableClipboard={false}
        value={jsonData}
        theme={lightMode ? "light" : "auto"}
        highlightUpdates={true}
        sx={{paddingLeft: 2}}
        onChange={(path, oldValue, newValue) => {
          const updatedJsonData = applyValue(jsonData, path, newValue);
          setJsonData(updatedJsonData);
        }}
      />
    </AntModal>
  );
};
