import { theme } from "antd";
import { FunctionComponent } from "react";
import Icon from "@ant-design/icons";
export interface IconProperties {
  className?: string;
  style?: any;
  component: FunctionComponent;
  size?: string | number;
  hexColor?: string;
  onClick?: () => void;
}

export const SvgIcon: React.FC<IconProperties> = ({
  onClick,
  className,
  style,
  component,
  size,
  hexColor,
}) => {
  const { token } = theme.useToken();
  const IconComponent = component;
  return (
    <Icon
      onClick={onClick}
      component={IconComponent}
      className={className}
      style={{
        ...style,
        fontSize: size || token.fontSizeHeading4,
        color: hexColor || token.colorPrimary,
      }}
    />
  );
};
