import { TextType } from "types"

//operator param value
export type OperatorParamValue = string | number | boolean | any;
export type OperatorParamValueMap = Record<string, OperatorParamValue>;

//operator param type
export enum OperatorIODataType {
    String = "string",
    Numeric = "numeric",
    Boolean = "boolean",
    Conditions = "conditions",
    Array = "array",
    Map = "map",
    Records = "records",
}

//operator loop type
export enum OperatorLoopType {
    On = "on",
    Range = "range",
}

export interface OperatorParameterApprover {
    id: string;
    email: string;
    isManagedUser: boolean;
}

export enum OperatorKVValueType {
    String = "string",
    Numeric = "numeric",
    Boolean = "boolean",
    Array = "array",
}

export type OperatorKVItems = OperatorKVItem[];

export interface OperatorKVItem {
	type: string;
	key: string;
	value: string;
}

export enum OperatorAIResponseFormat {
    String = "string",
    JsonObject = "jsonObject",
    JsonArray = "jsonArray"
}

//operator param type
export interface OperatorParameter {
    description: string;
    displayName: string;
    type: OperatorIODataType;
    required: boolean;
    values?: any[];
    min: number;
    max: number;
    default?: any;
    order?: number;
    textType?: TextType;
}

export interface OperatorOutput {
    description: string;
    displayName: string;
    type: OperatorIODataType;
    required: boolean;
}

export interface PathInfo {
	path: string;
	edgeID: string;
}

export interface IODataMeta {
	type: string;
	field: string;
	pathInfos: PathInfo[];
}

export type OperatorParameterMap = Record<string, OperatorParameter>;

export type OperatorOutputMap = Record<string, OperatorOutput>;

//Operators types
export enum OperatorType {
    Ai = "ai",
    Approval = "approval",
    Break = "break",
    Condition = "condition",
    Db = "db",
    Http = "http",
    KV = "kv",
    Loop = "loop",
    Parallel = "parallel",
    Search = "search",
    Subworkflow = "subworkflow",
    Wait = "wait"
}

export interface Operator {
    id: string;
    name: string;
    displayName: string;
    type: OperatorType;
    description: string;
    parameters: Record<string, OperatorParameter>;
    outputs: Record<string, OperatorOutput>;
}
