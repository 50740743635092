import { Avatar, Card, Space, Spin, Tooltip, Typography, theme } from "antd";
import { FC, useEffect, useState } from "react";

import { useActivityStore, useOrganizationStore, useUserInfoStore } from "store";

import { activityIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { Activity, dateTimeFormatOptions } from "types";
import { ColumnsType } from "antd/es/table";
import { HLink } from "components/Link";

const { Text, Link } = Typography;

const AuditLogs: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const defaultPageSize = 12;

  const context = useOrganizationStore((state) => state.context);

  const { activities, getActivities } = useActivityStore((state) => ({
    activities: state.activities,
    getActivities: state.getActivities,
  }));

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const getActivityList = async () => {
    try {
      setLoader(true);
      await getActivities(
        currentPage,
        defaultPageSize,
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getActivityList();
  }, [currentPage, context]);

  useEffect(() => {
    const userIds = activities.map((activity) => activity.userID);
    userIds.length > 0 && loadUserInfos(userIds);
  }, [activities]);

  const autitLogColumns: ColumnsType<Activity> = [
    {
      title: "Time",
      dataIndex: "localTime",
      key: "time",
      sorter: (a, b) =>
        new Date(a.localTime).getTime() - new Date(b.localTime).getTime(),
      sortDirections: ["descend", "ascend"],
      render: (updatedAt: string) =>
        new Date(updatedAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        ),
    },
    {
      title: "User",
      dataIndex: "userID",
      key: "user",
      sorter: (a, b) =>
        a.userID.localeCompare(
          b.userID
        ),
      sortDirections: ["descend", "ascend"],
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && <Avatar src={userInfos[userID]?.logoUrl} />}
          <HLink href={"mailto:" + userInfos[userID]?.email} text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      sorter: (a, b) => a.message.localeCompare(b.message),
      sortDirections: ["descend", "ascend"],
      render: (message) => (
        <Text>{message}</Text>
      ),
    },
  ];

  return (
    <Spin spinning={loader}>
      <EntitiesDisplay
        header="Audit Logs"
        dataSource={activities}
        columns={autitLogColumns}
        pageNumber={currentPage}
        pageSize={defaultPageSize}
        total={activities?.length as number}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
      />
    </Spin>
  );
};

export default AuditLogs;
