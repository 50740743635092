import { FC } from "react";
import { Button, Modal as AntModal } from "antd";
import { ModalProps } from "types";

const Modal: FC<ModalProps> = ({
  open,
  loader,
  title,
  children,
  footerName,
  disableSubmit,
  onClose,
  onSubmit,
}) => {
  return (
    <AntModal
      title={title}
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loader}
          onClick={onSubmit}
          disabled={disableSubmit ? disableSubmit : false}
        >
          {footerName ? footerName : "Confirm"}
        </Button>,
      ]}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
