import { FC, useEffect, useState } from "react";
import {
  Button,
  Spin,
  Form,
  FormInstance,
  Input,
  Space,
  Typography,
  theme,
  Row,
  Switch
} from "antd";

import {
  WorkflowStepAction,
  WorkflowStepProvider,
  WorkflowNodeCardProps,
  TriggerType,
  WorkflowAppSubscriptionInfo,
} from "types";
import { notification } from 'utility/notification';
import { useWorkflowStepProvidersStore, useWorkflowStore } from "store";
import {
  generateReferenceName,
} from "utility/workflow";
import { validateFormFields } from "utility";
import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree
} from "components/Suggestions";
import ActionForm from "components/HyprFlows/ActionForm";
import { FieldLabel } from "components/FieldLabel";

const { Text } = Typography;
const ActionCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  nodeType,
  editMode,
  onClose
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [actionDataForm, setActionDataForm] = useState<undefined | FormInstance>(undefined);
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const [actionData, setActionData] = useState<Record<string, any>>({});

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(selectedWorkflow?.steps[id]?.referenceName);

  const { actionProvidersMap, getActionProviders } =
    useWorkflowStepProvidersStore((state) => ({
      getActionProviders: state.getActionProviders,
      actionProvidersMap: state.actionProvidersMap,
    }));

  const loadProviders = async () => {
    try {
      setLoader(true);
      await getActionProviders(resourceId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const syncCurrentState = async () => {
    try {
      const actionStep = selectedWorkflow.steps[id] as WorkflowStepAction;

      const actionData = {} as Record<string, any>;
      actionData.paramValues = actionStep.parameters;

      if (actionStep.provider && actionStep.provider.providerID) {
        actionData.providerId = actionStep.provider.providerID;
        actionData.appSubscriptions = actionStep.provider?.appSubscriptionInfos?.map((s) => (s.appSubscriptionID));
        actionData.providerParamValues = actionStep.provider?.parameters;
      }
      
      setActionData(actionData);
      form.setFieldValue("actionData", actionData);
    } catch (error) {
      console.log(error);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    loadProviders();
    syncCurrentState();
    buildParameterSuggestions();
  }, [selectedWorkflow]);

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const getAppInfo = (providerId: string) => {
    const actionProvider = actionProvidersMap.get(resourceId);
    return actionProvider?.providers.find((provider) => provider.id == providerId)?.appInfo;
  };

  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const actionStep = selectedWorkflow.steps[id] as WorkflowStepAction;
        actionStep.isConfigured = true;
        actionStep.name = values.name;
        actionStep.referenceName = referenceName;
        setReferenceName(actionStep.referenceName);

        const actionProvider = {} as WorkflowStepProvider;
        const ad = values.actionData;
        if (ad) {
          actionStep.parameters = ad.paramValues;
          const providerId = ad.providerId as string;
          if (providerId) {
            const appInfo = getAppInfo(providerId);
            if (appInfo) {
              actionProvider.providerID = providerId;
              actionProvider.appID = appInfo.id;
              actionProvider.displayName = appInfo.displayName;
              actionProvider.parameters = ad.providerParamValues;
              actionProvider.appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
              const subscriptions = ad.appSubscriptions as string[];
              subscriptions?.map((subscriptionId) => {
                actionProvider.appSubscriptionInfos.push({
                  appSubscriptionID: subscriptionId,
                } as WorkflowAppSubscriptionInfo);
              });
            }
          }
          setActionData(ad);
        }

        actionStep.includeRawOutput = values.includeRawOutput;
        actionStep.provider = actionProvider;
        actionStep.isAuditRequired = false;
        selectedWorkflow.steps[id] = actionStep;
        updateSelectedWorkflow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Form
      form={form}
      name="actionCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} help={"Name of action"} />}
            initialValue={selectedWorkflow.steps[id]?.name}
            rules={[
              { required: true, message: "Name is required!" },
            ]}
            extra={referenceName != "" &&  <Text italic type="secondary">Reference Name: {referenceName}</Text>}
          >
            <Input disabled={!editMode} onChange={(e) => setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))}/>
          </Form.Item>
          <Form.Item 
            name="actionData"
            validateTrigger="onSubmit"
            rules={[
              { validator: (_, value) =>  validateFormFields(actionDataForm) },
            ]}
          >
            <ActionForm 
              actionId={resourceId}
              tenantId={selectedWorkflow.tenantID}
              isConfigured={selectedWorkflow?.steps[id]?.isConfigured}
              editMode={editMode}
              actionData={actionData}
              suggestionsTree={parameterSuggestionsTree}
              onRender={(form) => setActionDataForm(form)}
            />
          </Form.Item>
          <Form.Item
            name="includeRawOutput"
            label={<FieldLabel label={"Include Raw Output"} help={"Store step raw output to access in following steps"} />}
            initialValue={selectedWorkflow.steps[id]?.includeRawOutput}
          >
            <Switch
              disabled={!editMode}
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={false}
            />
          </Form.Item>
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default ActionCard;
