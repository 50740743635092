import {
  Button,
  Form,
  Space,
  Spin,
  Typography,
  Input,
  theme,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { useProfileStore } from "store";
import { ContactType, Contact } from "./types";
import { generateRandomString } from "utility";
import { notification } from 'utility/notification';

const { Title, Text } = Typography;
const Initial_Timer = 60;

export interface AddContactProps {
  onAddComplete: (contact: Contact) => Promise<void>;
  onClose: () => Promise<void>;
  type: ContactType;
}

export const AddContact = (props: AddContactProps) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [showVerify, setShowVerify] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [form] = Form.useForm();

  const { token } = theme.useToken();
  useEffect(() => {
    if (timer <= 0) return;
    setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
  }, [timer]);

  const {
    phoneVerifyStart,
    phoneVerifyComplete,
    emailVerifyStart,
    emailVerifyComplete,
  } = useProfileStore((state) => ({
    phoneVerifyStart: state.phoneVerifyStart,
    phoneVerifyComplete: state.phoneVerifyComplete,
    emailVerifyStart: state.emailVerifyStart,
    emailVerifyComplete: state.emailVerifyComplete,
  }));

  const resendOtp = async () => {
    if (timer === 0) {
      verificationStart(form.getFieldValue(props.type));
    }
  };

  const verificationStart = async (data: string) => {
    try {
      setLoader(true);
      if (props.type === ContactType.Email) {
        await emailVerifyStart(data, generateRandomString());
      } else {
        await phoneVerifyStart(`+${data}`, generateRandomString());
      }
      setShowVerify(true);
      setTimer(Initial_Timer);
    } finally {
      setLoader(false);
    }
  };

  const verifyComplete = async () => {
    if (props.type === ContactType.Email) {
      await emailVerifyComplete(otp);
    } else {
      await phoneVerifyComplete(otp);
    }
  };

  const verifyOtp = async () => {
    try {
      setLoader(true);
      verifyComplete();
      notification.success({
        message: "Otp verified successfully",
        duration: 6,
      });
      const contact: Contact = {
        type: props.type,
        value: form.getFieldValue(props.type),
        primary: false,
      };
      props.onAddComplete(contact);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Otp verification failed",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const reset = () => {
    setShowVerify(false);
    setOtp("");
    form.resetFields();
  };

  return (
    <Spin spinning={loader}>
      <div>
        <Title level={4}>Add a new {props.type}</Title>
        <Text>A verification code will be sent to you for validation</Text>
        <Divider></Divider>
      </div>
      <Form form={form} name="addContact" autoComplete="off">
        <Form.Item
          name={props.type}
          rules={[
            { required: true, message: `${props.type} is required!` },
            props.type === ContactType.Email
              ? {
                  type: "email",
                  message: `Please enter a valid ${props.type}`,
                }
              : {},
          ]}
        >
          {props.type === ContactType.Email ? (
            <Input placeholder="Email" name={props.type} />
          ) : (
            <PhoneInput
              country={"us"}
              enableSearch={true}
              onChange={(phone) => form.setFieldValue(props.type, phone)}
              inputStyle={{
                width: "100%",
                height: "2rem",
                borderRadius: token.borderRadius,
              }}
            />
          )}
        </Form.Item>
        {showVerify && (
          <div>
            <Title level={5}>Enter One time code</Title>
            <OtpInput
              containerStyle={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: token.margin,
              }}
              inputStyle={{
                height: "2rem",
                borderRadius: token.borderRadius,
                borderColor: token.colorBorder,
                borderWidth: "1px",
                borderStyle: "solid",
                width: "30px",
              }}
              numInputs={6}
              onChange={setOtp}
              renderSeparator={<span>{"-"}</span>}
              value={otp}
              inputType="text"
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus
            />
            <Space>
              <Text style={{ marginBottom: token.marginXXS }}>
                {"Didnt receive the otp? "}
              </Text>

              <Button
                type="link"
                onClick={resendOtp}
                style={{ margin: token.marginXXS }}
              >
                {"Resend"}
              </Button>
              <Text>{`in ${timer} seconds`}</Text>
            </Space>
          </div>
        )}
        <Form.Item>
          <Space>
            <Button onClick={props.onClose} type="default">
              Cancel
            </Button>
            <Button onClick={reset} type="default">
              Clear
            </Button>
            {!showVerify ? (
              <Button
                type="primary"
                onClick={() =>
                  form
                    .validateFields()
                    .then((result) => verificationStart(result[props.type]))
                    .catch((err) => {
                      notification.error({ message: err.message, duration: 6 });
                    })
                }
              >
                Send Code
              </Button>
            ) : (
              <Button
                type="primary"
                disabled={otp?.length < 6}
                onClick={verifyOtp}
              >
                Verify
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  );
};
