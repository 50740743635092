import { Divider, Form, Input, Select, Spin, theme } from "antd";
import { Drawer } from "components/Drawer";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useOrganizationStore } from "store";
import { CreationalSubscriber, Subscriber, userRegions } from "types";
import { notification } from "utility/notification";

export interface SubscriberEditProps {
  subscriber?: Subscriber;
  isAdd: boolean;
  onClose: () => Promise<void>;
}

export const ProviderEditSubscribers = (props: SubscriberEditProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [drawerState, setDrawerState] = useState(false);
  const { token } = theme.useToken();

  const createSubscriber = useOrganizationStore(
    (state) => state.createSubscriber
  );

  const handleSubmit = async () => {
    let values: any;
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }
      const creationalOrganization: CreationalSubscriber = {
        name: values.name,
        description: values.description,
        primary: values.primary,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        phone: values.phone,
        region: values.region,
      };
      await createSubscriber(creationalOrganization);
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to ${
            props.isAdd ? "create" : "update"
          } user, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        onClose();
      }
    }
  };

  const formFields = [
    {
      name: "name",
      label: "Name",
      rules: [{ required: true, message: "Please input organization name!" }],
    },
    {
      name: "description",
      label: "Description",
      rules: [
        { required: true, message: "Please input organization description!" },
      ],
    },
    {
      name: "primary",
      label: "Email",
      rules: [{ required: true, message: "Please input primary email!" }],
    },
    {
      name: "firstName",
      label: "First name",
    },
    {
      name: "lastName",
      label: "Last Name",
    },
    {
      name: "password",
      label: "Password",
    },
    {
      name: "confirmPassword",
      label: "Confirm",
    },
    {
      name: "phone",
      label: "Phone",
      rules: [{ required: true, message: "Please input phone!" }],
    },
    {
      name: "region",
      label: "Region",
      rules: [{ required: true, message: "Please input region!" }],
    },
  ];

  useEffect(() => {
    const asyncUseEffect = async () => {
      /** If this is a new user then the userRoles state should be reset */
      setDrawerState(true);
      try {
        setLoading(true);
        if (props.subscriber) {
          const fields = {
            name: props.subscriber.name,
          };
          form.setFieldsValue(fields);
          /** All assignable roles, to be passed to the Assignment component */
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    asyncUseEffect();
  }, [props.subscriber]);

  const renderSwitchForInputType = (fieldType: string): JSX.Element => {
    switch (fieldType) {
      case "phone":
        return (
          <PhoneInput
            country={"us"}
            enableSearch={true}
            onChange={(phone) => form.setFieldsValue({ phone })}
            disabled={!props.isAdd}
            inputStyle={{ width: "100%", borderRadius: token.borderRadius }}
            dropdownStyle={{ borderRadius: token.borderRadius }}
          />
        );
      case "region":
        return (
          <div>
            <Select
              onSelect={(value) => form.setFieldsValue({ region: value })}
            >
              {userRegions.map((region) => (
                <Select.Option key={region} value={region}>
                  {region}
                </Select.Option>
              ))}
            </Select>
          </div>
        );
      default:
        return <Input disabled={fieldType == "email" && !props.isAdd} />;
    }
  };
  const onClose = async () => {
    setDrawerState(false);
    await props.onClose();
  };

  return (
    <Drawer
      title="Subscriber"
      onClose={onClose}
      open={drawerState}
      subtitle="Create a new subscriber"
      onSubmit={handleSubmit}
    >
      <Spin spinning={loading}>
        <Form form={form} labelCol={{ span: 8 }}>
          {formFields.map((field) =>
            field.name == "password" ? (
              <Form.Item
                key={field.name}
                name="password"
                label="Password"
                rules={[
                  { required: true, message: "Password is required!" },
                  () => ({
                    validator(rule, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (
                        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
                          value
                        )
                      ) {
                        return Promise.reject(
                          "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            ) : field.name == "confirmPassword" ? (
              <Form.Item
                name="confirmPassword"
                label="Confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            ) : (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={field.rules}
              >
                {renderSwitchForInputType(field.name)}
              </Form.Item>
            )
          )}
        </Form>
        <Divider />
      </Spin>
    </Drawer>
  );
};
