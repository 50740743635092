import { FC, useEffect, useState } from "react";
import {
  Form,
  Input,
  Spin,
  Space,
  theme,
  Flex,
  Checkbox,
} from "antd";

import { SaveSearchModalProps, SearchTabType } from "types";
import { useOrganizationStore, useSearchStore } from "store";
import Modal from "components/Modal";
import { FieldLabel } from "components/FieldLabel";
import SearchableTags from "components/SearchableTags";

const { TextArea } = Input;

const SaveSearchModal: FC<SaveSearchModalProps> = ({
  open,
  onClose,
  onSubmit,
  loader,
  isEditId
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [includeTags, setIncludeTags] = useState<boolean>(false);

  const { mspEnabled, context, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    context: state.context,
    tags: state.tags,
  }));

  const {
    searchItemsMap,
  } = useSearchStore(
    (state) => ({
      searchItemsMap: state.searchItemsMap,
    }),
  );

  useEffect(() => {
    if (isEditId) {      
      const selectedData = searchItemsMap.get(SearchTabType.SavedSearches)?.find((i)=> i.id === isEditId);
      form.setFieldsValue({name: selectedData?.name, description: selectedData?.description})
    }
  }, [isEditId])
  
  return (
    <Modal
      loader={loader}
      title={isEditId ? "Edit Search" : "Save Search"}
      open={open}
      footerName="Save"
      onClose={onClose}
      onSubmit={() => 
        form
          .validateFields()
          .then((value) => {
            const tagsOnly = selectedTags && (selectedTags.length > 0) && !value?.saveFor?.includes('includeMe');
            onSubmit(value.name, value.description, value.tags, tagsOnly);
          })
          .catch((err) => {
            console.log(err);
          })
      }
    >
      <Spin spinning={loader}>
        <Form
          form={form}
          name="saveSearchForm"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical" 
        >
          <Flex vertical gap={0}>
            <Form.Item
              name="name"
              label={<FieldLabel label={"Search Name"}/>}
              initialValue={""}
              required
              rules={[{ required: true, message: "Name is required!" }]}
            >
              <Input placeholder="Enter search name"/>
            </Form.Item>
            <Form.Item
              name="description"
              label={<FieldLabel label={"Description"}/>}
              rules={[{ required: true, message: "Description is required!" }]}
              required
              initialValue={""}
            >
              <TextArea placeholder="Enter description" autoSize maxLength={2048}/>
            </Form.Item>
            {!isEditId && mspEnabled && context?.endsWith('_tag') 
              &&
              <Form.Item
                name="saveFor"
                required
                rules={[{ required: true, message: "Select an option to save!" }]}
              >
                <Checkbox.Group 
                  options={[
                    { label: 'Save search for me', value: 'includeMe' },
                    { label: 'Save search for tags', value: 'includeTags' },
                  ]} 
                  onChange={(values) => {
                    setIncludeTags(values.includes('includeTags'));
                  }} 
                />
              </Form.Item>
            }
            {includeTags 
              &&
              <Form.Item
                name="tags"
                required
                rules={[{ required: true, message: "Tags should be selected!" }]}
              >
                <SearchableTags
                  tags={tags}
                  selectedTags={selectedTags}
                  onSelect={(tag) => {
                    let tags = [...selectedTags, tag.id];
                    if (selectedTags.includes(tag.id)) {
                      tags = [...selectedTags.filter((t) => t != tag.id)];
                    } 
                    setSelectedTags(tags);
                    form.setFieldValue("tags", tags)
                  }}
                />
              </Form.Item>
            }
          </Flex>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SaveSearchModal;