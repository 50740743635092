import { Form, Input, Modal } from "antd";
import { useState } from "react";
import { useSearchStore } from "store";
import { Search, SearchTabType } from "types";
import { notification } from "utility/notification";
export interface SaveSearchProps {
  onClose: () => void;
  onSaved: (item: Search) => void;
}

export const SaveSearch = (props: SaveSearchProps) => {
  const [, setLoading] = useState(false);
  const { createSearch, getSearchItems } = useSearchStore((state) => ({
    createSearch: state.createSearch,
    getSearchItems: state.getSearchItems,
  }));
  const handleSubmit = async () => {
    let values: any;
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }
      const search = await createSearch(values.name, values.description);
      await getSearchItems(SearchTabType.SavedSearches, 1, 1000);
      /** Now look for the search item that has the same name, description and query as ours */
      props.onSaved(search);
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to save search`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        props.onClose();
      }
    }
  };

  const formFields = [
    {
      name: "name",
      label: "Name",
      rules: [{ required: true, message: "Please input search name!" }],
    },
    {
      name: "description",
      label: "Description",
      rules: [{ required: true, message: "Please input search description!" }],
    },
  ];
  const [form] = Form.useForm();
  const renderSwitchForInputType = (fieldType: string): JSX.Element => {
    switch (fieldType) {
      case "description":
        return (
          <Input.TextArea
            rows={4}
            onChange={(e) => {
              form.setFieldsValue({ description: e.target.value });
            }}
          />
        );
      default:
        return <Input />;
    }
  };

  return (
    <Modal
      onCancel={props.onClose}
      onOk={handleSubmit}
      title="Save Search"
      open={true}
      width={512}
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 8, offset: 0 }}
          wrapperCol={{ span: 16, offset: 0 }}
        >
          {formFields.map((field) => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
            >
              {renderSwitchForInputType(field.name)}
            </Form.Item>
          ))}
        </Form>
      </div>
    </Modal>
  );
};
