import { useDashboardStore } from "store";
import { CustomDashboard } from "./CustomDashboard";

export const CustomDashboardTab = () => {
  const dashboards = useDashboardStore((state) => state.dashboards);
  return (
    <div>
      {Object.values(dashboards).map((dashboard) => (
        <CustomDashboard key={dashboard.id} dashboard={dashboard} />
      ))}
    </div>
  );
};
