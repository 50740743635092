import { FC, useEffect, useState } from "react";
import { 
  Badge, 
  Button, 
  Card, 
  Empty, 
  Flex, 
  Space, 
  Spin, 
  Tooltip, 
  Typography, 
  notification, 
  theme 
} from "antd";

import { v4 as uuidv4 } from 'uuid';
import { useEdgeStore, useOrganizationStore, useSettingsStore } from "store";

import { EntitiesDisplay } from "components/EntitiesDisplay";
import { Edge, EdgeDeployStatus, EdgeInfraTypeMap, EdgeManageType, IODataType, ParameterStoreParameter, ParameterStoreEdgePolicyName, Policy, PolicyCategoryType, EdgeStatus } from "types";
import { ColumnsType } from "antd/es/table";
import { HLink } from "components/Link";
import ParameterStoreItem  from "./item";
import { usePolicyStore } from "store/policy";
import { SvgIcon } from "components/SvgIcon";
import SearchInput from "components/SearchInput";
import { getEdgeShortLogo } from "utility/edge";
import { useParameterStoreStore } from "store/parameter_store";

const { Text } = Typography;

const ParameterStore: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const [showEdges, setShowEdges] = useState<boolean>(false);
  const [selectedEdge, setSelectedEdge] = useState<Edge|undefined>();
  const [selectedParameter, setSelectedParameter] = useState<ParameterStoreParameter | null>(null);
  const [parameterStorePolicy, setParameterStorePolicy] = useState<Policy|undefined>();
  
  const [edgeFilter, setEdgeFilter] = useState("");
  const [edgeHoverId, setEdgeHoverId] = useState("");

  const context = useOrganizationStore((state) => state.context);

  const mode = useSettingsStore((state) =>  state.lightMode);
  
  const {
    getPolicy,
    updatePolicy,
  } = usePolicyStore((state) => ({
    getPolicy: state.getPolicy,
    updatePolicy: state.updatePolicy,
  }));

  const {
    edges,
    getEdges,
    getSelectedEdge,
   } = useEdgeStore((state) => ({
    edges: state.edges,
    getEdges: state.getEdges,
    getSelectedEdge: state.getSelectedEdge,
  }));

  const {
    parameters,
    getParameters,
    createParameter,
    updateParameter,
    deleteParameter,
  } = useParameterStoreStore((state) => ({
    parameters: state.parameters,
    getParameters: state.getParameters,
    createParameter: state.createParameter,
    updateParameter: state.updateParameter,
    deleteParameter: state.deleteParameter,
  }));
  
  const loadParamStorePolicy = async () => {
    try {
      setLoader(true);
      const policy = await getPolicy(PolicyCategoryType.WorkflowGeneral, ParameterStoreEdgePolicyName, true);
      if (policy) {
        console.log("found policy", policy);
        setParameterStorePolicy(policy);
        if (policy?.value.data && policy?.value.data != "") {
          const edgeId = policy?.value.data;
          const edge = await getSelectedEdge(edgeId);
          setSelectedEdge(edge);
        } else {
          //TBD - load based on user scroll instead of all
          await getEdges();
        }
      } else {
        console.log("no policy");
        setSelectedEdge(undefined);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadParamStorePolicy()
  }, [context]);

  const loadParameters = async () => {
    try {
      setLoader(true);
      if (selectedEdge?.id && parameterStorePolicy?.id) {
        await getParameters(selectedEdge?.id, parameterStorePolicy?.id, currentPage, pageSize);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadParameters();
  }, [selectedEdge]);

  const updateParamStorePolicy = async (edgeId: string) => {
    try {
      setLoader(true);
      if (parameterStorePolicy) {
        const policyValue = parameterStorePolicy?.value;
        policyValue.data = edgeId;
        const newPolicy = {
          id: parameterStorePolicy.id,
          value: policyValue
        } as Policy;
        await updatePolicy(PolicyCategoryType.WorkflowGeneral, newPolicy);
        notification.success({
          message: `Parameter store edge policy updated successfully`,
          duration: 6,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while updating policy...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }

  const onParameterCreate = async (parameter: ParameterStoreParameter) => {
    try {
      setLoader(true);
      if (selectedEdge?.id && parameterStorePolicy?.id) {
        await createParameter(selectedEdge?.id, parameterStorePolicy?.id, parameter);
        notification.success({
          message: `Parameter created successfully`,
          duration: 6,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while creating parameter...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const onParameterUpdate = async (name: string, parameter: ParameterStoreParameter) => {
    try {
      setLoader(true);
      if (selectedEdge?.id && parameterStorePolicy?.id) {
        await updateParameter(selectedEdge?.id, parameterStorePolicy?.id, name, parameter);
        notification.success({
          message: `Parameter updated successfully`,
          duration: 6,
        });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while updating parameter...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const onParametersDelete = async (parameters: ParameterStoreParameter[]) => {
    try {
      setLoader(true);
      if (selectedEdge?.id && parameterStorePolicy?.id) {
        await Promise.all(
          parameters.map(async (parameter) => {
            await deleteParameter(selectedEdge?.id, parameterStorePolicy?.id, parameter.name);
            notification.success({
              message: `Parameter ${parameter.name} deleted successfully`,
              duration: 6,
            });
          })
        );
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting parameters...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const columns: ColumnsType<ParameterStoreParameter> = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (p: ParameterStoreParameter) => (
        <HLink
          onClick={() => {
            setSelectedParameter(p);
          }}
          text={p.name}
          tooltip={p.description}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (type) => (
        <Text>{type == IODataType.String ? "String" : "StringList"}</Text>
      ),
    },
    {
      title: "Value",
      dataIndex: "",
      key: "value",
      render: (p: ParameterStoreParameter) => (
        <Text>{p.type == IODataType.String ? p.value : p.value.join(",")}</Text>
      ),
    },
  ]

  return (
    <Spin spinning={loader}>
      {selectedParameter && (
        <ParameterStoreItem 
          editMode={true}
          item={selectedParameter}
          onSubmit={async (values) => {
            setSelectedParameter(null);
            (selectedParameter.name != "") ? onParameterUpdate(selectedParameter.name, values as ParameterStoreParameter)
                                    : onParameterCreate(values as ParameterStoreParameter);
          }}
          onClose={() => setSelectedParameter(null)}
          checkNameExists={(name) => {
            if (selectedParameter.name == "") {
              const parameter = parameters.find((p) => p.name == name);
              return parameter != undefined;
            }
            return false;
          }}
        />
      )}
      {selectedEdge 
        ? 
          <>
            <Flex gap={"middle"} style={{ marginTop: token.marginXXS }} >
              <Text strong>Edge: </Text>
              <Tooltip
                color={token.colorPrimaryHover}
                title={selectedEdge.description}
                placement={"top"}
              >
                <Text>{selectedEdge.displayName}</Text>
              </Tooltip>
              <Badge count={EdgeInfraTypeMap.get(selectedEdge.infraType)} color="pink" />
              <Badge count={selectedEdge.region} color="cyan" />
              <Badge count={selectedEdge.manageType == EdgeManageType.HyprEdge? "Managed" : "Unmanaged"} color={selectedEdge.manageType == EdgeManageType.HyprEdge? "volcano" : "green"}  />
            </Flex>
            <EntitiesDisplay
              header={"Parameters"}
              dataSource={parameters}
              columns={columns}
              pageNumber={currentPage}
              pageSize={pageSize}
              total={parameters?.length}
              rowKey={"name"}
              onPageChange={(page) => {
                setCurrentPage(page);
              }}
              getItemProps={() => {
                return {
                  disabled: false,
                };
              }}
              actions={
                [
                  {
                    key: "refresh", 
                    label: "Refresh", 
                    enable: () => true, 
                    onClick: () => {loadParameters()}
                  },
                  {
                    key: "add", 
                    label: "Add", 
                    enable: () => true, 
                    onClick: () => {setSelectedParameter({name: "", description: "", type: IODataType.String, value: ""} as ParameterStoreParameter)}
                  },
                  {
                    key: "delete", 
                    label: "Delete", 
                    enable: (itemIds) => {
                      return (itemIds && itemIds.length) ? true : false;
                    }, 
                    showWarn: true,
                    warnMessage: <div>Are you sure to delete parameters? <br></br> Dependent workflows or searches will be impacted</div>,
                    onClick: (value) => {
                      const names = value as string[];
                      const items = parameters.filter((p) => names.find((name) => name == p.name))
                      if (items) {
                        onParametersDelete(items);
                      }
                    }
                  }
                ]
              }
            />
          </>
        :
          showEdges 
            ?
              <div style={{ width: "60%", marginLeft: "20%" }}>
                <SearchInput
                  placeholder="search edges"
                  onFilter={(e) => setEdgeFilter(e.target.value)}
                /> 
                {edges
                  .sort((a, b) => a.status.localeCompare(b.status))
                  .filter((edge) => edge.displayName.indexOf(edgeFilter) !== -1)
                  .map((edge) => (
                    <Space key={edge.id} direction="vertical" style={{ display: "flex", marginTop: "3px" }}>
                      <Card
                        hoverable={true}
                        style={{ 
                          height: "40px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          background: (edge.status == EdgeStatus.Online ? (edge.id == edgeHoverId ? token.colorPrimaryBgHover : token.colorBgBase) : mode ? "#f5f5f5" : "#1f1f1f"),
                        }}
                        onClick={() => {
                          if (edge.status == EdgeStatus.Online) {
                            updateParamStorePolicy(edge.id);
                            setSelectedEdge(edge);
                          }
                        }}
                        onMouseEnter={() => setEdgeHoverId(edge.id)}
                        onMouseLeave={() => setEdgeHoverId("")}
                      >
                          <div style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              marginBottom: "2px"
                            }}
                          >
                          <div style={{ flex: 1 }}>
                            <Tooltip
                              color={token.colorPrimaryHover}
                              title={edge.description}
                              placement={"left"}
                            >
                              <Flex gap={"middle"} style={{ marginTop: token.marginXXS }} >
                                <SvgIcon size={25} component={getEdgeShortLogo(edge.infraType)} />
                                <Text>{edge.displayName}</Text>
                              </Flex>
                            </Tooltip>
                          </div>
                          <Flex gap={"middle"} style={{ marginTop: token.marginXXS }} >
                            <Badge count={edge.region} color="cyan" />
                            <Badge count={edge.manageType == EdgeManageType.HyprEdge? "HyprEdge Managed" : "Customer Managed"} color={edge.manageType == EdgeManageType.HyprEdge? "volcano" : "green"} />
                          </Flex>
                          </div>
                      </Card>
                    </Space>
                  ))}
              </div>
            :
              <Empty description={"Create Parameter Store at Edge"}>
                <Button type="primary" onClick={() => setShowEdges(true)}>Create</Button>
              </Empty>
      }
    </Spin>
  );
};

export default ParameterStore;
