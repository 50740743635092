import { FC, useEffect, useState } from "react";
import {
  Button,
  Spin,
  Space,
  Form,
  Select,
  Switch,
  Input,
  theme,
  Row,
} from "antd";
import timezones from "timezones-list";
import cron from "cron-validate";

import {
  TriggerType,
  WorkflowNodeCardProps,
  IOData,
  IODataValueMap,
  ScheduleType,
  ScheduleTypeOptions,
  SchdeuleMonthOptions,
  SchdeuleWeekDayOptions,
} from "types";

import { useWorkflowStepProvidersStore, useWorkflowStore } from "store";
import { notification } from 'utility/notification';

import { FieldLabel } from "components/FieldLabel";

const parseDurationMinutesRegEx = new RegExp("^@every ([5-9]|[1-5][0-9])m$");
const parseDurationHoursRegEx = new RegExp("^@every ([1-9]|1[0-9]|2[0-3])h$");
const parseDurationHoursMinutesRegEx = new RegExp("^@every ([0-9]|1[0-9]|2[0-3])h([0-9]|[1-5][0-9])m$");

const ScheduleTriggerCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  nodeType,
  editMode,
  onClose
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [parameters, setParameters] = useState(new Map<string, IOData>());
  const [parametersValues, setParametersValues] = useState(
    {} as IODataValueMap
  );

  const [scheduleType, setScheduleType] = useState<ScheduleType>(
    ScheduleType.DailyScheduleType
  );
  const [onDay, setOnDay] = useState(true);

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const { triggerProvidersMap, getTriggerProviders } =
    useWorkflowStepProvidersStore((state) => ({
      triggerProvidersMap: state.triggerProvidersMap,
      getTriggerProviders: state.getTriggerProviders,
    }));

  const loadScheduleTrigger = async () => {
    try {
      setLoader(true);
      await getTriggerProviders(
        selectedWorkflow.triggerRef.triggerID,
        TriggerType.Schedule
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadScheduleTrigger();
  }, [selectedWorkflow]);

  const syncTriggerParameters = async () => {
    try {
      const params = new Map<string, IOData>();
      const triggerProvider = triggerProvidersMap.get(
        selectedWorkflow.triggerRef.triggerID
      );
      if (
        triggerProvider &&
        triggerProvider.trigger &&
        triggerProvider.trigger.parameters
      ) {
        Object.entries(triggerProvider.trigger.parameters).forEach(
          ([paramName, paramDef]) => {
            params.set(paramName, paramDef);
          }
        );
      }
      setParameters(params);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    syncTriggerParameters();
    syncCurrentState();
  }, [triggerProvidersMap]);

  const syncCurrentState = async () => {
    try {
      const triggerRef = selectedWorkflow.triggerRef;
      let paramsValues = {} as IODataValueMap;

      //initialize with default values
      parameters &&
        parameters.forEach((paramDef, paramName) => {
          paramsValues[paramName] = paramDef.default;
        });

      if (!triggerRef.isConfigured) {
        //Overwrite timezone by user current timezone
        paramsValues["timezone"] =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      //merge current parameters values with defualt values
      paramsValues = { ...paramsValues, ...triggerRef.parameters };

      setScheduleType(parametersValues.scheduleType as ScheduleType);
      setOnDay(paramsValues.onDay as boolean);

      form.setFieldsValue(paramsValues);
      setParametersValues(paramsValues);
    } catch (error) {
      console.log(error);
    }
  };

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnFinish = async (values: any) => {
    form
      .validateFields()
      .then((values) => {
        const triggerRef = selectedWorkflow.triggerRef;

        triggerRef.parameters = values;
        triggerRef.isConfigured = true;
        selectedWorkflow.triggerRef = triggerRef;

        updateSelectedWorkflow();
        setParametersValues({ ...parametersValues, ...values });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const scheduleTypeFormItem = () => {
    return (
      <Form.Item 
        name="scheduleType" 
        label={<FieldLabel label={"Schedule Type"} help={"Type of schedule to trigger workflow"} />}
        rules={[
          { required: true, message: "Schedule Type should be selected!" }
        ]}
      >
        <Select
          disabled={!editMode}
          options={ScheduleTypeOptions}
          onChange={setScheduleType}
        />
      </Form.Item>
    );
  };

  const minutesFormItem = () => {
    return (
      <Form.Item name="minutes">
        <Select
          disabled={!editMode}
          options={Array.from({ length: 60 }, (_, i) => ({ value: i }))}
        />
      </Form.Item>
    );
  };

  const hoursFormItem = () => {
    return (
      <Form.Item name="hours">
        <Select
          disabled={!editMode}
          options={Array.from({ length: 24 }, (_, i) => ({ value: i }))}
        />
      </Form.Item>
    );
  };

  const timeAtFormItem = () => {
    return (
      <Space>
        {hoursFormItem()}
        <div style={{ marginBottom: "20px" }}>:</div>
        {minutesFormItem()}
      </Space>
    );
  };

  const daysOfWeekFormItem = (mode?: any) => {
    return (
      <Form.Item name="daysOfWeek">
        <Select
          mode={mode}
          disabled={!editMode}
          style={mode != "tags" ? { width: "125px" } : {}}
          options={SchdeuleWeekDayOptions}
        />
      </Form.Item>
    );
  };

  const onDayFormItem = () => {
    return (
      <Form.Item name="onDay">
        <Switch
          disabled={!editMode}
          checkedChildren="Day"
          unCheckedChildren="Week"
          defaultChecked={onDay}
          onChange={setOnDay}
        />
      </Form.Item>
    );
  };

  const dayOfMonthFormItem = () => {
    return (
      <Form.Item name="dayOfMonth">
        <Select
          disabled={!editMode}
          style={{ width: "125px" }}
          options={Array.from({ length: 32 }, (_, i) => ({ value: i }))}
        />
      </Form.Item>
    );
  };

  const weekOfMonthFormItem = () => {
    return (
      <Form.Item name="weekOfMonth">
        <Select
          disabled={!editMode}
          style={{ width: "125px" }}
          options={[
            { value: 1, label: "First" },
            { value: 2, label: "Second" },
            { value: 3, label: "Third" },
            { value: 4, label: "Fourth" },
            { value: 5, label: "Fifth" },
          ]}
        />
      </Form.Item>
    );
  };

  const monthsFormItem = () => {
    return (
      <Form.Item name="months">
        <Select
          mode="tags"
          options={SchdeuleMonthOptions}
        />
      </Form.Item>
    );
  };

  const cronFormItem = () => {
    return (
      <Form.Item
        name="cron"
        rules={[
          {
            validator: (_, value) =>
              cron(value).isValid() ||
              parseDurationMinutesRegEx.test(value) ||
              parseDurationHoursRegEx.test(value) ||
              parseDurationHoursMinutesRegEx.test(value)
                ? Promise.resolve()
                : Promise.reject("Invalid cron expression!"),
          },
        ]}
      >
        <Input disabled={!editMode} />
      </Form.Item>
    );
  };

  const timeZoneFormItem = () => {
    return (
      <Form.Item name="timezone">
        <Select
          disabled={!editMode}
          popupMatchSelectWidth={false}
          options={timezones.map((timezone) => ({
            value: timezone.tzCode,
            label: timezone.label,
          }))}
        />
      </Form.Item>
    );
  };

  return (
    <Form
      form={form}
      name="scheduleTriggerCardForm"
      layout="vertical"
      autoComplete="off"
      initialValues={parametersValues}
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" style={{ display: "flex" }}>
          {scheduleTypeFormItem()}
          {scheduleType == ScheduleType.MinutesScheduleType && (
            <>
              {<FieldLabel label={"Every"} />}
              <Space>
                {minutesFormItem()}
                <div style={{ marginBottom: token.margin}}>Minutes</div>
              </Space>
            </>
          )}
          {scheduleType == ScheduleType.HourlyScheduleType && (
            <>
              {<FieldLabel label={"Every"} />}
              <Space size={30}>
                <Space>
                  {hoursFormItem()}
                  <div style={{ marginBottom: token.margin}}>Hours</div>
                </Space>
                <Space>
                  {minutesFormItem()}
                  <div style={{ marginBottom: token.margin}}>Minutes</div>
                </Space>
              </Space>
            </>
          )}
          {scheduleType == ScheduleType.DailyScheduleType && (
            <>
              {<FieldLabel label={"At"} />}
              {timeAtFormItem()}
            </>
          )}
          {scheduleType == ScheduleType.WeeklyScheduleType && (
            <>
              {<FieldLabel label={"On"} />}
              {daysOfWeekFormItem("tags")}
              {<FieldLabel label={"At"} />}
              {timeAtFormItem()}
            </>
          )}
          {scheduleType == ScheduleType.MonthlyScheduleType && (
            <>
              {<FieldLabel label={"On"} />}
              {onDayFormItem()}
              {onDay ? (
                dayOfMonthFormItem()
              ) : (
                <Space>
                  {weekOfMonthFormItem()}
                  {daysOfWeekFormItem()}
                </Space>
              )}
              {<FieldLabel label={"At"} />}
              {timeAtFormItem()}
            </>
          )}
          {scheduleType == ScheduleType.YearlyScheduleType && (
            <>
              {<FieldLabel label={"On"} />}
              {monthsFormItem()}
              {onDayFormItem()}
              {onDay ? (
                dayOfMonthFormItem()
              ) : (
                <Space>
                  {weekOfMonthFormItem()}
                  {daysOfWeekFormItem()}
                </Space>
              )}
              {<FieldLabel label={"At"} />}
              {timeAtFormItem()}
            </>
          )}
          {scheduleType == ScheduleType.AdvancedScheduleType && (
            <>
              {<FieldLabel label={"Cron"} />}
              {cronFormItem()}
            </>
          )}
          {<FieldLabel label={"Timezone"} />}
          {timeZoneFormItem()}
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
    </Form>
  );
};

export default ScheduleTriggerCard;
