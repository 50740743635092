import { FC, useState, useEffect } from "react";
import {
  Button,
  Space,
  Spin,
  Form,
  Input,
  Typography,
  theme,
  Modal as AntModal,
  Empty,
  Image,
  Steps,
} from "antd";

import { SvgIcon } from "components/SvgIcon";
import {
  AppConfigProps,
  AdapterType,
  EdgeStatus,
} from "types";

import { FieldLabel } from "components/FieldLabel";

import { useAppSubscriptionStore, useEdgeStore, useOrganizationStore, useSettingsStore } from "store";

import HttpConfig from "./httpConfig";
import AppEdgeConfig from "./appEdgeConfig";

import { commonIcons } from "assets/icons";
import AppParameters from "./appParameters";
import SqlDBConfig from "./sqlConfig";
import { getAppLogoUrl } from "utility/app";

const { Text } = Typography;
const AppConfig: FC<AppConfigProps> = ({
  appId,
  appSubscriptionId,
  open,
  onClose,
  onSave,
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [edtiMode, setEditMode] = useState(true);

  const [stepCurrent, setStepCurrent] = useState(0);
  const [stepItems, setStepItems] = useState<any[]>([])

  const [form] = Form.useForm<{name: string}>();
  const appSubscriptionName = Form.useWatch('name', form);

  const [appParametersform] = Form.useForm();
  const [httpConfigform] = Form.useForm();
  const [sqlDBConfigform] = Form.useForm();
  const [edgeConfigform] = Form.useForm();

  const [loadAppSubscriptionFailed, setLoadAppSubscriptionFailed] =useState(false);

  const context = useOrganizationStore((state) => state.context);
  const mode  = useSettingsStore((state) => state.lightMode);
  const edges = useEdgeStore((state) => state.edges);

  const { 
    selectedApp,
    selectedAppSubscription,
    getAppSubscription,
    clearAppSubscription,
  } = useAppSubscriptionStore((state) => ({
    selectedApp: state.selectedApp,
    selectedAppSubscription: state.selectedAppSubscription,
    getAppSubscription: state.getAppSubscription,
    clearAppSubscription: state.clearAppSubscription,
  }));

  const loadAppSubscription = async (id: string) => {
    try {
      setLoader(true);
      const as = await getAppSubscription(id);
      setEditMode(context == `${as.tenantID}_tag`);
      setLoadAppSubscriptionFailed(false);
    } catch (error) {
      console.log(error);
      setLoadAppSubscriptionFailed(true);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    appSubscriptionId && appSubscriptionId != "" && loadAppSubscription(appSubscriptionId);
  }, [context, appSubscriptionId]);

  useEffect(() => {
    const items = [];
    if (selectedApp?.parameters?.length) {
      items.push({
        key: "parameters",
        title: "Parameters",
        icon: <SvgIcon component={commonIcons.parametersIcon}/>,
        form: appParametersform,
        status: selectedAppSubscription?.id ? "finish" : "wait",
        data: selectedAppSubscription?.parameters,
        setData: (values: any) => selectedAppSubscription.parameters = values
      });
    }
    if (selectedApp?.adapterTypes?.find((type) => type == AdapterType.Http)) {
      items.push({
        key: "http",
        title: "HTTP",
        icon: <SvgIcon component={commonIcons.httpIcon}/>,
        form: httpConfigform,
        status: selectedAppSubscription?.id ? "finish" : "wait",
        data: selectedAppSubscription?.httpConfiguration,
        setData: (values: any) => selectedAppSubscription.httpConfiguration = values
      });
    }
    if (selectedApp?.adapterTypes?.find((type) => type == AdapterType.Sql)) {
      items.push({
        key: "sqldb",
        title: "SQL DB",
        icon: <SvgIcon component={commonIcons.databaseIcon}/>,
        form: sqlDBConfigform,
        status: selectedAppSubscription?.id ? "finish" : "wait",
        data: selectedAppSubscription?.sqlDBConfiguration,
        setData: (values: any) => selectedAppSubscription.sqlDBConfiguration = values
      });
    }
    items.push({
      key: "edge",
      title: "Edge",
      icon: <SvgIcon component={commonIcons.edgeIcon}/>,
      form: edgeConfigform,
      status: selectedAppSubscription?.id ? "finish" : "wait",
      data: selectedAppSubscription?.edgeID,
      setData: (values: any) => selectedAppSubscription.edgeID = values.edgeID
    });
    setStepItems(items);

    if (selectedAppSubscription) {
      if (selectedAppSubscription?.name) {
        form.setFieldValue("name", selectedAppSubscription.name);
      }

      if (selectedAppSubscription?.edgeID) {
        edgeConfigform.setFieldValue("edgeID", selectedAppSubscription?.edgeID);
      }
    }
  }, [selectedAppSubscription]);
  
  return (
    <AntModal
      title={
        <Space size={10}>
          <Image 
            preview={false} 
            draggable={false}
            height={"12px"}
            src={getAppLogoUrl(selectedApp, mode)}
            alt="company-logo"
          />
          <Text>Configuration</Text>
          <Text>{appSubscriptionName}</Text>
        </Space>
      }
      open={open}
      width={800}
      centered={true}
      keyboard={false}
      maskClosable={false}
      onCancel={async () => {
        clearAppSubscription();
        onClose(false);
      }}
      footer={loadAppSubscriptionFailed || loader ?
        []
        :
        [
        stepCurrent > 0 
        && 
        <Button key="previous" style={{ margin: '0 8px' }} onClick={() => setStepCurrent(stepCurrent - 1)}>
          Previous
        </Button>,
        stepCurrent < stepItems.length - 1
        && 
        <Button 
          key="next"
          type="primary" 
          onClick={() => 
            edtiMode
              ?
              stepItems[stepCurrent].form.validateFields()
                .then((values: any) => {
                stepItems[stepCurrent].setData(values);
                stepItems[stepCurrent].status = "finish";
                setStepCurrent(stepCurrent + 1)
              }).catch((e: any) => {
                setStepCurrent(stepCurrent)
              })
              :
              setStepCurrent(stepCurrent + 1)
          }
        >
          Next
        </Button>,
        stepCurrent === stepItems.length - 1 
        &&
        edtiMode
        && 
          <Button 
            key="create"
            type="primary" 
            onClick={() => 
              stepItems[stepCurrent].form.validateFields()
              .then((values: any) => {
                stepItems[stepCurrent].setData(values);
                stepItems[stepCurrent].status = "finish";
                if (stepItems.every((stepItem) => stepItem.status == "finish")) {
                  form.validateFields().then((values: any) => {
                    selectedAppSubscription.name = values.name;
                    onSave(selectedAppSubscription);
                  }).catch((e: any) => {
                    setStepCurrent(stepCurrent)
                  })
                } else {
                  const items = stepItems.map((stepItem) => {
                    if (stepItem.status == "wait") {
                      return {...stepItem, status: "error"}
                    } else {
                      return stepItem
                    }
                  });
                  setStepItems(items);
                }
              }).catch((e: any) => {
                setStepCurrent(stepCurrent)
              })
            }
          >
            {selectedAppSubscription?.id ? "Update" : "Create"}
          </Button>
      ]}
    >
      {loadAppSubscriptionFailed ? (
        <Empty
          imageStyle={{ height: 60 }}
          description={
            <Space direction="vertical">
              <Text type="secondary">Error in loading configuration!</Text>
              {edges?.find((edge) => edge.id == selectedAppSubscription?.edgeID)?.status != EdgeStatus.Online
                &&
                <Text type="danger">It seems edge {edges?.find((edge) => edge.id == selectedAppSubscription?.edgeID)?.displayName} is not available</Text>
              }
            </Space>
          }
        >
          <Button type="primary" onClick={() => appSubscriptionId && loadAppSubscription(appSubscriptionId)}>Retry!</Button>
        </Empty>
      ) : (
        <Spin spinning={loader}>
          <Form
            form={form}
            name="configForm"
            disabled={!edtiMode}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              name="name"
              label={<FieldLabel label={"Name"} />}
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Input />
            </Form.Item>
          </Form>
          <Steps 
            onChange={(c) => {
              if(edtiMode && (c > stepCurrent)) {
                stepItems[stepCurrent].form.validateFields().then((values: any) => {
                  setStepCurrent(c);
                }).catch((e: any) => {
                  setStepCurrent(stepCurrent);
                })
              } else {
                setStepCurrent(c);
              }
            }}
            current={stepCurrent} 
            items={stepItems}
            size="small"
            type="navigation"
            style={{ marginBottom: token.margin }}
          />
          {stepItems[stepCurrent]?.key == "parameters" &&
            <AppParameters
              form={appParametersform}
              edtiMode={edtiMode}
            />
          }
          {stepItems[stepCurrent]?.key == "http" &&
            <HttpConfig
              form={httpConfigform}
              edtiMode={edtiMode}
            />
          }
          {stepItems[stepCurrent]?.key == "sqldb" &&
            <SqlDBConfig
              form={sqlDBConfigform}
              edtiMode={edtiMode}
            />
          }
          {stepItems[stepCurrent]?.key == "edge" &&
            <AppEdgeConfig
              form={edgeConfigform}
              appId={appId}
              enableEdgeAutoDetect={stepItems.find((s) => (s.key == "http") && (s.status == "finish")) != undefined}
              editMode={edtiMode}
            />
          }
        </Spin>
      )}
    </AntModal>
  );
};

export default AppConfig;