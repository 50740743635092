
import jwtDecode, {JwtPayload} from "jwt-decode";
import { auth0Issuer } from "api/constant";
interface TokenStatus {
  isAuthenticated: boolean;
  isSso: boolean;
  subject: string ;
  expiry: number;
}

export class TokenUtils {
  public static getTokenStatus = (token: string | null ) : TokenStatus => {
    if ( !token ) return {isAuthenticated: false, isSso: false, subject : "", expiry : 0};
    const decoded : JwtPayload = jwtDecode(token);          
    const leeway = 1000; // Allow for 1 second leeway/ skew in token expiry
    const isAuthenticated = decoded.exp && decoded.exp * 1000 - leeway > Date.now() ? true : false;
    const isSso =(decoded.iss && decoded.iss != auth0Issuer) ? true : false;
    return {isAuthenticated, isSso, subject : decoded.sub ?? "", expiry : decoded.exp ?? 0};
  }
}
