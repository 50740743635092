import {
  Badge,
  Button,
  Card,
  Image,
  Popover,
  Space,
  Spin,
  Tag,
  Typography,
  theme,
} from "antd";
import {
  EditOutlined
} from "@ant-design/icons";

import { FC, useEffect, useState } from "react";
import { useEdgeStore, useOrganizationStore, useSettingsStore } from "store";

import Modal from "components/Modal";
import { usePolicyStore } from "store/policy";
import { PoliciesTabProps } from "./policiesTab";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { getSearchArtifactProviderPoliciesApi } from "api";
import { HLink } from "components/Link";
import { ProviderApp,  getSelectedAppSubscriptionsAndAppInfosViews } from "./constant";
import SelectAppModal from "./selectAppModal";
import {AppCategoriesMap, Policy, PolicyCategoryType, PolicyDataType, PolicyValue } from "types";
import { viewAppConfiguration } from "./configurationView";
import { getTagColor } from "utility";

const { Text } = Typography;

interface DeleteAppProps {
  enable: boolean;
  key?: string;
  subscriptionId?: string
}


export const ArtifactsTab: FC<PoliciesTabProps> = (props) => {
  const [loader, setLoader] = useState(false);
  const { token } = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const context = useOrganizationStore((state) => state.context);
  const edges = useEdgeStore((state) => state.edges);
  const [providerApp, setProviderApp] = useState<Map<string, ProviderApp>>(new Map<string, ProviderApp>());
  const [selectedArtifactId, setSelectedArtifactId] = useState<string>("");
  const [selectedArtifactName, setSelectedArtifactName] = useState<string>("");
  const [pageNumber, setCurrentPage] = useState(1);
  const [deleteAppProps, setDeleteAppProps] = useState<DeleteAppProps>({ enable: false, subscriptionId: "" });
  const {
    policies,
    getPolicies,
    updatePolicy,
    createPolicy,
    deletePolicy,
  } = usePolicyStore((state) => ({
    policies: state.policies,
    getPolicies: state.getPolicies,
    createPolicy: state.createPolicy,
    updatePolicy: state.updatePolicy,
    deletePolicy: state.deletePolicy,
  }));

  const loadArtifactProviders = async () => {
    const [artifactProviders, total] = await getSearchArtifactProviderPoliciesApi();
    const newProviderApp = new Map<string, ProviderApp>();
    artifactProviders.forEach((ap) => {
      const artifactPolicy = newProviderApp.get(ap.artifactID);
      if (artifactPolicy) {
        artifactPolicy.appInfos.push(ap.appInfo);
        newProviderApp.set(ap.artifactID, artifactPolicy);
      } else {
        newProviderApp.set(ap.artifactID, {
          id: ap.artifactID,
          name: ap.artifacts.displayName,
          description: ap.artifacts.description,
          appInfos: [ap.appInfo],
          tags: ap.artifacts.tags,
        } as ProviderApp);
      }
    });
    setProviderApp(newProviderApp)
  }

  const loadPolicies = async () => {
    try {
      setLoader(true);
      await Promise.all([loadArtifactProviders(), getPolicies(props.type)])
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadPolicies();
  }, [context]);

  
  const columns: ColumnsType<ProviderApp> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_, aa) => (
        <Text>{aa.name}</Text>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, aa) => (
        <Popover content={aa.description}>
          <Text style={{minWidth: "300px", maxWidth: "400px"}} ellipsis>{aa.description}</Text>
        </Popover>
      ),
    },
    {
      title: "Apps",
      dataIndex: "app",
      render: (_, aa) => {
        const views = getSelectedAppSubscriptionsAndAppInfosViews(aa, edges);
        if(views && views.length) {
          return (
            <Space size={0}>
              {views?.filter((_, index) => index == 0).map((view) => {
                return (
                  <Badge 
                    key={view.asi.id} 
                    count={
                      views?.length == 1
                        ? 0
                        :
                          <Popover
                            title={"Apps"}
                            style={{ overflow: "scroll" }}
                            content={
                              <Space direction="vertical">
                              {views.filter((as, index) => index > 0).map((view) => viewAppConfiguration(lightMode, view))}
                              </Space>
                            }
                          >
                            <Button 
                              type={"primary"} 
                              size={"small"} 
                              shape={"circle"} 
                              style={{ background: token.colorLinkHover }}
                              
                            >
                              {`+${views?.length-1}`}
                            </Button>
                          </Popover>
                    }
                  >
                    {viewAppConfiguration(lightMode, views[0])  }
                  </Badge>
              )})}
              <Button
                type="default"
                size="small"
                style={{
                  position: "absolute",
                  right: 10,
                  top: 30,
                  zIndex: 1,
                }}
                icon={<EditOutlined style={{
                  color: token.colorPrimary,
                  position: "absolute",
                  right: 4,
                  top: 4,
                }} />}
                onClick={() => {
                  setSelectedArtifactId(aa.id);
                  setSelectedArtifactName(aa.name);
                }}
              />
            </Space>
          )
          
        }
        return (
          <div style={{ minWidth: "150px", justifyContent: "center" }}>
            <HLink
              onClick={() => {
                setSelectedArtifactId(aa.id);
                setSelectedArtifactName(aa.name);
              }}
              text={"Select"}
              underline
              alwaysLinkColor
            />
          </div>
        )
      },
    },
    // {
    //   title: "Categories",
    //   dataIndex: "tags",
    //   key: "tags",
    //   width: '10%',
    //   filters: Array.from(AppCategoriesMap, ([tag, value]) => ({ text: value, value: tag })),
    //   filterSearch: true,
    //   render: (tags: string[]) => {
    //     return (
    //       <Space size={1} style={{ display: "flex" }}>
    //         {tags?.map((tag) => 
    //           <Tag
    //             key={tag}
    //             color={getTagColor(tag)}
    //           >
    //             {AppCategoriesMap.get(tag)}
    //           </Tag>
    //         )}
    //       </Space>
    //     );
    //   },
    // },
  ];

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setCurrentPage(pageNumber);
  }

  const onAppClear = async (artifactId: string, subscriptionId: string) => {
    try {
      setLoader(true);
      const providerPolicies = policies.get(props.type);
      let policy = undefined;
      if (providerPolicies) {
        policy = providerPolicies.find((p) => p.name == artifactId)
        if (policy) {
          const subscriptions = (policy.value.data as string []).filter( (x) => x !== subscriptionId);
          if(subscriptions && subscriptions.length) {
            policy.value.data = subscriptions;
            await updatePolicy(props.type, policy);
          }else {
            await deletePolicy(props.type, policy.id);
          }
          loadPolicies();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
      setSelectedArtifactId("");
      setDeleteAppProps({ enable: false });
    }
  }
  const onAppsSelect = async (artifactId: string, artifactName: string, selectedApps: Record<string, string | string[]>[]) => {
    try {
      setLoader(true);
      const providerPolicies = policies.get(props.type);
      let policy = undefined;
      let create = true;
      const subscriptions: string[] = [];
      selectedApps.forEach ( (selectedApp) => {
        (selectedApp.appSubscriptions as string[]).forEach( (s) => subscriptions.push(s));
      })
      if (providerPolicies) {
        policy = providerPolicies.find((p) => p.name == artifactId)
        if (policy) {
          policy.value.data = subscriptions;
          create = false;
        }
      }
      if (!policy) {
        policy = {
          name: `${artifactId}`,
          displayName: `selected app for ${artifactName}`,
          description: `selected app for  ${artifactName}`,
          value: {
            type: PolicyDataType.Array,
            data: subscriptions,
          } as PolicyValue,
          editable: true,
          hidden: false,
          category: PolicyCategoryType.ArtifactProviders,
        } as Policy
      }
      if (create) {
        await createPolicy(props.type, policy);
      } else {
        await updatePolicy(props.type, policy);
      }
      loadPolicies();
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
      setSelectedArtifactId("");
    }
  };


  return (
    <Spin spinning={loader}>
      {deleteAppProps.enable && (
        <Modal
          title="Delete App Selection"
          onClose={() => {
            setDeleteAppProps({ enable: false });
          }}
          open={deleteAppProps.enable}
          onSubmit={() => deleteAppProps.key && deleteAppProps.subscriptionId &&  onAppClear(deleteAppProps.key, deleteAppProps.subscriptionId)}
        >
        {"Are you sure you want to delete app selection?"}
        </Modal>
      )}
      <EntitiesDisplay
        header={"Artifacts"}
        dataSource={Array.from(providerApp.values()) as any[]}
        columns={columns.filter(item => !item.hidden)}
        total={providerApp.size}
        onPageChange={onPageChange}
      />
      {selectedArtifactId != "" &&
        <SelectAppModal
          providerApp={providerApp.get(selectedArtifactId)}
          isSingle={false}
          onClose={() => { setSelectedArtifactId(""); setSelectedArtifactName(""); }}
          onChange={(apps) => {
            const selectedApps = apps as Record<string, string | string[]>[];
            onAppsSelect(selectedArtifactId, selectedArtifactName, selectedApps)
          }}
        />
      }
    </Spin>
  );
}