import { FC, useEffect, useState } from "react";
import { Button, Checkbox, Radio, Space, Tooltip, Tree, Typography, theme } from 'antd';

import { useNavigate } from "react-router-dom";

import { NodeCardAppsProps, AppInfo } from "types";
import { DataNode } from "antd/es/tree";
import { HLink } from "components/Link";
const { Text, Link } = Typography;

const NodeCardApps: FC<NodeCardAppsProps> = ({
  isSingle,
  appInfos,
  selectedApps,
  editMode,
  onChange
}) => {
  const { token } = theme.useToken();
  const [checkedApps, setCheckedApps] = useState<Record<string, string | string[]>[]>([]);
  const [appSubscriptions, setAppSubscriptions] = useState([] as string[]);

  const navigate = useNavigate();

  useEffect(() => {
    if (isSingle) {
      const app = selectedApps as Record<string, string | string[]>;
      setAppSubscriptions(app?.appSubscriptions as string[]);
    } else {
      const apps = selectedApps as Record<string, string | string[]>[];
      loadAllSubscriptions(apps);
      setCheckedApps(apps);
    }
  }, [selectedApps]);
  
  const loadAllSubscriptions = (apps: Record<string, string | string[]>[]) => {
    const subscriptions = [] as string[];
    if (apps.length) {
      apps.map((app) => {
        const selectedSubscriptions = app?.appSubscriptions as string[];
        selectedSubscriptions?.map((selectedSubscription) => {
          subscriptions.push(selectedSubscription);
        });
      });
    }
    setAppSubscriptions(subscriptions);
  };

  const getSingleSelectTree = () => {
    const treeData: DataNode[] = [];
    if (appInfos) {
      appInfos.forEach((appInfo: AppInfo) => {
        treeData.push({
          key: appInfo.id,
          disabled: !appInfo.isConfigured,
          title: appInfo.displayName,
          isLeaf: false,
          children: appInfo.appSubscriptionsInfos?.map((appSubscriptionsInfo) => ({
            key: appSubscriptionsInfo.id,
            title: (
              <Radio
                value={appSubscriptionsInfo.id}
                onChange={(e: any) => {
                  onChange?.({
                    providerId: appInfo.providerID,
                    appSubscriptions: [e.target.value as string],
                  })
                }}
              >
                {appSubscriptionsInfo.name}
              </Radio>
            ),
          }))
        });
      });
    }
    return treeData;
  };

  const getMultiSelectTree = () => {
    const treeData: DataNode[] = [];
    if (appInfos) {
      appInfos.forEach((appInfo: AppInfo) => {
        treeData.push({
          key: appInfo.id,
          disabled: !appInfo.isConfigured,
          title: appInfo.displayName,
          isLeaf: false,
          children: appInfo.appSubscriptionsInfos?.map((appSubscriptionsInfo) => ({
            key: appSubscriptionsInfo.id,
            title: (
              <Checkbox
                value={appSubscriptionsInfo.id}
                onChange={(e: any) => {
                  const checked = e.target.checked as boolean;
                  const value = e.target.value as string;
                  let currentApps = checkedApps;
                  const currentapp = currentApps.find((app) => app.providerId == appInfo.providerID);
                  if (checked) {
                    if (currentapp) {
                      const subscriptions = currentapp.appSubscriptions as string[];
                      if (subscriptions) {
                        const appSub = currentapp.appSubscriptions as string[];
                        appSub.push(value);
                        currentapp.appSubscriptions = appSub;
                      } else {
                        currentapp.appSubscriptions = [value];
                      }
                    } else {
                      currentApps.push({
                        providerId: appInfo.providerID,
                        appSubscriptions: [value],
                      });
                    }
                  } else {
                    if (currentapp) {
                      const subscriptions = currentapp?.appSubscriptions as string[];
                      if (subscriptions) {
                        currentapp.appSubscriptions = subscriptions.filter((subscription) => subscription != value);
                        if (currentapp.appSubscriptions.length == 0) {
                          currentApps = currentApps.filter((app) => app.providerId != appInfo.providerID)
                        }
                      }
                    }
                  }
                  loadAllSubscriptions(currentApps);
                  setCheckedApps(currentApps);
                  onChange?.(currentApps);
                }}
              >
                {appSubscriptionsInfo.name}
              </Checkbox>
            ),
          }))
        });
      });
    }
    return treeData;
  };

  const infoWithAppLink = (info: string) => {
    return (
      <Space>
        <Text style={{ color: token.colorError }}>{info}</Text>
        <HLink onClick={() => window.open('/apps','_blank', 'rel=noopener noreferrer')} text= "Check here!"/>
      </Space>
    )
  };

  return (
    !appInfos.size
      ? infoWithAppLink("Apps are not available!")
      : isSingle 
          ?
          <Space direction="vertical">
            <Radio.Group disabled={!editMode} value={appSubscriptions?appSubscriptions[0]:""}>
              <Tree
                defaultExpandAll
                treeData={getSingleSelectTree()}
              />
            </Radio.Group>
            {Array.from(appInfos.entries()).every(([_, appInfo]) => !appInfo.isConfigured) 
              && infoWithAppLink("Apps are not configured!")
            }
          </Space>
          :
          <Space direction="vertical">
            <Checkbox.Group disabled={!editMode} value={appSubscriptions}>
              <Tree
                defaultExpandAll
                treeData={getMultiSelectTree()}
              />
            </Checkbox.Group>
            {Array.from(appInfos.entries()).every(([_, appInfo]) => !appInfo.isConfigured) 
              && infoWithAppLink("Apps are not configured!")
            }
          </Space>
  );
};
  

export default NodeCardApps;