import { Space, Typography, theme, Switch , Image} from "antd";
import SearchBar from "components/SearchBar";
import { useSearchStore, useSettingsStore } from "store";
import { SearchMetaData } from "types";

import Icon from "@ant-design/icons";
import { commonIcons } from "assets/icons";

const { Title } = Typography;

const SearchBox = () => {
  const {token} = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));

  const { searchQuery, setSearchQuery} = useSearchStore(
    (state) => ({
      searchQuery: state.searchQuery,
      setSearchQuery: state.setSearchQuery,
    })
  );

  return (
    <div 
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: token.colorBgBase,
        borderRadius: "10px",
      }}
    >
      <Title level={3}>Search Anything, Anytime, Anywhere</Title>
      <Space direction="horizontal">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: token.paddingContentHorizontalSM,
          }}
        >
          <Image 
            preview={false}
            height={48}
            src={lightMode ? commonIcons.coPilotLightModeSvg : commonIcons.coPilotDarkModeSvg}
          />
        </div>
        <Switch
          style={{marginTop: "-20px"}}
          checked={searchQuery.isAiBasedQuery}
          onChange={()  => setSearchQuery({query:"", isAiBasedQuery: !searchQuery.isAiBasedQuery} as SearchMetaData)}
        />
      </Space>
      <div
        key="searchbar"
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          marginTop: token.margin,
          marginBottom: token.marginXXL
        }}
      >
        <SearchBar inHeader={false} />
      </div>
    </div>
  );
};

export default SearchBox;