import { useState } from "react";
import { theme } from "antd";
import { Contact, ContactType } from "./types";
import { useProfileStore } from "store";
import { DisplayContacts } from "./DisplayContacts";
import { AddContact } from "./AddContact";

export interface ContactEditorProps {
  type: ContactType;
  contacts: Contact[];
}

export const ContactEditor = (props: ContactEditorProps) => {
  const { updatePrimaryEmail, updatePrimaryPhone, removeEmail, removePhone } =
    useProfileStore((state) => ({
      updatePrimaryEmail: state.updatePrimaryEmail,
      updatePrimaryPhone: state.updatePrimaryPhone,
      removeEmail: state.removeEmail,
      removePhone: state.removePhone,
    }));

  const { token } = theme.useToken();
  const [addContact, setAddContact] = useState<boolean>(false);
  const onAddComplete = async (contact: Contact) => {
    setAddContact(false);
  };

  const onRemove = async (contact: Contact) => {
    (await props.type) === ContactType.Email
      ? removeEmail(contact.value)
      : removePhone(contact.value);
  };

  const onMakePrimary = async (contact: Contact) => {
    (await props.type) === ContactType.Email
      ? updatePrimaryEmail(contact.value)
      : updatePrimaryPhone(contact.value);
  };

  return (
    <div style={{ margin: token.margin }}>
      {addContact ? (
        <AddContact
          type={props.type}
          onClose={async () => setAddContact(false)}
          onAddComplete={onAddComplete}
        />
      ) : (
        <DisplayContacts
          contacts={props.contacts}
          type={props.type}
          onAdd={async () => setAddContact(true)}
          onRemove={onRemove}
          onMakePrimary={onMakePrimary}
        />
      )}
    </div>
  );
};
