import { DataType } from "@textea/json-viewer";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { Edge, EdgeInfraType, EdgeManageType, PageInfo } from "types";

export const EdgeStore = "edge-store";

export interface EdgeStore {
    edges: Edge[];
    edgeEndpointsMap: Record<string, string>;
    selectedEdge: Edge;
    edgePage: PageInfo;
    edgeFilter: Record<string, FilterValue | null>;
    edgeSorter: SorterResult<DataType> | SorterResult<DataType>[];
    edgeSearchTextMap: Map<string, string>;
    totalEdgesCount: number;

    getEdges: (paginate?: boolean, infraType?: EdgeInfraType, manageType?: EdgeManageType) => Promise<Edge[]>;
    clearEdges: () => void;
    resetEdgeStore: () => void;

    getEdgeEndpoint: (edgeId: string) => Promise<string>;
    getSelectedEdge: (edgeId: string) => Promise<Edge>;
    clearSelectedEdge: () => void;

    createEdge: (edge: Edge, deployNow?: boolean) => Promise<Edge>;
    updateEdge: (edge: Edge, deployNow?: boolean) => Promise<void>;
    deleteEdge: (edgeId: string) => Promise<void>;

    enableEdgeSubscription: (any: any) => Promise<void>;
    disableEdgeSubscription: (any: any) => Promise<void>;

    setCurrentPage: (pageNumber?: number, pageSize?: number) => void;
    setEdgeFilter: (filters?: Record<string, FilterValue | null>) => void;
    setEdgeSorter: (sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => void;
    setEdgeSearchTextMap: (searchText: Map<string, string>) => void;
}