import { FC, useEffect, useState } from "react";


import Modal from "../../components/Modal";
import { Form, FormInstance, Input, Radio, Space, Tree, Typography } from "antd";
import { FieldLabel } from "components/FieldLabel";
import WorkflowParameters from "components/HyprFlows/WorkflowParameters";
import { TriggerType, Workflow,  WorkflowRunInput } from "types";
import { validateFormFields } from "utility";
import { DataNode } from "antd/es/tree";
import { getTriggerProviderApi } from "api";
import { 
  JsonViewer, 
  applyValue,
} from '@textea/json-viewer'
import { useSettingsStore } from "store";

const { Text } = Typography;

interface RunInputModalProps {
  open: boolean;
  testMode: boolean;
  selectedWorkflow: Workflow;
  onClose: () => void;
  onSubmit: (runInput: WorkflowRunInput) => void
}

const RunInputModal: FC<RunInputModalProps> = ({
    open,
    testMode,
    selectedWorkflow,
    onClose,
    onSubmit
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const lightMode = useSettingsStore((state) => state.lightMode);
  const [form] = Form.useForm();
  const [workflowParamsForm, setWorkflowParamsForm] = useState<undefined | FormInstance>(undefined);
  const [triggerOutputs, setTriggerOutputs] = useState<Record<string, any> | undefined>();
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [appInfo, setAppInfo] = useState<{providerId: string, subscriptionId: string}>({
    providerId: selectedWorkflow.triggerRef.providers[0]?.providerID, 
    subscriptionId: selectedWorkflow.triggerRef.providers[0]?.appSubscriptionInfos[0].appSubscriptionID
  });
  
  const loadTriggerProvider = async () => {
    try {
      setLoader(true);
      const triggerProvider = await getTriggerProviderApi(appInfo.providerId);
      if (triggerProvider && triggerProvider.sampleOutputs != "") {
        setTriggerOutputs(JSON.parse(atob(triggerProvider.sampleOutputs)));
      } else {
        setDisableSubmit(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    (appInfo.providerId != "") && loadTriggerProvider();
  }, [appInfo.providerId]);

  const getSelectTree = () => {
    const treeData: DataNode[] = [];
    selectedWorkflow?.triggerRef?.providers?.map((provider) => {
      treeData.push({
        key: provider.providerID,
        title: provider.displayName,
        isLeaf: false,
        children: provider.appSubscriptionInfos?.map((appSubscriptionsInfo) => ({
          key: appSubscriptionsInfo.appSubscriptionID,
          title: (
            <Radio
              value={appSubscriptionsInfo.appSubscriptionID}
              onChange={(e: any) => {
                setAppInfo({
                  providerId: provider.providerID,
                  subscriptionId: e.target.value as string,
                });
              }}
            >
              {appSubscriptionsInfo.name}
            </Radio>
          ),
        }))
      });
    });
    return treeData;
  };

  const onRun = () => {
    form.validateFields()
      .then((values) => {
        const runInput = {
          runName: values.runName,
          parametersValues: values.workflow_parameters,
        } as WorkflowRunInput;

        console.log("values", values);

        if (selectedWorkflow.triggerRef.triggerType != TriggerType.Custom) {
          runInput.triggerProviderID = selectedWorkflow.triggerRef.providers[0].providerID
          runInput.edgeID = selectedWorkflow.triggerRef.providers[0].appSubscriptionInfos[0].edgeID
        } else {
          runInput.triggerProviderID = appInfo.providerId;
          const provider = selectedWorkflow?.triggerRef?.providers?.find((provider) => provider.providerID == appInfo.providerId);
          if(provider) {
            runInput.appID = provider.appID;
            runInput.appSubscriptionID = appInfo.subscriptionId;
            const subscription = provider.appSubscriptionInfos.find((s) => s.appSubscriptionID == appInfo.subscriptionId);
            if (subscription) {
              runInput.edgeID = subscription.edgeID;
            }
          }
        }
        if (triggerOutputs) {
          runInput.triggerOutputsValues = triggerOutputs;
        }
        onSubmit(runInput);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Modal
      title={testMode ? "Workflow Test Execution" : "Workflow Execution"}
      onClose={() => onClose()}
      loader={loader}
      open={open}
      footerName="Run"
      disableSubmit={disableSubmit}
      onSubmit={onRun}
    >
      <Form
        form={form}
        name="workflowRunInputForm"
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          name={"runName"}
          label={<FieldLabel label={"Name"} />}
          validateTrigger="onSubmit"
          initialValue={
            testMode
              ? "workflow_test_execution_" + selectedWorkflow?.id?.split("-")[0]
              : "workflow-execution-" + selectedWorkflow?.id?.split("-")[0]
          }
        >
          <Input />
        </Form.Item>
        {selectedWorkflow?.parameters?.length > 0
          &&
          <Form.Item
            name="workflow_parameters"
            label={<FieldLabel label={"Parameters"} />}
            validateTrigger="onSubmit"
            rules={[
              { validator: (_, value) => validateFormFields(workflowParamsForm) },
            ]}
          >
            <WorkflowParameters
              editMode={true}
              parameters={selectedWorkflow?.parameters}
              parameterSuggestionsTree={[]}
              onRender={(form) => setWorkflowParamsForm(form)}
            />
          </Form.Item>
        }
        {selectedWorkflow?.triggerRef?.triggerType == TriggerType.Custom
          &&
          <Form.Item
            name="appInfo"
            label={<FieldLabel label={"App"} />}
            validateTrigger="onSubmit"
            initialValue={selectedWorkflow.triggerRef.providers[0].appSubscriptionInfos[0].appSubscriptionID}
            rules={[
              { validator: (_, value) => value && value != "" ? Promise.resolve() : Promise.reject("App should be selected") },
            ]}
          >
            <Radio.Group value={appInfo?.subscriptionId}>
              <Tree
                defaultExpandAll
                treeData={getSelectTree()}
              />
            </Radio.Group>
          </Form.Item>
        }
        {selectedWorkflow?.triggerRef?.triggerType == TriggerType.Custom
          &&
          <Space direction="vertical">
            <FieldLabel label={"Trigger Outputs"} />
            {triggerOutputs
              ?
              <JsonViewer
                rootName={false}
                editable={true}
                displayDataTypes={false}
                displaySize={false}
                enableClipboard={false}
                value={triggerOutputs}
                theme={lightMode ? "light" : "auto"}
                highlightUpdates={true}
                sx={{paddingLeft: 2}}
                onChange={(path, oldValue, newValue) => {
                  const updatedJsonData = applyValue(triggerOutputs, path, newValue);
                  setTriggerOutputs(updatedJsonData);
                }}
              />
              :
              <Text type="danger">Sample outputs are not provided by app</Text>
            }
          </Space>
        }
      </Form>
    </Modal>
  );
};

export default RunInputModal;
