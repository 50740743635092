import { FC, useState } from "react";
import PageWrapper from "../../components/PageWrapper";

import { Tabs, type TabsProps, Button, theme } from "antd";
import { CustomDashboardTab } from "components/Dashboard/CustomDashboardTab";
import { HyprEdgeDashboard } from "components/Dashboard/HyprEdgeDashboard";
import { DashboardNew } from "components/Dashboard/DashboardNew";

const items: TabsProps["items"] = [
  {
    key: "hypredge-dashboard",
    label: "HyprEdge Dashboard",
    children: <HyprEdgeDashboard />,
  },
  {
    key: "custom-dashboard",
    label: "My Dashboards",
    children: <CustomDashboardTab />,
  },
];

const DashBoardWrap: FC = () => {
  const { token } = theme.useToken();
  const [newDashboard, setNewDashboard] = useState(false);
  return (
    <PageWrapper
      content={
        <>
          <Tabs
            defaultActiveKey="hypredge-dashboard"
            items={items}
            style={{ width: "100%" }}
            tabBarExtraContent={
              <Button
                style={{ margin: token.marginSM }}
                onClick={() => setNewDashboard(true)}
              >
                Add dashboard
              </Button>
            }
          />
          {newDashboard && (
            <DashboardNew
              open={newDashboard}
              onClose={async () => setNewDashboard(false)}
            />
          )}
        </>
      }
    />
  );
};

export default DashBoardWrap;
