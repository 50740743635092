
import { type FC, type CSSProperties, useMemo } from "react";
import { Card, Space, Tooltip, theme, Typography } from "antd";
import { useDragLayer, useDragDropManager, XYCoord } from 'react-dnd'
import { PlusOutlined } from "@ant-design/icons";
import { DraggableItem } from "types";
import { SvgIcon } from "components/SvgIcon";
import { getStepColor, getStepLogo, getTitle } from "utility/workflow";

import { useNodeStyles } from "../styles";
import { Dimensions } from "components/HyprFlows/constant";
import { useSettingsStore } from "store";

const { Text } = Typography;
const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function getItemStyles(
  initialCursorOffset: XYCoord | null,
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset || !initialCursorOffset) {
    return {
      display: "none",
    };
  }

  const x = initialCursorOffset?.x + (currentOffset.x - initialOffset.x);
  const y = initialCursorOffset?.y + (currentOffset.y - initialOffset.y);
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

const NodeDragPreview: FC = () => {
  const { token } = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const commonStyles = useNodeStyles();
  const manager = useDragDropManager();
  const monitor = manager.getMonitor();
  const targetIds = monitor.getTargetIds();
  const isOver = useMemo(() => {
    return targetIds.some((id) => monitor.canDropOnTarget(id));
  }, [monitor, targetIds]);


  const {
    item,
    isDragging,
    initialCursorOffset,
    initialFileOffset,
    currentFileOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem() as DraggableItem,
    itemType: monitor.getItemType(),
    initialCursorOffset: monitor.getInitialClientOffset(),
    initialFileOffset: monitor.getInitialSourceClientOffset(),
    currentFileOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  const nodeBandColor = getStepColor(item.nodeType);
  const dragOverLayStyle: CSSProperties = {
    opacity: isOver ? 1 : "0.5",
  };

  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(
          initialCursorOffset,
          initialFileOffset,
          currentFileOffset
        )}
      >
        <Card.Grid
          className={commonStyles.node}
          style={{
            ...dragOverLayStyle,
            backgroundColor: token.colorBgBase,
            borderRadius: token.sizeXS,
            border: "1px solid",
            borderLeftWidth: token.sizeXXS,
            borderLeftColor: nodeBandColor,
            borderRightColor: token.colorBorder,
            borderTopColor: token.colorBorder,
            borderBottomColor: token.colorBorder,
            position: "fixed",
            cursor: "grabbing"
          }}
        >
          <Space size={token.sizeXXS}>
            <SvgIcon
              size={40}
              style={{
                marginTop: token.sizeXXS,
                marginLeft: token.sizeXXS,
                height: "40px",
                draggable: "false"
              }}
              component={getStepLogo(item.nodeType, item.resourceType)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: token.sizeXS,
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: token.colorTextLabel,
                  marginTop: token.sizeXXS,
                }}
                ellipsis
              >
                {getTitle(item.nodeType, item.resourceId)}
              </Text>
            </div>
            {isOver &&
              <div
                style={{
                  width: Dimensions.dropzoneNodeActiveWidthHeight,
                  height: Dimensions.dropzoneNodeActiveWidthHeight,
                  position: "absolute", right: "5px", top: "14px",
                  color: token.colorPrimary,
                  background: lightMode ? token.colorPrimary : "#DED7D7",
                  borderRadius: Dimensions.dropzoneNodeVisibleWidthHeight,
                }}
              >
                <PlusOutlined
                  style={{
                    color: token.colorText,
                    margin: "3px",
                    zIndex: "3",
                  }}
                  size={5}
                />
              </div>
            }
          </Space>
        </Card.Grid>
      </div>
    </div>

  );
}

export default NodeDragPreview;