import { FC, useState, useEffect, useReducer } from "react";

import {
  Form,
  Button,
  Space,
  FormInstance,
  theme,
  Input,
  Spin,
  Row,
  Typography,
  notification,
} from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";

import {
  IODataType,
  WorkflowNodeCardProps,
  OperatorKVItems,
  WorkflowStepOperator,
  TriggerType,
} from "types";
import Modal from "components/Modal";
import CollapsePanel from "components/CollapsePanel";

import { FieldLabel } from "components/FieldLabel";

import { validateFormFields } from "utility";
import { useWorkflowStore } from "store";
import { generateReferenceName } from "utility/workflow";
import { buildStepOutputTree, buildTriggerOutputTree, buildWorkflowParameterTree } from "components/Suggestions";
import ConditionsForm from "components/ConditionsForm";
import KVItem from "./KVItem";

const { Text } = Typography;

interface DeleteItemProps {
  enable: boolean;
  index?: number;
  key?: string;
}

const KVOperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  resourceType,
  nodeType,
  editMode,
  onClose,
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [conditionsForm, setConditionsForm] = useState<undefined|FormInstance>(undefined);
  const [currentItems, setCurrentItems] = useState<OperatorKVItems>([] as OperatorKVItems);
  const [deleteItemProps, setDeleteItemProps] = useState<DeleteItemProps>({ enable: false });
  
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  
  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(
    selectedWorkflow?.steps[id]?.referenceName
  );

  const [itemsForms, dispatch] = useReducer(
    (itemsForms: FormInstance[], action: any) => {
      switch (action.type) {
        case "add": {
          const forms = [...itemsForms];
          forms.splice(action.key, 0, action.form);
          return forms;
        }
        case "delete": {
          const forms = [...itemsForms];
          forms.splice(action.key, 1);
          return forms;
        }
        default:
          throw new Error(`Unknown action type: ${action.type}`);
      }
    },
    []
  );

  const syncCurrentState = async () => {
    const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
    if (operatorStep) {
        form.setFieldsValue({ 
            conditions: operatorStep.parameters.conditions,
        });
        setCurrentItems(operatorStep.parameters.items);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
  }, [selectedWorkflow]);

  const onKVItemAdd = () => {
    let items = currentItems ? currentItems.map((_, index) =>  form.getFieldValue(["items", index])) : [] ;
    items = [...items, {key: `Key_${items.length}`, type: IODataType.String, value: ""}];
    //form.setFields([{name:["items"], value: items}]);
    setCurrentItems(items);
  };

  const onKVItemDelete = () => {
    if (deleteItemProps.index != undefined) {
      const items = currentItems.map((_, index) =>  form.getFieldValue(["items", index]));
      items.splice(deleteItemProps.index, 1);
      dispatch({type: "delete", key: deleteItemProps.index});
      form.resetFields(["items", deleteItemProps.index]);
      //form.setFields([{name:["items"], value: items}]);
      setCurrentItems(items);
      setDeleteItemProps({enable: false});
    }
  };
  
  useEffect(() => {
    form.setFields([{name:["items"], value: currentItems}]);
  }, [currentItems]);


  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
        operatorStep.isConfigured = true;
        operatorStep.name = values.name;
        operatorStep.referenceName = referenceName;

        operatorStep.parameters = {};
        operatorStep.parameters.items = currentItems;

        if (values.conditions) {
            operatorStep.parameters.conditions = values.conditions;
        }

        selectedWorkflow.steps[id] = operatorStep;
        updateSelectedWorkflow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Form
        form={form}
        name="kvForm"
        layout="vertical"
        autoComplete="off"
        onFinish={OnFinish}
        onValuesChange={(changedValues: any, _: any) => {
          const values = form.getFieldsValue(true);
          values.items && values.items.length > 0 && setCurrentItems(values.items);
        }}
      >
        <Spin spinning={loader}>
            <Space direction="vertical" style={{ display: "flex" }}>
              <Form.Item
                name="name"
                label={<FieldLabel label={"Name"} help={"Operator Name"} />}
                initialValue={selectedWorkflow.steps[id]?.name}
                rules={[{ required: true, message: "Name is required!" }]}
                extra={
                    referenceName != "" && (
                    <Text italic type="secondary">
                        Reference Name: {referenceName}
                    </Text>
                    )
                }
                >
                <Input
                  disabled={!editMode}
                  onChange={(e) =>
                  setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))}
                />
              </Form.Item>
              <Form.List 
                name="items"
                rules={[
                    {
                      validator: (_, value) => currentItems.length > 0 ? Promise.resolve() : Promise.reject(),
                      message: "Items are required!"
                    },
                ]}
              >
                {(fields) => (
                  <Space direction="vertical" style={{ display: "flex" }}>
                    {fields.map((field) => (
                      <CollapsePanel
                        key={field.key}
                        name={currentItems[field.key].key}
                        collapsePanel={false}
                        extraElement={
                          editMode && (
                          <Button
                            type="default"
                            shape="circle"
                            size="small"
                            style={{ background: token.colorPrimaryBg }}
                            icon={ <DeleteFilled style={{ color: token.colorPrimary }}/> }
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setDeleteItemProps({
                                  enable: true,
                                  index: field.key,
                                  key: currentItems[field.key].key,
                              });
                            }}
                          />
                          )
                        }
                      >
                        <Form.Item
                          {...field}
                          key={field.key}
                          name={[field.key]}
                          rules={[
                            {
                                validator: (_, value) =>
                                validateFormFields(itemsForms[field.key]),
                            },
                          ]}
                          validateTrigger="onSubmit"
                        >
                          <KVItem
                            editMode={editMode}
                            item={currentItems[field.key]}
                            suggestionsTree={parameterSuggestionsTree}
                            onRender={(form) =>
                                dispatch({
                                  type: "add",
                                  key: field.key,
                                  form: form,
                                })
                            }
                          />
                        </Form.Item>
                      </CollapsePanel>
                    ))}
                    {editMode && (
                      <Form.Item>
                        <Button
                          type="default"
                          style={{ background: token.colorPrimaryBg }}
                          onClick={onKVItemAdd}
                          block
                          icon={<PlusOutlined />}
                        >
                          Item
                        </Button>
                      </Form.Item>
                    )}
                  </Space>
                )}
              </Form.List>
              {currentItems?.length > 0 
                && 
                <Form.Item 
                  name="conditions"
                  label={<FieldLabel label={"Conditions"} help={"Conditions to build kv, optional"} />}
                  rules={[{ validator: (_, value) => validateFormFields(conditionsForm) }]}
                  validateTrigger="onSubmit"
                >
                  <ConditionsForm
                    editMode={editMode}
                    required={false}
                    conditions={selectedWorkflow?.steps[id]?.parameters?.conditions ? selectedWorkflow?.steps[id]?.parameters?.conditions : undefined}
                    factSuggestionsTree={parameterSuggestionsTree}
                    onRender={form => setConditionsForm(form)}
                  />
                </Form.Item>
              }
            </Space>
        </Spin>
        {editMode && (
          <Row justify="space-between" style={{ marginTop: token.margin }}>
            <Button key="cancel" onClick={onClose}>
              Cancel
            </Button>
            <Button key="save" type="primary" htmlType="submit" size="middle">
              Save
            </Button>
          </Row>
        )}
      </Form>
      {deleteItemProps.enable && (
        <Modal
          title="Delete Parameter"
          onClose={() => {
            setDeleteItemProps({ enable: false });
          }}
          open={deleteItemProps.enable}
          onSubmit={() => onKVItemDelete()}
        >
          {'Are you sure you want to delete workflow parameter "' +
            `${deleteItemProps?.key}` +
            '" ?'}
        </Modal>
      )}
    </>
  );
};

export default KVOperatorCard;
